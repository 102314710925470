.products-sort-order{
    position: relative;
    ul{
        padding: 0;
        li{
            list-style: none;
            display: inline-block;
            margin: 5px;
            font-size: 14px;
            &.sort-selected{
                background-color: $primario2;
                color:white;
                padding: 5px 10px;
                border-radius: 2px;
                display: block;
                width: 25%;
            }
            a{
                padding: 5px 10px;
                background-color: $gris2;
                border-radius: 2px;
                display: inline-block;
                color: $gris6;
            }
        }
    }
    select{
        border:none;
        background-color: white;
        font-size: $font-xxs;
        position: absolute;
        right: 0;
        top: -18px;
    }
    @media(max-width: $screen-md){
        select{
            border: none;
            background-color: #eee;
        }
    }
}
