#index{
    .banner-campania{
        @include djv-container();
        img{
            width: 100%;
            @media(max-width: $screen-md){
                width: calc(200% - 60px);
                margin-left: calc(-50% + 30px);
            }
        }
    }
    .propuestas-valor{
        @include djv-container();
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        text-align: center;
        margin-top: 40px;
        border-bottom: 1px solid $gris2;
        @media(max-width: $screen-md){
            margin-top: 15px;
            flex-flow: row wrap;
        }
        .propuestas-valor-item{
            padding: 10px;
            [class*='gol-icon']:before{
                font-size: $font-xxxl + 5px;
                    @media(max-width: $screen-md){
                        display: none;
                    }
            }

            @media(max-width: $screen-md){
              max-width: 33%;
              img{
                display: none;
              }
            }

            h3{
                font-size: $font-md + 1px;
                @include fuente($font-second,$font-regular);
                    @media(max-width: $screen-md){
                        @include fuente($font-second,$font-regular);
                        font-size: $font-md - 2px;
                    }
            }
            p{
                @include fuente($font-first,$font-light);
                font-size: $font-sm - 1px;
                @media(max-width: $screen-md){
                    font-size: $font-xs - 1px;
                }
            }
        }
    }
    .djv-listas-productos{

    }
    .valoraciones-portada{
        background-color: $gris1;
        padding-top: 30px;
        padding-bottom: 30px;
        margin-top: 50px;
        h3{
            text-align: center;
            @include fuente($font-second,$font-regular);
            font-size: $font-xl;
        }
        .valoraciones-portada-container{
            @include djv-container();
            display: grid;
            grid-template-columns: 50% 50%;
            padding-top: 40px;
            @media(max-width: $screen-md){
                display: block;
                padding-top: 15px;
            }
        }
        .eKomiContainer{
            background-image: url("#{$urlimagenes}ekomi_home.png");
            background-repeat: no-repeat;
            background-position-y: 85px;
            background-position-x: 110px;
            height: 320px;
            padding: 0 15px;
            text-align: center;
            @media(max-width: $screen-md){
                background-image: none;
                margin-bottom: 20px;
            }
            .ekomi_home_title{
                @include fuente($font-first,$font-light);
                font-size:$font-xl - 3px;
            }
            .ekomi_home_text{

            }
            #eKomiWidget_default{
                > a {
                    margin-left: 120px;
                    @media(max-width: $screen-md){
                        margin-left: 0;
                    }
                }
            }
            .ver-todas{
                @include fuente($font-first,$font-regular);
                font-size: $font-xs;
                border: 1px solid $gris9;
                color:$gris9;
                display: block;
                margin: 25px auto auto;
                text-align: center;
                width: 300px;
                padding: 5px;
                @media(max-width: $screen-md){
                    width: 100%;
                    padding: 5px 40px;;
                }
            }
        }
        .ekomi_last_comments{
            padding: 0 15px;
            text-align: center;
            .ekomi_home_title{
                @include fuente($font-first,$font-light);
                text-align: center;
                font-size:$font-xl - 3px;
            }
            .ekomi_home_comments_container {
                width: 100%;
                display: block;
                overflow: hidden;
                position: relative;
                &.djv_carouselh{

                }
                .djv_carouselh-viewer .djv_carouselh-left-arrow,
                .djv_carouselh-viewer .djv_carouselh-right-arrow{
                    background-color: transparent;
                    border: none;
                    &::before{
                        top:48%;
                        font-size: $font-md;
                        background-color: #ffffffb3;
                        @media(max-width: $screen-md){
                            color:black;
                        }
                    }
                }
                .djv_carouselh-viewer .djv_carouselh-left-arrow{
                    &::before{
                        left: 5px;
                    }
                }
                .djv_carouselh-viewer .djv_carouselh-right-arrow{
                    &::before{
                        right:5px;
                    }
                }
            }
            .comments-home-list{
                padding: 0;
                position: absolute;
                transition: left 1s;
                @media(max-width: $screen-md){
                    width: 100%;
                    overflow-x: auto;
                    overflow-y: hidden;
                    white-space: nowrap;
                    -webkit-overflow-scrolling: touch;
                    -webkit-scroll-snap-type: mandatory;
                    scroll-snap-type: x mandatory;
                    -webkit-scroll-snap-points-x: repeat(100%);
                    scroll-snap-points-x: repeat(100%);
                    position: relative;
                }
                .comment{
                    display: block;
                    float: left;
                    background-color: white;
                    text-align: left;
                    min-width: 555px;
                    width: 555px;
                    @media(max-width: $screen-md){
                        scroll-snap-align: start;
                        width: 100%;
                        min-width: auto;
                        position: relative;
                        display: inline-block;
                        margin-right: -4px;
                        text-align: center;
                        border-right: 0;
                        float: none;
                    }
                    .coment-header{
                        padding-top: 15px;
                        overflow: auto;
                        margin-bottom: 15px;
                        .comment_image{
                            float: left;
                            margin-right: 25px;
                            margin-left: 25px;
                            @media(max-width: $screen-md){
                                margin-left: 0;
                                margin-right: 0px;
                                margin-bottom: 0px;
                                float: none;
                            }
                            img{
                                max-width: 90px;
                            }
                        }
                        .comment_title{
                            @include fuente($font-first,$font-medium);
                            font-size: $font-xs;
                            color:$gris10;
                            padding-top: 10px;
                            @media(max-width: $screen-md){
                                font-size: $font-xxs ;
                            }
                        }
                        .comment_author{
                            @include fuente($font-first,$font-light);
                            font-size: $font-xs - 1px;
                            color: $gris7;
                            @media(max-width: $screen-md){
                                font-size: $font-xxxs - 2px;
                            }
                        }
                    }
                    .comment_body{
                        background-color: $gris7;
                        padding: 25px 20px 20px;
                        clear: both;
                        min-height: 130px;
                        position: relative;
                        &::before{
                            content: "";
                            width: 0;
                            height: 0;
                            border-left: 10px solid transparent;
                            border-right: 10px solid transparent;
                            border-bottom: 10px solid $gris7;
                            position: absolute;
                            top: -10px;
                            left: 50%;
                            transform: translateX(-50%);
                        }
                        .comment-text{
                            color: white;
                            font-size: $font-xs;
                            font-style: italic;
                            padding: 5px 15px;
                        }
                    }
                }
            }
        }
    }
    .djv-home-propuestasvalor {
        background-image: url("#{$cdnserver1}/themes/base/assets/img/parallax-home.jpg");
        text-align: center;
        background-attachment: fixed;
        background-position: bottom;
        background-size: cover;
        background-repeat: no-repeat;
        @media(max-width: $screen-md){
            background-image: url("#{$cdnserver1}/themes/base/assets/img/parallax-home_mobile.jpg");
        }
        .djv-home-propuestasvalor-container {
            @include djv-container();
            display: flex;
            flex-direction: row;
            .djv-home-propuestasvalor-element {
                margin: 0 15px;
                background-color: rgba(255,255,255,0.65);
                //background-color: $primario8;
                padding: 40px 20px;
                flex: 1;
                @media(max-width: $screen-md){
                    padding: 20px;
                }

                i {
                    font-size: $font-xxxl*1.2;
                    color: $primario8;
                    margin-bottom: 10px;
                    display:block;
                    &.djv-icon-calidad{
                        font-size: $font-xxxl*1.1;
                    }
                }

                h3 {
                    font-size: $font-xl - 2px;
                    @include fuente($font-second,$font-regular);
                    color: $primario8;
                    font-weight: 600;
                    position: relative;
                    margin-top: -17px;
                    margin-bottom: 5px;
                }

                p {
                    font-size: $font-xs;
                }

                a {
                    color: inherit;
                    font-size: $font-xxs;
                }
            }
        }
    }
    .djv-home-categories-shortcut {
        @include djv-container();
        text-align: center;
        margin-bottom: 50px;
        .djv-home-categories-shortcut-explain {
            font-size: $font-sm + 1px;
            margin-top: 0px;
            @include fuente($font-first,$font-light);
            line-height: auto;
            margin-bottom: 20px;
        }
        .djv-home-categories-shortcut-claim {
            @include fuente($font-second,$font-regular);
            font-size: $font-xl;
            margin: 10px;
        }
        .djv-home-categories-shortcut-container {
            @include grid(4,15);
            @media (max-width:768px) {
                display: block;
                width: 100%;
                .djv-home-categories-shortcut-element {
                    margin-bottom: 10px;
                    width: 100%;
                }
            }
            img{
                max-width: 100%;
                @media(max-width: $screen-md){
                    display: none;
                }
            }
            .djv-home-categories-shortcut-element {
                border: 1px solid $gris2;
                padding: 0 0 6px 0;
                @media(max-width: $screen-md){
                    background-image: none !important;
                    border: 2px solid $gris4;
                    border-radius: 50px;
                    min-height: auto;
                }
                .djv-home-categories-shortcut-element-wrapper {
                    color: black;
                    width: 100%;
                    @media(max-width: $screen-md){
                        margin-top: 0;
                        padding: 10px 5px;
                    }
                    h3 {
                        font-size: $font-bmd ;
                        @include fuente($font-second,$font-semibold);
                        position: relative;
                        margin-top: 5px;
                        margin-bottom: 5px;
                        color: black;
                        @media(max-width: $screen-md){
                            color:$primario7;
                            text-decoration: underline;
                            font-size: $font-md - 1px;
                        }
                    }
                    p {
                        font-size: $font-xs + 1px;
                        @include fuente($font-first,$font-light);
                        @media(max-width: $screen-md) {
                            font-size: $font-xs - 1px;
                            color:$gris8;
                            margin-bottom: 0px;
                        }
                    }
                    a{
                        color:$primario7;
                    }
                }
            }
        }
    }
    .mejor-tienda{
        @include djv-container();
        text-align: center;
        margin-bottom: 20px;
        .mejor-tienda-header{
            margin-bottom: 20px;
            img{
                width: 80px;
            }
            h2{
                @include fuente($font-second, $font-regular);
                font-size: $font-lg;
            }
            p{
                font-size: $font-sm;
            }
        }
        .mejor-tienda-grid{
            @include grid(2,0);
            grid-row-gap: 0;
            width: 100%;
            >div{
                border-top: 1px solid $gris2;
                border-bottom: 1px solid $gris2;
                padding: 16px 0;
                margin: 0 10px;
                &:nth-of-type(1),&:nth-of-type(2){
                    border-bottom: 0;
                }
                h3{
                    @include fuente($font-second, $font-regular);
                    font-size: $font-md;
                    color: $primario6;
                }
                p{
                    font-size: $font-sm - 2px;
                    width: 80%;
                    display: block;
                    margin: 0 auto;
                    a{
                        color: $primario6;
                    }
                }
            }
        }

    }
    @media (max-width:768px) {
        .djv-home-propuestasvalor {
            background-attachment:inherit;
            .djv-home-propuestasvalor-container {
                display: block!important;
                padding: 15px !important;
                #h1Portada{
                    text-align: center;
                    @include fuente($font-second,$font-regular);
                    font-size: $font-lg + 2;
                }
                #claimPortada{
                    color:inherit;
                }
                .djv-home-propuestasvalor-element {
                    display: block;
                    width: 95%;
                    margin: 20px auto !important;
                }
            }
        }
    }

}
