/* #### Visualizaciones #### */
.xs-visible{
    @media(max-width: $screen-md){
        display: block!important;
    }
}

/* #### BOTONES #### */

// TODO: convertir a mixin el boton animado

.btn-download{
    font-size: $font-sm;
    padding: 5px 10px;
    background-color: $complementario4;
    color: white;
    border-radius: 5px;
    padding: 5px 32px 5px 10px;
    position: relative;
    &::after{
        font-family: 'gamalux';
        font-style: normal;
        font-weight: normal;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        content:'\004b';
        background-color: white;
        color:$complementario4;
        position: absolute;
        right: 8px;
        top: 4px;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        font-size: $font-xs;
        padding: 5px 0px 2px;
    }
}

/* ···· FIN BOTONES ···· */

.etiqueta-flecha{
    -webkit-clip-path: polygon(0% 0%, 72% 0, 100% 50%, 72% 100%, 0% 100%);
    clip-path: polygon(0% 0%, 72% 0, 100% 50%, 72% 100%, 0% 100%);
}

/* #### LAYOUT #### */
.djv-container{
    width: 95%;
    margin-right: auto;
    margin-left: auto;
    max-width: 1170px;
    min-width: 920px;
    @media(max-width:$screen-lg){
        max-width: 920px;
        min-width: 750px;
    }
    @media(max-width:$screen-md){
        max-width: 750px;
        min-width: 480px;
    }
    @media(max-width:$screen-xs){
        max-width: 500px;
        min-width: 300px;
    }
}
.djv-container-stickytop{
    margin-top: 200px;
}
/* ---- grid ---- */
.grid-2-col{
    display: grid;
    grid-template-columns: calc(50% - 20px) calc(50% - 20px);
    grid-gap: 10px 40px;
}

/* ···· FIN BOTONES ···· */

/* #### TITULARES #### */
.djv-titulos-barras-laterales{
    display: grid;
    grid-template-columns: auto 30% auto;
    position: relative;
    @media(max-width: $screen-md){
        grid-template-columns: auto 80% auto;
    }
    &::before{
        content: "";
        border-bottom: 1px solid $gris3;
        display: block;
        width: 100%;
        margin-right: 20px;
        position: relative;
        transform: translateY(-50%);
    }
    &::after{
        content: "";
        border-bottom: 1px solid $gris3;
        display: block;
        width: 100%;
        margin-right: 20px;
        position: relative;
        transform: translateY(-50%);
    }
}
.djv-titulos-left-linea-discontinua{
    font-size: $font-lg;
    @include fuente($font-first,$font-medium);
    padding: 10px 0 15px 5px;
    border-bottom: 1px dashed $gris7;
    margin-bottom: 20px;
    text-transform: uppercase;
}
/* ···· FIN TITULARES ···· */
/* #### BLOQUES #### */
.cms-block-example{
    background-color: $gris1;
    display: block;
    padding: 30px;
    margin: 16px 0px;
    outline:1px dashed $gris3;
    outline-offset:-10px;
}
/* ···· FIN BLOQUES ···· */

/* #### BORDES #### */
.djv-border-top-dashed{
    border-top: 1px dashed $gris3;
    padding-top: 20px;
    margin-top: 20px;
}
.djv-border-top-solid{
    border-top:  1px solid $gris3;
    padding-top: 20px;
    margin-top: 20px;
}
.djv-border-bottom-dashed{
    border-bottom: 1px dashed $gris3;
    padding-bottom:20px;
    margin-bottom:20px;
}
.djv-border-bottom-solid{
    border-bottom: 1px solid $gris3;
    padding-bottom:20px;
    margin-bottom:20px;
}
/* ···· FIN BORDES ···· */
