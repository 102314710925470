#index{
     .products{
        .product-miniature{
            border-right: 1px solid $gris2;
            padding-left: 10px;
            padding-right: 10px;
            text-align: center;
            position: relative;
            .product-description-short{
                p{
                    margin-bottom: 5px;
                }
            }
            .add-to-cart,.djv-ver-mas{
            }
            >a{
                img{
                    width: 100%;
                    max-width: 210px;
                }
            }
        }
    }
}
