
.product-information-extended{

}
.product-information-extended-heading {
    @include fuente($font-first,$font-regular);
    font-size: $font-lg;
    padding: 10px 0;
    color: black;
    border-bottom: 1px solid $gris2;
}

.product-information-extended-description {
    padding: 0;
    @include fuente($font-first,$font-light);
    font-size: $font-md*0.9;
    color: $gris8;
    h2{
        margin-top: 20px;
        margin-bottom: 0px;
        font-size: $font-lg;
        color: black;
    }
}

.product-information-extended-enviosdevoluciones {
    p {
        @include fuente($font-first,$font-light);
        font-size: $font-md*0.9;
        color: $gris8;

        a {
            font-size: $font-xs;
        }
    }
}
