#addresses{
    #main{
        @include djv-container();
        .page-addresses{
            display: grid;
            grid-template-columns: calc(33% - 16px) calc(33% - 16px) calc(33% - 16px);
            grid-gap: 16px;
            @media(max-width: $screen-md){
                display: block;
            }
            article{
                border: 1px dashed #dadada;
                padding: 8px;
                position: relative;
                .action-list{
                    padding-left: 0;
                    li{
                        display: inline-block;
                        a{
                        display: block;
                        padding: 3px;
                        text-align: center;
                        color: white;
                        font-size: 14px;
                            &[data-link-action="edit-address"]{
                                @include btn-hover-animated($primario5,white,2px,4px,10px);
                            }
                            &[data-link-action="delete-address"]{
                                @include btn-hover-animated($complementario2,white,2px,4px,10px);
                            }
                        }
                    }
                }
            }
        }
        .page-footer{
            @include djv-border-top(dashed);
            ul{
                padding: 0;
                li{
                    display: inline-block;
                    list-style: none;
                    @media(max-width: $screen-md){
                        display: block;
                        margin-bottom: 10px;
                    }
                    a{
                        @include btn-hover-animated($primario5,white,2px,4px,10px);
                        padding-left: 16px;
                        padding-right: 16px;
                        @media(max-width: $screen-md){
                            display: block;
                        }
                    }
                }
            }
        }
    }
}
#address{
    #main{
        @include djv-container();
        .form-fields{
            display: grid;
            grid-template-columns: repeat(3,calc(33.333% - 10px));
            grid-gap: 10px;
            @media(max-width: $screen-md){
                display: block;
            }
            label{
                @media(max-width: $screen-md){
                    display: block;
                    margin-bottom: 15px;
                }
                &.required{
                    &::before{
                        content: "*";
                        color:red;
                        position: absolute;
                    }
                    span{
                        padding-left: 9px;
                    }
                }
                span{
                    display: block;
                }
                input, select{
                    border: none;
                    background-color: #eee;
                    padding: 5px;
                    width: 100%;
                }
            }
            &::after{
                content: "Los campos marcados con un asterisco son obligatorios";
            }
        }
        .form-footer{
            @include djv-border-top(solid);
            button{
                @include btn-hover-animated($complementario1,white,2px,4px,10px);
            }
        }
        .page-footer{
            @include djv-border-top(solid);
            ul{
                margin: 0;
                padding: 0;
                li{
                    list-style: none;
                    display: inline-block;
                    a{
                        @include btn-hover-animated($primario5,white,2px,4px,10px);
                    }
                }
            }

        }
    }
}
