.product-variants{
	label{
		display:block;
		font-size: 14px;
	}

	.product-variants-tallas{
		ul{
			display: grid;
			grid-template-columns: auto auto;
			padding-left: 0;
			.product-variants-tallas-input-container{
				list-style: none;
				display: block;
				overflow: hidden;
				margin-bottom: 10px;
				cursor: pointer;
				.swap-bg{
					width: 24px;
					height: 24px;
					background-color: white;
					border: 1px solid #aaa;
					margin-right: 10px;
					display: inline-block;
					input{
						opacity:0;
					}
				}
				.attribute-name{
					background-color: #aaa;
					padding: 5px 15px;
					font-size: 14px;
				}
				&.selected{
					.swap-bg::before{
						content:"✔";
						position: absolute;
						margin-left: 5px;
					}
					.attribute-name{
						background-color: $primario3;
						color:white;
						padding: 5px 15px;
						font-size: 14px;
					}
				}
				&.disabled{
					position: relative;
					span{
						opacity: .5;
					}
				}
			}
		}
	}
	.product-variants-color{
		ul{
			padding-left: 0;
			.product-variants-color-input-container{
				list-style: none;
				display: inline-block;
				margin-bottom: 10px;
				width: 36px;
				height: 36px;
				cursor: pointer;
				.swap-bg{
					width: 36px;
					height: 36px;
					border: 1px solid #aaa;
					margin-right: 14px;
					display: inline-block;
					input{
						opacity:0;
					}
				}
				&.selected{
					position: relative;
					.swap-bg{
						outline: 5px solid rgba(100,100,100,.8);
	    				outline-offset: -5px;
					}
					&::before{
						content: "\2714";
						display: block;
						width: 20px;
						height: 20px;
						background-color: rgba(255,255,255,.9);
						color: $primario12;
						border-radius: 40px;
						position: absolute;
						z-index: 9;
						right: -5px;
						top: -5px;
						font-size: 14px;
						padding: 0px 4px;
					}
				}
				&.disabled{
					position: relative;
					span{
						opacity: .5;
					}
					&::after{
						content:"";
						display: block;
						width: 46px;
						height: 46px;

						position: absolute;
						z-index: 9;
						left: 50%;
						top: 50%;
						transform: translate(-50%, -50%);

						padding: 0px 4px;
						border-radius: 40px;
						background-image: url("/themes/base/assets/img/aspa.svg");
						font-size: 36px;
						color: $primario6;

					}
				}
			}
		}
	}
}
