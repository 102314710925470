.stars{
    color:$primario8;
    grid-area:stars;
    span{
      font-size: 12px;
      color: $gris7;
      display: inline-block;
      margin-top: 2px;
      position: absolute;
      margin-left: 4px;
    }
}
#product {
    .page-content {
        @include djv-container();
        @include fuente($font-first,$font-regular);
        margin:0px auto 0;
        padding-top: 20px;
        @media(max-width: $screen-sm) {
            width: calc(100vw - 10px);
        }
        .texto-adicional{
            display: none;
        }
        .product-first-section {
            display: grid;
            grid-gap: 10px 20px;
            grid-template-columns: repeat(2, calc(50% - 20px));
            align-items: start;
            @media(max-width: $screen-sm) {
                display: block;
            }
            &.not-for-sale{
                .product-buy-actions{
                    .djv-advertencia-descatalogado{
                        text-align: center;
                        color:red;
                    }
                    .product-accessories{
                        width: 100%;
                        .product-accessories-container.djv_carouselh article{
                            width: 250px;
                        }
                    }
                }

            }
            > div {
                padding:0 16px;
            }
        }
        .djv-product-images{
            grid-area: images;
        }
        .product-first-section-right{
            display: grid;
            grid-template-areas:
            "productName productName productName productManufacturer"
            "productReference stars stars productManufacturer"
            "actions actions actions actions"
            "descriptionshort descriptionshort descriptionshort descriptionshort"
            "condiciones condiciones condiciones condiciones"
            "social social social social ";
            grid-template-columns: 30% 25% 25% 20%;
            @media(max-width: $screen-md){
                display: block;
                padding: 16px;
            }
            #product-information-name {
                grid-area: productName;
                h1 {
                    @include fuente($font-first,$font-bold);
                    font-size: $font-xl;
                    @media(max-width: $screen-md){
                        font-size: $font-lg;
                    }
                }
            }
            #product-reference {
                grid-area: productReference;
                display: inline-block;
                font-size: $font-xxs;
                color: $gris5;
                @media(max-width: $screen-md){
                    width: auto;
                    margin-right: 5px;
                }
            }

            #product-manufacturer{
                grid-area: productManufacturer;
                @media(max-width: $screen-md){
                    display: inline-block;
                }
                #manufacturer_logo{
                    text-align: right;
                    display: block;
                    img {
                        max-height: 125px;
                        max-width: 125px;
                        width: 100%;
                        @media(max-width: $screen-md){
                            max-width: 80px;
                        }
                    }
                }
                #manufacturer_name{
                    display: none;
                }
            }
            .stars{
                grid-area: stars;
                padding-top: 10px;
                @media(max-width: $screen-md){
                    width: auto;
                    margin-right: 5px;
                }
            }
            .product-buy-actions{
                grid-area: actions;
                @include djv-border-top(solid);
                padding-bottom: 5px;
                margin-bottom: 5px;

            }
            #add-to-cart-or-refresh{
                display: grid;
                grid-template-areas:
                "prices availability addtocart"
                "prices quantity addtocart";
                grid-gap: 5px 15px;
                grid-template-columns: repeat(3,33%);
                grid-template-rows: 50px auto;
                align-items: center;
                @include djv-border-bottom(solid);
                margin-top: 0;
                margin-bottom: 0;
                padding-top: 5px;
                @media(max-width: $screen-md){
                    grid-template-areas:
                    "prices quantity"
                    "prices addtocart"
                    "availability availability";
                    grid-template-columns:auto auto;
                    grid-template-columns: 50% 50%;
                }

            }
            .product-prices{
                grid-area:prices;
                text-align: center;

            }
            .djv-product-availability-info{
                grid-area: availability;
                text-align: center;
                align-self: end;
            }

            .product-quantity{
                grid-area:quantity;
                align-self: center;
                justify-self: center;
                padding-top: 8px;
            }
            .solicitar-informacion{
                width: 100%;
                display: block;
                @include btn-hover-animated($primario7, white, 2px,5px,15px);
            }
            .product-add-to-cart{
                grid-area: addtocart;
                align-self: center;
                .add-to-cart{
                    @include btn-hover-animated($primario5,white,2px,10px,10px);
                    width: 120px;
                    display: block;
                    margin: auto;
                }

            }
        }
        #product-description-short{
            grid-area: descriptionshort;
            h3{
                margin-top: 20px;
                font-size: $font-lg;
            }
            p{
                margin-bottom: 0;
            }
            #sigue_leyendo{
                display: block;
                font-size: $font-xxxs;
                color: $primario3;
                cursor: pointer;
            }
        }
        .golo-condicionesyayuda {
            grid-area:condiciones;
            display: grid;
            grid-template-columns: repeat(2,calc(50% - 5px));
            grid-gap: 10px;
            text-align: center;
            margin: 15px 0 10px;
            border-bottom: 1px solid $gris3;
            @media(max-width: $screen-md){
                display: block;
            }
            > div {
                padding: 0 15px;

                i {
                    font-size: $font-xxxl;
                    display: block;
                }

                h4 {
                    font-size: $font-sm;
                    text-transform: uppercase;
                    @include fuente($font-first,$font-medium);
                }

                p {
                    font-size: $font-xxs;
                    color: $gris7;
                    @include fuente($font-first,$font-light);
                    margin-bottom: 0;
                }

                &:first-of-type {
                    border-right: 1px solid $gris3;
                    @media(max-width: $screen-md){
                        border-right: none;
                        border-bottom: 1px solid $gris3;
                        margin-bottom: 16px;
                    }
                }
            }
        }
        .social-sharing {
            grid-area:social;
            font-size: $font-lg;
            display: grid;
            grid-template-columns: repeat(6,16.666%);
            text-align: center;
            width: 50%;
            margin-left: auto;
            margin-right: auto;

            a {
                color: $gris7;
            }
        }

        .product-second-section {
            display: flex;
            width: 100%;
            margin-top: 20px;

            @media(max-width: $screen-sm) {
                display: block;
                width: auto;
                padding: 5px 5px;
            }
        }
        .product-information-extended{
            flex: 1;
        }
        .product-information-extended-heading {
            @include fuente($font-first,$font-regular);
            font-size: $font-lg;
            padding: 10px 0;
            color: black;
            border-bottom: 1px solid $gris2;
        }

        .product-information-extended-description {
            padding: 0;
            @include fuente($font-first,$font-light);
            font-size: $font-md*0.9;
            color: $gris8;
            h2{
                margin-top: 20px;
                margin-bottom: 0px;
                font-size: $font-lg;
                color: black;
            }
        }

        .product-information-extended-enviosdevoluciones {
            p {
                @include fuente($font-first,$font-light);
                font-size: $font-md*0.9;
                color: $gris8;

                a {
                    font-size: $font-xs;
                }
            }
        }
        .djv-product-opiniones{
            clear: both;
            display: block;
            width: 60%;
            height: auto;
            transition: all 1500ms;
            @media(max-width: $screen-md){
                padding: 16px;
                width: 100%;
            }
            .product-information-extended-heading{
                @include fuente($font-first,$font-regular);
                font-size: $font-lg;
                padding: 10px 0;
                color: black;
                border-bottom: 1px solid $gris2;
            }
            &.ocultar{
                display: none;
            }
        }
        .masOpiniones{
            display: block;
            cursor: pointer;
            margin: 16px auto;
            &::before{
                width: 25px;
                height: 25px;
                border-radius: 25px;
                background-color: $primario7;
                color: white;
                padding: 5px;
            }
        }

    }
    &.recogido{
        .page-content{
            margin-top: 0px;
        }
    }
}
