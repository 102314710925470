#my-account {
    .link-list {
        @include fuente($font-first,$font-regular);
        padding-left: 0;
        display: grid;
        grid-template-columns: calc(33% - 10px) calc(33% - 10px) calc(33% - 10px);
        grid-gap: 10px;
        @media(max-width: $screen-sm) {
            display: block;
        }

        li {
            list-style: none;
            padding: 5px 10px;
            background-color: $primario5;
            transition: all 500ms;
            @media(max-width: $screen-sm) {
                margin-bottom: 10px;
            }

            a {
                color: white;
            }

            &::before {
                /*content: "";
                    width: 30px;
                    margin-right: 5px;
                    padding-right: 5px;
                    border-right: 1px solid $gris2;
                    transition: all 500ms;*/
            }

            &:hover {
                background-color: $primario11;
                color: white;
                &::before {
                    /*    border-right: 1px solid white;*/
                }
            }
            &:nth-of-type(4),&:nth-of-type(5),&:nth-of-type(6){
                display: none;
            }
        }
    }
}
