.djv-icon-usuario{
    font-size: $font-lg;
    top: 7px;
    position: absolute;
    right: 70px;
    color:$primario3;
}
.user-info{
    display: none;
    background-color: $primario3;
    position: absolute;
    right: 0px;
    top: 40px;
    padding: 10px;
    width: 296px;
    text-align: center;
    z-index: 1;
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
    @media(max-width:$screen-md){
        padding-top: 9px;
        width: 100vw;
    }
    > *{
        font-size: 0.8rem;
    }
    a, a:visited, a:hover{
        text-decoration: none;
        color: inherit;
        display: inline-block;
        span{
            display: none;
        }
        i{
            //color: $primario5;
            display: inline-block;
        }
    }
    .account,
    .login,
    .logout {
        display: inline-block;
        font-size: $font-sm;
        margin: 0 10px;
        background-color: white;
        padding: 4px 15px;
        border-radius: 2px;
        @media(max-width:$screen-md){
            margin: 0 10px;
        }
    }
    > span {
        display: inline-block;
        font-size: $font-sm;
        margin: 0 10px;
        padding: 4px 15px;
        border-radius: 2px;
        //color: $primario5;
        @media(max-width: $screen-md){
            display:none;
        }
    }
    .logout {
        &::before{
            //color: $primario5;
        }
        @media(max-width:$screen-md){

        }
    }
}

@media (min-width: $screen-md){
  .user-info{
    a, a:visited, a:hover{
      display: inline-block;
      span{
       display: inline-block;
      }
      i{
        display: inline-block;
      }
    }
  }
}
