.djv-product-availability-info{
    &.level1{
        color: $complementario1;
        a{
            color:$complementario1;
        }
    }
    &.level2{
        color:$complementario3;
        a{
            color:$complementario3;
        }
    }
    &.level3{
        color:$complementario2;
        a{
            color:$complementario2;
        }
    }
}
