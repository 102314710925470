#djv-navigation-left {

    .filterDebug{
        display: block;
        padding: 5px;
        margin: 20px 5px;
        border: 1px solid $primario5;
        text-align: center;
    }

    .djv_navigation-left-filters {
        .djv_navigation-left-filters-header{
            display: grid;
            grid-template-columns: 80% 20%;
            span{
                font-size: 11px;
                color: $gris3;
                padding-top: 8px;
            }
            span.title{
                font-family: "Oswald-Medium";
                font-size: $font-lg;
                margin-bottom: 20px;
                color:$gris6;
                padding-top: 0;
            }
        }

        li{
            display: grid;
            grid-template-columns: 80% 20%;
            margin-bottom: 0;
            border-bottom:1px solid $primario5;
            font-size: $font-sm;
            background-color: rgba(130,130,130,0);
            transition: background-color 750ms;
            padding: 1px;
            a{padding: 2px;}
            span{
                text-align: center;
                padding: 1px;
                font-size: 11px;
                color: $gris3;
            }
            &:hover{
                background-color: rgba(245,245,245,1);
            }
        }
    }
}
