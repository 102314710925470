.header-nav-corporative{

  list-style: none;
  padding-left: 0;
  margin-top: -40px;
  li.nav-item{
    font-size: $font-xs !important;
    a{
      color:$primario3;
    }
  }
  li.nav-item-separator{
    font-size: $font-xs;
    padding-top: 7px;
    color:$gris3;
  }
}
