#pagenotfound{
    #djv-two-columns-layer{
        @include djv-container();
        #search_widget{
            input[type="text"]{
                padding: 6px;
            }
            button{
                @include btn-hover-animated($complementario1,white,2px,4px,10px);
                &::after{
                    content: "Buscar";
                }
            }
        }
    }
}
.page-not-found{
    p{
        display: block;
        width: 400px;
    }
    #search_widget{
        input[type="text"]{
            padding: 6px;
        }
        button{
            @include btn-hover-animated($complementario1,white,2px,4px,10px);
            &::after{
                content: "Buscar";
            }
        }
    }
}
