@charset "UTF-8";
/* Generated by Glyphter (http://www.glyphter.com) on  Thu May 16 2019*/
@font-face {
  font-family: 'comunes';
  src: url("project/djv-icon-font/fonts/comunes.eot");
  src: url("project/djv-icon-font/fonts/comunes.eot?#iefix") format("embedded-opentype"), url("project/djv-icon-font/fonts/comunes.woff") format("woff"), url("project/djv-icon-font/fonts/comunes.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

[class*='djv-icon']:before {
  display: inline-block;
  font-family: 'comunes';
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.djv-icon-coment:before {
  content: '\0041'; }

.djv-icon-coment-2:before {
  content: '\0042'; }

.djv-icon-carro:before {
  content: '\0043'; }

.djv-icon-menu:before {
  content: '\0044'; }

.djv-icon-lupa:before {
  content: '\0045'; }

.djv-icon-lupa-plus:before {
  content: '\0046'; }

.djv-icon-lupa-minus:before {
  content: '\0047'; }

.djv-icon-usuario:before {
  content: '\0048'; }

.djv-icon-telefono:before {
  content: '\0049'; }

.djv-icon-mail:before {
  content: '\004a'; }

.djv-icon-arrow-left:before {
  content: '\004b'; }

.djv-icon-arrow-right:before {
  content: '\004c'; }

.djv-icon-arrow-up:before {
  content: '\004d'; }

.djv-icon-arrow-down:before {
  content: '\004e'; }

.djv-icon-facebook:before {
  content: '\004f'; }

.djv-icon-twitter:before {
  content: '\0050'; }

.djv-icon-youtube:before {
  content: '\0051'; }

.djv-icon-instagram:before {
  content: '\0052'; }

.djv-icon-delete:before {
  content: '\0053'; }

.djv-icon-close:before {
  content: '\0054'; }

.djv-icon-ok:before {
  content: '\0055'; }

.djv-icon-ok-circle:before {
  content: '\0056'; }

.djv-icon-ok-circle-open:before {
  content: '\0057'; }

.djv-icon-plus:before {
  content: '\0058'; }

.djv-icon-minus:before {
  content: '\0059'; }

.djv-icon-refresh:before {
  content: '\005a'; }

.djv-icon-reload:before {
  content: '\0061'; }

.djv-icon-reply:before {
  content: '\0062'; }

.djv-icon-move:before {
  content: '\0063'; }

.djv-icon-filter:before {
  content: '\0064'; }

.djv-icon-exit:before {
  content: '\0065'; }

.djv-icon-gear:before {
  content: '\0066'; }

.djv-icon-question:before {
  content: '\0067'; }

.djv-icon-question-circle:before {
  content: '\0068'; }

.djv-icon-information:before {
  content: '\0069'; }

.djv-icon-warning:before {
  content: '\006a'; }

.djv-icon-star:before {
  content: '\006b'; }

.djv-icon-empty-star:before {
  content: '\006c'; }

.djv-icon-printer:before {
  content: '\006d'; }

.djv-icon-swipe:before {
  content: '\006e'; }

.djv-icon-tap:before {
  content: '\006f'; }

.djv-icon-whatsapp:before {
  content: '\0070'; }

.djv-icon-pinterest:before {
  content: '\0071'; }

/* Generated by Glyphter (http://www.glyphter.com) on  Mon Nov 18 2019*/
@font-face {
  font-family: 'golosinas';
  src: url("project/djv-icon-font/fonts/golosinas.eot");
  src: url("project/djv-icon-font/fonts/golosinas.eot?#iefix") format("embedded-opentype"), url("project/djv-icon-font/fonts/golosinas.woff") format("woff"), url("project/djv-icon-font/fonts/golosinas.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: fallback; }

[class*='gol-icon']:before {
  display: inline-block;
  font-family: 'golosinas';
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.gol-icon-asesoramiento:before {
  content: '\0041'; }

.gol-icon-calidad:before {
  content: '\0042'; }

.gol-icon-bebidas:before {
  content: '\0043'; }

.gol-icon-contacto:before {
  content: '\0044'; }

.gol-icon-garantia:before {
  content: '\0045'; }

.gol-icon-truck:before {
  content: '\0046'; }

.gol-icon-triangulo:before {
  content: '\004b'; }

.gol-icon-usuario:before {
  content: '\004d'; }

.gol-icon-caramelo:before {
  content: '\0050'; }

.gol-icon-snacks:before {
  content: '\0051'; }

.gol-icon-chocolate:before {
  content: '\0052'; }

.gol-icon-fiestas:before {
  content: '\0053'; }

.gol-icon-cromos:before {
  content: '\0054'; }

.gol-icon-otros:before {
  content: '\0055'; }

.gol-icon-mayor:before {
  content: '\0056'; }

.gol-icon-new:before {
  content: '\0057'; }

.gol-icon-clock:before {
  content: '\005a'; }

.gol-icon-question:before {
  content: '\0061'; }

.gol-icon-truck:before {
  content: '\0062'; }

.gol-icon-heart:before {
  content: '\0068'; }

.gol-icon-devoluciones:before {
  content: '\006f'; }

.gol-icon-exit:before {
  content: '\0070'; }

.gol-icon-box:before {
  content: '\0075'; }

.gol-icon-card:before {
  content: '\0076'; }

.gol-icon-piggy:before {
  content: '\0077'; }

.gol-icon-shipped:before {
  content: '\0078'; }

.gol-icon-spain:before {
  content: '\0079'; }

.gol-icon-swipe:before {
  content: '\007a'; }

/* #### Visualizaciones #### */
@media (max-width: 768px) {
  .xs-visible {
    display: block !important; } }

/* #### BOTONES #### */
.btn-download {
  font-size: 16px;
  padding: 5px 10px;
  background-color: #0083ff;
  color: white;
  border-radius: 5px;
  padding: 5px 32px 5px 10px;
  position: relative; }
  .btn-download::after {
    font-family: 'gamalux';
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: '\004b';
    background-color: white;
    color: #0083ff;
    position: absolute;
    right: 8px;
    top: 4px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    font-size: 15px;
    padding: 5px 0px 2px; }

/* ···· FIN BOTONES ···· */
.etiqueta-flecha {
  -webkit-clip-path: polygon(0% 0%, 72% 0, 100% 50%, 72% 100%, 0% 100%);
  clip-path: polygon(0% 0%, 72% 0, 100% 50%, 72% 100%, 0% 100%); }

/* #### LAYOUT #### */
.djv-container {
  width: 95%;
  margin-right: auto;
  margin-left: auto;
  max-width: 1170px;
  min-width: 920px; }
  @media (max-width: 1024px) {
    .djv-container {
      max-width: 920px;
      min-width: 750px; } }
  @media (max-width: 768px) {
    .djv-container {
      max-width: 750px;
      min-width: 480px; } }
  @media (max-width: 480px) {
    .djv-container {
      max-width: 500px;
      min-width: 300px; } }

.djv-container-stickytop {
  margin-top: 200px; }

/* ---- grid ---- */
.grid-2-col {
  display: grid;
  grid-template-columns: calc(50% - 20px) calc(50% - 20px);
  grid-gap: 10px 40px; }

/* ···· FIN BOTONES ···· */
/* #### TITULARES #### */
.djv-titulos-barras-laterales {
  display: grid;
  grid-template-columns: auto 30% auto;
  position: relative; }
  @media (max-width: 768px) {
    .djv-titulos-barras-laterales {
      grid-template-columns: auto 80% auto; } }
  .djv-titulos-barras-laterales::before {
    content: "";
    border-bottom: 1px solid #cccccc;
    display: block;
    width: 100%;
    margin-right: 20px;
    position: relative;
    transform: translateY(-50%); }
  .djv-titulos-barras-laterales::after {
    content: "";
    border-bottom: 1px solid #cccccc;
    display: block;
    width: 100%;
    margin-right: 20px;
    position: relative;
    transform: translateY(-50%); }

.djv-titulos-left-linea-discontinua, #manufacturer #main h1, #order-confirmation #main h3, #order-detail #order-history h1, #contact #content h1, #search h2 {
  font-size: 22px;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  padding: 10px 0 15px 5px;
  border-bottom: 1px dashed #666666;
  margin-bottom: 20px;
  text-transform: uppercase; }

/* ···· FIN TITULARES ···· */
/* #### BLOQUES #### */
.cms-block-example, #checkout #payment-option-1-additional-information section p, #order-detail #order-infos {
  background-color: #eeeeee;
  display: block;
  padding: 30px;
  margin: 16px 0px;
  outline: 1px dashed #cccccc;
  outline-offset: -10px; }

/* ···· FIN BLOQUES ···· */
/* #### BORDES #### */
.djv-border-top-dashed {
  border-top: 1px dashed #cccccc;
  padding-top: 20px;
  margin-top: 20px; }

.djv-border-top-solid {
  border-top: 1px solid #cccccc;
  padding-top: 20px;
  margin-top: 20px; }

.djv-border-bottom-dashed {
  border-bottom: 1px dashed #cccccc;
  padding-bottom: 20px;
  margin-bottom: 20px; }

.djv-border-bottom-solid {
  border-bottom: 1px solid #cccccc;
  padding-bottom: 20px;
  margin-bottom: 20px; }

/* ···· FIN BORDES ···· */
* {
  box-sizing: border-box; }

body {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  overflow-x: hidden;
  margin: 0;
  padding: 0;
  overflow-x: hidden; }

a {
  color: #6abac7; }
  a:hover {
    color: #03b8d6;
    text-decoration: underline; }

p {
  margin-bottom: auto; }

.ps-alert-success {
  padding: 90px 20px; }
  .ps-alert-success li {
    list-style: none;
    border-bottom: 1px dashed #666666; }
    .ps-alert-success li i {
      float: left;
      margin-right: 20px;
      color: #5abe20;
      font-size: 30px;
      padding-bottom: 20px; }

.whatsapp {
  cursor: pointer; }

.full-width {
  width: 100vw;
  display: block;
  position: relative;
  margin-left: 50%;
  transform: translateX(-50%); }

#lgcookieslaw_banner #lgcookieslaw_accept {
  background-color: #dadada;
  padding: 5px;
  display: block;
  border: 0;
  border-radius: 6px; }

body {
  margin: 0;
  padding: 0; }

#wrapper {
  clear: both; }

#wrapper,
.header-top,
.header-topbar {
  padding-left: 0;
  padding-right: 0; }

.header-banner {
  text-align: center;
  background-color: black; }

#djv-two-columns-layer {
  min-height: 480px;
  margin-top: 20px; }
  @media (max-width: 1024px) {
    #djv-two-columns-layer {
      margin-top: 100px; } }

.recogido #djv-two-columns-layer {
  margin-top: 240px; }

@media (max-width: 1024px) {
  #index .carousel {
    margin-top: 90px;
    margin-bottom: 0px; } }

@media (max-width: 768px) {
  #index .carousel {
    margin-top: 40px; } }

#index #djv-two-columns-layer {
  margin-top: 40px; }
  @media (max-width: 768px) {
    #index #djv-two-columns-layer {
      margin-top: 0px; } }

#index.recogido .carousel {
  margin-top: 140px;
  margin-bottom: 0px; }

#module-djv_navigation-nsearch .carousel {
  margin-bottom: 0; }
  @media (max-width: 768px) {
    #module-djv_navigation-nsearch .carousel {
      margin-top: 40px; } }

#module-djv_navigation-nsearch #djv-two-columns-layer {
  display: grid;
  grid-template-columns: 18% 82%;
  margin-top: 0px; }
  #module-djv_navigation-nsearch #djv-two-columns-layer.force-one-column {
    grid-template-columns: 100%; }
  #module-djv_navigation-nsearch #djv-two-columns-layer > div {
    padding: 5px; }
    @media (max-width: 1024px) {
      #module-djv_navigation-nsearch #djv-two-columns-layer > div {
        padding: 8px; } }
  @media (max-width: 1024px) {
    #module-djv_navigation-nsearch #djv-two-columns-layer {
      display: block; } }

#module-djv_navigation-nsearch.recogido .djv-navigation-nsearch-header {
  margin-top: 140px; }

#module-djv_navigation-nsearch.recogido .carousel {
  margin-top: 210px;
  margin-bottom: 0; }

#module-djv_navigation-nsearch.recogido .carousel ~ .djv-navigation-nsearch-header {
  margin-top: 0px; }

#module-djv_navigation-nsearch.recogido #djv-two-columns-layer {
  margin-top: auto; }

#module-djv_navigation-nsearch .slide .slide-inner .slide-item {
  min-height: 280px; }

#product #djv-two-columns-layer {
  margin-top: 0px; }

#product.recogido .djv-breadcrumb {
  margin-top: 240px; }

#authentication #djv-two-columns-layer {
  margin-top: 40px; }

#authentication.recogido #djv-two-columns-layer {
  margin-top: 160px; }

.djv-breadcrumb {
  border-bottom: 1px solid #cccccc;
  padding-bottom: 10px;
  margin-bottom: 10px;
  text-align: center;
  font-size: 13px;
  padding-bottom: 0;
  margin-top: 25px;
  color: #999999; }
  @media (max-width: 768px) {
    .djv-breadcrumb {
      padding-top: 0;
      margin-top: 50px;
      margin-bottom: 0;
      text-align: left; } }
  @media (max-width: 768px) {
    .djv-breadcrumb ol {
      padding-left: 10px; } }
  .djv-breadcrumb ol li {
    display: inline-block; }
    .djv-breadcrumb ol li::after {
      content: ">";
      margin-left: 2px;
      margin-right: 2px; }
    .djv-breadcrumb ol li a {
      color: #777777; }

#header {
  min-height: 212px;
  position: relative;
  z-index: 99;
  width: 100%;
  top: 0;
  box-shadow: 1px 1px 5px 2px rgba(0, 0, 0, 0.2); }
  @media (max-width: 1024px) {
    #header {
      min-height: 40PX;
      height: 40px;
      position: fixed; } }
  #header #header-topbanner {
    position: relative;
    width: 100%;
    overflow: hidden;
    background-color: white; }
    #header #header-topbanner .djv-container {
      position: relative; }
      @media (max-width: 768px) {
        #header #header-topbanner .djv-container {
          width: 100%;
          margin-left: 0;
          margin-right: 0;
          max-height: 70px; } }
      #header #header-topbanner .djv-container #close-banner-top {
        position: absolute;
        top: 5px;
        right: 10px;
        background-color: white;
        border-radius: 50%;
        height: 18px; }
        @media (max-width: 768px) {
          #header #header-topbanner .djv-container #close-banner-top {
            right: 2px; } }
      #header #header-topbanner .djv-container a {
        position: relative;
        display: block;
        overflow: hidden;
        width: 100%; }
      #header #header-topbanner .djv-container img {
        position: relative;
        left: 50%;
        transform: translateX(-50%); }
        @media (max-width: 768px) {
          #header #header-topbanner .djv-container img {
            max-width: 180%;
            max-height: 70px; } }
  #header .djv-icon-lupa {
    display: none;
    position: relative;
    color: #26314c;
    cursor: pointer;
    z-index: 9;
    top: 9px;
    font-size: 19px;
    position: absolute;
    right: 115px; }
    @media (max-width: 1024px) {
      #header .djv-icon-lupa {
        display: block;
        right: auto;
        left: calc(-100vw + 169px);
        position: absolute; } }
  #header #menu-icon-responsive {
    display: inline-block;
    position: relative;
    top: 1px;
    font-size: 22px;
    left: 2px;
    color: #26314c;
    cursor: pointer;
    z-index: 9; }
    @media (max-width: 1024px) {
      #header #menu-icon-responsive::before {
        display: inline-block; } }
    @media (max-width: 1024px) {
      #header #menu-icon-responsive span {
        display: none; } }
  #header .headerGrid {
    background-image: url("/themes/base/assets/img/topbarbg.gif");
    background-repeat: repeat-x;
    background-size: 1px 40px;
    background-color: white;
    height: 240px; }
    @media (max-width: 1024px) {
      #header .headerGrid {
        height: 40px; } }
    #header .headerGrid > .djv-container {
      display: flex;
      grid-template-columns: 30% 40% 30%;
      grid-template-areas: "topbarleft topbarcenter topbarright" "headersectionleft headersectioncenter headersectionright" "mainMenu mainMenu mainMenu";
      align-items: center;
      /* Bloque de grid */
      /* --- Fin Bloque de elementos adicionales*/
      /* Bloque de elementos adicionales*/
      /* -- Fin Bloque de elementos adicionales*/ }
      @media (max-width: 1024px) {
        #header .headerGrid > .djv-container {
          grid-template-columns: 100px auto 100px;
          grid-template-areas: "navicons headersectioncenter topbarright"; } }
      @media (max-width: 1024px) {
        #header .headerGrid > .djv-container {
          margin-left: 0px;
          margin-right: 0px;
          width: 100%;
          position: relative; } }
      #header .headerGrid > .djv-container .nav-icons {
        grid-area: navicons;
        display: none; }
        @media (max-width: 1024px) {
          #header .headerGrid > .djv-container .nav-icons {
            display: block; } }
      #header .headerGrid > .djv-container .header-topbar-before {
        grid-area: topbarleft;
        display: inline-block;
        font-size: 0.8rem; }
        #header .headerGrid > .djv-container .header-topbar-before i::before {
          margin-right: 8px;
          margin-left: 5px; }
        @media (max-width: 1024px) {
          #header .headerGrid > .djv-container .header-topbar-before {
            display: none; } }
        #header .headerGrid > .djv-container .header-topbar-before a {
          color: inherit; }
      #header .headerGrid > .djv-container .header-topbar-center {
        grid-area: topbarcenter;
        display: inline-block;
        font-size: 0.8rem;
        text-align: center; }
        #header .headerGrid > .djv-container .header-topbar-center i {
          position: relative; }
          #header .headerGrid > .djv-container .header-topbar-center i::before {
            position: absolute;
            font-size: 22px;
            left: -28px;
            top: -3px;
            font-weight: bold; }
        @media (max-width: 1024px) {
          #header .headerGrid > .djv-container .header-topbar-center {
            display: none; } }
      #header .headerGrid > .djv-container .header-topbar-after {
        grid-area: topbarright;
        display: inline-block;
        font-size: 0.8rem;
        position: relative; }
      #header .headerGrid > .djv-container .header-section-left {
        grid-area: headersectionleft; }
        @media (max-width: 1024px) {
          #header .headerGrid > .djv-container .header-section-left {
            display: none; } }
        #header .headerGrid > .djv-container .header-section-left h1, #header .headerGrid > .djv-container .header-section-left h2 {
          font-size: 20px;
          line-height: 16px;
          font-family: "Bree Serif", serif;
          font-weight: 300; }
          #header .headerGrid > .djv-container .header-section-left h1 > p:first-of-type, #header .headerGrid > .djv-container .header-section-left h2 > p:first-of-type {
            font-family: "Roboto", sans-serif;
            font-weight: 300;
            font-size: 17px; }
          @media (max-width: 1024px) {
            #header .headerGrid > .djv-container .header-section-left h1, #header .headerGrid > .djv-container .header-section-left h2 {
              display: none; } }
        #header .headerGrid > .djv-container .header-section-left > p:first-of-type {
          font-family: "Roboto", sans-serif;
          font-weight: 300;
          font-size: 17px; }
        @media (max-width: 1024px) {
          #header .headerGrid > .djv-container .header-section-left {
            display: none; } }
      #header .headerGrid > .djv-container .header-section-center {
        grid-area: headersectioncenter; }
      #header .headerGrid > .djv-container .header-section-right {
        grid-area: headersectionright;
        position: relative; }
        @media (max-width: 1024px) {
          #header .headerGrid > .djv-container .header-section-right {
            display: none;
            position: absolute;
            top: 40px;
            width: 100%;
            padding: 0px 4px;
            background-color: #6abac7; } }
      #header .headerGrid > .djv-container .header-nav-top {
        grid-area: mainMenu; }
        @media (max-width: 1024px) {
          #header .headerGrid > .djv-container .header-nav-top {
            display: none; } }
      #header .headerGrid > .djv-container .djv-icon-usuario {
        font-size: 21px;
        position: absolute;
        top: 8px;
        right: 71px;
        padding: 0 6px;
        cursor: pointer;
        color: #26314c; }
      #header .headerGrid > .djv-container .djv-icon-coment, #header .headerGrid > .djv-container .djv-icon-crono {
        font-size: 17.6px;
        margin-right: 4px;
        position: relative;
        display: inline-block;
        width: 20px;
        height: 20px; }
      #header .headerGrid > .djv-container .djv-icon-coment::before, #header .headerGrid > .djv-container .djv-icon-crono::before {
        margin-right: 5px;
        font-size: 17.6px;
        left: 0;
        top: 5px;
        position: absolute; }
      #header .headerGrid > .djv-container .logo {
        width: 260px;
        height: 130px;
        display: block;
        margin: 0px auto 0;
        padding-top: 20px; }
        @media (max-width: 1024px) {
          #header .headerGrid > .djv-container .logo {
            width: 100px;
            height: auto;
            padding-top: 0px; }
            #header .headerGrid > .djv-container .logo img {
              width: 100%; } }
      #header .headerGrid > .djv-container #search_widget {
        width: 100%;
        display: block;
        margin: 5px auto;
        position: relative; }
        #header .headerGrid > .djv-container #search_widget input {
          background-color: white;
          width: 100%;
          display: block;
          position: relative;
          border: 1px solid #999999;
          border-radius: 3px;
          padding: 3px 20px 3px 5px;
          color: #26445C;
          font-family: "OpenSans-Regular";
          font-size: 15px; }
          @media (max-width: 1024px) {
            #header .headerGrid > .djv-container #search_widget input {
              border: none; } }
          #header .headerGrid > .djv-container #search_widget input::placeholder {
            font-size: 12px;
            color: #666666;
            font-family: "OpenSans-Light";
            text-align: center; }
        #header .headerGrid > .djv-container #search_widget button {
          position: absolute;
          top: 8px;
          right: 0px;
          border: none;
          background-color: transparent;
          width: 30px;
          height: 30px;
          cursor: pointer; }
          #header .headerGrid > .djv-container #search_widget button:before {
            display: inline-block;
            font-family: "comunes";
            font-style: normal;
            font-weight: normal;
            line-height: 1;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            content: '\0045';
            font-size: 20px;
            right: 4px;
            top: -2px;
            position: absolute;
            color: #cccccc; }
            @media (max-width: 1024px) {
              #header .headerGrid > .djv-container #search_widget button:before {
                z-index: 0; } }
      #header .headerGrid > .djv-container #blockcart-wrapper {
        position: relative;
        z-index: 999;
        display: inline-block;
        float: right; }
      #header .headerGrid > .djv-container .blockcart .header {
        color: white;
        font-size: 0.8rem; }
      #header .headerGrid > .djv-container .header-nav-corporative {
        margin: 0;
        padding: 0;
        text-align: center; }
        @media (max-width: 1024px) {
          #header .headerGrid > .djv-container .header-nav-corporative {
            display: none; } }
        #header .headerGrid > .djv-container .header-nav-corporative li {
          list-style: none;
          display: inline-block;
          font-size: 13px;
          color: #6abac7; }
          #header .headerGrid > .djv-container .header-nav-corporative li a {
            color: inherit; }
            #header .headerGrid > .djv-container .header-nav-corporative li a.nav-link {
              padding: 5px; }
          #header .headerGrid > .djv-container .header-nav-corporative li.nav-item-separator {
            color: #333333; }
  #header.recogido {
    height: 40px;
    min-height: auto;
    position: fixed;
    top: 0; }
    #header.recogido #menu-icon-responsive {
      display: inline-block;
      font-size: 16px;
      top: 0px;
      position: relative; }
      #header.recogido #menu-icon-responsive::before {
        display: none; }
      #header.recogido #menu-icon-responsive span {
        font-style: normal; }
        #header.recogido #menu-icon-responsive span::after {
          content: '\0057';
          display: inline-block;
          font-family: "golosinas";
          font-style: normal;
          font-weight: normal;
          line-height: 1;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          margin-left: 10px;
          position: absolute;
          top: 5px; }
    #header.recogido .djv-icon-lupa {
      display: inline-block; }
    #header.recogido .headerGrid {
      height: 40px;
      border-bottom: 1px solid #6abac7; }
      #header.recogido .headerGrid > .djv-container {
        grid-template-columns: 180px auto 140px;
        grid-template-areas: "headersectioncenter mainMenu topbarright";
        position: relative; }
        #header.recogido .headerGrid > .djv-container form .djv-icon-lupa {
          display: none; }
      #header.recogido .headerGrid .nav-icons {
        grid-area: navicons;
        display: none; }
      #header.recogido .headerGrid .header-topbar-before {
        display: none; }
      #header.recogido .headerGrid .header-topbar-center {
        display: none; }
      #header.recogido .headerGrid .header-topbar-after {
        grid-area: topbarright; }
      #header.recogido .headerGrid .header-section-left {
        display: none; }
      #header.recogido .headerGrid .header-section-center {
        grid-area: headersectioncenter; }
      #header.recogido .headerGrid .header-section-right {
        display: none;
        position: absolute;
        right: 110px;
        width: 200px;
        top: 40px;
        background-color: #6abac7;
        padding: 10px;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px; }
        @media (max-width: 768px) {
          #header.recogido .headerGrid .header-section-right {
            top: 79px;
            position: absolute; } }
      #header.recogido .headerGrid .header-nav-top {
        grid-area: mainMenu;
        display: block;
        position: relative;
        left: 0;
        top: 12px; }
      #header.recogido .headerGrid .header-nav-corporative {
        display: none; }
      #header.recogido .headerGrid .logo {
        width: 90px;
        display: block;
        margin: 0px auto 0;
        padding-top: 0px;
        height: auto; }
        @media (max-width: 1024px) {
          #header.recogido .headerGrid .logo {
            margin: 0; } }

.header-nav-corporative {
  list-style: none;
  padding-left: 0;
  margin-top: -40px; }
  .header-nav-corporative li.nav-item {
    font-size: 15px !important; }
    .header-nav-corporative li.nav-item a {
      color: #6abac7; }
  .header-nav-corporative li.nav-item-separator {
    font-size: 15px;
    padding-top: 7px;
    color: #cccccc; }

#header {
  min-height: 212px;
  position: relative;
  z-index: 99;
  width: 100%;
  top: 0;
  box-shadow: 1px 1px 5px 2px rgba(0, 0, 0, 0.2); }
  @media (max-width: 768px) {
    #header {
      min-height: 40PX;
      height: 40px;
      position: fixed; } }
  #header #header-topbanner {
    position: relative;
    width: 100%;
    overflow: hidden;
    background-color: white; }
    #header #header-topbanner .djv-container {
      position: relative; }
      @media (max-width: 768px) {
        #header #header-topbanner .djv-container {
          width: 100%;
          margin-left: 0;
          margin-right: 0;
          max-height: 70px; } }
      #header #header-topbanner .djv-container #close-banner-top {
        position: absolute;
        top: 5px;
        right: 10px;
        background-color: white;
        border-radius: 50%;
        height: 18px; }
        @media (max-width: 768px) {
          #header #header-topbanner .djv-container #close-banner-top {
            right: 2px; } }
      #header #header-topbanner .djv-container a {
        position: relative;
        display: block;
        overflow: hidden;
        width: 100%; }
      #header #header-topbanner .djv-container img {
        position: relative;
        left: 50%;
        transform: translateX(-50%); }
        @media (max-width: 768px) {
          #header #header-topbanner .djv-container img {
            max-width: 180%;
            max-height: 70px; } }
  #header .djv-icon-lupa {
    display: none;
    position: relative;
    color: #26314c;
    cursor: pointer;
    z-index: 9;
    top: 9px;
    font-size: 19px;
    position: absolute;
    right: 115px; }
    @media (max-width: 1024px) {
      #header .djv-icon-lupa {
        display: block;
        right: auto;
        left: calc(-100vw + 169px);
        position: absolute; } }
  #header #menu-icon-responsive {
    display: inline-block;
    position: relative;
    top: 1px;
    font-size: 22px;
    left: 2px;
    color: #26314c;
    cursor: pointer;
    z-index: 9; }
    @media (max-width: 1024px) {
      #header #menu-icon-responsive::before {
        display: inline-block; } }
    @media (max-width: 1024px) {
      #header #menu-icon-responsive span {
        display: none; } }
  #header .headerGrid {
    background-image: url("/themes/base/assets/img/topbarbg.gif");
    background-repeat: repeat-x;
    background-size: 1px 40px;
    background-color: white;
    height: 240px; }
    @media (max-width: 1024px) {
      #header .headerGrid {
        height: 40px; } }
    #header .headerGrid > .djv-container {
      display: grid;
      grid-template-columns: 30% 40% 30%;
      grid-template-areas: "topbarleft topbarcenter topbarright" "headersectionleft headersectioncenter headersectionright" "mainMenu mainMenu mainMenu";
      align-items: center;
      /* Bloque de grid */
      /* --- Fin Bloque de elementos adicionales*/
      /* Bloque de elementos adicionales*/
      /* -- Fin Bloque de elementos adicionales*/ }
      @media (max-width: 1024px) {
        #header .headerGrid > .djv-container {
          grid-template-columns: 100px auto 100px;
          grid-template-areas: "navicons headersectioncenter topbarright"; } }
      @media (max-width: 1024px) {
        #header .headerGrid > .djv-container {
          margin-left: 0px;
          margin-right: 0px;
          width: 100%;
          position: relative; } }
      #header .headerGrid > .djv-container .nav-icons {
        grid-area: navicons;
        display: none; }
        @media (max-width: 1024px) {
          #header .headerGrid > .djv-container .nav-icons {
            display: block; } }
      #header .headerGrid > .djv-container .header-topbar-before {
        grid-area: topbarleft;
        display: inline-block;
        font-size: 0.8rem; }
        #header .headerGrid > .djv-container .header-topbar-before i::before {
          margin-right: 8px;
          margin-left: 5px; }
        @media (max-width: 1024px) {
          #header .headerGrid > .djv-container .header-topbar-before {
            display: none; } }
        #header .headerGrid > .djv-container .header-topbar-before a {
          color: inherit; }
      #header .headerGrid > .djv-container .header-topbar-center {
        grid-area: topbarcenter;
        display: inline-block;
        font-size: 0.8rem;
        text-align: center; }
        #header .headerGrid > .djv-container .header-topbar-center i {
          position: relative; }
          #header .headerGrid > .djv-container .header-topbar-center i::before {
            position: absolute;
            font-size: 22px;
            left: -28px;
            top: -3px;
            font-weight: bold; }
        @media (max-width: 1024px) {
          #header .headerGrid > .djv-container .header-topbar-center {
            display: none; } }
      #header .headerGrid > .djv-container .header-topbar-after {
        grid-area: topbarright;
        display: inline-block;
        font-size: 0.8rem;
        position: relative; }
      #header .headerGrid > .djv-container .header-section-left {
        grid-area: headersectionleft; }
        @media (max-width: 1024px) {
          #header .headerGrid > .djv-container .header-section-left {
            display: none; } }
        #header .headerGrid > .djv-container .header-section-left h1, #header .headerGrid > .djv-container .header-section-left h2 {
          font-size: 20px;
          line-height: 16px;
          font-family: "Bree Serif", serif;
          font-weight: 300; }
          #header .headerGrid > .djv-container .header-section-left h1 > p:first-of-type, #header .headerGrid > .djv-container .header-section-left h2 > p:first-of-type {
            font-family: "Roboto", sans-serif;
            font-weight: 300;
            font-size: 17px; }
          @media (max-width: 1024px) {
            #header .headerGrid > .djv-container .header-section-left h1, #header .headerGrid > .djv-container .header-section-left h2 {
              display: none; } }
        #header .headerGrid > .djv-container .header-section-left > p:first-of-type {
          font-family: "Roboto", sans-serif;
          font-weight: 300;
          font-size: 17px; }
        @media (max-width: 1024px) {
          #header .headerGrid > .djv-container .header-section-left {
            display: none; } }
      #header .headerGrid > .djv-container .header-section-center {
        grid-area: headersectioncenter; }
      #header .headerGrid > .djv-container .header-section-right {
        grid-area: headersectionright;
        position: relative; }
        @media (max-width: 1024px) {
          #header .headerGrid > .djv-container .header-section-right {
            display: none;
            position: absolute;
            top: 40px;
            width: 100%;
            padding: 0px 4px;
            background-color: #6abac7; } }
      #header .headerGrid > .djv-container .header-nav-top {
        grid-area: mainMenu; }
        @media (max-width: 1024px) {
          #header .headerGrid > .djv-container .header-nav-top {
            display: none; } }
      #header .headerGrid > .djv-container .djv-icon-usuario {
        font-size: 21px;
        position: absolute;
        top: 8px;
        right: 71px;
        padding: 0 6px;
        cursor: pointer;
        color: #26314c; }
      #header .headerGrid > .djv-container .djv-icon-coment, #header .headerGrid > .djv-container .djv-icon-crono {
        font-size: 17.6px;
        margin-right: 4px;
        position: relative;
        display: inline-block;
        width: 20px;
        height: 20px; }
      #header .headerGrid > .djv-container .djv-icon-coment::before, #header .headerGrid > .djv-container .djv-icon-crono::before {
        margin-right: 5px;
        font-size: 17.6px;
        left: 0;
        top: 5px;
        position: absolute; }
      #header .headerGrid > .djv-container .logo {
        width: 260px;
        height: 130px;
        display: block;
        margin: 0px auto 0;
        padding-top: 20px; }
        @media (max-width: 1024px) {
          #header .headerGrid > .djv-container .logo {
            width: 100px;
            height: auto;
            padding-top: 0px; }
            #header .headerGrid > .djv-container .logo img {
              width: 100%; } }
      #header .headerGrid > .djv-container #search_widget {
        width: 100%;
        display: block;
        margin: 5px auto;
        position: relative; }
        #header .headerGrid > .djv-container #search_widget input {
          background-color: white;
          width: 100%;
          display: block;
          position: relative;
          border: 1px solid #999999;
          border-radius: 3px;
          padding: 3px 20px 3px 5px;
          color: #26445C;
          font-family: "OpenSans-Regular";
          font-size: 15px; }
          @media (max-width: 1024px) {
            #header .headerGrid > .djv-container #search_widget input {
              border: none; } }
          #header .headerGrid > .djv-container #search_widget input::placeholder {
            font-size: 12px;
            color: #666666;
            font-family: "OpenSans-Light";
            text-align: center; }
        #header .headerGrid > .djv-container #search_widget button {
          position: absolute;
          top: 8px;
          right: 0px;
          border: none;
          background-color: transparent;
          width: 30px;
          height: 30px;
          cursor: pointer; }
          #header .headerGrid > .djv-container #search_widget button:before {
            display: inline-block;
            font-family: "comunes";
            font-style: normal;
            font-weight: normal;
            line-height: 1;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            content: '\0045';
            font-size: 20px;
            right: 4px;
            top: -2px;
            position: absolute;
            color: #cccccc; }
            @media (max-width: 1024px) {
              #header .headerGrid > .djv-container #search_widget button:before {
                z-index: 0; } }
      #header .headerGrid > .djv-container #blockcart-wrapper {
        position: relative;
        z-index: 999;
        display: inline-block;
        float: right; }
      #header .headerGrid > .djv-container .blockcart .header {
        color: white;
        font-size: 0.8rem; }
      #header .headerGrid > .djv-container .header-nav-corporative {
        margin: 0;
        padding: 0;
        text-align: center; }
        @media (max-width: 1024px) {
          #header .headerGrid > .djv-container .header-nav-corporative {
            display: none; } }
        #header .headerGrid > .djv-container .header-nav-corporative li {
          list-style: none;
          display: inline-block;
          font-size: 13px;
          color: #6abac7; }
          #header .headerGrid > .djv-container .header-nav-corporative li a {
            color: inherit; }
            #header .headerGrid > .djv-container .header-nav-corporative li a.nav-link {
              padding: 5px; }
          #header .headerGrid > .djv-container .header-nav-corporative li.nav-item-separator {
            color: #333333; }
  #header.recogido {
    height: 40px;
    min-height: auto;
    position: fixed;
    top: 0; }
    #header.recogido #menu-icon-responsive {
      display: inline-block;
      font-size: 16px;
      top: 0px;
      position: relative; }
      #header.recogido #menu-icon-responsive::before {
        display: none; }
      #header.recogido #menu-icon-responsive span {
        font-style: normal; }
        #header.recogido #menu-icon-responsive span::after {
          content: '\0057';
          display: inline-block;
          font-family: "golosinas";
          font-style: normal;
          font-weight: normal;
          line-height: 1;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          margin-left: 10px;
          position: absolute;
          top: 5px; }
    #header.recogido .djv-icon-lupa {
      display: inline-block; }
    #header.recogido .headerGrid {
      height: 40px;
      border-bottom: 1px solid #6abac7; }
      #header.recogido .headerGrid > .djv-container {
        grid-template-columns: 180px auto 140px;
        grid-template-areas: "headersectioncenter mainMenu topbarright";
        position: relative; }
        #header.recogido .headerGrid > .djv-container form .djv-icon-lupa {
          display: none; }
      #header.recogido .headerGrid .nav-icons {
        grid-area: navicons;
        display: none; }
      #header.recogido .headerGrid .header-topbar-before {
        display: none; }
      #header.recogido .headerGrid .header-topbar-center {
        display: none; }
      #header.recogido .headerGrid .header-topbar-after {
        grid-area: topbarright; }
      #header.recogido .headerGrid .header-section-left {
        display: none; }
      #header.recogido .headerGrid .header-section-center {
        grid-area: headersectioncenter; }
      #header.recogido .headerGrid .header-section-right {
        display: none;
        position: absolute;
        right: 110px;
        width: 200px;
        top: 40px;
        background-color: #6abac7;
        padding: 10px;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px; }
        @media (max-width: 768px) {
          #header.recogido .headerGrid .header-section-right {
            top: 79px;
            position: absolute; } }
      #header.recogido .headerGrid .header-nav-top {
        grid-area: mainMenu;
        display: block;
        position: relative;
        left: 0;
        top: 12px; }
      #header.recogido .headerGrid .header-nav-corporative {
        display: none; }
      #header.recogido .headerGrid .logo {
        width: 90px;
        display: block;
        margin: 0px auto 0;
        padding-top: 0px;
        height: auto; }
        @media (max-width: 1024px) {
          #header.recogido .headerGrid .logo {
            margin: 0; } }

.djv-icon-menu {
  display: none;
  color: #6abac7;
  font-size: 20px;
  padding: 7px 10px 5px;
  width: 40px;
  height: 40px;
  font-size: 22px;
  position: absolute;
  top: 0; }
  @media (max-width: 1024px) {
    .djv-icon-menu {
      display: block; } }

#searcher-icon-responsive {
  display: none; }
  @media (max-width: 1024px) {
    #searcher-icon-responsive {
      display: block;
      position: absolute;
      top: 2px;
      left: 45px;
      border-radius: 50%;
      width: 35px;
      height: 35px;
      text-align: center;
      cursor: pointer;
      padding: 5px;
      font-size: 22px;
      color: #6abac7; } }

@media (max-width: 1024px) {
  .header-nav-top {
    display: none; } }

#main-menu {
  margin-right: auto;
  margin-left: auto;
  max-width: 1170px;
  min-width: 920px; }
  @media (max-width: 1024px) {
    #main-menu {
      max-width: 920px;
      min-width: 750px; } }
  @media (max-width: 768px) {
    #main-menu {
      max-width: 750px;
      min-width: 480px; } }
  @media (max-width: 480px) {
    #main-menu {
      max-width: 500px;
      min-width: 300px; } }
  @media (max-width: 1024px) {
    #main-menu {
      margin-top: 0;
      position: fixed;
      background-color: rgba(255, 255, 255, 0.95);
      height: 100vh;
      padding-top: 40px;
      border-top: 1px solid #eeeeee;
      width: 100vw;
      overflow: hidden;
      top: 0;
      left: 0;
      z-index: 1000; } }
  #main-menu #djv-close-nav-responsive {
    display: none; }
    @media (max-width: 1024px) {
      #main-menu #djv-close-nav-responsive {
        display: block;
        position: absolute;
        top: 10px;
        right: 24px;
        border-radius: 50%;
        width: 35px;
        height: 35px;
        text-align: center;
        cursor: pointer;
        padding: 5px; }
        #main-menu #djv-close-nav-responsive::before {
          color: #444444;
          font-size: 28px; } }
  @media (max-width: 1024px) {
    #main-menu > .djv-container {
      overflow-y: auto;
      max-height: calc(100%); } }
  #main-menu > .djv-container .djv-main-menu {
    display: flex;
    justify-content: center;
    padding: 0;
    margin-left: auto;
    margin-right: auto;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-size: 15px;
    color: #333333;
    white-space: nowrap; }
    @media (max-width: 1024px) {
      #main-menu > .djv-container .djv-main-menu {
        padding-top: 20px;
        display: block; } }
    #main-menu > .djv-container .djv-main-menu li {
      list-style: none; }
    #main-menu > .djv-container .djv-main-menu > li {
      display: inline-block;
      text-transform: uppercase;
      margin-left: 2%;
      margin-right: 2%;
      cursor: pointer;
      position: relative;
      cursor: context-menu;
      transition: all 500ms;
      position: relative; }
      #main-menu > .djv-container .djv-main-menu > li > span a {
        color: inherit; }
      #main-menu > .djv-container .djv-main-menu > li > span .djv-icon-arrow-down::before {
        display: none; }
      @media (max-width: 1024px) {
        #main-menu > .djv-container .djv-main-menu > li {
          display: block;
          text-transform: none;
          padding-bottom: 0;
          margin-bottom: 0;
          font-family: "Roboto", sans-serif;
          font-weight: 700;
          font-size: 17px; }
          #main-menu > .djv-container .djv-main-menu > li > span > .menu-toggle-icon {
            display: block;
            position: absolute;
            right: 10px;
            top: 15px;
            z-index: 2; }
            #main-menu > .djv-container .djv-main-menu > li > span > .menu-toggle-icon::before {
              display: none;
              display: block;
              font-size: 15px; }
          #main-menu > .djv-container .djv-main-menu > li.desplegado {
            border-bottom: 1px solid #dddddd; }
            #main-menu > .djv-container .djv-main-menu > li.desplegado > span > .djv-icon-arrow-down::before {
              display: none;
              content: '\004d'; }
          #main-menu > .djv-container .djv-main-menu > li > span {
            display: block;
            position: relative;
            border-bottom: 1px solid #dddddd;
            padding-left: 10px;
            padding-bottom: 10px;
            padding-top: 10px; }
          #main-menu > .djv-container .djv-main-menu > li:last-of-type span {
            display: block;
            padding: 10px; }
          #main-menu > .djv-container .djv-main-menu > li:last-of-type::before {
            content: ""; } }
      #main-menu > .djv-container .djv-main-menu > li .marcador {
        border-bottom: 2px solid #03b8d6;
        width: 0;
        transition: width 500ms; }
        @media (max-width: 1024px) {
          #main-menu > .djv-container .djv-main-menu > li .marcador {
            display: none; } }
      #main-menu > .djv-container .djv-main-menu > li .grupo-submenu {
        position: absolute;
        display: none;
        padding: 24px;
        background-color: white;
        box-shadow: 0 3px 5px rgba(0, 0, 0, 0.4); }
        #main-menu > .djv-container .djv-main-menu > li .grupo-submenu.alineado-derecha {
          right: 0; }
        @media (max-width: 1024px) {
          #main-menu > .djv-container .djv-main-menu > li .grupo-submenu {
            position: relative;
            box-shadow: none;
            padding: 0;
            position: relative;
            background-color: rgba(255, 255, 255, 0);
            overflow: hidden;
            display: none; } }
        #main-menu > .djv-container .djv-main-menu > li .grupo-submenu > ul {
          padding: 0 20px 0 0;
          text-align: left;
          margin-right: 20px;
          border-right: 1px solid #ddd; }
          @media (max-width: 1024px) {
            #main-menu > .djv-container .djv-main-menu > li .grupo-submenu > ul {
              border-right: none;
              font-family: "Roboto", sans-serif;
              font-weight: 400;
              font-size: 13px;
              padding: 0;
              text-align: left;
              margin-right: 0;
              margin-left: -10px; } }
          #main-menu > .djv-container .djv-main-menu > li .grupo-submenu > ul > li {
            text-transform: none;
            white-space: nowrap;
            margin-bottom: 5px; }
            #main-menu > .djv-container .djv-main-menu > li .grupo-submenu > ul > li .djv-icon-arrow-down::before {
              display: none; }
            @media (max-width: 1024px) {
              #main-menu > .djv-container .djv-main-menu > li .grupo-submenu > ul > li {
                border-bottom: 1px solid #dddddd;
                padding-bottom: 5px;
                text-transform: none;
                white-space: nowrap;
                padding-left: 20px;
                margin-bottom: 0; }
                #main-menu > .djv-container .djv-main-menu > li .grupo-submenu > ul > li:not(.encabezados-submenu) {
                  padding-left: 30px;
                  padding-top: 5px;
                  padding-bottom: 5px;
                  display: none; } }
          #main-menu > .djv-container .djv-main-menu > li .grupo-submenu > ul:last-of-type {
            border-right: none;
            padding-right: 0;
            margin-right: 0; }
      #main-menu > .djv-container .djv-main-menu > li:hover {
        color: #6abac7; }
        #main-menu > .djv-container .djv-main-menu > li:hover [class*="gol-icon-"]::before {
          margin-top: -10px;
          margin-bottom: 15px; }
        #main-menu > .djv-container .djv-main-menu > li:hover .djv-icon-cromos::before {
          font-size: 38px !important;
          margin-bottom: 5px !important; }
        #main-menu > .djv-container .djv-main-menu > li:hover .marcador {
          width: 100%; }
          @media (max-width: 1024px) {
            #main-menu > .djv-container .djv-main-menu > li:hover .marcador {
              display: none; } }
        @media (min-width: 1024px) {
          #main-menu > .djv-container .djv-main-menu > li:hover .grupo-submenu {
            display: flex; } }
        #main-menu > .djv-container .djv-main-menu > li:hover .grupo-submenu > ul > li > a {
          color: #777777;
          cursor: pointer; }
          @media (min-width: 1024px) {
            #main-menu > .djv-container .djv-main-menu > li:hover .grupo-submenu > ul > li > a:hover {
              color: #6abac7; } }
        #main-menu > .djv-container .djv-main-menu > li:hover .grupo-submenu > ul > li.encabezados-submenu > a {
          color: #6abac7; }
    #main-menu > .djv-container .djv-main-menu .vertodos {
      display: none; }
      @media (max-width: 1024px) {
        #main-menu > .djv-container .djv-main-menu .vertodos {
          display: inline-block;
          font-family: "Roboto", sans-serif;
          font-weight: 300;
          font-size: 13px;
          margin-left: 10px;
          color: #666666;
          background-color: #d3f0f5;
          padding: 0 6px;
          border-radius: 6px;
          margin: 10px 5px; } }
    #main-menu > .djv-container .djv-main-menu [class*="gol-icon-"]::before {
      display: block;
      text-align: center;
      font-size: 28px;
      margin-bottom: 5px;
      opacity: 1;
      transition: all 500ms; }
      @media (max-width: 1024px) {
        #main-menu > .djv-container .djv-main-menu [class*="gol-icon-"]::before {
          display: none; } }
    #main-menu > .djv-container .djv-main-menu .djv-icon-cromos::before {
      font-size: 38px !important;
      margin-bottom: -5px !important; }
    #main-menu > .djv-container .djv-main-menu .encabezados-submenu {
      font-family: "Roboto", sans-serif;
      font-weight: 500;
      text-decoration: underline;
      margin-bottom: 10px;
      position: relative; }
      #main-menu > .djv-container .djv-main-menu .encabezados-submenu a {
        color: #6abac7; }
      @media (max-width: 1024px) {
        #main-menu > .djv-container .djv-main-menu .encabezados-submenu {
          text-decoration: none;
          padding-bottom: 5px;
          padding-top: 5px;
          margin-bottom: 15px;
          margin-top: 0;
          border-bottom: 1px solid #dddddd;
          color: #666666;
          font-family: "Roboto", sans-serif;
          font-weight: 400;
          font-size: 15px; }
          #main-menu > .djv-container .djv-main-menu .encabezados-submenu .menu-toggle-icon::after {
            font-family: 'comunes';
            font-style: normal;
            font-weight: normal;
            line-height: 1;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            content: '\004e';
            position: absolute;
            right: 10px;
            font-size: 13px;
            z-index: 2;
            top: 10px; }
          #main-menu > .djv-container .djv-main-menu .encabezados-submenu .menu-toggle-icon.djv-icon-arrow-down::before {
            display: none; }
          #main-menu > .djv-container .djv-main-menu .encabezados-submenu.desplegado {
            background-color: rgba(1, 1, 1, 0.05); }
            #main-menu > .djv-container .djv-main-menu .encabezados-submenu.desplegado .menu-toggle-icon::after {
              content: '\004d'; } }

.recogido #main-menu {
  min-width: 200px; }
  .recogido #main-menu .djv-container {
    min-width: 200px; }
    .recogido #main-menu .djv-container .djv-main-menu {
      width: 85%;
      font-size: 11.7px;
      min-width: 200px; }
      .recogido #main-menu .djv-container .djv-main-menu [class*="gol-icon-"]::before {
        font-size: 0;
        opacity: 0;
        margin-top: -2px;
        margin-bottom: 0; }
      .recogido #main-menu .djv-container .djv-main-menu .djv-icon-cromos::before {
        font-size: 0 !important;
        margin-bottom: -0px !important; }
      .recogido #main-menu .djv-container .djv-main-menu > li {
        margin-left: 1%;
        margin-right: 1%; }
        @media (min-width: 1024px) {
          .recogido #main-menu .djv-container .djv-main-menu > li:hover {
            color: #6abac7; }
            .recogido #main-menu .djv-container .djv-main-menu > li:hover [class*="gol-icon-"]::before {
              margin-top: -10px;
              margin-bottom: 15px; }
            .recogido #main-menu .djv-container .djv-main-menu > li:hover .djv-icon-cromos::before {
              font-size: 0 !important;
              margin-bottom: 15px !important; } }

.djv-nav-drop-columns-column-2 {
  margin: 0 14px;
  padding: 0px;
  column-count: 2; }
  @media (max-width: 768px) {
    .djv-nav-drop-columns-column-2 {
      width: 100%;
      column-count: 1;
      margin: inherit; }
      .djv-nav-drop-columns-column-2 .xs-hidden {
        display: none; } }

.cart-preview.cart-overview {
  width: 100%;
  position: inherit; }

.cart-preview.cart-overview .body {
  display: block;
  position: inherit;
  width: 100%;
  color: #777777; }

.cart-preview {
  float: right;
  position: relative; }
  .cart-preview a,
  .cart-preview a:hover,
  .cart-preview a:visited {
    text-decoration: none;
    color: inherit; }
  .cart-preview .header {
    display: block;
    font-weight: bold;
    cursor: pointer;
    background-color: #6abac7;
    text-align: center;
    align-content: center;
    height: 40px;
    padding: 0 15px 0 10px; }
    .cart-preview .header > :first-child {
      float: left; }
    .cart-preview .header > :last-child {
      float: right; }
    .cart-preview .header i {
      font-size: 30px;
      display: block;
      line-height: 13px;
      padding-top: 6px; }
    .cart-preview .header span {
      position: absolute;
      top: 2px;
      left: 20px;
      font-size: 12px;
      color: #fdf278;
      text-align: center;
      display: block;
      width: 20px; }
  .cart-preview .body {
    display: none;
    width: calc(100vw - 1.1rem);
    background-color: white;
    right: 0;
    border: 1px solid #6abac7;
    border-top: 0;
    color: #777777; }
    .cart-preview .body:hover {
      display: block;
      position: absolute; }
    .cart-preview .body > ul {
      margin: 0;
      padding: 5px 14px 5px 0;
      margin-top: 20px;
      margin-bottom: 20px;
      max-height: 210px;
      overflow: auto;
      padding-right: 16px; }
      .cart-preview .body > ul li {
        list-style: none;
        margin-bottom: 5px;
        padding: 0;
        display: grid;
        grid-template-columns: 9% 60% 26% 5%; }
        .cart-preview .body > ul li > * {
          vertical-align: top; }
        .cart-preview .body > ul li .product-name {
          text-align: left;
          padding: 0px; }
        .cart-preview .body > ul li .product-quantity {
          color: #6abac7;
          height: auto; }
          .cart-preview .body > ul li .product-quantity::after {
            content: 'x';
            margin-right: 8px; }
        .cart-preview .body > ul li .remove-from-cart {
          float: right; }
          .cart-preview .body > ul li .remove-from-cart i::before {
            color: #03b8d6; }
    .cart-preview .body .cart-subtotals {
      border-top: 1px solid #6abac7;
      padding-top: 10px; }
      .cart-preview .body .cart-subtotals .products {
        text-align: right; }
        .cart-preview .body .cart-subtotals .products .value {
          min-width: 60px;
          display: inline-block; }
          .cart-preview .body .cart-subtotals .products .value::after {
            content: " €"; }
      .cart-preview .body .cart-subtotals .shipping, .cart-preview .body .cart-subtotals .tax, .cart-preview .body .cart-subtotals .discount {
        text-align: right; }
        .cart-preview .body .cart-subtotals .shipping .value, .cart-preview .body .cart-subtotals .tax .value, .cart-preview .body .cart-subtotals .discount .value {
          min-width: 60px;
          display: inline-block; }
          .cart-preview .body .cart-subtotals .shipping .value::after, .cart-preview .body .cart-subtotals .tax .value::after, .cart-preview .body .cart-subtotals .discount .value::after {
            content: " €"; }
      .cart-preview .body .cart-subtotals .tax {
        display: none; }
    @media (min-width: 576px) {
      .cart-preview .body {
        width: 400px; } }
    .cart-preview .body .ps-cart-remove-icon {
      position: relative; }
      .cart-preview .body .ps-cart-remove-icon::before {
        position: absolute;
        color: #6abac7;
        display: inline-block;
        font-family: 'comunes';
        font-style: normal;
        font-weight: normal;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        content: '\0053';
        font-size: 6px;
        border: 1px solid;
        border-radius: 50%;
        padding: 3px;
        right: -2px;
        top: 17px; }
    .cart-preview .body .blockcart-finalizar {
      background-color: #13a323;
      color: white;
      margin: 20px auto 10px;
      display: block;
      width: 90%;
      padding: 6px;
      text-align: center;
      text-transform: uppercase;
      cursor: pointer;
      border: 2px solid #13a323;
      transition: all 500ms; }
      .cart-preview .body .blockcart-finalizar:hover {
        box-shadow: 2px 2px 9px rgba(0, 0, 0, 0.4);
        background-color: white;
        color: #61af26;
        border: 2px solid #61af26; }
  .cart-preview:hover .body {
    display: block;
    position: absolute;
    z-index: 999; }

/* this is to make the containers take up vertical space */
.cart-preview .cart-total .label::after {
  content: ": "; }

.cart-preview .cart-total > div {
  clear: both;
  border-bottom: 1px solid #ccc; }

.cart-preview .cart-total > div:not(:last-child) {
  margin-bottom: 5px; }

.cart-preview .cart-total {
  font-weight: bold;
  padding-top: 14px;
  margin-top: 14px;
  border-top: 1px solid #ccc; }
  .cart-preview .cart-total span {
    font-size: 18px; }
  .cart-preview .cart-total .value::after {
    content: " €"; }

#footer {
  margin-top: 50px; }
  #footer .djv-email-subscription {
    background-color: #eafafd;
    padding: 16px 0 6px; }
    #footer .djv-email-subscription .djv-email-subscription-container {
      margin-right: auto;
      margin-left: auto;
      max-width: 1170px;
      min-width: 920px;
      display: grid;
      grid-template-columns: 22% 40% 35%;
      grid-gap: 35px; }
      @media (max-width: 1024px) {
        #footer .djv-email-subscription .djv-email-subscription-container {
          max-width: 920px;
          min-width: 750px; } }
      @media (max-width: 768px) {
        #footer .djv-email-subscription .djv-email-subscription-container {
          max-width: 750px;
          min-width: 480px; } }
      @media (max-width: 480px) {
        #footer .djv-email-subscription .djv-email-subscription-container {
          max-width: 500px;
          min-width: 300px; } }
      @media (max-width: 768px) {
        #footer .djv-email-subscription .djv-email-subscription-container {
          display: block; }
          #footer .djv-email-subscription .djv-email-subscription-container > div {
            margin-bottom: 20px; }
            #footer .djv-email-subscription .djv-email-subscription-container > div > img {
              width: 75%;
              margin-left: auto;
              margin-right: auto;
              display: block;
              max-width: 300px; } }
      #footer .djv-email-subscription .djv-email-subscription-container .email-subcription-text {
        padding-top: 5px;
        padding-left: 10px; }
        @media (max-width: 768px) {
          #footer .djv-email-subscription .djv-email-subscription-container .email-subcription-text {
            margin-bottom: 0; } }
        #footer .djv-email-subscription .djv-email-subscription-container .email-subcription-text h4 {
          font-size: 24px;
          border-bottom: 1px solid #cccccc;
          padding-bottom: 3px;
          display: inline-block;
          padding-right: 25px;
          font-family: "Bree Serif", serif;
          font-weight: 400; }
          @media (max-width: 768px) {
            #footer .djv-email-subscription .djv-email-subscription-container .email-subcription-text h4 {
              font-size: 19px;
              text-align: center;
              padding: 0;
              padding-bottom: 10px; } }
        #footer .djv-email-subscription .djv-email-subscription-container .email-subcription-text p {
          font-family: "Roboto", sans-serif;
          font-weight: 300;
          font-size: 15px;
          color: #666666; }
        #footer .djv-email-subscription .djv-email-subscription-container .email-subcription-text p.notification {
          position: relative;
          right: -453px;
          width: 350px; }
          #footer .djv-email-subscription .djv-email-subscription-container .email-subcription-text p.notification.notification-success {
            color: #13a323; }
            @media (max-width: 768px) {
              #footer .djv-email-subscription .djv-email-subscription-container .email-subcription-text p.notification.notification-success {
                right: 0;
                margin-bottom: 0; } }
          #footer .djv-email-subscription .djv-email-subscription-container .email-subcription-text p.notification.notification-error {
            color: #f13340; }
            #footer .djv-email-subscription .djv-email-subscription-container .email-subcription-text p.notification.notification-error::before {
              content: "* ";
              font-size: 16px; }
            @media (max-width: 768px) {
              #footer .djv-email-subscription .djv-email-subscription-container .email-subcription-text p.notification.notification-error {
                right: 0;
                margin-bottom: 0; } }
      #footer .djv-email-subscription .djv-email-subscription-container .djv-email-subscription-form input[type="text"] {
        border: 1px solid #dddddd;
        padding: 11px 16px;
        font-size: 13px;
        width: 70%;
        margin-right: 5%; }
      #footer .djv-email-subscription .djv-email-subscription-container .djv-email-subscription-form input[type="submit"] {
        width: 20%;
        background-color: #03b8d6;
        border: none;
        color: white;
        padding: 11px;
        font-size: 13px; }
      #footer .djv-email-subscription .djv-email-subscription-container .djv-email-subscription-form .control-label {
        font-size: 15px;
        margin-top: 10px;
        color: #999999; }
      #footer .djv-email-subscription .djv-email-subscription-container .djv-email-subscription-form form p {
        display: none; }
      #footer .djv-email-subscription .djv-email-subscription-container .notification-error {
        position: relative;
        right: -496px;
        width: 350px;
        color: #a51c5a; }
  #footer .block-contact {
    padding: 10px;
    background-color: white;
    font-size: 14px;
    background-color: #c7ecf2;
    font-family: "Roboto", sans-serif;
    font-weight: 300; }
    #footer .block-contact .djv-block-contact-container {
      margin-right: auto;
      margin-left: auto;
      max-width: 1170px;
      min-width: 920px; }
      @media (max-width: 1024px) {
        #footer .block-contact .djv-block-contact-container {
          max-width: 920px;
          min-width: 750px; } }
      @media (max-width: 768px) {
        #footer .block-contact .djv-block-contact-container {
          max-width: 750px;
          min-width: 480px; } }
      @media (max-width: 480px) {
        #footer .block-contact .djv-block-contact-container {
          max-width: 500px;
          min-width: 300px; } }
      #footer .block-contact .djv-block-contact-container > div:not(.djv-block-contact-social) {
        margin: 5px;
        display: inline; }
        #footer .block-contact .djv-block-contact-container > div:not(.djv-block-contact-social) i {
          margin-right: 5px; }
      #footer .block-contact .djv-block-contact-container .djv-block-contact-social {
        float: right; }
        @media (max-width: 768px) {
          #footer .block-contact .djv-block-contact-container .djv-block-contact-social {
            margin-top: 10px;
            padding-top: 10px;
            border-top: 1px solid #dddddd;
            float: none; } }
        #footer .block-contact .djv-block-contact-container .djv-block-contact-social [class*='djv-icon-']::before {
          font-size: 18px;
          color: black; }
      #footer .block-contact .djv-block-contact-container a {
        color: #666666; }
      @media (max-width: 768px) {
        #footer .block-contact .djv-block-contact-container > div:not(.djv-block-contact-social) {
          display: block; }
        #footer .block-contact .djv-block-contact-container > div {
          display: block; } }
  #footer .djv-footer-navigation {
    background: #333333;
    color: white;
    padding: 30px 0; }
    @media (max-width: 768px) {
      #footer .djv-footer-navigation {
        padding: 10px 0; } }
    #footer .djv-footer-navigation .djv-footer-navigation-container {
      margin-right: auto;
      margin-left: auto;
      max-width: 1170px;
      min-width: 920px;
      display: grid;
      grid-template-columns: repeat(3, calc(33.333% - 20px));
      grid-gap: 40px; }
      @media (max-width: 1024px) {
        #footer .djv-footer-navigation .djv-footer-navigation-container {
          max-width: 920px;
          min-width: 750px; } }
      @media (max-width: 768px) {
        #footer .djv-footer-navigation .djv-footer-navigation-container {
          max-width: 750px;
          min-width: 480px; } }
      @media (max-width: 480px) {
        #footer .djv-footer-navigation .djv-footer-navigation-container {
          max-width: 500px;
          min-width: 300px; } }
      @media (max-width: 768px) {
        #footer .djv-footer-navigation .djv-footer-navigation-container {
          display: block; } }
      #footer .djv-footer-navigation .djv-footer-navigation-container > div {
        padding: 0 40px 0 0;
        border-right: 1px solid #aaaaaa; }
        @media (max-width: 768px) {
          #footer .djv-footer-navigation .djv-footer-navigation-container > div {
            margin-bottom: 15px;
            border-right: none;
            padding: 10px; } }
        #footer .djv-footer-navigation .djv-footer-navigation-container > div h4 {
          font-size: 15px;
          padding-bottom: 6px;
          border-bottom: 1px solid #aaaaaa;
          display: block; }
        #footer .djv-footer-navigation .djv-footer-navigation-container > div ul {
          margin: 0;
          padding: 0; }
          #footer .djv-footer-navigation .djv-footer-navigation-container > div ul li {
            list-style: none;
            font-family: "Roboto", sans-serif;
            font-weight: 300;
            font-size: 13px;
            background: transparent;
            background-size: 0%;
            transition: all 1s;
            padding: 3px; }
            #footer .djv-footer-navigation .djv-footer-navigation-container > div ul li:hover {
              background: #999999;
              cursor: pointer; }
            #footer .djv-footer-navigation .djv-footer-navigation-container > div ul li a {
              color: inherit; }
        #footer .djv-footer-navigation .djv-footer-navigation-container > div:last-of-type {
          border-right: none; }
        @media (max-width: 768px) {
          #footer .djv-footer-navigation .djv-footer-navigation-container > div.djv-email-subscription-logo img {
            width: 250px; } }
      #footer .djv-footer-navigation .djv-footer-navigation-container .djv-footer-navigation-metodos-pago > div img:nth-of-type(1), #footer .djv-footer-navigation .djv-footer-navigation-container .djv-footer-navigation-metodos-pago > div img:nth-of-type(2) {
        width: 110px;
        display: inline-block;
        margin: 10px 20px 0 20px;
        text-align: center; }
        @media (max-width: 768px) {
          #footer .djv-footer-navigation .djv-footer-navigation-container .djv-footer-navigation-metodos-pago > div img:nth-of-type(1), #footer .djv-footer-navigation .djv-footer-navigation-container .djv-footer-navigation-metodos-pago > div img:nth-of-type(2) {
            width: 20%;
            margin: 2px; } }
      #footer .djv-footer-navigation .djv-footer-navigation-container .djv-footer-navigation-metodos-pago > div img:nth-of-type(3) {
        width: 90px;
        display: inline-block; }
        @media (max-width: 768px) {
          #footer .djv-footer-navigation .djv-footer-navigation-container .djv-footer-navigation-metodos-pago > div img:nth-of-type(3) {
            width: calc(20% - 10px);
            margin: 2px; } }
      #footer .djv-footer-navigation .djv-footer-navigation-container .djv-footer-navigation-metodos-pago > div img:nth-of-type(4) {
        width: 72px;
        display: inline-block; }
        @media (max-width: 768px) {
          #footer .djv-footer-navigation .djv-footer-navigation-container .djv-footer-navigation-metodos-pago > div img:nth-of-type(4) {
            width: calc(20% - 20px);
            margin: 2px; } }
      #footer .djv-footer-navigation .djv-footer-navigation-container .djv-footer-navigation-metodos-pago > div img:nth-of-type(5) {
        width: 110px;
        display: inline-block; }
        @media (max-width: 768px) {
          #footer .djv-footer-navigation .djv-footer-navigation-container .djv-footer-navigation-metodos-pago > div img:nth-of-type(5) {
            width: calc(20% - 10px);
            margin: 2px; } }
  #footer .djv-footer-below {
    background-color: #111111;
    padding: 5px 0px; }
    #footer .djv-footer-below .djv-footer-below-container {
      margin-right: auto;
      margin-left: auto;
      max-width: 1170px;
      min-width: 920px; }
      @media (max-width: 1024px) {
        #footer .djv-footer-below .djv-footer-below-container {
          max-width: 920px;
          min-width: 750px; } }
      @media (max-width: 768px) {
        #footer .djv-footer-below .djv-footer-below-container {
          max-width: 750px;
          min-width: 480px; } }
      @media (max-width: 480px) {
        #footer .djv-footer-below .djv-footer-below-container {
          max-width: 500px;
          min-width: 300px; } }
      #footer .djv-footer-below .djv-footer-below-container .djv-footer-below-copyright {
        font-size: 13px;
        color: #dddddd; }
        #footer .djv-footer-below .djv-footer-below-container .djv-footer-below-copyright p {
          margin: 0; }
        @media (max-width: 768px) {
          #footer .djv-footer-below .djv-footer-below-container .djv-footer-below-copyright {
            font-size: 9.6px; } }

.slide {
  position: relative;
  display: block;
  margin: auto;
  overflow: hidden; }
  @media (max-width: 1024px) {
    .slide {
      max-height: 332px; } }
  .slide .slide-control-prev, .slide .slide-control-next {
    position: absolute;
    top: 50%;
    z-index: 5;
    transform: translateY(-50%);
    background-color: #E65EA6;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    font-size: 20px;
    color: white;
    cursor: pointer; }
    @media (max-width: 1024px) {
      .slide .slide-control-prev, .slide .slide-control-next {
        width: 80px;
        height: 80px;
        background-color: rgba(230, 94, 166, 0.5); } }
  .slide .slide-control-prev {
    left: 10%; }
    @media (max-width: 1024px) {
      .slide .slide-control-prev {
        left: -40px; } }
  .slide .slide-control-next {
    right: 10%; }
    @media (max-width: 1024px) {
      .slide .slide-control-next {
        right: -40px; } }
  .slide .djv-icon-arrow-left::before, .slide .djv-icon-arrow-right::before {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }
  @media (max-width: 1024px) {
    .slide .djv-icon-arrow-left::before {
      left: auto;
      right: 10%;
      font-weight: bold; }
    .slide .djv-icon-arrow-right::before {
      left: 30%;
      font-weight: bold; } }
  .slide .slide-inner {
    position: relative;
    left: 0;
    display: flex;
    flex-direction: row;
    transition: all .5s; }
    .slide .slide-inner.noanimate {
      transition: none; }
    .slide .slide-inner .slide-item {
      position: relative;
      width: 100%;
      overflow: hidden;
      min-height: 320px; }
      @media (max-width: 1024px) {
        .slide .slide-inner .slide-item {
          min-height: auto; } }
      .slide .slide-inner .slide-item img {
        width: auto;
        object-fit: cover;
        object-position: center;
        position: relative;
        left: 50%;
        transform: translateX(-50%); }
        @media (max-width: 1024px) {
          .slide .slide-inner .slide-item img {
            max-width: 100vw; } }
  .slide .slide-indicator-container {
    display: block;
    position: relative;
    bottom: 0em;
    height: 22px;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    border-top: 1px solid #dddddd;
    border-bottom: 1px solid #dddddd; }
    .slide .slide-indicator-container .slide-indicators {
      margin-right: auto;
      margin-left: auto;
      max-width: 1170px;
      min-width: 920px;
      position: relative;
      right: 0;
      bottom: 0px;
      left: 0;
      z-index: 15;
      display: flex;
      justify-content: center;
      padding-left: 0;
      margin-right: auto;
      margin-left: auto;
      margin-bottom: 0;
      list-style: none;
      width: 70%; }
      @media (max-width: 1024px) {
        .slide .slide-indicator-container .slide-indicators {
          max-width: 920px;
          min-width: 750px; } }
      @media (max-width: 768px) {
        .slide .slide-indicator-container .slide-indicators {
          max-width: 750px;
          min-width: 480px; } }
      @media (max-width: 480px) {
        .slide .slide-indicator-container .slide-indicators {
          max-width: 500px;
          min-width: 300px; } }
      .slide .slide-indicator-container .slide-indicators > li {
        border-left: 1px solid #dddddd;
        border-right: 1px solid #dddddd;
        width: auto;
        height: 20px;
        text-indent: 0;
        background-color: white;
        padding: 0px 15px;
        text-align: center;
        cursor: pointer;
        border-radius: 0;
        margin: 0;
        font-family: "Roboto", sans-serif;
        font-weight: 300;
        font-size: 15px;
        white-space: nowrap;
        text-overflow: ellipsis; }
        .slide .slide-indicator-container .slide-indicators > li::before {
          display: none; }
        .slide .slide-indicator-container .slide-indicators > li::after {
          display: none; }
        .slide .slide-indicator-container .slide-indicators > li.active {
          width: auto;
          height: auto;
          margin: 0;
          background-color: #E65EA6;
          color: white;
          border: none; }
    @media (max-width: 1024px) {
      .slide .slide-indicator-container {
        display: block;
        position: relative;
        bottom: 0;
        height: 22px;
        width: 100%;
        padding: 4px;
        margin-right: auto;
        margin-left: auto; }
        .slide .slide-indicator-container .slide-indicators {
          margin-right: auto;
          margin-left: auto;
          max-width: 1170px;
          min-width: 920px;
          position: relative;
          right: 0;
          bottom: 0px;
          left: 0;
          z-index: 15;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-pack: center;
          -ms-flex-pack: center;
          justify-content: center;
          padding-left: 0;
          margin-right: auto;
          margin-left: auto;
          margin-bottom: 0;
          list-style: none;
          width: 70%;
          min-width: 100px; } }
    @media (max-width: 1024px) and (max-width: 1024px) {
      .slide .slide-indicator-container .slide-indicators {
        max-width: 920px;
        min-width: 750px; } }
    @media (max-width: 1024px) and (max-width: 768px) {
      .slide .slide-indicator-container .slide-indicators {
        max-width: 750px;
        min-width: 480px; } }
    @media (max-width: 1024px) and (max-width: 480px) {
      .slide .slide-indicator-container .slide-indicators {
        max-width: 500px;
        min-width: 300px; } }
    @media (max-width: 1024px) {
          .slide .slide-indicator-container .slide-indicators > li {
            border-right: 0;
            width: 12px;
            height: 12px;
            padding: 0;
            border-radius: 50px;
            margin-right: 5px;
            font-family: "Roboto", sans-serif;
            font-weight: 300;
            font-size: 0;
            background-color: #d3f0f5;
            color: white;
            cursor: pointer; }
            .slide .slide-indicator-container .slide-indicators > li::before {
              display: none; }
            .slide .slide-indicator-container .slide-indicators > li::after {
              display: none; }
            .slide .slide-indicator-container .slide-indicators > li.active {
              background-color: #6abac7;
              width: 12px;
              height: 12px;
              padding: 0;
              border-radius: 50px;
              margin-right: 5px; } }
      @media (max-width: 1024px) and (min-width: 1024px) {
        .slide .slide-indicator-container .slide-indicators > li:last-of-type {
          border-right: none; } }
    @media (max-width: 1024px) {
            .slide .slide-indicator-container .slide-indicators > li::marker {
              display: none; } }

/*#bannerSlider{
    height: 445px;
}*/
.djv_carouselh {
  position: relative; }
  .djv_carouselh > * {
    overflow: hidden;
    position: relative; }

/* ###  Manejadores ### */
.djv_carouselh-viewer {
  position: relative; }

.djv_carouselh-left-arrow,
.djv_carouselh-right-arrow {
  position: absolute;
  z-index: 1;
  top: 50%;
  transform: translateY(-50%);
  width: 40px;
  height: 100%;
  cursor: pointer;
  display: inline-block;
  font-family: 'comunes';
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }
  @media (max-width: 768px) {
    .djv_carouselh-left-arrow,
    .djv_carouselh-right-arrow {
      width: 24px;
      height: 34px;
      font-size: 32px; } }
  @media (max-width: 576px) {
    .djv_carouselh-left-arrow,
    .djv_carouselh-right-arrow {
      background-color: #03b8d6; } }
  .djv_carouselh-left-arrow::before,
  .djv_carouselh-right-arrow::before {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: white;
    font-size: 16px;
    border: 1px solid #eeeeee;
    padding: 8px;
    border-radius: 50%;
    transition: all 500ms; }
    @media (max-width: 576px) {
      .djv_carouselh-left-arrow::before,
      .djv_carouselh-right-arrow::before {
        color: white;
        background-color: transparent;
        box-shadow: none;
        padding: inherit;
        border-radius: 0;
        border: none; } }

.djv_carouselh-left-arrow {
  left: 0px; }
  @media (max-width: 768px) {
    .djv_carouselh-left-arrow {
      border-top-right-radius: 16px;
      border-bottom-right-radius: 16px; } }
  .djv_carouselh-left-arrow::before {
    left: 2px; }
    @media (max-width: 576px) {
      .djv_carouselh-left-arrow::before {
        font-size: 19px;
        left: 0; } }

.djv_carouselh-right-arrow {
  right: 0px; }
  @media (max-width: 768px) {
    .djv_carouselh-right-arrow {
      border-top-left-radius: 16px;
      border-bottom-left-radius: 16px; } }
  .djv_carouselh-right-arrow::before {
    right: 2px; }
    @media (max-width: 576px) {
      .djv_carouselh-right-arrow::before {
        font-size: 19px;
        right: 0; } }

/* ###  Fin manejadores Manejadores ### */
/* ###  Carousel de productos ### */
.djv_carouselh .products {
  text-align: left;
  position: absolute;
  transition: left 1s;
  display: flex; }
  .djv_carouselh .products .products, .djv_carouselh .products .thumbnails.products {
    margin: 0;
    padding: 5px;
    max-width: 100%; }
  @media (max-width: 768px) {
    .djv_carouselh .products {
      position: relative;
      display: block;
      height: 100%; } }

@media (max-width: 768px) {
  .djv_carouselh.swipe::after {
    display: inline-block;
    font-family: 'comunes';
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: '\006e';
    position: relative;
    left: 50%;
    top: -5px;
    transform: translateX(-50%);
    margin: 10px 0;
    font-size: 42px;
    color: #999999; }
  .djv_carouselh > * {
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
    -webkit-scroll-snap-type: mandatory;
    scroll-snap-type: x mandatory;
    -webkit-scroll-snap-points-x: repeat(100%);
    scroll-snap-points-x: repeat(100%); }
    .djv_carouselh > * > * {
      scroll-snap-align: start;
      width: 100%;
      position: relative;
      display: inline-block;
      margin-right: -4px;
      text-align: center; } }

/* ### Fin Carousel de productos ### */
/* ###  Carousel de marcas ### */
.djv-troncal-marcasdestacadas-carousel ul {
  text-align: left;
  position: absolute;
  transition: left 1s; }
  .djv-troncal-marcasdestacadas-carousel ul > li {
    display: inline-block;
    width: 120px;
    min-height: 100px;
    margin: 0;
    padding: 5px 16px; }
    .djv-troncal-marcasdestacadas-carousel ul > li img {
      width: 100%; }

#carouselMarcas {
  margin-right: auto;
  margin-left: auto;
  max-width: 1170px;
  min-width: 920px;
  border-top: 1px solid #eeeeee;
  border-bottom: 1px solid #eeeeee; }
  @media (max-width: 1024px) {
    #carouselMarcas {
      max-width: 920px;
      min-width: 750px; } }
  @media (max-width: 768px) {
    #carouselMarcas {
      max-width: 750px;
      min-width: 480px; } }
  @media (max-width: 480px) {
    #carouselMarcas {
      max-width: 500px;
      min-width: 300px; } }
  #carouselMarcas .carouselh-brands {
    text-align: left;
    position: absolute;
    transition: left 1s; }
    @media (max-width: 768px) {
      #carouselMarcas .carouselh-brands {
        position: relative; } }
    #carouselMarcas .carouselh-brands .brands-miniature {
      display: inline-block !important;
      width: 120px;
      min-width: 120px;
      min-height: 120px;
      margin: 0;
      padding: 5px;
      float: left; }
      @media (max-width: 768px) {
        #carouselMarcas .carouselh-brands .brands-miniature {
          width: 80px;
          min-width: 80px;
          height: 80px;
          min-height: 80px; } }
      #carouselMarcas .carouselh-brands .brands-miniature img {
        width: 100%;
        transition: all 500ms;
        filter: grayscale(100%);
        opacity: 0.7; }
        #carouselMarcas .carouselh-brands .brands-miniature img:hover {
          filter: grayscale(0%);
          opacity: 1; }
  #carouselMarcas .djv_carouselh-viewer {
    position: relative; }
    #carouselMarcas .djv_carouselh-viewer .djv_carouselh-left-arrow,
    #carouselMarcas .djv_carouselh-viewer .djv_carouselh-right-arrow {
      position: absolute;
      z-index: 1;
      top: 50%;
      transform: translateY(-50%);
      width: 44px;
      height: 100%;
      cursor: pointer;
      background-color: white; }
      @media (max-width: 768px) {
        #carouselMarcas .djv_carouselh-viewer .djv_carouselh-left-arrow,
        #carouselMarcas .djv_carouselh-viewer .djv_carouselh-right-arrow {
          background-color: #03b8d6;
          width: 24px;
          height: 34px;
          font-size: 32px;
          box-shadow: none; } }
      #carouselMarcas .djv_carouselh-viewer .djv_carouselh-left-arrow::before,
      #carouselMarcas .djv_carouselh-viewer .djv_carouselh-right-arrow::before {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        font-size: 30px; }
        @media (max-width: 768px) {
          #carouselMarcas .djv_carouselh-viewer .djv_carouselh-left-arrow::before,
          #carouselMarcas .djv_carouselh-viewer .djv_carouselh-right-arrow::before {
            color: white;
            font-size: 22px; } }
    #carouselMarcas .djv_carouselh-viewer .djv_carouselh-left-arrow {
      left: 0;
      box-shadow: none; }
      @media (max-width: 768px) {
        #carouselMarcas .djv_carouselh-viewer .djv_carouselh-left-arrow {
          border-top-right-radius: 16px;
          border-bottom-right-radius: 16px; } }
      #carouselMarcas .djv_carouselh-viewer .djv_carouselh-left-arrow::before {
        left: 12px;
        content: '\0058';
        display: inline-block;
        font-family: 'comunes';
        font-style: normal;
        font-weight: normal;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale; }
        @media (max-width: 768px) {
          #carouselMarcas .djv_carouselh-viewer .djv_carouselh-left-arrow::before {
            font-size: 20px;
            left: 0; } }
    #carouselMarcas .djv_carouselh-viewer .djv_carouselh-right-arrow {
      right: 0;
      box-shadow: none; }
      @media (max-width: 768px) {
        #carouselMarcas .djv_carouselh-viewer .djv_carouselh-right-arrow {
          border-top-left-radius: 16px;
          border-bottom-left-radius: 16px; } }
      #carouselMarcas .djv_carouselh-viewer .djv_carouselh-right-arrow::before {
        right: 12px;
        content: '\0059';
        display: inline-block;
        font-family: 'comunes';
        font-style: normal;
        font-weight: normal;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale; }
        @media (max-width: 768px) {
          #carouselMarcas .djv_carouselh-viewer .djv_carouselh-right-arrow::before {
            font-size: 20px;
            right: 0; } }
  @media (max-width: 768px) {
    #carouselMarcas .carouselh-brands {
      width: 100%;
      overflow-x: auto;
      overflow-y: hidden;
      white-space: nowrap;
      -webkit-overflow-scrolling: touch;
      -webkit-scroll-snap-type: mandatory;
      scroll-snap-type: x mandatory;
      -webkit-scroll-snap-points-x: repeat(100%);
      scroll-snap-points-x: repeat(100%); }
      #carouselMarcas .carouselh-brands .brands-miniature {
        scroll-snap-align: start;
        position: relative;
        display: inline-block;
        margin-right: -4px;
        text-align: center;
        float: none; } }

/* ### fin Carousel de marcas ### */
#modal-zoom {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: white;
  z-index: 5000; }
  @media (max-width: 768px) {
    #modal-zoom {
      overflow: hidden; } }
  @media (max-width: 768px) {
    #modal-zoom #consolaDebug {
      position: fixed;
      top: 60px;
      width: 80px;
      padding: 10px; } }
  #modal-zoom #modal-zoom-close {
    margin-bottom: 50px;
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 10;
    font-size: 32px; }
    @media (max-width: 768px) {
      #modal-zoom #modal-zoom-close {
        top: 16px;
        z-index: 11;
        font-size: 26px;
        color: white; } }
  #modal-zoom .controls {
    position: absolute;
    left: 50%;
    bottom: 142px;
    z-index: 10;
    transform: translateX(-50%);
    background-color: black;
    padding: 18px 10px 10px;
    display: block;
    border-radius: 3px; }
    @media (max-width: 768px) {
      #modal-zoom .controls {
        bottom: auto;
        top: 0;
        right: auto;
        left: 0;
        transform: none;
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;
        border-bottom-right-radius: 3px;
        width: 100%;
        display: flex;
        justify-content: center; } }
    #modal-zoom .controls > i {
      font-style: normal;
      font-weight: 800;
      font-size: 18px;
      color: white;
      margin: 5px 10px 1px;
      cursor: pointer; }
  #modal-zoom #image-inside-modal {
    position: fixed;
    display: block;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh; }
    @media (max-width: 768px) {
      #modal-zoom #image-inside-modal {
        overflow: scroll; } }
    #modal-zoom #image-inside-modal #consola {
      position: fixed;
      top: 0;
      left: 0;
      z-index: 1000; }
    @media (max-width: 768px) {
      #modal-zoom #image-inside-modal .product-cover {
        position: absolute;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        overflow-x: scroll; } }
    #modal-zoom #image-inside-modal .product-cover img {
      touch-action: pinch-zoom;
      position: absolute;
      transform-origin: center center 0;
      width: auto;
      height: 67vh;
      cursor: move; }
      @media (max-width: 768px) {
        #modal-zoom #image-inside-modal .product-cover img {
          position: relative;
          width: 100vw;
          height: auto;
          display: block;
          border: 1px solid black; } }
    #modal-zoom #image-inside-modal #thumbs-modal {
      position: absolute;
      bottom: 0px;
      width: 100%;
      padding: 10px;
      background-color: #ebebeb;
      border: 1px solid #ddd;
      border-radius: 10px; }
      #modal-zoom #image-inside-modal #thumbs-modal ul {
        list-style: none;
        padding: 5px;
        display: flex;
        justify-content: space-around;
        margin-bottom: 0; }
        @media (max-width: 768px) {
          #modal-zoom #image-inside-modal #thumbs-modal ul {
            width: 100%;
            overflow-x: auto;
            overflow-y: hidden;
            white-space: nowrap;
            -webkit-overflow-scrolling: touch;
            -webkit-scroll-snap-type: mandatory;
            scroll-snap-type: x mandatory;
            -webkit-scroll-snap-points-x: repeat(100%);
            scroll-snap-points-x: repeat(100%); } }
        #modal-zoom #image-inside-modal #thumbs-modal ul li {
          margin: 0 4px;
          width: 85px;
          height: 85px;
          padding: 0; }
          @media (max-width: 768px) {
            #modal-zoom #image-inside-modal #thumbs-modal ul li {
              scroll-snap-align: start;
              width: 100%;
              position: relative;
              display: inline-block;
              margin-right: -4px;
              text-align: center; } }
          #modal-zoom #image-inside-modal #thumbs-modal ul li img {
            background-color: white;
            margin-top: 0;
            transition: all 500ms ease-out;
            cursor: pointer;
            box-shadow: 0 0 0 rgba(10, 10, 10, 0);
            width: 85px;
            height: 85px; }
            #modal-zoom #image-inside-modal #thumbs-modal ul li img:hover {
              margin-top: -10px;
              box-shadow: 6px 3px 10px rgba(10, 10, 10, 0.85); }
    #modal-zoom #image-inside-modal .product-flags {
      display: none; }
    #modal-zoom #image-inside-modal .djv-product-img-action {
      display: none; }

#modal-zoom-pan {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: white;
  z-index: 5000; }
  @media (max-width: 768px) {
    #modal-zoom-pan {
      overflow: hidden; } }
  #modal-zoom-pan #controls-modal {
    position: absolute;
    left: 50%;
    bottom: 142px;
    z-index: 10;
    transform: translateX(-50%);
    background-color: black;
    padding: 18px 10px 10px;
    display: block;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px; }
    @media (max-width: 768px) {
      #modal-zoom-pan #controls-modal {
        bottom: auto;
        top: 0;
        right: auto;
        left: 0;
        transform: none;
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;
        border-bottom-right-radius: 3px;
        width: 100%;
        display: flex;
        justify-content: center; } }
    #modal-zoom-pan #controls-modal > i {
      font-style: normal;
      font-weight: 800;
      font-size: 18px;
      color: white;
      margin: 5px 10px 1px;
      cursor: pointer; }
  #modal-zoom-pan #modal-zoom-close {
    margin-bottom: 50px;
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 10;
    font-size: 32px; }
    @media (max-width: 768px) {
      #modal-zoom-pan #modal-zoom-close {
        top: 16px;
        z-index: 11;
        font-size: 26px;
        color: white; } }
  #modal-zoom-pan #imgZoomPan {
    position: absolute;
    /*-webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;*/ }
  #modal-zoom-pan #thumbsZoomPan {
    position: absolute;
    bottom: 0px;
    width: 100%;
    padding: 10px;
    background-color: #ebebeb;
    border: 1px solid #ddd;
    border-radius: 10px; }
    #modal-zoom-pan #thumbsZoomPan ul {
      list-style: none;
      padding: 5px;
      display: flex;
      justify-content: space-around;
      margin-bottom: 0; }
      @media (max-width: 768px) {
        #modal-zoom-pan #thumbsZoomPan ul {
          width: 100%;
          overflow-x: auto;
          overflow-y: hidden;
          white-space: nowrap;
          -webkit-overflow-scrolling: touch;
          -webkit-scroll-snap-type: mandatory;
          scroll-snap-type: x mandatory;
          -webkit-scroll-snap-points-x: repeat(100%);
          scroll-snap-points-x: repeat(100%); } }
      #modal-zoom-pan #thumbsZoomPan ul li {
        margin: 0 4px;
        width: 85px;
        height: 85px;
        padding: 0; }
        @media (max-width: 768px) {
          #modal-zoom-pan #thumbsZoomPan ul li {
            scroll-snap-align: start;
            width: 100%;
            position: relative;
            display: inline-block;
            margin-right: -4px;
            text-align: center; } }
        #modal-zoom-pan #thumbsZoomPan ul li img {
          background-color: white;
          margin-top: 0;
          transition: all 500ms ease-out;
          cursor: pointer;
          box-shadow: 0 0 0 rgba(10, 10, 10, 0);
          width: 85px;
          height: 85px; }
          #modal-zoom-pan #thumbsZoomPan ul li img:hover {
            margin-top: -10px;
            box-shadow: 6px 3px 10px rgba(10, 10, 10, 0.85); }

.expandText .content {
  position: relative;
  overflow: hidden;
  transition: all 600ms; }

.expandText .expand-handler, .expandText .contract-handler {
  text-align: center;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: #ab2b65;
  padding: 10px 0;
  margin-bottom: 0px;
  cursor: pointer; }
  .expandText .expand-handler::before, .expandText .contract-handler::before {
    margin-right: 6px;
    font-size: 16px; }

#checkout .cart-voucher {
  margin-top: 10px;
  padding: 0px 16px 5px; }
  #checkout .cart-voucher h3 {
    font-family: "Bree Serif", serif;
    font-weight: 600;
    font-size: 28px; }
  #checkout .cart-voucher input {
    display: block;
    padding: 4px 10px;
    width: calc(100% - 4px); }
  #checkout .cart-voucher button {
    background-color: #ab2b65;
    color: white;
    border: 2px solid #ab2b65;
    border-radius: 30px;
    transition: all 500ms;
    text-align: center;
    text-transform: uppercase;
    padding: 4px 10px;
    cursor: pointer;
    margin-top: 10px;
    width: 100%;
    font-size: 16px; }
    #checkout .cart-voucher button:hover {
      background-color: white;
      color: #ab2b65; }
  #checkout .cart-voucher p {
    font-size: 15px;
    margin-top: 10px; }
  #checkout .cart-voucher #vouchers-form {
    height: 0;
    opacity: 0;
    display: none; }
  #checkout .cart-voucher #added_vouchers + .vouchers-title + #vouchers-form {
    height: 100%;
    opacity: 1; }
  #checkout .cart-voucher .show-vouchers {
    animation-name: showvouchers;
    animation-duration: 1s;
    animation-fill-mode: forwards; }

@keyframes showvouchers {
  from {
    height: 0;
    opacity: 0; }
  to {
    height: 100%;
    opacity: 1; } }
  #checkout .cart-voucher .added-vouchers-title, #checkout .cart-voucher .vouchers-title {
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-size: 20px;
    color: #76c9d7;
    text-decoration: underline; }
  #checkout .cart-voucher .vouchers-title {
    margin-bottom: 10px;
    text-align: center;
    cursor: pointer; }
  #checkout .cart-voucher #added_vouchers {
    list-style: none;
    font-size: 16px;
    padding: 5px; }
    #checkout .cart-voucher #added_vouchers li {
      display: flex;
      justify-content: space-between; }
      #checkout .cart-voucher #added_vouchers li > * {
        display: table-cell; }
  #checkout .cart-voucher form ul {
    padding-left: 0; }
    #checkout .cart-voucher form ul li {
      list-style: none;
      font-size: 15px;
      text-align: center;
      padding: 5px;
      margin: 10px auto;
      background-color: #e0e0e0;
      transition: background-color 500ms; }
      #checkout .cart-voucher form ul li:hover {
        background-color: #fafafa; }
        #checkout .cart-voucher form ul li:hover .label .code {
          font-size: 22px; }
      #checkout .cart-voucher form ul li .label {
        font-size: 20px; }
        #checkout .cart-voucher form ul li .label .code {
          display: block;
          font-family: "Roboto", sans-serif;
          font-weight: 700;
          font-size: 20px;
          cursor: pointer;
          transition: font-size 500ms; }
      #checkout .cart-voucher form ul li p {
        display: inline-block;
        width: 49%; }
      #checkout .cart-voucher form ul li a {
        display: inline-block;
        float: right;
        padding: 0 14px;
        font-size: 14px; }
  #checkout .cart-voucher input[name="discount_name"] {
    width: 160px;
    text-align: center;
    font-size: 15px;
    border: 1px solid #cccccc;
    padding: 3px; }
  #checkout .cart-voucher button {
    background-color: #ab2b65;
    color: white;
    border: 2px solid #ab2b65;
    border-radius: 30px;
    transition: all 500ms;
    text-align: center;
    text-transform: uppercase;
    padding: 3px 27px;
    cursor: pointer;
    float: right;
    font-size: 14px; }
    #checkout .cart-voucher button:hover {
      background-color: white;
      color: #ab2b65; }
  #checkout .cart-voucher .djv-icon-question {
    font-size: 14px;
    color: #76c9d7;
    margin-top: 46px;
    display: block; }
    #checkout .cart-voucher .djv-icon-question::before {
      margin-right: 5px; }
  #checkout .cart-voucher .notification {
    padding: 5px 0px;
    display: none; }
    #checkout .cart-voucher .notification .js-error-text {
      color: #D53128;
      font-size: 16px;
      margin: 0; }

#cart .cart-voucher {
  margin-top: 16px;
  padding: 16px; }
  #cart .cart-voucher .vouchers-title {
    font-family: "Bree Serif", serif;
    font-weight: 400;
    font-size: 20px; }
  #cart .cart-voucher #vouchers-form {
    height: 0;
    opacity: 0; }
  #cart .cart-voucher .show-vouchers {
    animation-name: showvouchers;
    animation-duration: 1s;
    animation-fill-mode: forwards; }

@keyframes showvouchers {
  from {
    height: 0;
    opacity: 0; }
  to {
    height: 100%;
    opacity: 1; } }
  #cart .cart-voucher .added-vouchers-title, #cart .cart-voucher .vouchers-title {
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-size: 20px;
    color: #76c9d7;
    text-decoration: underline; }
  #cart .cart-voucher .vouchers-title {
    margin-bottom: 10px;
    text-align: center;
    cursor: pointer; }
  #cart .cart-voucher p {
    font-size: 15px;
    margin-top: 10px; }
  #cart .cart-voucher ul {
    padding-left: 0; }
    #cart .cart-voucher ul li {
      list-style: none;
      font-size: 15px;
      display: flex;
      padding: 5px;
      align-items: center; }
      #cart .cart-voucher ul li p, #cart .cart-voucher ul li span {
        display: block;
        margin-right: 5px;
        width: 100%; }
      #cart .cart-voucher ul li a {
        display: inline-block;
        padding: 0;
        font-size: 14px; }
      #cart .cart-voucher ul li:nth-of-type(2n - 1) {
        background-color: #eeeeee; }
  #cart .cart-voucher input[name="discount_name"] {
    width: 160px;
    text-align: center;
    font-size: 15px;
    border: 1px solid #cccccc;
    padding: 3px; }
  #cart .cart-voucher button {
    background-color: #ab2b65;
    color: white;
    border: 2px solid #ab2b65;
    border-radius: 50px;
    transition: all 500ms;
    text-align: center;
    text-transform: uppercase;
    padding: 20px 5px;
    cursor: pointer;
    padding: 3px 27px 2px;
    float: right;
    font-size: 14px; }
    #cart .cart-voucher button:hover {
      background-color: white;
      color: #ab2b65; }
  #cart .cart-voucher .djv-icon-question {
    font-size: 14px;
    color: #76c9d7;
    margin-top: 16px;
    display: block; }
    #cart .cart-voucher .djv-icon-question::before {
      margin-right: 5px; }

.stars {
  color: #a51c5a;
  grid-area: stars; }
  .stars span {
    font-size: 12px;
    color: #666666;
    display: inline-block;
    margin-top: 2px;
    position: absolute;
    margin-left: 4px; }

#product .page-content {
  margin-right: auto;
  margin-left: auto;
  max-width: 1170px;
  min-width: 920px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  margin: 0px auto 0;
  padding-top: 20px; }
  @media (max-width: 1024px) {
    #product .page-content {
      max-width: 920px;
      min-width: 750px; } }
  @media (max-width: 768px) {
    #product .page-content {
      max-width: 750px;
      min-width: 480px; } }
  @media (max-width: 480px) {
    #product .page-content {
      max-width: 500px;
      min-width: 300px; } }
  @media (max-width: 576px) {
    #product .page-content {
      width: calc(100vw - 10px); } }
  #product .page-content .texto-adicional {
    display: none; }
  #product .page-content .product-first-section {
    display: grid;
    grid-gap: 10px 20px;
    grid-template-columns: repeat(2, calc(50% - 20px));
    align-items: start; }
    @media (max-width: 576px) {
      #product .page-content .product-first-section {
        display: block; } }
    #product .page-content .product-first-section.not-for-sale .product-buy-actions .djv-advertencia-descatalogado {
      text-align: center;
      color: red; }
    #product .page-content .product-first-section.not-for-sale .product-buy-actions .product-accessories {
      width: 100%; }
      #product .page-content .product-first-section.not-for-sale .product-buy-actions .product-accessories .product-accessories-container.djv_carouselh article {
        width: 250px; }
    #product .page-content .product-first-section > div {
      padding: 0 16px; }
  #product .page-content .djv-product-images {
    grid-area: images; }
  #product .page-content .product-first-section-right {
    display: grid;
    grid-template-areas: "productName productName productName productManufacturer" "productReference stars stars productManufacturer" "actions actions actions actions" "descriptionshort descriptionshort descriptionshort descriptionshort" "condiciones condiciones condiciones condiciones" "social social social social ";
    grid-template-columns: 30% 25% 25% 20%; }
    @media (max-width: 768px) {
      #product .page-content .product-first-section-right {
        display: block;
        padding: 16px; } }
    #product .page-content .product-first-section-right #product-information-name {
      grid-area: productName; }
      #product .page-content .product-first-section-right #product-information-name h1 {
        font-family: "Roboto", sans-serif;
        font-weight: 700;
        font-size: 28px; }
        @media (max-width: 768px) {
          #product .page-content .product-first-section-right #product-information-name h1 {
            font-size: 22px; } }
    #product .page-content .product-first-section-right #product-reference {
      grid-area: productReference;
      display: inline-block;
      font-size: 13px;
      color: #999999; }
      @media (max-width: 768px) {
        #product .page-content .product-first-section-right #product-reference {
          width: auto;
          margin-right: 5px; } }
    #product .page-content .product-first-section-right #product-manufacturer {
      grid-area: productManufacturer; }
      @media (max-width: 768px) {
        #product .page-content .product-first-section-right #product-manufacturer {
          display: inline-block; } }
      #product .page-content .product-first-section-right #product-manufacturer #manufacturer_logo {
        text-align: right;
        display: block; }
        #product .page-content .product-first-section-right #product-manufacturer #manufacturer_logo img {
          max-height: 125px;
          max-width: 125px;
          width: 100%; }
          @media (max-width: 768px) {
            #product .page-content .product-first-section-right #product-manufacturer #manufacturer_logo img {
              max-width: 80px; } }
      #product .page-content .product-first-section-right #product-manufacturer #manufacturer_name {
        display: none; }
    #product .page-content .product-first-section-right .stars {
      grid-area: stars;
      padding-top: 10px; }
      @media (max-width: 768px) {
        #product .page-content .product-first-section-right .stars {
          width: auto;
          margin-right: 5px; } }
    #product .page-content .product-first-section-right .product-buy-actions {
      grid-area: actions;
      border-top: 1px solid #cccccc;
      padding-top: 10px;
      margin-top: 10px;
      padding-bottom: 5px;
      margin-bottom: 5px; }
    #product .page-content .product-first-section-right #add-to-cart-or-refresh {
      display: grid;
      grid-template-areas: "prices availability addtocart" "prices quantity addtocart";
      grid-gap: 5px 15px;
      grid-template-columns: repeat(3, 33%);
      grid-template-rows: 50px auto;
      align-items: center;
      border-bottom: 1px solid #cccccc;
      padding-bottom: 10px;
      margin-bottom: 10px;
      margin-top: 0;
      margin-bottom: 0;
      padding-top: 5px; }
      @media (max-width: 768px) {
        #product .page-content .product-first-section-right #add-to-cart-or-refresh {
          grid-template-areas: "prices quantity" "prices addtocart" "availability availability";
          grid-template-columns: auto auto;
          grid-template-columns: 50% 50%; } }
    #product .page-content .product-first-section-right .product-prices {
      grid-area: prices;
      text-align: center; }
    #product .page-content .product-first-section-right .djv-product-availability-info {
      grid-area: availability;
      text-align: center;
      align-self: end; }
    #product .page-content .product-first-section-right .product-quantity {
      grid-area: quantity;
      align-self: center;
      justify-self: center;
      padding-top: 8px; }
    #product .page-content .product-first-section-right .solicitar-informacion {
      width: 100%;
      display: block;
      background-color: #ab2b65;
      color: white;
      border: 2px solid #ab2b65;
      border-radius: 2px;
      transition: all 500ms;
      text-align: center;
      text-transform: uppercase;
      padding: 5px 15px;
      cursor: pointer; }
      #product .page-content .product-first-section-right .solicitar-informacion:hover {
        background-color: white;
        color: #ab2b65; }
    #product .page-content .product-first-section-right .product-add-to-cart {
      grid-area: addtocart;
      align-self: center; }
      #product .page-content .product-first-section-right .product-add-to-cart .add-to-cart {
        background-color: #03b8d6;
        color: white;
        border: 2px solid #03b8d6;
        border-radius: 2px;
        transition: all 500ms;
        text-align: center;
        text-transform: uppercase;
        padding: 10px 10px;
        cursor: pointer;
        width: 120px;
        display: block;
        margin: auto; }
        #product .page-content .product-first-section-right .product-add-to-cart .add-to-cart:hover {
          background-color: white;
          color: #03b8d6; }
  #product .page-content #product-description-short {
    grid-area: descriptionshort; }
    #product .page-content #product-description-short h3 {
      margin-top: 20px;
      font-size: 22px; }
    #product .page-content #product-description-short p {
      margin-bottom: 0; }
    #product .page-content #product-description-short #sigue_leyendo {
      display: block;
      font-size: 12px;
      color: #6abac7;
      cursor: pointer; }
  #product .page-content .golo-condicionesyayuda {
    grid-area: condiciones;
    display: grid;
    grid-template-columns: repeat(2, calc(50% - 5px));
    grid-gap: 10px;
    text-align: center;
    margin: 15px 0 10px;
    border-bottom: 1px solid #cccccc; }
    @media (max-width: 768px) {
      #product .page-content .golo-condicionesyayuda {
        display: block; } }
    #product .page-content .golo-condicionesyayuda > div {
      padding: 0 15px; }
      #product .page-content .golo-condicionesyayuda > div i {
        font-size: 40px;
        display: block; }
      #product .page-content .golo-condicionesyayuda > div h4 {
        font-size: 16px;
        text-transform: uppercase;
        font-family: "Roboto", sans-serif;
        font-weight: 500; }
      #product .page-content .golo-condicionesyayuda > div p {
        font-size: 13px;
        color: #666666;
        font-family: "Roboto", sans-serif;
        font-weight: 300;
        margin-bottom: 0; }
      #product .page-content .golo-condicionesyayuda > div:first-of-type {
        border-right: 1px solid #cccccc; }
        @media (max-width: 768px) {
          #product .page-content .golo-condicionesyayuda > div:first-of-type {
            border-right: none;
            border-bottom: 1px solid #cccccc;
            margin-bottom: 16px; } }
  #product .page-content .social-sharing {
    grid-area: social;
    font-size: 22px;
    display: grid;
    grid-template-columns: repeat(6, 16.666%);
    text-align: center;
    width: 50%;
    margin-left: auto;
    margin-right: auto; }
    #product .page-content .social-sharing a {
      color: #666666; }
  #product .page-content .product-second-section {
    display: flex;
    width: 100%;
    margin-top: 20px; }
    @media (max-width: 576px) {
      #product .page-content .product-second-section {
        display: block;
        width: auto;
        padding: 5px 5px; } }
  #product .page-content .product-information-extended {
    flex: 1; }
  #product .page-content .product-information-extended-heading {
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-size: 22px;
    padding: 10px 0;
    color: black;
    border-bottom: 1px solid #dddddd; }
  #product .page-content .product-information-extended-description {
    padding: 0;
    font-family: "Roboto", sans-serif;
    font-weight: 300;
    font-size: 18px;
    color: #444444; }
    #product .page-content .product-information-extended-description h2 {
      margin-top: 20px;
      margin-bottom: 0px;
      font-size: 22px;
      color: black; }
  #product .page-content .product-information-extended-enviosdevoluciones p {
    font-family: "Roboto", sans-serif;
    font-weight: 300;
    font-size: 18px;
    color: #444444; }
    #product .page-content .product-information-extended-enviosdevoluciones p a {
      font-size: 15px; }
  #product .page-content .djv-product-opiniones {
    clear: both;
    display: block;
    width: 60%;
    height: auto;
    transition: all 1500ms; }
    @media (max-width: 768px) {
      #product .page-content .djv-product-opiniones {
        padding: 16px;
        width: 100%; } }
    #product .page-content .djv-product-opiniones .product-information-extended-heading {
      font-family: "Roboto", sans-serif;
      font-weight: 400;
      font-size: 22px;
      padding: 10px 0;
      color: black;
      border-bottom: 1px solid #dddddd; }
    #product .page-content .djv-product-opiniones.ocultar {
      display: none; }
  #product .page-content .masOpiniones {
    display: block;
    cursor: pointer;
    margin: 16px auto; }
    #product .page-content .masOpiniones::before {
      width: 25px;
      height: 25px;
      border-radius: 25px;
      background-color: #ab2b65;
      color: white;
      padding: 5px; }

#product.recogido .page-content {
  margin-top: 0px; }

#product .djv-product-images {
  position: relative; }
  #product .djv-product-images .product-flags {
    position: absolute;
    top: 12px;
    left: 0px;
    z-index: 2;
    margin-left: 0;
    padding-left: 0;
    text-transform: uppercase;
    color: white;
    list-style: none;
    font-size: 14px;
    text-align: center; }
    @media (max-width: 768px) {
      #product .djv-product-images .product-flags {
        left: 10px; } }
    #product .djv-product-images .product-flags li {
      padding: 6px;
      margin-bottom: 10px; }
    #product .djv-product-images .product-flags .discount {
      background-color: #D53128; }
    #product .djv-product-images .product-flags .new {
      background-color: #ff8a00; }
    #product .djv-product-images .product-flags .on-sale, #product .djv-product-images .product-flags .special {
      background-color: #a51c5a; }
  #product .djv-product-images .product-cover {
    overflow: hidden;
    position: relative;
    margin-bottom: 20px;
    cursor: pointer; }
    @media (max-width: 576px) {
      #product .djv-product-images .product-cover {
        min-width: 200px;
        min-height: 0px; } }
    #product .djv-product-images .product-cover img {
      width: auto;
      max-width: 100%;
      margin-left: auto;
      margin-right: auto;
      display: block; }
      @media (max-width: 576px) {
        #product .djv-product-images .product-cover img {
          max-width: 100%; } }
  #product .djv-product-images .djv-product-img-action {
    display: none;
    position: relative;
    overflow: auto;
    top: -70px;
    left: 50%;
    transform: translateX(-50%);
    background: rgba(255, 255, 255, 0.6);
    -moz-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.5);
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.5);
    padding: 10px;
    cursor: pointer; }
    #product .djv-product-images .djv-product-img-action .ver-grande {
      position: relative;
      font-size: 16px;
      text-align: center; }
  @media (max-width: 576px) {
    #product .djv-product-images .product-thumbnails.djv_carouselh, #product .djv-product-images .product-thumbnails {
      margin-top: -16px; } }
  #product .djv-product-images .product-thumbnails.djv_carouselh .products, #product .djv-product-images .product-thumbnails .products {
    /*width: 100%;*/
    height: 125px;
    overflow: hidden;
    padding: 0; }
    #product .djv-product-images .product-thumbnails.djv_carouselh .products li, #product .djv-product-images .product-thumbnails .products li {
      list-style: none;
      padding: 6px;
      display: inline-block;
      width: 125px;
      height: 125px;
      min-height: 125px;
      min-width: 125px; }
      @media (max-width: 576px) {
        #product .djv-product-images .product-thumbnails.djv_carouselh .products li, #product .djv-product-images .product-thumbnails .products li {
          height: 120px;
          width: 120px;
          min-height: 120px;
          min-width: 120px; } }
      #product .djv-product-images .product-thumbnails.djv_carouselh .products li img, #product .djv-product-images .product-thumbnails .products li img {
        width: 100%;
        border: 1px solid #dddddd; }
  #product .djv-product-images .djv-light-box {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: calc(100vw - 16px);
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 100;
    padding: 10%; }
    #product .djv-product-images .djv-light-box .djv-light-box-container {
      position: relative;
      width: 90%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: white; }
      #product .djv-product-images .djv-light-box .djv-light-box-container .products > * {
        width: 100%; }
      #product .djv-product-images .djv-light-box .djv-light-box-container img {
        width: 90%;
        max-width: 750px;
        margin-left: auto;
        margin-right: auto;
        display: block; }
      #product .djv-product-images .djv-light-box .djv-light-box-container .djv-light-box-button-close {
        position: absolute;
        right: 10px;
        top: 10px;
        z-index: 2; }
        #product .djv-product-images .djv-light-box .djv-light-box-container .djv-light-box-button-close::before {
          font-size: 28px; }

.product-quantity {
  height: 50px;
  position: relative; }
  .product-quantity label {
    float: left;
    margin-top: 30px;
    margin-right: 10px;
    font-size: 15px;
    display: none; }
  .product-quantity .product-add-to-cart-quantity {
    display: inline-block; }
    .product-quantity .product-add-to-cart-quantity #quantity_wanted {
      width: 80px;
      text-align: center;
      background-color: #eaeafa;
      border: 2px solid #d7d7d7;
      height: 37px;
      display: block;
      float: left;
      padding-top: 5px; }
      @media (max-width: 768px) {
        .product-quantity .product-add-to-cart-quantity #quantity_wanted {
          width: 55px;
          font-size: 16px; } }
    .product-quantity .product-add-to-cart-quantity .djv-product-add-to-cart-quantity-btn-less {
      background-color: white;
      border: 2px solid #d7d7d7;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
      display: block;
      height: 37px;
      margin-top: 0px;
      position: relative;
      float: left;
      border-right: none;
      padding: 0px 10px;
      font-size: 29px;
      line-height: 31px;
      cursor: pointer; }
    .product-quantity .product-add-to-cart-quantity .djv-product-add-to-cart-quantity-btn-plus {
      background-color: white;
      border: 2px solid #d7d7d7;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
      display: block;
      height: 37px;
      margin-top: 0px;
      position: relative;
      float: left;
      border-left: none;
      padding: 5px 10px;
      line-height: 21px;
      cursor: pointer; }

#product .product-prices .desde {
  display: none; }

#product .product-prices .product-price .with-taxes {
  font-size: 42px;
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  display: inline-block;
  min-width: 180px; }
  @media (max-width: 768px) {
    #product .product-prices .product-price .with-taxes {
      font-size: 30px;
      display: block;
      min-width: 0; } }
  #product .product-prices .product-price .with-taxes small {
    display: none; }

#product .product-prices .product-price .product-without-taxes {
  color: #dddddd; }
  #product .product-prices .product-price .product-without-taxes::after {
    content: "sin IVA";
    position: absolute;
    left: 0;
    font-family: "Roboto", sans-serif;
    font-weight: 300;
    font-size: 12px;
    top: -18px; }

#product .product-prices .product-price.has-discount .with-taxes {
  color: #a51c5a; }

#product .product-prices .unit-price {
  display: block;
  color: #999999;
  font-size: 11.7px; }
  #product .product-prices .unit-price span {
    font-weight: bold;
    font-size: 20px;
    display: block;
    text-align: center; }

#product .product-prices .product-discount {
  margin-top: -14px;
  position: absolute; }
  #product .product-prices .product-discount .discount-amount,
  #product .product-prices .product-discount .discount-percentage {
    display: none;
    min-height: auto;
    padding: 0 5px;
    background-color: #E65EA6;
    font-size: 16px;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-size: 22px;
    color: white; }
  #product .product-prices .product-discount .regular-price {
    display: inline-block;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-size: 19px;
    text-decoration: line-through;
    color: #2c2c2c;
    margin-left: 5px; }

.product-variants label {
  display: block;
  font-size: 14px; }

.product-variants .product-variants-tallas ul {
  display: grid;
  grid-template-columns: auto auto;
  padding-left: 0; }
  .product-variants .product-variants-tallas ul .product-variants-tallas-input-container {
    list-style: none;
    display: block;
    overflow: hidden;
    margin-bottom: 10px;
    cursor: pointer; }
    .product-variants .product-variants-tallas ul .product-variants-tallas-input-container .swap-bg {
      width: 24px;
      height: 24px;
      background-color: white;
      border: 1px solid #aaa;
      margin-right: 10px;
      display: inline-block; }
      .product-variants .product-variants-tallas ul .product-variants-tallas-input-container .swap-bg input {
        opacity: 0; }
    .product-variants .product-variants-tallas ul .product-variants-tallas-input-container .attribute-name {
      background-color: #aaa;
      padding: 5px 15px;
      font-size: 14px; }
    .product-variants .product-variants-tallas ul .product-variants-tallas-input-container.selected .swap-bg::before {
      content: "✔";
      position: absolute;
      margin-left: 5px; }
    .product-variants .product-variants-tallas ul .product-variants-tallas-input-container.selected .attribute-name {
      background-color: #6abac7;
      color: white;
      padding: 5px 15px;
      font-size: 14px; }
    .product-variants .product-variants-tallas ul .product-variants-tallas-input-container.disabled {
      position: relative; }
      .product-variants .product-variants-tallas ul .product-variants-tallas-input-container.disabled span {
        opacity: .5; }

.product-variants .product-variants-color ul {
  padding-left: 0; }
  .product-variants .product-variants-color ul .product-variants-color-input-container {
    list-style: none;
    display: inline-block;
    margin-bottom: 10px;
    width: 36px;
    height: 36px;
    cursor: pointer; }
    .product-variants .product-variants-color ul .product-variants-color-input-container .swap-bg {
      width: 36px;
      height: 36px;
      border: 1px solid #aaa;
      margin-right: 14px;
      display: inline-block; }
      .product-variants .product-variants-color ul .product-variants-color-input-container .swap-bg input {
        opacity: 0; }
    .product-variants .product-variants-color ul .product-variants-color-input-container.selected {
      position: relative; }
      .product-variants .product-variants-color ul .product-variants-color-input-container.selected .swap-bg {
        outline: 5px solid rgba(100, 100, 100, 0.8);
        outline-offset: -5px; }
      .product-variants .product-variants-color ul .product-variants-color-input-container.selected::before {
        content: "\2714";
        display: block;
        width: 20px;
        height: 20px;
        background-color: rgba(255, 255, 255, 0.9);
        color: #13a323;
        border-radius: 40px;
        position: absolute;
        z-index: 9;
        right: -5px;
        top: -5px;
        font-size: 14px;
        padding: 0px 4px; }
    .product-variants .product-variants-color ul .product-variants-color-input-container.disabled {
      position: relative; }
      .product-variants .product-variants-color ul .product-variants-color-input-container.disabled span {
        opacity: .5; }
      .product-variants .product-variants-color ul .product-variants-color-input-container.disabled::after {
        content: "";
        display: block;
        width: 46px;
        height: 46px;
        position: absolute;
        z-index: 9;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        padding: 0px 4px;
        border-radius: 40px;
        background-image: url("/themes/base/assets/img/aspa.svg");
        font-size: 36px;
        color: #E65EA6; }

.djv-product-availability-info.level1 {
  color: #5abe20; }
  .djv-product-availability-info.level1 a {
    color: #5abe20; }

.djv-product-availability-info.level2 {
  color: #ff7926; }
  .djv-product-availability-info.level2 a {
    color: #ff7926; }

.djv-product-availability-info.level3 {
  color: #D53128; }
  .djv-product-availability-info.level3 a {
    color: #D53128; }

#product .product-accessories {
  width: 30%;
  margin-left: 10px; }
  @media (max-width: 768px) {
    #product .product-accessories {
      width: auto;
      float: none;
      padding: 0px;
      margin: 0; } }
  #product .product-accessories > h3 {
    border-bottom: 1px solid #cccccc;
    padding-bottom: 10px;
    margin-bottom: 10px;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-size: 20px;
    padding: 10px 0;
    color: black;
    margin-top: 3px; }
    @media (max-width: 768px) {
      #product .product-accessories > h3 {
        border-bottom: 0; } }
  #product .product-accessories .product-accessories-container {
    padding: 16px;
    text-align: center;
    font-family: "Roboto", sans-serif;
    font-weight: 500; }
    @media (max-width: 576px) {
      #product .product-accessories .product-accessories-container {
        padding: 0; } }
    @media (max-width: 768px) {
      #product .product-accessories .product-accessories-container.djv_carouselh {
        left: -10px; } }
    #product .product-accessories .product-accessories-container.djv_carouselh .product-miniature {
      margin-bottom: 20px;
      width: 320px;
      border-right: 0;
      padding: 0 40px; }
      #product .product-accessories .product-accessories-container.djv_carouselh .product-miniature .product-flags {
        list-style: none;
        position: absolute;
        top: 12px;
        text-transform: uppercase;
        color: white;
        left: 0px;
        font-size: 14px;
        margin-left: 0;
        padding-left: 0;
        z-index: 2; }
        @media (max-width: 768px) {
          #product .product-accessories .product-accessories-container.djv_carouselh .product-miniature .product-flags {
            left: 10px; } }
        #product .product-accessories .product-accessories-container.djv_carouselh .product-miniature .product-flags li {
          padding: 6px;
          margin-bottom: 10px; }
        #product .product-accessories .product-accessories-container.djv_carouselh .product-miniature .product-flags .discount, #product .product-accessories .product-accessories-container.djv_carouselh .product-miniature .product-flags .new {
          background-color: #ff8a00; }
        #product .product-accessories .product-accessories-container.djv_carouselh .product-miniature .product-flags .on-sale, #product .product-accessories .product-accessories-container.djv_carouselh .product-miniature .product-flags .special {
          background-color: #a51c5a; }
      @media (max-width: 768px) {
        #product .product-accessories .product-accessories-container.djv_carouselh .product-miniature {
          width: 100%; } }
      #product .product-accessories .product-accessories-container.djv_carouselh .product-miniature .product-miniature-img-block .thumbnail {
        border-bottom: 1px solid #cccccc;
        padding-bottom: 10px;
        margin-bottom: 10px;
        margin-bottom: 5px;
        padding-bottom: 5px; }
        #product .product-accessories .product-accessories-container.djv_carouselh .product-miniature .product-miniature-img-block .thumbnail img {
          font-size: 12px;
          max-width: 210px;
          margin-left: auto;
          margin-right: auto; }
      #product .product-accessories .product-accessories-container.djv_carouselh .product-miniature .product-miniature-data-block .name-product {
        height: auto; }
      #product .product-accessories .product-accessories-container.djv_carouselh .product-miniature .product-miniature-data-block .product-price-and-shipping .discount-percentage {
        display: none; }
      #product .product-accessories .product-accessories-container.djv_carouselh .product-miniature .product-miniature-data-block .product-price-and-shipping .price {
        font-size: 28px; }
        @media (max-width: 576px) {
          #product .product-accessories .product-accessories-container.djv_carouselh .product-miniature .product-miniature-data-block .product-price-and-shipping .price {
            font-size: 20px; } }
      #product .product-accessories .product-accessories-container.djv_carouselh .product-miniature .product-miniature-data-block .product-price-and-shipping .regular-price {
        font-size: 14px;
        color: #999999;
        text-decoration: line-through; }
      #product .product-accessories .product-accessories-container.djv_carouselh .product-miniature .product-miniature-data-block .product-price-and-shipping .product-discount {
        margin-top: 3px;
        display: block; }
        #product .product-accessories .product-accessories-container.djv_carouselh .product-miniature .product-miniature-data-block .product-price-and-shipping .product-discount .discount-amount,
        #product .product-accessories .product-accessories-container.djv_carouselh .product-miniature .product-miniature-data-block .product-price-and-shipping .product-discount .discount-percentage {
          display: none; }
      #product .product-accessories .product-accessories-container.djv_carouselh .product-miniature .product-miniature-data-block .product-availability.unavailable {
        color: #999999;
        font-size: 12px; }
    #product .product-accessories .product-accessories-container.djv_carouselh .djv_carouselh-left-arrow {
      left: 0; }
    #product .product-accessories .product-accessories-container.djv_carouselh .djv_carouselh-right-arrow {
      right: 0; }

.product-information-extended-heading {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 22px;
  padding: 10px 0;
  color: black;
  border-bottom: 1px solid #dddddd; }

.product-information-extended-description {
  padding: 0;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-size: 18px;
  color: #444444; }
  .product-information-extended-description h2 {
    margin-top: 20px;
    margin-bottom: 0px;
    font-size: 22px;
    color: black; }

.product-information-extended-enviosdevoluciones p {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-size: 18px;
  color: #444444; }
  .product-information-extended-enviosdevoluciones p a {
    font-size: 15px; }

#product .djv-product-detail-breadcrumb {
  border-bottom: 1px solid #dddddd;
  text-align: center;
  font-size: 13px;
  color: #cccccc;
  margin-bottom: 11px; }
  #product .djv-product-detail-breadcrumb ol {
    margin-bottom: 10px; }
    #product .djv-product-detail-breadcrumb ol li {
      display: inline; }
      #product .djv-product-detail-breadcrumb ol li::after {
        content: " > "; }
      #product .djv-product-detail-breadcrumb ol li:last-of-type::after {
        content: ""; }
      #product .djv-product-detail-breadcrumb ol li a {
        color: inherit; }

#product .djv-product-detail-share {
  border-top: 1px solid #dddddd;
  margin-top: 20px;
  padding-top: 20px;
  text-align: center;
  color: #999999;
  font-size: 14px; }
  #product .djv-product-detail-share i {
    margin: 10px;
    font-size: 24px;
    cursor: pointer; }
    @media (max-width: 576px) {
      #product .djv-product-detail-share i {
        font-size: 18px; } }
    #product .djv-product-detail-share i:last-of-type {
      margin-left: 60px; }

#product .page-content #ancla-opiniones {
  height: 20px;
  margin-top: -20px; }

#product .page-content .djv-product-opiniones .djv-product-opinion .comment_author {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-size: 15px;
  color: #666666; }

#product .page-content .djv-product-opiniones .djv-product-opinion .comment_body {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-size: 20px;
  color: #333333; }

#product .page-content .djv-product-opiniones .djv-product-opinion.ocultar {
  display: none; }

.product-miniature {
  text-align: center;
  margin-bottom: 20px;
  position: relative;
  border-right: 1px solid #eeeeee;
  display: grid;
  grid-auto-rows: 50% 50%;
  height: 450px; }
  .product-miniature:nth-of-type(4n) {
    border-right: 0px; }
  @media (max-width: 768px) {
    .product-miniature {
      padding: 5px;
      padding-bottom: 10px;
      border-bottom: 0px solid #eeeeee;
      border-right: 0px;
      min-height: 200px;
      margin-bottom: 15px; } }
  .product-miniature .product-miniature-img-block {
    border: none;
    display: block;
    padding: 0px 0 10px 0;
    margin: 0px; }
    @media (max-width: 768px) {
      .product-miniature .product-miniature-img-block .product-thumbnail {
        border: none;
        display: block; } }
    .product-miniature .product-miniature-img-block .product-thumbnail img {
      display: block;
      position: relative;
      width: 100%;
      max-width: 210px;
      margin-left: auto;
      margin-right: auto; }
  .product-miniature .product-miniature-data-block {
    display: grid;
    grid-template-areas: "name" "stars" "prices" "button";
    grid-template-columns: auto;
    grid-template-rows: max-content max-content auto 44px;
    grid-gap: 5px;
    align-items: end;
    line-height: normal;
    height: 150px; }
    @media (max-width: 768px) {
      .product-miniature .product-miniature-data-block {
        grid-gap: 0px; } }
    .product-miniature .product-miniature-data-block .name-product {
      grid-area: name;
      align-self: start;
      display: block;
      font-size: 16px;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 0; }
      @media (max-width: 768px) {
        .product-miniature .product-miniature-data-block .name-product {
          align-self: center; } }
      .product-miniature .product-miniature-data-block .name-product a {
        color: #444444; }
        @media (max-width: 768px) {
          .product-miniature .product-miniature-data-block .name-product a {
            white-space: normal; } }
    .product-miniature .product-miniature-data-block .stars {
      grid-area: stars; }
    .product-miniature .product-miniature-data-block .djv-product-availability-info {
      display: none; }
    .product-miniature .product-miniature-data-block .product-prices {
      grid-area: prices;
      margin-bottom: 0px;
      align-self: start;
      display: block; }
      @media (max-width: 768px) {
        .product-miniature .product-miniature-data-block .product-prices {
          min-height: auto; } }
      .product-miniature .product-miniature-data-block .product-prices .product-discount {
        position: relative;
        margin-top: 0;
        display: inline-block; }
        .product-miniature .product-miniature-data-block .product-prices .product-discount .regular-price {
          text-decoration: line-through;
          margin-right: 4px; }
      .product-miniature .product-miniature-data-block .product-prices .product-price:not(.has-discount) {
        width: 100%;
        display: block; }
        .product-miniature .product-miniature-data-block .product-prices .product-price:not(.has-discount) .with-taxes {
          text-align: center;
          font-size: 28px;
          font-family: "Roboto", sans-serif;
          font-weight: 700;
          color: black; }
          .product-miniature .product-miniature-data-block .product-prices .product-price:not(.has-discount) .with-taxes small {
            display: none; }
      .product-miniature .product-miniature-data-block .product-prices .product-price.has-discount {
        min-width: auto;
        display: inline-block; }
        .product-miniature .product-miniature-data-block .product-prices .product-price.has-discount .with-taxes {
          min-width: auto;
          color: #a51c5a;
          font-size: 28px;
          font-family: "Roboto", sans-serif;
          font-weight: 700; }
        .product-miniature .product-miniature-data-block .product-prices .product-price.has-discount small {
          display: none; }
      .product-miniature .product-miniature-data-block .product-prices .discount-percentage {
        display: none; }
      .product-miniature .product-miniature-data-block .product-prices .unit-price {
        display: block;
        color: #777777;
        font-size: 12px;
        margin-top: 0px; }
        .product-miniature .product-miniature-data-block .product-prices .unit-price span {
          font-size: 13px; }
      .product-miniature .product-miniature-data-block .product-prices .decimales {
        font-size: 75%; }
    .product-miniature .product-miniature-data-block .product-flags {
      position: absolute !important;
      top: 0;
      left: 0px;
      padding-left: 0; }
      .product-miniature .product-miniature-data-block .product-flags > li {
        list-style: none;
        margin-bottom: 5px;
        text-transform: uppercase;
        font-size: 13px;
        padding: 5px 10px;
        color: white; }
      .product-miniature .product-miniature-data-block .product-flags .new {
        background-color: #ff8a00; }
      .product-miniature .product-miniature-data-block .product-flags .discount {
        background-color: #D53128; }
      .product-miniature .product-miniature-data-block .product-flags .on-sale {
        background-color: #a51c5a; }
    .product-miniature .product-miniature-data-block .variant-links {
      display: none;
      grid-area: variants; }
      .product-miniature .product-miniature-data-block .variant-links .color {
        display: inline-block;
        width: 20px;
        height: 20px;
        border: 1px solid #cccccc; }
    .product-miniature .product-miniature-data-block .product-availability {
      display: none; }
    .product-miniature .product-miniature-data-block .product-list-actions {
      grid-area: button; }
      .product-miniature .product-miniature-data-block .product-list-actions .djv-ver-mas, .product-miniature .product-miniature-data-block .product-list-actions .add-to-cart {
        background-color: #03b8d6;
        color: white;
        border: 2px solid #03b8d6;
        border-radius: 0px;
        transition: all 500ms;
        text-align: center;
        text-transform: uppercase;
        padding: 6px 20px;
        cursor: pointer; }
        .product-miniature .product-miniature-data-block .product-list-actions .djv-ver-mas:hover, .product-miniature .product-miniature-data-block .product-list-actions .add-to-cart:hover {
          background-color: white;
          color: #03b8d6; }

#module-djv_navigation-nsearch .cabecera-imagen-estatica, #module-djv_navigation-nsearch .category-cover, #search .cabecera-imagen-estatica, #search .category-cover {
  display: none; }

#module-djv_navigation-nsearch .djv-navigation-nsearch-header, #search .djv-navigation-nsearch-header {
  margin-right: auto;
  margin-left: auto;
  max-width: 1170px;
  min-width: 920px; }
  @media (max-width: 1024px) {
    #module-djv_navigation-nsearch .djv-navigation-nsearch-header, #search .djv-navigation-nsearch-header {
      max-width: 920px;
      min-width: 750px; } }
  @media (max-width: 768px) {
    #module-djv_navigation-nsearch .djv-navigation-nsearch-header, #search .djv-navigation-nsearch-header {
      max-width: 750px;
      min-width: 480px; } }
  @media (max-width: 480px) {
    #module-djv_navigation-nsearch .djv-navigation-nsearch-header, #search .djv-navigation-nsearch-header {
      max-width: 500px;
      min-width: 300px; } }
  #module-djv_navigation-nsearch .djv-navigation-nsearch-header #category-description, #search .djv-navigation-nsearch-header #category-description {
    padding: 10px 0 20px; }
    @media (max-width: 768px) {
      #module-djv_navigation-nsearch .djv-navigation-nsearch-header #category-description, #search .djv-navigation-nsearch-header #category-description {
        margin-left: 0;
        width: 100%;
        margin-bottom: 0px;
        padding-bottom: 10px;
        border: none; } }
    #module-djv_navigation-nsearch .djv-navigation-nsearch-header #category-description h1, #search .djv-navigation-nsearch-header #category-description h1 {
      display: block;
      font-size: 30px;
      font-family: "Bree Serif", serif;
      font-weight: 400;
      text-align: center; }
      @media (max-width: 768px) {
        #module-djv_navigation-nsearch .djv-navigation-nsearch-header #category-description h1, #search .djv-navigation-nsearch-header #category-description h1 {
          font-size: 20px; } }
    #module-djv_navigation-nsearch .djv-navigation-nsearch-header #category-description a, #search .djv-navigation-nsearch-header #category-description a {
      color: #E65EA6; }
    #module-djv_navigation-nsearch .djv-navigation-nsearch-header #category-description p, #search .djv-navigation-nsearch-header #category-description p {
      font-family: "Roboto", sans-serif;
      font-weight: 300;
      font-size: 19px;
      text-align: center;
      padding: 5px 20px;
      color: #666666; }
      @media (max-width: 768px) {
        #module-djv_navigation-nsearch .djv-navigation-nsearch-header #category-description p, #search .djv-navigation-nsearch-header #category-description p {
          font-size: 15px; } }
    #module-djv_navigation-nsearch .djv-navigation-nsearch-header #category-description .djv-product-list-count, #search .djv-navigation-nsearch-header #category-description .djv-product-list-count {
      position: absolute;
      top: 15px;
      right: 15px; }
    @media (max-width: 768px) {
      #module-djv_navigation-nsearch .djv-navigation-nsearch-header #category-description, #search .djv-navigation-nsearch-header #category-description {
        width: 100%; } }
    #module-djv_navigation-nsearch .djv-navigation-nsearch-header #category-description span, #search .djv-navigation-nsearch-header #category-description span {
      color: #E65EA6;
      cursor: pointer; }
      #module-djv_navigation-nsearch .djv-navigation-nsearch-header #category-description span:hover, #search .djv-navigation-nsearch-header #category-description span:hover {
        text-decoration: underline; }
    #module-djv_navigation-nsearch .djv-navigation-nsearch-header #category-description .cart-advisor, #search .djv-navigation-nsearch-header #category-description .cart-advisor {
      display: none;
      background-color: #E65EA6;
      padding: 6px 16px;
      text-align: center;
      color: #fff; }

#module-djv_navigation-nsearch #djv-two-columns-layer, #search #djv-two-columns-layer {
  margin-right: auto;
  margin-left: auto;
  max-width: 1170px;
  min-width: 920px; }
  @media (max-width: 1024px) {
    #module-djv_navigation-nsearch #djv-two-columns-layer, #search #djv-two-columns-layer {
      max-width: 920px;
      min-width: 750px; } }
  @media (max-width: 768px) {
    #module-djv_navigation-nsearch #djv-two-columns-layer, #search #djv-two-columns-layer {
      max-width: 750px;
      min-width: 480px; } }
  @media (max-width: 480px) {
    #module-djv_navigation-nsearch #djv-two-columns-layer, #search #djv-two-columns-layer {
      max-width: 500px;
      min-width: 300px; } }
  @media (max-width: 768px) {
    #module-djv_navigation-nsearch #djv-two-columns-layer, #search #djv-two-columns-layer {
      margin-top: 0px !important; } }
  #module-djv_navigation-nsearch #djv-two-columns-layer #content-wrapper, #search #djv-two-columns-layer #content-wrapper {
    position: relative; }
    #module-djv_navigation-nsearch #djv-two-columns-layer #content-wrapper h1, #search #djv-two-columns-layer #content-wrapper h1 {
      display: block;
      text-align: center;
      font-size: 20px; }
      @media (max-width: 768px) {
        #module-djv_navigation-nsearch #djv-two-columns-layer #content-wrapper h1, #search #djv-two-columns-layer #content-wrapper h1 {
          font-size: 28px; } }
    #module-djv_navigation-nsearch #djv-two-columns-layer #content-wrapper #js-product-list-top, #search #djv-two-columns-layer #content-wrapper #js-product-list-top {
      border-bottom: 1px solid #eeeeee;
      margin: -8px 0 20px;
      display: block;
      padding: 15px 0 5px; }
      @media (max-width: 768px) {
        #module-djv_navigation-nsearch #djv-two-columns-layer #content-wrapper #js-product-list-top, #search #djv-two-columns-layer #content-wrapper #js-product-list-top {
          grid-template-columns: 100%;
          margin-top: 5px;
          padding: 5px 5px 0px;
          border: none;
          background-color: #eee; } }
      #module-djv_navigation-nsearch #djv-two-columns-layer #content-wrapper #js-product-list-top .products-sort-order, #search #djv-two-columns-layer #content-wrapper #js-product-list-top .products-sort-order {
        position: relative; }
        #module-djv_navigation-nsearch #djv-two-columns-layer #content-wrapper #js-product-list-top .products-sort-order ul, #search #djv-two-columns-layer #content-wrapper #js-product-list-top .products-sort-order ul {
          padding: 0; }
          #module-djv_navigation-nsearch #djv-two-columns-layer #content-wrapper #js-product-list-top .products-sort-order ul li, #search #djv-two-columns-layer #content-wrapper #js-product-list-top .products-sort-order ul li {
            list-style: none;
            display: inline-block;
            margin: 5px;
            font-size: 14px; }
            #module-djv_navigation-nsearch #djv-two-columns-layer #content-wrapper #js-product-list-top .products-sort-order ul li.sort-selected, #search #djv-two-columns-layer #content-wrapper #js-product-list-top .products-sort-order ul li.sort-selected {
              background-color: #d3f0f5;
              color: white;
              padding: 5px 10px;
              border-radius: 2px;
              display: block;
              width: 25%; }
            #module-djv_navigation-nsearch #djv-two-columns-layer #content-wrapper #js-product-list-top .products-sort-order ul li a, #search #djv-two-columns-layer #content-wrapper #js-product-list-top .products-sort-order ul li a {
              padding: 5px 10px;
              background-color: #dddddd;
              border-radius: 2px;
              display: inline-block;
              color: #777777; }
        #module-djv_navigation-nsearch #djv-two-columns-layer #content-wrapper #js-product-list-top .products-sort-order select, #search #djv-two-columns-layer #content-wrapper #js-product-list-top .products-sort-order select {
          border: none;
          background-color: white;
          font-size: 13px;
          position: absolute;
          right: 0;
          top: -18px; }
        @media (max-width: 768px) {
          #module-djv_navigation-nsearch #djv-two-columns-layer #content-wrapper #js-product-list-top .products-sort-order select, #search #djv-two-columns-layer #content-wrapper #js-product-list-top .products-sort-order select {
            border: none;
            background-color: #eee; } }
      #module-djv_navigation-nsearch #djv-two-columns-layer #content-wrapper #js-product-list-top .djv-navigator-responsive-filter-button, #search #djv-two-columns-layer #content-wrapper #js-product-list-top .djv-navigator-responsive-filter-button {
        display: none;
        font-size: 14px;
        color: #777777;
        text-align: right;
        padding-bottom: 5px;
        position: relative;
        cursor: pointer; }
        #module-djv_navigation-nsearch #djv-two-columns-layer #content-wrapper #js-product-list-top .djv-navigator-responsive-filter-button::before, #search #djv-two-columns-layer #content-wrapper #js-product-list-top .djv-navigator-responsive-filter-button::before {
          font-size: 22px;
          margin-right: 10px;
          position: absolute;
          right: 30px;
          top: -45px; }
        @media (max-width: 768px) {
          #module-djv_navigation-nsearch #djv-two-columns-layer #content-wrapper #js-product-list-top .djv-navigator-responsive-filter-button, #search #djv-two-columns-layer #content-wrapper #js-product-list-top .djv-navigator-responsive-filter-button {
            display: block;
            font-size: 0; }
            #module-djv_navigation-nsearch #djv-two-columns-layer #content-wrapper #js-product-list-top .djv-navigator-responsive-filter-button::before, #search #djv-two-columns-layer #content-wrapper #js-product-list-top .djv-navigator-responsive-filter-button::before {
              right: 0;
              top: -24px; } }
      #module-djv_navigation-nsearch #djv-two-columns-layer #content-wrapper #js-product-list-top #djv-filters-your-seleccion, #search #djv-two-columns-layer #content-wrapper #js-product-list-top #djv-filters-your-seleccion {
        margin-left: 0;
        padding-left: 0;
        margin-top: 20px;
        display: none; }
        #module-djv_navigation-nsearch #djv-two-columns-layer #content-wrapper #js-product-list-top #djv-filters-your-seleccion li, #search #djv-two-columns-layer #content-wrapper #js-product-list-top #djv-filters-your-seleccion li {
          list-style: none; }
          #module-djv_navigation-nsearch #djv-two-columns-layer #content-wrapper #js-product-list-top #djv-filters-your-seleccion li a, #search #djv-two-columns-layer #content-wrapper #js-product-list-top #djv-filters-your-seleccion li a {
            color: inherit; }
            #module-djv_navigation-nsearch #djv-two-columns-layer #content-wrapper #js-product-list-top #djv-filters-your-seleccion li a b, #search #djv-two-columns-layer #content-wrapper #js-product-list-top #djv-filters-your-seleccion li a b {
              display: none; }
        #module-djv_navigation-nsearch #djv-two-columns-layer #content-wrapper #js-product-list-top #djv-filters-your-seleccion .djv-navigation-left-selected-item a::before, #search #djv-two-columns-layer #content-wrapper #js-product-list-top #djv-filters-your-seleccion .djv-navigation-left-selected-item a::before {
          display: inline-block;
          font-style: normal;
          font-weight: normal;
          line-height: 1;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          content: 'X';
          color: white;
          background-color: #D53128;
          padding: 4px 6px;
          border-radius: 50px;
          font-size: 12px;
          font-weight: bold;
          margin-right: 5px; }
    #module-djv_navigation-nsearch #djv-two-columns-layer #content-wrapper #js-product-list, #search #djv-two-columns-layer #content-wrapper #js-product-list {
      display: grid;
      grid-template-columns: repeat(4, 25%); }
      @media (max-width: 1024px) {
        #module-djv_navigation-nsearch #djv-two-columns-layer #content-wrapper #js-product-list, #search #djv-two-columns-layer #content-wrapper #js-product-list {
          position: relative;
          width: 100%;
          grid-template-columns: 50% 50%;
          grid-gap: 5px; } }
      @media (max-width: 768px) {
        #module-djv_navigation-nsearch #djv-two-columns-layer #content-wrapper #js-product-list .product-miniature .name-product, #search #djv-two-columns-layer #content-wrapper #js-product-list .product-miniature .name-product {
          height: 40px;
          overflow: hidden; } }
    #module-djv_navigation-nsearch #djv-two-columns-layer #content-wrapper .textoSeo, #search #djv-two-columns-layer #content-wrapper .textoSeo {
      color: #666666;
      font-family: "Roboto", sans-serif;
      font-weight: 300; }
      #module-djv_navigation-nsearch #djv-two-columns-layer #content-wrapper .textoSeo img, #search #djv-two-columns-layer #content-wrapper .textoSeo img {
        margin-top: 40px;
        margin-bottom: 20px; }
      #module-djv_navigation-nsearch #djv-two-columns-layer #content-wrapper .textoSeo a, #search #djv-two-columns-layer #content-wrapper .textoSeo a {
        color: #E65EA6; }
      #module-djv_navigation-nsearch #djv-two-columns-layer #content-wrapper .textoSeo h2, #search #djv-two-columns-layer #content-wrapper .textoSeo h2 {
        font-family: "Bree Serif", serif;
        font-weight: 400;
        font-weight: 400;
        font-size: 30px;
        margin-top: 30px;
        color: #333333; }

.pagination {
  position: relative;
  display: block;
  padding-bottom: 1px;
  margin: 0px 0;
  border-radius: 0;
  width: 100%;
  font-size: 13px; }
  @media (max-width: 768px) {
    .pagination {
      display: block;
      padding-bottom: 0px; } }
  .pagination .pagination-summary {
    color: #777777; }
    @media (max-width: 768px) {
      .pagination .pagination-summary {
        display: none; } }
  .pagination ul {
    position: absolute;
    margin-left: 0;
    padding-left: 0;
    right: 16px;
    top: -3px; }
    @media (max-width: 768px) {
      .pagination ul {
        position: relative;
        margin-top: 0px;
        margin-bottom: 0px;
        right: 0;
        top: 0; } }
    .pagination ul li {
      display: inline-block;
      position: relative; }
      .pagination ul li a {
        color: #999999;
        padding: 1px 6px; }
        .pagination ul li a[rel="prev"] {
          margin-right: 10px; }
        .pagination ul li a[rel="nofollow"] {
          min-width: 26px;
          display: block;
          text-align: center;
          border: 1px solid #eeeeee;
          font-size: 14px; }
        .pagination ul li a[rel="next"] {
          margin-left: 10px; }
      .pagination ul li.current a[rel="nofollow"] {
        background-color: white;
        color: #eeeeee; }

.products-sort-order {
  position: relative; }
  .products-sort-order ul {
    padding: 0; }
    .products-sort-order ul li {
      list-style: none;
      display: inline-block;
      margin: 5px;
      font-size: 14px; }
      .products-sort-order ul li.sort-selected {
        background-color: #d3f0f5;
        color: white;
        padding: 5px 10px;
        border-radius: 2px;
        display: block;
        width: 25%; }
      .products-sort-order ul li a {
        padding: 5px 10px;
        background-color: #dddddd;
        border-radius: 2px;
        display: inline-block;
        color: #777777; }
  .products-sort-order select {
    border: none;
    background-color: white;
    font-size: 13px;
    position: absolute;
    right: 0;
    top: -18px; }
  @media (max-width: 768px) {
    .products-sort-order select {
      border: none;
      background-color: #eee; } }

@media (max-width: 768px) {
  #module-djv_navigation-nsearch #left-column {
    display: none; } }

html:not([dir="rtl"]) .noUi-horizontal .noUi-handle {
  right: -10px !important;
  left: auto; }

#djv-navigation-left {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 16px; }
  @media (max-width: 576px) {
    #djv-navigation-left {
      position: fixed;
      top: 0;
      background-color: white;
      z-index: 100;
      width: 100%;
      left: 0;
      padding: 45px 10px 10px;
      overflow-y: scroll;
      height: 100vh; } }
  #djv-navigation-left h2 {
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-size: 18.4px;
    padding: 0px 0 4px 0;
    border-bottom: 1px solid #dddddd;
    margin-bottom: 10px;
    text-transform: uppercase; }
    @media (max-width: 768px) {
      #djv-navigation-left h2 {
        position: fixed;
        font-size: 16px;
        width: 100%;
        background-color: white;
        left: 0;
        top: 0;
        padding: 10px;
        z-index: 2; } }
  #djv-navigation-left #left-responsive-switch {
    font-family: "Roboto", sans-serif;
    font-weight: 700;
    font-size: 16px;
    text-transform: uppercase;
    color: #a51c5a;
    padding-top: 12px;
    padding-left: 0;
    display: block;
    height: 36px;
    border-bottom: 1px solid #eee;
    padding-bottom: 30px; }
    #djv-navigation-left #left-responsive-switch #djv-icon-close-filters {
      display: none;
      float: right;
      font-size: 12px;
      font-style: normal;
      font-family: "Roboto", sans-serif;
      font-weight: 400;
      cursor: pointer;
      background-color: #D53128;
      color: white;
      padding: 5px;
      border-radius: 5px; }
      #djv-navigation-left #left-responsive-switch #djv-icon-close-filters::before {
        display: inline-block;
        font-style: normal;
        font-weight: normal;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        content: 'X';
        color: #D53128;
        background-color: white;
        padding: 4px 6px;
        border-radius: 50px;
        font-size: 13px;
        font-weight: bold;
        margin-right: 0px; }
      @media (max-width: 576px) {
        #djv-navigation-left #left-responsive-switch #djv-icon-close-filters {
          display: inline-block;
          padding: 4px 7px;
          position: absolute;
          right: 10px;
          top: 5px; } }
    #djv-navigation-left #left-responsive-switch span {
      float: right;
      width: 20%;
      font-size: 13px;
      color: #cccccc;
      padding-top: 8px; }
  #djv-navigation-left h3 {
    font-family: "Roboto", sans-serif;
    font-weight: 700;
    font-size: 16px;
    color: #a51c5a;
    padding-top: 0; }
  #djv-navigation-left .djv_navigation-price {
    margin-bottom: 15px; }
  #djv-navigation-left article, #djv-navigation-left div {
    margin-bottom: 15px; }
    #djv-navigation-left article ul, #djv-navigation-left div ul {
      margin-left: 0;
      padding-left: 0; }
      #djv-navigation-left article ul li, #djv-navigation-left div ul li {
        list-style: none;
        font-size: 13px; }
        @media (max-width: 768px) {
          #djv-navigation-left article ul li, #djv-navigation-left div ul li {
            font-size: 16px; } }
        #djv-navigation-left article ul li a, #djv-navigation-left div ul li a {
          color: inherit; }
          #djv-navigation-left article ul li a b, #djv-navigation-left div ul li a b {
            display: none; }
        #djv-navigation-left article ul li span, #djv-navigation-left div ul li span {
          margin-left: 5px;
          font-size: 9.75px;
          color: #aaaaaa; }
    #djv-navigation-left article.special, #djv-navigation-left div.special {
      background-color: #E65EA6;
      padding: 5px; }
  #djv-navigation-left .djv_navigation-price .djv_navigation-price-slider-range {
    margin-bottom: 15px;
    font-size: 13px; }
    @media (max-width: 768px) {
      #djv-navigation-left .djv_navigation-price .djv_navigation-price-slider-range {
        font-size: 16px; } }
  #djv-navigation-left .djv_navigation-price .noUi-base, #djv-navigation-left .djv_navigation-price .noUi-connects {
    height: 2px;
    background: #eeeeee; }
  #djv-navigation-left .djv_navigation-price .noUi-target {
    background: #999999;
    border-radius: 0px;
    border: none;
    box-shadow: none;
    height: 2px; }
  #djv-navigation-left .djv_navigation-price .djv_navigation-price-slider {
    margin-bottom: 20px;
    width: 90%;
    margin-left: 5%;
    margin-bottom: 20px; }
    #djv-navigation-left .djv_navigation-price .djv_navigation-price-slider .noUi-connect {
      background: #999999; }
    #djv-navigation-left .djv_navigation-price .djv_navigation-price-slider .noUi-handle {
      border: none;
      border-radius: 50px;
      background: #999999;
      cursor: default;
      box-shadow: none;
      width: 14px;
      height: 14px; }
    #djv-navigation-left .djv_navigation-price .djv_navigation-price-slider .noUi-handle::after, #djv-navigation-left .djv_navigation-price .djv_navigation-price-slider .noUi-handle::before {
      content: "";
      display: none; }
  #djv-navigation-left .djv_navigation-price .djv_navigation-price-apply-button {
    background-color: #03b8d6;
    color: white;
    border: 2px solid #03b8d6;
    border-radius: 2px;
    transition: all 500ms;
    text-align: center;
    text-transform: uppercase;
    padding: 4px 10px;
    cursor: pointer;
    margin-top: 29px;
    width: 99%;
    margin-left: auto;
    margin-right: auto;
    display: block;
    font-size: 13px; }
    #djv-navigation-left .djv_navigation-price .djv_navigation-price-apply-button:hover {
      background-color: white;
      color: #03b8d6; }
  #djv-navigation-left .djv-navigation-left-selected-item a {
    padding-left: 18px;
    position: relative; }
    #djv-navigation-left .djv-navigation-left-selected-item a::before {
      display: inline-block;
      font-family: 'comunes';
      font-style: normal;
      font-weight: normal;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      content: '\0054';
      color: #a51c5a;
      position: absolute;
      left: 0;
      top: 1px; }
  #djv-navigation-left .djv-navigation-left-nofilter-selected {
    color: #777777; }
  #djv-navigation-left .filters-selected #djv-filters-your-seleccion {
    padding-left: 0;
    margin-left: 5px; }
    #djv-navigation-left .filters-selected #djv-filters-your-seleccion .djv-navigation-left-selected-item {
      list-style: none; }
      #djv-navigation-left .filters-selected #djv-filters-your-seleccion .djv-navigation-left-selected-item .djv-icon-delete label {
        display: none; }
      #djv-navigation-left .filters-selected #djv-filters-your-seleccion .djv-navigation-left-selected-item .djv-icon-delete::before {
        margin-right: 5px; }

#djv-navigation-left .filterDebug {
  display: block;
  padding: 5px;
  margin: 20px 5px;
  border: 1px solid #03b8d6;
  text-align: center; }

#djv-navigation-left .djv_navigation-left-filters .djv_navigation-left-filters-header {
  display: grid;
  grid-template-columns: 80% 20%; }
  #djv-navigation-left .djv_navigation-left-filters .djv_navigation-left-filters-header span {
    font-size: 11px;
    color: #cccccc;
    padding-top: 8px; }
  #djv-navigation-left .djv_navigation-left-filters .djv_navigation-left-filters-header span.title {
    font-family: "Oswald-Medium";
    font-size: 22px;
    margin-bottom: 20px;
    color: #777777;
    padding-top: 0; }

#djv-navigation-left .djv_navigation-left-filters li {
  display: grid;
  grid-template-columns: 80% 20%;
  margin-bottom: 0;
  border-bottom: 1px solid #03b8d6;
  font-size: 16px;
  background-color: rgba(130, 130, 130, 0);
  transition: background-color 750ms;
  padding: 1px; }
  #djv-navigation-left .djv_navigation-left-filters li a {
    padding: 2px; }
  #djv-navigation-left .djv_navigation-left-filters li span {
    text-align: center;
    padding: 1px;
    font-size: 11px;
    color: #cccccc; }
  #djv-navigation-left .djv_navigation-left-filters li:hover {
    background-color: whitesmoke; }

.djv-listas-productos {
  margin-top: 25px;
  margin-bottom: 25px;
  width: calc(100% - 100px);
  margin-left: auto;
  margin-right: auto; }
  @media (max-width: 768px) {
    .djv-listas-productos {
      width: 100%; } }
  .djv-listas-productos h2 {
    font-family: "Bree Serif", serif;
    font-weight: 400;
    font-size: 28px;
    text-align: center; }
    @media (max-width: 768px) {
      .djv-listas-productos h2 {
        font-size: 24px; } }
  .djv-listas-productos p {
    text-align: center; }
  .djv-listas-productos .djv_carouselh {
    margin-top: 40px;
    margin-bottom: 40px; }
    .djv-listas-productos .djv_carouselh h2 {
      font-family: "Roboto", sans-serif;
      font-weight: 400; }
    .djv-listas-productos .djv_carouselh article {
      width: 214px; }
      @media (max-width: 768px) {
        .djv-listas-productos .djv_carouselh article {
          scroll-snap-align: start;
          width: 100%;
          position: relative;
          display: inline-block;
          margin-right: -4px;
          text-align: center;
          border-right: 0; } }

#index .products .product-miniature {
  border-right: 1px solid #dddddd;
  padding-left: 10px;
  padding-right: 10px;
  text-align: center;
  position: relative; }
  #index .products .product-miniature .product-description-short p {
    margin-bottom: 5px; }
  #index .products .product-miniature > a img {
    width: 100%;
    max-width: 210px; }

#pagenotfound #djv-two-columns-layer {
  margin-right: auto;
  margin-left: auto;
  max-width: 1170px;
  min-width: 920px; }
  @media (max-width: 1024px) {
    #pagenotfound #djv-two-columns-layer {
      max-width: 920px;
      min-width: 750px; } }
  @media (max-width: 768px) {
    #pagenotfound #djv-two-columns-layer {
      max-width: 750px;
      min-width: 480px; } }
  @media (max-width: 480px) {
    #pagenotfound #djv-two-columns-layer {
      max-width: 500px;
      min-width: 300px; } }
  #pagenotfound #djv-two-columns-layer #search_widget input[type="text"] {
    padding: 6px; }
  #pagenotfound #djv-two-columns-layer #search_widget button {
    background-color: #5abe20;
    color: white;
    border: 2px solid #5abe20;
    border-radius: 2px;
    transition: all 500ms;
    text-align: center;
    text-transform: uppercase;
    padding: 4px 10px;
    cursor: pointer; }
    #pagenotfound #djv-two-columns-layer #search_widget button:hover {
      background-color: white;
      color: #5abe20; }
    #pagenotfound #djv-two-columns-layer #search_widget button::after {
      content: "Buscar"; }

.page-not-found p {
  display: block;
  width: 400px; }

.page-not-found #search_widget input[type="text"] {
  padding: 6px; }

.page-not-found #search_widget button {
  background-color: #5abe20;
  color: white;
  border: 2px solid #5abe20;
  border-radius: 2px;
  transition: all 500ms;
  text-align: center;
  text-transform: uppercase;
  padding: 4px 10px;
  cursor: pointer; }
  .page-not-found #search_widget button:hover {
    background-color: white;
    color: #5abe20; }
  .page-not-found #search_widget button::after {
    content: "Buscar"; }

#blockcart-modal.modal {
  display: block;
  background-color: rgba(0, 0, 0, 0.5); }

.modal-dialog {
  max-width: 920px;
  width: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -70%); }
  .modal-dialog .modal-content .modal-header {
    text-align: center;
    background-color: #03b8d6;
    color: white; }
    .modal-dialog .modal-content .modal-header button.close {
      position: absolute;
      right: 16px;
      top: 16px; }
    .modal-dialog .modal-content .modal-header h4 {
      text-align: center; }
      .modal-dialog .modal-content .modal-header h4 .djv-icon-ok {
        margin-right: 20px; }
  .modal-dialog .modal-content .modal-body {
    display: grid;
    grid-template-areas: "imagen data content" "imagen data buttons";
    grid-gap: 10px; }
    @media (max-width: 768px) {
      .modal-dialog .modal-content .modal-body {
        grid-template-areas: "imagen data" "content content" "buttons buttons"; } }
    .modal-dialog .modal-content .modal-body .modal-body-product-img {
      grid-area: imagen; }
      @media (max-width: 768px) {
        .modal-dialog .modal-content .modal-body .modal-body-product-img {
          max-width: 250px; } }
      .modal-dialog .modal-content .modal-body .modal-body-product-img img {
        max-width: 250px; }
        @media (max-width: 768px) {
          .modal-dialog .modal-content .modal-body .modal-body-product-img img {
            width: 100%; } }
    .modal-dialog .modal-content .modal-body .modal-body-product-data {
      grid-area: data; }
      .modal-dialog .modal-content .modal-body .modal-body-product-data h6 {
        display: block;
        min-height: 44px; }
      .modal-dialog .modal-content .modal-body .modal-body-product-data .modal-product-price {
        font-size: 22px;
        color: #a51c5a;
        font-family: "Roboto", sans-serif;
        font-weight: 700; }
    .modal-dialog .modal-content .modal-body .modal-body-cart-content {
      grid-area: content; }
      @media (max-width: 768px) {
        .modal-dialog .modal-content .modal-body .modal-body-cart-content {
          padding-left: calc(15px + 5%);
          padding-right: calc(15px + 5%);
          padding-top: 15px;
          border-top: 1px solid #dadada; } }
      @media (max-width: 768px) {
        .modal-dialog .modal-content .modal-body .modal-body-cart-content p {
          margin-bottom: auto;
          line-height: 22px; } }
    .modal-dialog .modal-content .modal-body .cart-content-btn {
      grid-area: buttons;
      margin-top: 15px;
      display: flex;
      width: 100%; }
      .modal-dialog .modal-content .modal-body .cart-content-btn > * {
        flex: 1;
        margin-left: 1%;
        margin-right: 1%; }
        @media (max-width: 768px) {
          .modal-dialog .modal-content .modal-body .cart-content-btn > * {
            font-size: 15px; } }
      .modal-dialog .modal-content .modal-body .cart-content-btn .djv-icon-ok {
        margin-right: 10px; }
        @media (max-width: 768px) {
          .modal-dialog .modal-content .modal-body .cart-content-btn .djv-icon-ok {
            display: none; } }
      .modal-dialog .modal-content .modal-body .cart-content-btn .btn-primary {
        padding-top: 10px; }

#manufacturer #main {
  margin-right: auto;
  margin-left: auto;
  max-width: 1170px;
  min-width: 920px; }
  @media (max-width: 1024px) {
    #manufacturer #main {
      max-width: 920px;
      min-width: 750px; } }
  @media (max-width: 768px) {
    #manufacturer #main {
      max-width: 750px;
      min-width: 480px; } }
  @media (max-width: 480px) {
    #manufacturer #main {
      max-width: 500px;
      min-width: 300px; } }
  #manufacturer #main h1 {
    font-size: 22px; }
  #manufacturer #main ul {
    display: grid;
    grid-template-columns: repeat(4, calc(25% - 15px));
    margin: 0;
    padding: 0;
    grid-gap: 20px; }
    @media (max-width: 576px) {
      #manufacturer #main ul {
        grid-template-columns: repeat(2, calc(50% - 2px));
        grid-gap: 2px; } }
    #manufacturer #main ul li {
      list-style: none;
      text-align: center;
      list-style: none;
      text-align: center;
      border: 1px dashed #dddddd; }
      #manufacturer #main ul li .brand-img {
        margin: 20px auto; }
      #manufacturer #main ul li .brand-infos {
        padding: 10px 0 1px 0px;
        background-color: #03b8d6; }
        #manufacturer #main ul li .brand-infos h3 {
          font-size: 22px; }
      #manufacturer #main ul li a {
        color: #777777; }
      #manufacturer #main ul li .brand-products a {
        display: block;
        font-family: "Bree Serif", serif;
        font-weight: 400;
        display: block;
        font-family: "OpenSans-Regular";
        background-color: #f6f6f6;
        padding-bottom: 10px;
        font-size: 13px; }
        #manufacturer #main ul li .brand-products a:last-of-type {
          background-color: #03b8d6;
          color: white;
          border: 2px solid #03b8d6;
          border-radius: 2px;
          transition: all 500ms;
          text-align: center;
          text-transform: uppercase;
          padding: 4px 10px;
          cursor: pointer;
          margin: 20px auto;
          width: 70%; }
          #manufacturer #main ul li .brand-products a:last-of-type:hover {
            background-color: white;
            color: #03b8d6; }

#checkout {
  background-color: #eee;
  font-family: "Roboto", sans-serif;
  font-weight: 400; }
  #checkout a {
    color: #03b8d6;
    text-decoration: underline; }
  #checkout small, #checkout em, #checkout p {
    font-size: 0.8em;
    color: #444444; }
  #checkout h1 {
    font-family: "Roboto", sans-serif;
    font-weight: 600;
    font-size: 22px;
    border-bottom: 1px solid #aaa;
    padding-bottom: 10px;
    margin: 10px 0;
    color: black; }
  #checkout .-complete h1 {
    color: #999; }
    #checkout .-complete h1::after {
      font-size: 16px;
      color: #76c9d7;
      content: "editar";
      float: right;
      cursor: pointer;
      padding-top: 5px; }
  #checkout h2 {
    font-family: "Bree Serif", serif;
    font-weight: 600;
    text-align: left;
    font-size: 20px;
    margin-bottom: 10px; }
  #checkout h4 {
    font-size: 20px; }
  #checkout .btn-hover-animated {
    background-color: #0083ff;
    color: white;
    border: 2px solid #0083ff;
    border-radius: 50px;
    transition: all 500ms;
    text-align: center;
    text-transform: uppercase;
    padding: 10px 10px;
    cursor: pointer;
    width: 90%;
    margin: 20px auto;
    font-size: 16px; }
    #checkout .btn-hover-animated:hover {
      background-color: white;
      color: #0083ff; }
    @media (max-width: 1024px) {
      #checkout .btn-hover-animated {
        width: 100%; } }
  #checkout #header {
    position: relative;
    top: -5px;
    min-height: 36px;
    box-shadow: none; }
    #checkout #header .checkout-header-simplified {
      background-color: #d3f0f5; }
      #checkout #header .checkout-header-simplified div {
        margin-right: auto;
        margin-left: auto;
        max-width: 1170px;
        min-width: 920px; }
        @media (max-width: 1024px) {
          #checkout #header .checkout-header-simplified div {
            max-width: 920px;
            min-width: 750px; } }
        @media (max-width: 768px) {
          #checkout #header .checkout-header-simplified div {
            max-width: 750px;
            min-width: 480px; } }
        @media (max-width: 480px) {
          #checkout #header .checkout-header-simplified div {
            max-width: 500px;
            min-width: 300px; } }
        #checkout #header .checkout-header-simplified div .logo {
          background-image: url("/themes/base/assets/img/logo.svg");
          width: 120px;
          height: 60px;
          display: block;
          background-repeat: no-repeat;
          background-size: contain;
          margin: 5px;
          background-position: center; }
          @media (max-width: 576px) {
            #checkout #header .checkout-header-simplified div .logo {
              margin-left: auto;
              margin-right: auto; } }
  #checkout #content {
    margin-right: auto;
    margin-left: auto;
    max-width: 1170px;
    min-width: 920px;
    display: grid;
    grid-template-columns: calc(65% - 16px) calc(35% - 16px);
    grid-column-gap: 16px; }
    @media (max-width: 1024px) {
      #checkout #content {
        max-width: 920px;
        min-width: 750px; } }
    @media (max-width: 768px) {
      #checkout #content {
        max-width: 750px;
        min-width: 480px; } }
    @media (max-width: 480px) {
      #checkout #content {
        max-width: 500px;
        min-width: 300px; } }
    @media (max-width: 576px) {
      #checkout #content {
        grid-template-columns: auto;
        padding: 0 10px; } }
    #checkout #content > * {
      background-color: white;
      border: 1px solid #dadada;
      padding: 15px; }
      @media (max-width: 576px) {
        #checkout #content > * {
          margin-bottom: 10px; } }
  #checkout .checkout-process {
    background-color: white;
    padding: 22px; }
    @media (max-width: 576px) {
      #checkout .checkout-process {
        margin-bottom: 10px; } }
    #checkout .checkout-process section .content {
      display: none; }
    #checkout .checkout-process section.js-current-step .content {
      display: block; }
  #checkout .djv-checkout-blocks {
    display: grid;
    grid-template-columns: repeat(2, 50%); }
    @media (max-width: 1024px) {
      #checkout .djv-checkout-blocks {
        display: block; } }
    #checkout .djv-checkout-blocks .djv-checkout-block-login {
      padding: 10px 20px;
      border-right: 1px solid #666666; }
      @media (max-width: 1024px) {
        #checkout .djv-checkout-blocks .djv-checkout-block-login {
          border-right: none;
          border-bottom: 1px solid #666666;
          margin-bottom: 20px;
          padding: 10px 10px; } }
    #checkout .djv-checkout-blocks .djv-checkout-block-register {
      padding: 10px 20px;
      position: relative; }
      @media (max-width: 1024px) {
        #checkout .djv-checkout-blocks .djv-checkout-block-register {
          border-bottom: 1px solid #666666;
          margin-bottom: 20px;
          padding: 10px 10px; } }
  #checkout #login-form label input {
    width: 100%; }
  #checkout #djv-checkout-form-register {
    display: none; }
    #checkout #djv-checkout-form-register #customer-form .checkbox-field {
      position: relative; }
      #checkout #djv-checkout-form-register #customer-form .checkbox-field input[type="checkbox"] {
        float: left;
        margin-top: 5px;
        margin-right: 5px; }
  #checkout #delivery_message {
    width: 100%;
    padding: 16px;
    border: 1px solid #444444; }
  @media (max-width: 576px) {
    #checkout .js-address-form .form-fields {
      display: block; } }
  #checkout .js-address-form .form-fields label {
    display: grid;
    grid-template-columns: 30% 70%; }
    #checkout .js-address-form .form-fields label span {
      display: inline-block;
      font-size: 16px;
      color: #777777; }
    #checkout .js-address-form .form-fields label input:not([type="checkbox"]), #checkout .js-address-form .form-fields label select {
      padding: 5px 10px;
      border: none;
      background-color: #eee;
      width: 100%;
      display: inline-block; }
    #checkout .js-address-form .form-fields label input[name="use_same_address"] + span {
      display: inline;
      margin-left: 5px; }
    @media (max-width: 576px) {
      #checkout .js-address-form .form-fields label {
        width: 100%; } }
    #checkout .js-address-form .form-fields label input[name="esempresa"] {
      width: 25px; }
  #checkout .js-address-form .form-footer {
    border-top: 1px dashed #cccccc;
    padding-top: 10px;
    margin-top: 10px;
    margin: 10px 0; }
    #checkout .js-address-form .form-footer a {
      background-color: #E65EA6;
      color: white;
      border: 2px solid #E65EA6;
      border-radius: 30px;
      transition: all 500ms;
      text-align: center;
      text-transform: uppercase;
      padding: 15px 15px;
      cursor: pointer; }
      #checkout .js-address-form .form-footer a:hover {
        background-color: white;
        color: #E65EA6; }
    #checkout .js-address-form .form-footer button {
      background-color: #5abe20;
      color: white;
      border: 2px solid #5abe20;
      border-radius: 30px;
      transition: all 500ms;
      text-align: center;
      text-transform: uppercase;
      padding: 15px 15px;
      cursor: pointer; }
      #checkout .js-address-form .form-footer button:hover {
        background-color: white;
        color: #5abe20; }
  #checkout .continue {
    background-color: #5abe20;
    color: white;
    border: 2px solid #5abe20;
    border-radius: 30px;
    transition: all 500ms;
    text-align: center;
    text-transform: uppercase;
    padding: 6px 15px;
    cursor: pointer;
    width: 120px;
    margin: 10px 0; }
    #checkout .continue:hover {
      background-color: white;
      color: #5abe20; }
    @media (max-width: 1024px) {
      #checkout .continue {
        width: 100%;
        margin: 20px auto; } }
  #checkout #delivery-addresses, #checkout #invoice-addresses {
    display: grid;
    grid-template-columns: calc(33% - 16px) calc(33% - 16px) calc(33% - 16px);
    grid-gap: 16px;
    margin-bottom: 30px; }
    @media (max-width: 576px) {
      #checkout #delivery-addresses, #checkout #invoice-addresses {
        grid-template-columns: auto; } }
    #checkout #delivery-addresses .address-item, #checkout #invoice-addresses .address-item {
      border: 1px dashed #dadada;
      padding: 8px;
      position: relative; }
      #checkout #delivery-addresses .address-item:last-of-type, #checkout #invoice-addresses .address-item:last-of-type {
        border-bottom: 1px dashed #dadada; }
      #checkout #delivery-addresses .address-item.selected, #checkout #invoice-addresses .address-item.selected {
        border: 4px solid #6abac7; }
      #checkout #delivery-addresses .address-item label, #checkout #invoice-addresses .address-item label {
        width: 100%;
        display: block;
        padding-left: 26px;
        margin-bottom: 16px;
        padding-bottom: 40px; }
        #checkout #delivery-addresses .address-item label input, #checkout #invoice-addresses .address-item label input {
          margin-left: -26px;
          margin-right: 10px; }
      #checkout #delivery-addresses .address-item header, #checkout #invoice-addresses .address-item header {
        font-size: 22px;
        color: #777777;
        border-bottom: 1px solid #dadada;
        padding-bottom: 6px; }
      #checkout #delivery-addresses .address-item footer, #checkout #invoice-addresses .address-item footer {
        position: absolute;
        display: block;
        margin-top: 40px;
        bottom: 10px;
        width: 100%;
        display: block; }
        #checkout #delivery-addresses .address-item footer a, #checkout #invoice-addresses .address-item footer a {
          display: inline-block;
          padding: 3px 16px;
          text-align: center;
          color: white;
          font-size: 16px; }
        #checkout #delivery-addresses .address-item footer a[data-link-action="edit-address"], #checkout #invoice-addresses .address-item footer a[data-link-action="edit-address"] {
          background-color: #E65EA6; }
        #checkout #delivery-addresses .address-item footer a[data-link-action="delete-address"], #checkout #invoice-addresses .address-item footer a[data-link-action="delete-address"] {
          background-color: #E65EA6; }
  #checkout #delivery-addresses + a + h2 {
    margin: 30px 0 0 0; }
  #checkout #checkout-addresses-step,
  #checkout #checkout-delivery-step,
  #checkout #checkout-payment-step {
    padding-bottom: 5px;
    margin-bottom: 5px; }
  #checkout #login-form label {
    display: block; }
    #checkout #login-form label span {
      display: block;
      margin: 10px 0; }
    #checkout #login-form label input {
      border: none;
      background-color: #eee;
      padding: 5px; }
      @media (max-width: 576px) {
        #checkout #login-form label input {
          width: 100%; } }
  #checkout #customer-form {
    margin: 10px 0; }
    #checkout #customer-form .addDoc {
      display: block;
      padding: 16px;
      background-color: #eeeeee; }
      #checkout #customer-form .addDoc br {
        margin-bottom: 16px; }
    #checkout #customer-form .form-fields > label {
      display: block;
      margin-bottom: 16px; }
      #checkout #customer-form .form-fields > label span {
        display: inline-block;
        min-width: 255px;
        max-width: calc(100% - 250px);
        margin-top: 5px; }
      #checkout #customer-form .form-fields > label input[type="radio"] {
        margin: 0 10px; }
      #checkout #customer-form .form-fields > label:not(.required):not(.checkbox-field) {
        display: none; }
      #checkout #customer-form .form-fields > label input:not([type="checkbox"]):not([type="radio"]) {
        width: 100%;
        border: none;
        background-color: #eee;
        padding: 5px; }
        @media (max-width: 576px) {
          #checkout #customer-form .form-fields > label input:not([type="checkbox"]):not([type="radio"]) {
            width: 100%; } }
    #checkout #customer-form .form-fields .checkbox-field {
      border-top: 1px dashed #cccccc;
      padding-top: 10px;
      margin-top: 10px; }
      #checkout #customer-form .form-fields .checkbox-field > label {
        display: block; }
        @media (max-width: 1024px) {
          #checkout #customer-form .form-fields .checkbox-field > label {
            overflow: auto; } }
        #checkout #customer-form .form-fields .checkbox-field > label span {
          margin-left: 10px; }
          @media (max-width: 1024px) {
            #checkout #customer-form .form-fields .checkbox-field > label span {
              min-width: 180px;
              display: block;
              margin-bottom: 10px;
              float: right; } }
    #checkout #customer-form .form-fields .checkbox-field ~ .checkbox-field {
      margin-top: 0px;
      border-top: 0;
      border-bottom: 1px dashed #cccccc;
      padding-bottom: 10px;
      margin-bottom: 10px; }
  #checkout .delivery-options-list .delivery-options {
    display: block; }
    #checkout .delivery-options-list .delivery-options .delivery-option {
      display: grid;
      grid-template-columns: 30px calc(100% - 30px);
      align-items: start; }
      #checkout .delivery-options-list .delivery-options .delivery-option input[type="radio"] {
        margin-top: 5px; }
      #checkout .delivery-options-list .delivery-options .delivery-option img {
        margin-bottom: 3px;
        max-width: 30px;
        margin-left: 10px; }
  #checkout #bankwire-modal {
    display: none;
    width: 1px;
    height: 1px; }

#js-checkout-summary h5 a {
  font-family: "Bree Serif", serif;
  font-weight: 600;
  font-size: 22px;
  color: black; }

#js-checkout-summary header p {
  margin-bottom: 0;
  display: flex;
  justify-content: space-between;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 16px;
  color: black; }

#js-checkout-summary .products {
  border-top: 1px solid #cccccc;
  padding-top: 10px;
  margin-top: 10px; }

#js-checkout-summary .show-details {
  animation-name: showdetails;
  animation-duration: 1s;
  animation-fill-mode: forwards; }

@keyframes showdetails {
  from {
    height: 0;
    opacity: 0; }
  to {
    height: 100%;
    opacity: 1; } }

#js-checkout-summary .cart-summary-totals .aviso-portes {
  display: block;
  font-size: 16px; }

#js-checkout-summary .cart-summary-totals .cart-summary-totals {
  border-top: 1px solid #666666;
  margin-top: 15px;
  padding-top: 15px;
  text-align: center;
  color: black; }
  #js-checkout-summary .cart-summary-totals .cart-summary-totals .label {
    font-size: 20px;
    display: block; }
  #js-checkout-summary .cart-summary-totals .cart-summary-totals .value {
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-size: 30px; }

#js-checkout-summary .checkout-continuar-comprando {
  border-top: 1px solid #cccccc;
  padding-top: 10px;
  margin-top: 10px;
  position: relative;
  font-size: 20px;
  text-align: center; }
  #js-checkout-summary .checkout-continuar-comprando i {
    display: none; }
  #js-checkout-summary .checkout-continuar-comprando a {
    display: block;
    font-size: 16px; }

#cart-summary-ul {
  padding-left: 0;
  display: none;
  height: 0;
  opacity: 0; }
  #cart-summary-ul li {
    list-style: none;
    font-size: 16px;
    display: grid;
    grid-template-columns: 40px auto 100px;
    padding: 5px 0;
    border-bottom: 1px solid #ddd; }
    #cart-summary-ul li:last-of-type {
      border-bottom: 0; }
    #cart-summary-ul li .product-price {
      text-align: right; }
    #cart-summary-ul li .product-name {
      font-size: 16px; }
    #cart-summary-ul li span:first-of-type {
      text-align: center; }
    #cart-summary-ul li span:last-of-type {
      text-align: right; }

#cart-summary .shipping .shipping-link {
  display: block; }
  #cart-summary .shipping .shipping-link a {
    color: #444444;
    font-size: 12px; }

#cart-summary .tax {
  font-size: 16px;
  color: #444444; }

#cart-summary > div {
  display: grid;
  grid-template-columns: repeat(2, calc(50% - 5px));
  grid-gap: 10px;
  margin-bottom: 10px; }
  #cart-summary > div > .value {
    text-align: right; }

#cart-summary-product-list {
  margin-top: 10px; }

#cart-summary-viewdetails {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 15px;
  color: #76c9d7;
  padding: 5px 0px;
  cursor: pointer; }
  #cart-summary-viewdetails::before {
    margin-right: 5px;
    font-size: 12px; }

#conditions-to-approve {
  margin-top: 20px; }
  #conditions-to-approve ul {
    margin-left: 0;
    padding-left: 0; }
    #conditions-to-approve ul li {
      list-style: none;
      display: flex;
      align-items: start; }
      #conditions-to-approve ul li label {
        margin-bottom: 0;
        margin-left: 5px; }

#payment-confirmation button {
  border: none;
  background-color: #dddddd;
  color: #aaaaaa;
  padding: 6px;
  margin: 10px; }
  #payment-confirmation button:not([disabled]) {
    background-color: #5abe20;
    color: white;
    border: 2px solid #5abe20;
    border-radius: 30px;
    transition: all 500ms;
    text-align: center;
    text-transform: uppercase;
    padding: 15px 15px;
    cursor: pointer;
    margin: 10px; }
    #payment-confirmation button:not([disabled]):hover {
      background-color: white;
      color: #5abe20; }

.form-errors {
  margin-left: 0;
  padding-left: 0; }
  .form-errors .errorExist {
    background-color: red;
    color: white;
    padding: 16px; }

#cart #notifications {
  display: block;
  margin-left: auto;
  margin-right: auto; }

#cart #content-wrapper {
  margin-right: auto;
  margin-left: auto;
  max-width: 1170px;
  min-width: 920px; }
  @media (max-width: 1024px) {
    #cart #content-wrapper {
      max-width: 920px;
      min-width: 750px; } }
  @media (max-width: 768px) {
    #cart #content-wrapper {
      max-width: 750px;
      min-width: 480px; } }
  @media (max-width: 480px) {
    #cart #content-wrapper {
      max-width: 500px;
      min-width: 300px; } }

#cart #main {
  display: grid;
  grid-template-columns: 75% 25%;
  gap: 0; }
  @media (max-width: 768px) {
    #cart #main {
      display: block; } }
  @media (max-width: 768px) {
    #cart #main > div {
      box-shadow: 2px 2px 8px rgba(0, 0, 0, 0); } }

#cart .cart-advisor {
  display: none;
  background-color: #E65EA6;
  padding: 6px 16px;
  text-align: center;
  color: white; }

#cart h1 {
  padding: 16px;
  border-bottom: 1px solid white;
  text-transform: uppercase;
  font-size: 23px; }

#cart .cart-overview {
  margin-right: 25px; }
  @media (max-width: 768px) {
    #cart .cart-overview {
      margin-right: 0; } }
  #cart .cart-overview .body {
    padding: 15px 0px; }
    #cart .cart-overview .body ul {
      padding-left: 0; }
  #cart .cart-overview .cart-header-items {
    list-style: none;
    display: grid;
    grid-template-columns: 65% 35%;
    border-bottom: 1px solid #cccccc;
    color: #666666; }
    #cart .cart-overview .cart-header-items .cart-header-items-total {
      text-align: right;
      margin-right: 50px; }
  #cart .cart-overview .cart-item {
    list-style: none;
    display: grid;
    grid-template-columns: 65% 35%;
    gap: 16px;
    padding-bottom: 16px;
    border-bottom: 1px dashed #dddddd;
    padding-top: 16px;
    font-size: 20px; }
    @media (max-width: 1024px) {
      #cart .cart-overview .cart-item {
        display: block;
        margin-bottom: 20px; } }
    #cart .cart-overview .cart-item a {
      color: inherit; }
    #cart .cart-overview .cart-item .secction-label {
      font-family: "Roboto", sans-serif;
      font-weight: 500;
      font-size: 20px; }
    #cart .cart-overview .cart-item .cart-detailed-product-line-detail {
      display: grid;
      grid-template-columns: 30% 70%;
      gap: 16px; }
      @media (max-width: 768px) {
        #cart .cart-overview .cart-item .cart-detailed-product-line-detail {
          display: block; } }
      @media (max-width: 768px) {
        #cart .cart-overview .cart-item .cart-detailed-product-line-detail .cart-detailed-product-line-detail-image {
          float: left;
          width: 40%;
          margin-right: 15px;
          margin-bottom: 15px; } }
      #cart .cart-overview .cart-item .cart-detailed-product-line-detail .cart-detailed-product-line-detail-image img {
        width: 100%; }
      #cart .cart-overview .cart-item .cart-detailed-product-line-detail .cart-detailed-product-line-detail-tex > span {
        display: block; }
      #cart .cart-overview .cart-item .cart-detailed-product-line-detail .cart-detailed-product-line-detail-tex .product-price {
        font-family: "Roboto", sans-serif;
        font-weight: 700;
        font-size: 22px; }
        #cart .cart-overview .cart-item .cart-detailed-product-line-detail .cart-detailed-product-line-detail-tex .product-price small {
          font-family: "Roboto", sans-serif;
          font-weight: 400;
          font-size: 13px; }
          @media (max-width: 768px) {
            #cart .cart-overview .cart-item .cart-detailed-product-line-detail .cart-detailed-product-line-detail-tex .product-price small {
              display: block; } }
      #cart .cart-overview .cart-item .cart-detailed-product-line-detail .cart-detailed-product-line-detail-tex .product-discount {
        position: relative; }
        #cart .cart-overview .cart-item .cart-detailed-product-line-detail .cart-detailed-product-line-detail-tex .product-discount .regular-price {
          text-decoration: line-through;
          color: #cccccc; }
        #cart .cart-overview .cart-item .cart-detailed-product-line-detail .cart-detailed-product-line-detail-tex .product-discount .discount-percentage {
          left: -60px; }
          @media (max-width: 768px) {
            #cart .cart-overview .cart-item .cart-detailed-product-line-detail .cart-detailed-product-line-detail-tex .product-discount .discount-percentage {
              top: -80px;
              left: 80px; } }
      @media (max-width: 576px) {
        #cart .cart-overview .cart-item .cart-detailed-product-line-detail .cart-detailed-product-line-detail-tex .cart-resume-selected-attributes {
          display: block;
          clear: both; } }
      #cart .cart-overview .cart-item .cart-detailed-product-line-detail .cart-detailed-product-line-detail-tex .cart-resume-selected-attributes .cart-resume-attributes {
        margin-left: 20px;
        list-style: none;
        margin-bottom: 10px; }
        #cart .cart-overview .cart-item .cart-detailed-product-line-detail .cart-detailed-product-line-detail-tex .cart-resume-selected-attributes .cart-resume-attributes li {
          line-height: 16px;
          font-size: 15px; }
          #cart .cart-overview .cart-item .cart-detailed-product-line-detail .cart-detailed-product-line-detail-tex .cart-resume-selected-attributes .cart-resume-attributes li label {
            margin-bottom: 0; }
    #cart .cart-overview .cart-item .cart-detailed-product-line-modify {
      display: grid;
      grid-template-columns: 45% 45% 20px;
      text-align: center; }
      @media (max-width: 1024px) {
        #cart .cart-overview .cart-item .cart-detailed-product-line-modify {
          margin-top: 5px;
          padding-top: 15px;
          border-top: 1px dashed #cccccc;
          clear: both; } }
      #cart .cart-overview .cart-item .cart-detailed-product-line-modify .totalUnidades {
        font-size: 15px;
        margin-top: 3px; }
      #cart .cart-overview .cart-item .cart-detailed-product-line-modify .product-price {
        text-align: center;
        font-size: 22px; }
      #cart .cart-overview .cart-item .cart-detailed-product-line-modify .cart-edit-personalization {
        display: none;
        padding-top: 10px;
        color: #76c9d7;
        font-size: 15px; }
        #cart .cart-overview .cart-item .cart-detailed-product-line-modify .cart-edit-personalization::before {
          font-size: 16px; }
      #cart .cart-overview .cart-item .cart-detailed-product-line-modify .djv-icon-delete {
        text-align: center;
        font-size: 16px;
        color: red;
        padding-top: 6px; }
      #cart .cart-overview .cart-item .cart-detailed-product-line-modify .product-quantity {
        border-bottom: 0; }
        #cart .cart-overview .cart-item .cart-detailed-product-line-modify .product-quantity .product-add-to-cart-quantity {
          width: 135px; }
          #cart .cart-overview .cart-item .cart-detailed-product-line-modify .product-quantity .product-add-to-cart-quantity #quantity_wanted {
            width: 55px; }

#cart .djv-cart-promo {
  font-size: 15px;
  margin-top: 10px;
  padding: 8px 16px;
  border-bottom: 1px solid #dddddd; }
  #cart .djv-cart-promo .djv-icon-calidad::before {
    margin-right: 8px; }
  #cart .djv-cart-promo .djv-cart-promo-item {
    display: grid;
    grid-template-columns: 50% 40% 10%;
    color: #6abac7; }
    #cart .djv-cart-promo .djv-cart-promo-item .djv-icon-close {
      color: #D53128; }

#cart .cart-detailed-totals {
  border: 1px solid #dddddd;
  border-top: none; }
  #cart .cart-detailed-totals .cart-subtotals {
    padding: 8px 16px;
    border-bottom: 1px solid #dddddd; }
    #cart .cart-detailed-totals .cart-subtotals .products {
      display: none; }
    #cart .cart-detailed-totals .cart-subtotals .shipping {
      display: grid;
      grid-template-columns: auto auto;
      padding-bottom: 10px;
      margin-bottom: 10px; }
      #cart .cart-detailed-totals .cart-subtotals .shipping .value {
        text-align: right; }
    #cart .cart-detailed-totals .cart-subtotals .discount {
      display: grid;
      grid-template-columns: auto auto;
      padding-bottom: 10px;
      margin-bottom: 10px; }
      #cart .cart-detailed-totals .cart-subtotals .discount .value {
        text-align: right; }
        #cart .cart-detailed-totals .cart-subtotals .discount .value::before {
          content: "-"; }
    #cart .cart-detailed-totals .cart-subtotals .tax {
      display: grid;
      grid-template-columns: auto auto;
      padding-bottom: 10px;
      margin-bottom: 10px; }
      #cart .cart-detailed-totals .cart-subtotals .tax .value {
        text-align: right; }
  #cart .cart-detailed-totals .cart-total {
    display: grid;
    grid-template-columns: auto auto;
    grid-gap: 10px;
    align-items: center;
    font-size: 20px;
    padding: 8px 16px;
    color: black; }
    #cart .cart-detailed-totals .cart-total .label {
      text-align: left; }
    #cart .cart-detailed-totals .cart-total .value {
      text-align: right;
      font-size: 22px;
      font-weight: 700; }

#cart .tramitar-pedido-btn {
  background-color: #5abe20;
  color: white;
  border: 2px solid #5abe20;
  border-radius: 2px;
  transition: all 500ms;
  text-align: center;
  text-transform: uppercase;
  padding: 4px 10px;
  cursor: pointer;
  margin: 10px auto;
  display: block;
  width: 100%;
  font-size: 22px; }
  #cart .tramitar-pedido-btn:hover {
    background-color: white;
    color: #5abe20; }

#cart #block-reassurance {
  padding: 16px;
  border-bottom: 1px solid #dddddd; }
  #cart #block-reassurance ul {
    padding-left: 30px; }
    #cart #block-reassurance ul li {
      list-style: none;
      margin-bottom: 16px; }
      #cart #block-reassurance ul li img {
        margin-left: -30px;
        filter: sepia(100%) brightness(310%) hue-rotate(60deg) saturate(300%); }

#cart #items-subtotal {
  overflow: auto;
  border: 1px solid #dddddd;
  border-top: none; }
  #cart #items-subtotal > * {
    padding: 8px 16px; }
  #cart #items-subtotal .cart-title {
    background-color: #dddddd;
    color: black;
    text-transform: uppercase;
    font-size: 16px; }
  #cart #items-subtotal .js-subtotal {
    float: left; }
  #cart #items-subtotal span:not(.js-subtotal) {
    text-align: right;
    display: inline-block; }
    #cart #items-subtotal span:not(.js-subtotal):last-of-type {
      float: right; }

#cart .continue-shopping {
  width: 230px;
  margin-left: inherit;
  margin-top: 10px;
  padding-top: 6px;
  padding-left: 20px;
  position: relative; }
  @media (max-width: 768px) {
    #cart .continue-shopping {
      display: block;
      margin-left: auto;
      margin-right: auto;
      margin-top: 20px; } }
  #cart .continue-shopping i {
    position: absolute;
    left: 0px;
    top: 8px; }

#cart.cart-empty .checkout {
  display: none; }

#cart.cart-empty .cart-overview .body::after {
  content: "No hay articulos seleccionados"; }

#authentication #main {
  width: 100%;
  max-width: 920px;
  margin-left: auto;
  margin-right: auto; }
  @media (max-width: 576px) {
    #authentication #main {
      padding: 10px; } }
  #authentication #main .djv-register-link {
    display: block;
    border-top: 1px dashed #cccccc;
    padding-top: 10px;
    margin-top: 10px;
    border-bottom: 1px dashed #cccccc;
    padding-bottom: 10px;
    margin-bottom: 10px; }
  #authentication #main .form-footer button {
    border: none;
    background-color: #13a323;
    padding: 6px 30px;
    color: white;
    transition: all 500ms;
    border: 2px solid #13a323;
    cursor: pointer; }
    #authentication #main .form-footer button:hover {
      box-shadow: 2px 2px 9px rgba(0, 0, 0, 0.4);
      background-color: white;
      color: #13a323;
      border: 2px solid #13a323; }
  #authentication #main .page-authentication #login-form {
    margin-top: 20px; }
    #authentication #main .page-authentication #login-form label {
      display: inline-block;
      margin-right: 20px;
      vertical-align: top; }
      #authentication #main .page-authentication #login-form label span {
        display: block;
        margin-bottom: 10px; }
      #authentication #main .page-authentication #login-form label input {
        display: block;
        width: 100%; }
    #authentication #main .page-authentication #login-form .lost_password {
      margin-top: 20px; }
  #authentication #main .page-authentication #customer-form .form-fields {
    margin-top: 20px; }
    #authentication #main .page-authentication #customer-form .form-fields label {
      vertical-align: top; }
    #authentication #main .page-authentication #customer-form .form-fields label:nth-of-type(6), #authentication #main .page-authentication #customer-form .form-fields label:nth-of-type(5) {
      display: none !important; }
    #authentication #main .page-authentication #customer-form .form-fields label:nth-of-type(4) {
      margin-bottom: 30px; }
    #authentication #main .page-authentication #customer-form .form-fields label:not(.checkbox-field) {
      display: block;
      max-width: 60%; }
      #authentication #main .page-authentication #customer-form .form-fields label:not(.checkbox-field) span {
        display: block;
        width: 50%;
        margin-bottom: 10px; }
      #authentication #main .page-authentication #customer-form .form-fields label:not(.checkbox-field) input {
        display: block;
        width: 100%; }
    #authentication #main .page-authentication #customer-form .form-fields .lost_password {
      margin-top: 20px; }
    #authentication #main .page-authentication #customer-form .form-fields label.checkbox-field {
      display: block; }
      #authentication #main .page-authentication #customer-form .form-fields label.checkbox-field span {
        margin-left: 20px;
        display: block;
        margin-top: -24px;
        position: relative; }
        #authentication #main .page-authentication #customer-form .form-fields label.checkbox-field span::after {
          display: block;
          float: right;
          display: inline-block;
          position: absolute;
          left: 312px;
          top: 0; }
      #authentication #main .page-authentication #customer-form .form-fields label.checkbox-field:first-of-type {
        display: none; }
    #authentication #main .page-authentication #customer-form .form-fields label.radio-field label {
      display: inline-block;
      width: auto;
      margin-right: 5px; }
      #authentication #main .page-authentication #customer-form .form-fields label.radio-field label input {
        display: inline-block;
        width: auto;
        margin-right: 5px; }
    #authentication #main .page-authentication #customer-form .form-fields label.required span::after {
      content: " *";
      color: #b30000; }
  #authentication #main .form-errors {
    padding-left: 0; }
    #authentication #main .form-errors li {
      color: white;
      display: block;
      list-style: none;
      border: 1px dashed #b30000;
      padding: 10px;
      text-align: center; }

#order-confirmation #main {
  margin-right: auto;
  margin-left: auto;
  max-width: 1170px;
  min-width: 920px;
  font-family: "Roboto", sans-serif;
  font-weight: 400; }
  @media (max-width: 1024px) {
    #order-confirmation #main {
      max-width: 920px;
      min-width: 750px; } }
  @media (max-width: 768px) {
    #order-confirmation #main {
      max-width: 750px;
      min-width: 480px; } }
  @media (max-width: 480px) {
    #order-confirmation #main {
      max-width: 500px;
      min-width: 300px; } }
  #order-confirmation #main h3 {
    font-family: "Roboto", sans-serif;
    font-weight: 400; }
  #order-confirmation #main hr {
    border-top: 3px solid #6abac7;
    margin: 30px 0; }
  #order-confirmation #main #order-items .djv-order-confirmation-items-table {
    width: 100%; }
    #order-confirmation #main #order-items .djv-order-confirmation-items-table tr {
      border-bottom: 1px solid #6abac7; }
      @media (max-width: 576px) {
        #order-confirmation #main #order-items .djv-order-confirmation-items-table tr {
          border-bottom: 3px solid #6abac7; } }
      #order-confirmation #main #order-items .djv-order-confirmation-items-table tr td {
        padding: 10px;
        border-right: 1px dotted #cccccc;
        position: relative;
        text-align: center; }
        @media (max-width: 576px) {
          #order-confirmation #main #order-items .djv-order-confirmation-items-table tr td {
            display: block;
            padding: 10px;
            border-right: none;
            text-align: center; } }
        #order-confirmation #main #order-items .djv-order-confirmation-items-table tr td.djv-order-confirmation-quantity {
          font-size: 18px; }
          @media (max-width: 576px) {
            #order-confirmation #main #order-items .djv-order-confirmation-items-table tr td.djv-order-confirmation-quantity {
              display: inline-block;
              width: calc(50% - 10px); } }
        #order-confirmation #main #order-items .djv-order-confirmation-items-table tr td.djv-order-confirmation-price {
          font-size: 18px; }
          @media (max-width: 576px) {
            #order-confirmation #main #order-items .djv-order-confirmation-items-table tr td.djv-order-confirmation-price {
              display: inline-block;
              width: calc(50% - 10px); } }
        #order-confirmation #main #order-items .djv-order-confirmation-items-table tr td:last-of-type {
          border-right: none; }
        #order-confirmation #main #order-items .djv-order-confirmation-items-table tr td img {
          max-width: 100%; }
          @media (max-width: 576px) {
            #order-confirmation #main #order-items .djv-order-confirmation-items-table tr td img {
              width: 60%;
              margin-left: auto;
              margin-right: auto; } }
        @media (max-width: 576px) {
          #order-confirmation #main #order-items .djv-order-confirmation-items-table tr td:nth-of-type(2) {
            border-top: 1px dotted #6abac7;
            border-bottom: 1px dotted #6abac7;
            text-align: left; } }
      #order-confirmation #main #order-items .djv-order-confirmation-items-table tr:last-of-type {
        border-bottom: none; }
  #order-confirmation #main #order-items .djv-order-confirmation-resume-table tr {
    border-bottom: 1px dotted #6abac7; }
    #order-confirmation #main #order-items .djv-order-confirmation-resume-table tr td {
      padding: 16px 30px; }
    #order-confirmation #main #order-items .djv-order-confirmation-resume-table tr:last-of-type {
      border-bottom: none;
      background-color: #76c9d7;
      font-size: 16px; }

.djv-icon-usuario {
  font-size: 22px;
  top: 7px;
  position: absolute;
  right: 70px;
  color: #6abac7; }

.user-info {
  display: none;
  background-color: #6abac7;
  position: absolute;
  right: 0px;
  top: 40px;
  padding: 10px;
  width: 296px;
  text-align: center;
  z-index: 1;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px; }
  @media (max-width: 768px) {
    .user-info {
      padding-top: 9px;
      width: 100vw; } }
  .user-info > * {
    font-size: 0.8rem; }
  .user-info a, .user-info a:visited, .user-info a:hover {
    text-decoration: none;
    color: inherit;
    display: inline-block; }
    .user-info a span, .user-info a:visited span, .user-info a:hover span {
      display: none; }
    .user-info a i, .user-info a:visited i, .user-info a:hover i {
      display: inline-block; }
  .user-info .account,
  .user-info .login,
  .user-info .logout {
    display: inline-block;
    font-size: 16px;
    margin: 0 10px;
    background-color: white;
    padding: 4px 15px;
    border-radius: 2px; }
    @media (max-width: 768px) {
      .user-info .account,
      .user-info .login,
      .user-info .logout {
        margin: 0 10px; } }
  .user-info > span {
    display: inline-block;
    font-size: 16px;
    margin: 0 10px;
    padding: 4px 15px;
    border-radius: 2px; }
    @media (max-width: 768px) {
      .user-info > span {
        display: none; } }

@media (min-width: 768px) {
  .user-info a, .user-info a:visited, .user-info a:hover {
    display: inline-block; }
    .user-info a span, .user-info a:visited span, .user-info a:hover span {
      display: inline-block; }
    .user-info a i, .user-info a:visited i, .user-info a:hover i {
      display: inline-block; } }

#my-account .link-list {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  padding-left: 0;
  display: grid;
  grid-template-columns: calc(33% - 10px) calc(33% - 10px) calc(33% - 10px);
  grid-gap: 10px; }
  @media (max-width: 576px) {
    #my-account .link-list {
      display: block; } }
  #my-account .link-list li {
    list-style: none;
    padding: 5px 10px;
    background-color: #03b8d6;
    transition: all 500ms; }
    @media (max-width: 576px) {
      #my-account .link-list li {
        margin-bottom: 10px; } }
    #my-account .link-list li a {
      color: white; }
    #my-account .link-list li::before {
      /*content: "";
                    width: 30px;
                    margin-right: 5px;
                    padding-right: 5px;
                    border-right: 1px solid $gris2;
                    transition: all 500ms;*/ }
    #my-account .link-list li:hover {
      background-color: #ff8a00;
      color: white; }
      #my-account .link-list li:hover::before {
        /*    border-right: 1px solid white;*/ }
    #my-account .link-list li:nth-of-type(4), #my-account .link-list li:nth-of-type(5), #my-account .link-list li:nth-of-type(6) {
      display: none; }

#addresses #main {
  margin-right: auto;
  margin-left: auto;
  max-width: 1170px;
  min-width: 920px; }
  @media (max-width: 1024px) {
    #addresses #main {
      max-width: 920px;
      min-width: 750px; } }
  @media (max-width: 768px) {
    #addresses #main {
      max-width: 750px;
      min-width: 480px; } }
  @media (max-width: 480px) {
    #addresses #main {
      max-width: 500px;
      min-width: 300px; } }
  #addresses #main .page-addresses {
    display: grid;
    grid-template-columns: calc(33% - 16px) calc(33% - 16px) calc(33% - 16px);
    grid-gap: 16px; }
    @media (max-width: 768px) {
      #addresses #main .page-addresses {
        display: block; } }
    #addresses #main .page-addresses article {
      border: 1px dashed #dadada;
      padding: 8px;
      position: relative; }
      #addresses #main .page-addresses article .action-list {
        padding-left: 0; }
        #addresses #main .page-addresses article .action-list li {
          display: inline-block; }
          #addresses #main .page-addresses article .action-list li a {
            display: block;
            padding: 3px;
            text-align: center;
            color: white;
            font-size: 14px; }
            #addresses #main .page-addresses article .action-list li a[data-link-action="edit-address"] {
              background-color: #03b8d6;
              color: white;
              border: 2px solid #03b8d6;
              border-radius: 2px;
              transition: all 500ms;
              text-align: center;
              text-transform: uppercase;
              padding: 4px 10px;
              cursor: pointer; }
              #addresses #main .page-addresses article .action-list li a[data-link-action="edit-address"]:hover {
                background-color: white;
                color: #03b8d6; }
            #addresses #main .page-addresses article .action-list li a[data-link-action="delete-address"] {
              background-color: #D53128;
              color: white;
              border: 2px solid #D53128;
              border-radius: 2px;
              transition: all 500ms;
              text-align: center;
              text-transform: uppercase;
              padding: 4px 10px;
              cursor: pointer; }
              #addresses #main .page-addresses article .action-list li a[data-link-action="delete-address"]:hover {
                background-color: white;
                color: #D53128; }
  #addresses #main .page-footer {
    border-top: 1px dashed #cccccc;
    padding-top: 10px;
    margin-top: 10px; }
    #addresses #main .page-footer ul {
      padding: 0; }
      #addresses #main .page-footer ul li {
        display: inline-block;
        list-style: none; }
        @media (max-width: 768px) {
          #addresses #main .page-footer ul li {
            display: block;
            margin-bottom: 10px; } }
        #addresses #main .page-footer ul li a {
          background-color: #03b8d6;
          color: white;
          border: 2px solid #03b8d6;
          border-radius: 2px;
          transition: all 500ms;
          text-align: center;
          text-transform: uppercase;
          padding: 4px 10px;
          cursor: pointer;
          padding-left: 16px;
          padding-right: 16px; }
          #addresses #main .page-footer ul li a:hover {
            background-color: white;
            color: #03b8d6; }
          @media (max-width: 768px) {
            #addresses #main .page-footer ul li a {
              display: block; } }

#address #main {
  margin-right: auto;
  margin-left: auto;
  max-width: 1170px;
  min-width: 920px; }
  @media (max-width: 1024px) {
    #address #main {
      max-width: 920px;
      min-width: 750px; } }
  @media (max-width: 768px) {
    #address #main {
      max-width: 750px;
      min-width: 480px; } }
  @media (max-width: 480px) {
    #address #main {
      max-width: 500px;
      min-width: 300px; } }
  #address #main .form-fields {
    display: grid;
    grid-template-columns: repeat(3, calc(33.333% - 10px));
    grid-gap: 10px; }
    @media (max-width: 768px) {
      #address #main .form-fields {
        display: block; } }
    @media (max-width: 768px) {
      #address #main .form-fields label {
        display: block;
        margin-bottom: 15px; } }
    #address #main .form-fields label.required::before {
      content: "*";
      color: red;
      position: absolute; }
    #address #main .form-fields label.required span {
      padding-left: 9px; }
    #address #main .form-fields label span {
      display: block; }
    #address #main .form-fields label input, #address #main .form-fields label select {
      border: none;
      background-color: #eee;
      padding: 5px;
      width: 100%; }
    #address #main .form-fields::after {
      content: "Los campos marcados con un asterisco son obligatorios"; }
  #address #main .form-footer {
    border-top: 1px solid #cccccc;
    padding-top: 10px;
    margin-top: 10px; }
    #address #main .form-footer button {
      background-color: #5abe20;
      color: white;
      border: 2px solid #5abe20;
      border-radius: 2px;
      transition: all 500ms;
      text-align: center;
      text-transform: uppercase;
      padding: 4px 10px;
      cursor: pointer; }
      #address #main .form-footer button:hover {
        background-color: white;
        color: #5abe20; }
  #address #main .page-footer {
    border-top: 1px solid #cccccc;
    padding-top: 10px;
    margin-top: 10px; }
    #address #main .page-footer ul {
      margin: 0;
      padding: 0; }
      #address #main .page-footer ul li {
        list-style: none;
        display: inline-block; }
        #address #main .page-footer ul li a {
          background-color: #03b8d6;
          color: white;
          border: 2px solid #03b8d6;
          border-radius: 2px;
          transition: all 500ms;
          text-align: center;
          text-transform: uppercase;
          padding: 4px 10px;
          cursor: pointer; }
          #address #main .page-footer ul li a:hover {
            background-color: white;
            color: #03b8d6; }

#identity #main {
  margin-right: auto;
  margin-left: auto;
  max-width: 1170px;
  min-width: 920px; }
  @media (max-width: 1024px) {
    #identity #main {
      max-width: 920px;
      min-width: 750px; } }
  @media (max-width: 768px) {
    #identity #main {
      max-width: 750px;
      min-width: 480px; } }
  @media (max-width: 480px) {
    #identity #main {
      max-width: 500px;
      min-width: 300px; } }
  #identity #main .form-fields {
    width: auto;
    display: grid;
    grid-template-areas: 'item1 item1 item1' 'item2 item3 item4' 'item5 item6 empty1' 'item7 empty2 empty2' 'item8 item9 item10' 'item11 item11 item11';
    grid-template-columns: 33% 33% 33%;
    grid-gap: 20px; }
    @media (max-width: 768px) {
      #identity #main .form-fields {
        display: block;
        margin-bottom: 20px; } }
    @media (max-width: 768px) {
      #identity #main .form-fields label {
        display: block;
        margin-bottom: 20px; } }
    #identity #main .form-fields label.required span::before {
      content: "*";
      color: red;
      position: relative;
      padding-right: 5px; }
    #identity #main .form-fields label span {
      display: block; }
    #identity #main .form-fields label input:not([type="radio"]), #identity #main .form-fields label input:not([type="checkbox"]), #identity #main .form-fields label select {
      border: none;
      background-color: #eee;
      padding: 5px;
      width: 100%; }
    #identity #main .form-fields label input[type="radio"], #identity #main .form-fields label input[type="checkbox"] {
      display: inline-block;
      margin: 5px;
      padding: 5px; }
    #identity #main .form-fields > label:nth-of-type(1) {
      grid-area: item1; }
      #identity #main .form-fields > label:nth-of-type(1) label {
        text-align: center;
        margin-right: 10px; }
        @media (max-width: 768px) {
          #identity #main .form-fields > label:nth-of-type(1) label {
            display: block; } }
        #identity #main .form-fields > label:nth-of-type(1) label input {
          display: inline-block;
          width: auto;
          padding-right: 5px; }
    #identity #main .form-fields > label:nth-of-type(2) {
      grid-area: item2; }
    #identity #main .form-fields > label:nth-of-type(3) {
      grid-area: item3; }
    #identity #main .form-fields > label:nth-of-type(4) {
      grid-area: item4; }
    #identity #main .form-fields > label:nth-of-type(5) {
      grid-area: item5; }
    #identity #main .form-fields > label:nth-of-type(6) {
      grid-area: item6;
      display: none; }
    #identity #main .form-fields > label:nth-of-type(7) {
      grid-area: item7;
      display: none; }
    #identity #main .form-fields > label:nth-of-type(8) {
      grid-area: item8;
      display: grid;
      grid-template-columns: 20px auto;
      align-items: flex-start;
      grid-gap: 5px; }
      #identity #main .form-fields > label:nth-of-type(8) span {
        display: inline-block; }
      #identity #main .form-fields > label:nth-of-type(8) em {
        display: none; }
    #identity #main .form-fields > label:nth-of-type(9) {
      grid-area: item9;
      display: grid;
      grid-template-columns: 20px auto;
      align-items: flex-start;
      grid-gap: 5px; }
      #identity #main .form-fields > label:nth-of-type(9) span {
        display: inline-block; }
    #identity #main .form-fields > label:nth-of-type(10) {
      grid-area: item10;
      display: grid;
      grid-template-columns: 20px auto;
      align-items: flex-start;
      grid-gap: 5px; }
      #identity #main .form-fields > label:nth-of-type(10) span {
        display: inline-block; }
  #identity #main .form-footer [data-link-action="save-customer"] {
    background-color: #5abe20;
    color: white;
    border: 2px solid #5abe20;
    border-radius: 2px;
    transition: all 500ms;
    text-align: center;
    text-transform: uppercase;
    padding: 4px 10px;
    cursor: pointer; }
    #identity #main .form-footer [data-link-action="save-customer"]:hover {
      background-color: white;
      color: #5abe20; }
  #identity #main .page-footer {
    border-top: 1px solid #cccccc;
    padding-top: 10px;
    margin-top: 10px; }
    #identity #main .page-footer ul {
      margin: 0;
      padding: 0; }
      #identity #main .page-footer ul li {
        background-color: #03b8d6;
        color: white;
        border: 2px solid #03b8d6;
        border-radius: 2px;
        transition: all 500ms;
        text-align: center;
        text-transform: uppercase;
        padding: 4px 10px;
        cursor: pointer;
        display: inline-block;
        margin-right: 10px; }
        #identity #main .page-footer ul li:hover {
          background-color: white;
          color: #03b8d6; }
        #identity #main .page-footer ul li a {
          color: inherit; }

#password .form-fields header {
  margin-bottom: 30px; }

#password .form-fields label {
  display: block;
  margin-bottom: 25px; }
  #password .form-fields label span {
    display: block; }

#password .form-footer {
  margin-top: 40px; }
  #password .form-footer button {
    background-color: #5abe20;
    color: white;
    border: 2px solid #5abe20;
    border-radius: 2px;
    transition: all 500ms;
    text-align: center;
    text-transform: uppercase;
    padding: 4px 10px;
    cursor: pointer; }
    #password .form-footer button:hover {
      background-color: white;
      color: #5abe20; }

#history .page-content {
  margin-right: auto;
  margin-left: auto;
  max-width: 1170px;
  min-width: 920px; }
  @media (max-width: 1024px) {
    #history .page-content {
      max-width: 920px;
      min-width: 750px; } }
  @media (max-width: 768px) {
    #history .page-content {
      max-width: 750px;
      min-width: 480px; } }
  @media (max-width: 480px) {
    #history .page-content {
      max-width: 500px;
      min-width: 300px; } }
  #history .page-content h2 {
    font-size: 20px;
    border-bottom: 1px dashed #cccccc;
    padding-bottom: 10px;
    margin-bottom: 10px; }
  #history .page-content .page-footer {
    margin-right: auto;
    margin-left: auto;
    max-width: 1170px;
    min-width: 920px;
    margin-top: 40px;
    padding-top: 20px;
    border-top: 1px dashed #999; }
    @media (max-width: 1024px) {
      #history .page-content .page-footer {
        max-width: 920px;
        min-width: 750px; } }
    @media (max-width: 768px) {
      #history .page-content .page-footer {
        max-width: 750px;
        min-width: 480px; } }
    @media (max-width: 480px) {
      #history .page-content .page-footer {
        max-width: 500px;
        min-width: 300px; } }
    #history .page-content .page-footer ul {
      margin: 0;
      padding: 0; }
      #history .page-content .page-footer ul li {
        list-style: none;
        display: inline-block; }
        #history .page-content .page-footer ul li a {
          background-color: #03b8d6;
          color: white;
          border: 2px solid #03b8d6;
          border-radius: 2px;
          transition: all 500ms;
          text-align: center;
          text-transform: uppercase;
          padding: 4px 10px;
          cursor: pointer; }
          #history .page-content .page-footer ul li a:hover {
            background-color: white;
            color: #03b8d6; }

#history .history-list .history-list-header {
  display: grid;
  grid-template-columns: repeat(3, 12%) 22% repeat(2, 12%) 18%;
  border-bottom: solid 1px black;
  padding-bottom: 10px;
  margin-bottom: 20px; }
  @media (max-width: 576px) {
    #history .history-list .history-list-header {
      display: none; } }
  #history .history-list .history-list-header > div {
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-size: 16px; }

#history .history-list .history-list-body .history-list-body-item {
  display: grid;
  grid-template-columns: repeat(3, 12%) 22% repeat(2, 12%) 18%;
  padding: 20px 0;
  border-bottom: 1px solid #dddddd; }
  @media (max-width: 576px) {
    #history .history-list .history-list-body .history-list-body-item {
      display: block; } }
  #history .history-list .history-list-body .history-list-body-item .history-list-body-item-col {
    align-content: center;
    font-size: 14px; }
    @media (max-width: 576px) {
      #history .history-list .history-list-body .history-list-body-item .history-list-body-item-col {
        margin-bottom: 10px; } }
    #history .history-list .history-list-body .history-list-body-item .history-list-body-item-col .etiqueta {
      display: none; }
      @media (max-width: 576px) {
        #history .history-list .history-list-body .history-list-body-item .history-list-body-item-col .etiqueta {
          display: inline-block;
          font-weight: 600;
          margin-right: 10px; } }
    #history .history-list .history-list-body .history-list-body-item .history-list-body-item-col [data-link-action="view-order-details"] {
      background-color: #03b8d6;
      color: white;
      border: 2px solid #03b8d6;
      border-radius: 2px;
      transition: all 500ms;
      text-align: center;
      text-transform: uppercase;
      padding: 4px 10px;
      cursor: pointer;
      display: inline-block;
      margin-bottom: 8px;
      font-size: 13px; }
      #history .history-list .history-list-body .history-list-body-item .history-list-body-item-col [data-link-action="view-order-details"]:hover {
        background-color: white;
        color: #03b8d6; }
    #history .history-list .history-list-body .history-list-body-item .history-list-body-item-col .order-reorder-link {
      background-color: #5abe20;
      color: white;
      border: 2px solid #5abe20;
      border-radius: 2px;
      transition: all 500ms;
      text-align: center;
      text-transform: uppercase;
      padding: 4px 10px;
      cursor: pointer;
      display: inline-block;
      font-size: 13px; }
      #history .history-list .history-list-body .history-list-body-item .history-list-body-item-col .order-reorder-link:hover {
        background-color: white;
        color: #5abe20; }
    #history .history-list .history-list-body .history-list-body-item .history-list-body-item-col .order-status-label {
      font-size: 13px;
      white-space: nowrap;
      color: white;
      padding: 5px;
      display: inline-block;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      text-align: center; }

#order-detail #main {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  margin-right: auto;
  margin-left: auto;
  max-width: 1170px;
  min-width: 920px;
  margin-top: 40px; }
  @media (max-width: 1024px) {
    #order-detail #main {
      max-width: 920px;
      min-width: 750px; } }
  @media (max-width: 768px) {
    #order-detail #main {
      max-width: 750px;
      min-width: 480px; } }
  @media (max-width: 480px) {
    #order-detail #main {
      max-width: 500px;
      min-width: 300px; } }

#order-detail #order-infos {
  color: black; }
  #order-detail #order-infos a {
    color: black;
    text-decoration: underline; }

#order-detail #order-history {
  padding: 16px;
  margin-bottom: 16px; }
  #order-detail #order-history table thead {
    border-bottom: 1px solid #999; }
  #order-detail #order-history table td, #order-detail #order-history table th {
    padding: 8px; }
    #order-detail #order-history table td .order-status-label, #order-detail #order-history table th .order-status-label {
      padding: 4px 8px;
      font-size: 14px;
      white-space: nowrap;
      color: white; }

#order-detail #delivery-address, #order-detail #invoice-address {
  width: calc(50% - 20px);
  margin: 8px;
  display: inline-block;
  border: 1px dashed #999;
  padding: 16px; }
  @media (max-width: 576px) {
    #order-detail #delivery-address, #order-detail #invoice-address {
      width: calc(100% - 20px); } }

#order-detail .box ul {
  margin: 10px 0;
  padding: 0; }
  #order-detail .box ul li {
    list-style: none; }

#order-detail #order-products {
  margin: 40px 0; }
  #order-detail #order-products th {
    white-space: nowrap;
    padding: 8px;
    background-color: #dddddd; }
    @media (max-width: 576px) {
      #order-detail #order-products th {
        font-size: 13px; } }
  #order-detail #order-products td {
    padding: 8px; }
    @media (max-width: 576px) {
      #order-detail #order-products td {
        font-size: 13px; } }
  #order-detail #order-products tfoot, #order-detail #order-products .line-total {
    border-top: 2px solid #999999; }

#order-detail #order-shipping {
  display: block;
  width: 100%; }
  #order-detail #order-shipping tr {
    width: 100%; }
    @media (max-width: 576px) {
      #order-detail #order-shipping tr {
        font-size: 13px; } }
    #order-detail #order-shipping tr td {
      padding: 8px; }
      @media (max-width: 576px) {
        #order-detail #order-shipping tr td {
          font-size: 13px; } }
    #order-detail #order-shipping tr th {
      white-space: nowrap;
      padding: 8px;
      background-color: #dddddd; }

#order-detail .order-message-form {
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid #777777; }
  #order-detail .order-message-form .form-fields label {
    display: block;
    margin-bottom: 15px; }
    #order-detail .order-message-form .form-fields label span {
      margin-right: 10px; }
  #order-detail .order-message-form .form-footer button {
    background-color: #5abe20;
    color: white;
    border: 2px solid #5abe20;
    border-radius: 2px;
    transition: all 500ms;
    text-align: center;
    text-transform: uppercase;
    padding: 4px 10px;
    cursor: pointer; }
    #order-detail .order-message-form .form-footer button:hover {
      background-color: white;
      color: #5abe20; }

#index .banner-campania {
  margin-right: auto;
  margin-left: auto;
  max-width: 1170px;
  min-width: 920px; }
  @media (max-width: 1024px) {
    #index .banner-campania {
      max-width: 920px;
      min-width: 750px; } }
  @media (max-width: 768px) {
    #index .banner-campania {
      max-width: 750px;
      min-width: 480px; } }
  @media (max-width: 480px) {
    #index .banner-campania {
      max-width: 500px;
      min-width: 300px; } }
  #index .banner-campania img {
    width: 100%; }
    @media (max-width: 768px) {
      #index .banner-campania img {
        width: calc(200% - 60px);
        margin-left: calc(-50% + 30px); } }

#index .propuestas-valor {
  margin-right: auto;
  margin-left: auto;
  max-width: 1170px;
  min-width: 920px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  text-align: center;
  margin-top: 40px;
  border-bottom: 1px solid #dddddd; }
  @media (max-width: 1024px) {
    #index .propuestas-valor {
      max-width: 920px;
      min-width: 750px; } }
  @media (max-width: 768px) {
    #index .propuestas-valor {
      max-width: 750px;
      min-width: 480px; } }
  @media (max-width: 480px) {
    #index .propuestas-valor {
      max-width: 500px;
      min-width: 300px; } }
  @media (max-width: 768px) {
    #index .propuestas-valor {
      margin-top: 15px;
      flex-flow: row wrap; } }
  #index .propuestas-valor .propuestas-valor-item {
    padding: 10px; }
    #index .propuestas-valor .propuestas-valor-item [class*='gol-icon']:before {
      font-size: 45px; }
      @media (max-width: 768px) {
        #index .propuestas-valor .propuestas-valor-item [class*='gol-icon']:before {
          display: none; } }
    @media (max-width: 768px) {
      #index .propuestas-valor .propuestas-valor-item {
        max-width: 33%; }
        #index .propuestas-valor .propuestas-valor-item img {
          display: none; } }
    #index .propuestas-valor .propuestas-valor-item h3 {
      font-size: 21px;
      font-family: "Bree Serif", serif;
      font-weight: 400; }
      @media (max-width: 768px) {
        #index .propuestas-valor .propuestas-valor-item h3 {
          font-family: "Bree Serif", serif;
          font-weight: 400;
          font-size: 18px; } }
    #index .propuestas-valor .propuestas-valor-item p {
      font-family: "Roboto", sans-serif;
      font-weight: 300;
      font-size: 15px; }
      @media (max-width: 768px) {
        #index .propuestas-valor .propuestas-valor-item p {
          font-size: 14px; } }

#index .valoraciones-portada {
  background-color: #eeeeee;
  padding-top: 30px;
  padding-bottom: 30px;
  margin-top: 50px; }
  #index .valoraciones-portada h3 {
    text-align: center;
    font-family: "Bree Serif", serif;
    font-weight: 400;
    font-size: 28px; }
  #index .valoraciones-portada .valoraciones-portada-container {
    margin-right: auto;
    margin-left: auto;
    max-width: 1170px;
    min-width: 920px;
    display: grid;
    grid-template-columns: 50% 50%;
    padding-top: 40px; }
    @media (max-width: 1024px) {
      #index .valoraciones-portada .valoraciones-portada-container {
        max-width: 920px;
        min-width: 750px; } }
    @media (max-width: 768px) {
      #index .valoraciones-portada .valoraciones-portada-container {
        max-width: 750px;
        min-width: 480px; } }
    @media (max-width: 480px) {
      #index .valoraciones-portada .valoraciones-portada-container {
        max-width: 500px;
        min-width: 300px; } }
    @media (max-width: 768px) {
      #index .valoraciones-portada .valoraciones-portada-container {
        display: block;
        padding-top: 15px; } }
  #index .valoraciones-portada .eKomiContainer {
    background-image: url("/themes/base/assets/img/ekomi_home.png");
    background-repeat: no-repeat;
    background-position-y: 85px;
    background-position-x: 110px;
    height: 320px;
    padding: 0 15px;
    text-align: center; }
    @media (max-width: 768px) {
      #index .valoraciones-portada .eKomiContainer {
        background-image: none;
        margin-bottom: 20px; } }
    #index .valoraciones-portada .eKomiContainer .ekomi_home_title {
      font-family: "Roboto", sans-serif;
      font-weight: 300;
      font-size: 25px; }
    #index .valoraciones-portada .eKomiContainer #eKomiWidget_default > a {
      margin-left: 120px; }
      @media (max-width: 768px) {
        #index .valoraciones-portada .eKomiContainer #eKomiWidget_default > a {
          margin-left: 0; } }
    #index .valoraciones-portada .eKomiContainer .ver-todas {
      font-family: "Roboto", sans-serif;
      font-weight: 400;
      font-size: 15px;
      border: 1px solid #333333;
      color: #333333;
      display: block;
      margin: 25px auto auto;
      text-align: center;
      width: 300px;
      padding: 5px; }
      @media (max-width: 768px) {
        #index .valoraciones-portada .eKomiContainer .ver-todas {
          width: 100%;
          padding: 5px 40px; } }
  #index .valoraciones-portada .ekomi_last_comments {
    padding: 0 15px;
    text-align: center; }
    #index .valoraciones-portada .ekomi_last_comments .ekomi_home_title {
      font-family: "Roboto", sans-serif;
      font-weight: 300;
      text-align: center;
      font-size: 25px; }
    #index .valoraciones-portada .ekomi_last_comments .ekomi_home_comments_container {
      width: 100%;
      display: block;
      overflow: hidden;
      position: relative; }
      #index .valoraciones-portada .ekomi_last_comments .ekomi_home_comments_container .djv_carouselh-viewer .djv_carouselh-left-arrow,
      #index .valoraciones-portada .ekomi_last_comments .ekomi_home_comments_container .djv_carouselh-viewer .djv_carouselh-right-arrow {
        background-color: transparent;
        border: none; }
        #index .valoraciones-portada .ekomi_last_comments .ekomi_home_comments_container .djv_carouselh-viewer .djv_carouselh-left-arrow::before,
        #index .valoraciones-portada .ekomi_last_comments .ekomi_home_comments_container .djv_carouselh-viewer .djv_carouselh-right-arrow::before {
          top: 48%;
          font-size: 20px;
          background-color: #ffffffb3; }
          @media (max-width: 768px) {
            #index .valoraciones-portada .ekomi_last_comments .ekomi_home_comments_container .djv_carouselh-viewer .djv_carouselh-left-arrow::before,
            #index .valoraciones-portada .ekomi_last_comments .ekomi_home_comments_container .djv_carouselh-viewer .djv_carouselh-right-arrow::before {
              color: black; } }
      #index .valoraciones-portada .ekomi_last_comments .ekomi_home_comments_container .djv_carouselh-viewer .djv_carouselh-left-arrow::before {
        left: 5px; }
      #index .valoraciones-portada .ekomi_last_comments .ekomi_home_comments_container .djv_carouselh-viewer .djv_carouselh-right-arrow::before {
        right: 5px; }
    #index .valoraciones-portada .ekomi_last_comments .comments-home-list {
      padding: 0;
      position: absolute;
      transition: left 1s; }
      @media (max-width: 768px) {
        #index .valoraciones-portada .ekomi_last_comments .comments-home-list {
          width: 100%;
          overflow-x: auto;
          overflow-y: hidden;
          white-space: nowrap;
          -webkit-overflow-scrolling: touch;
          -webkit-scroll-snap-type: mandatory;
          scroll-snap-type: x mandatory;
          -webkit-scroll-snap-points-x: repeat(100%);
          scroll-snap-points-x: repeat(100%);
          position: relative; } }
      #index .valoraciones-portada .ekomi_last_comments .comments-home-list .comment {
        display: block;
        float: left;
        background-color: white;
        text-align: left;
        min-width: 555px;
        width: 555px; }
        @media (max-width: 768px) {
          #index .valoraciones-portada .ekomi_last_comments .comments-home-list .comment {
            scroll-snap-align: start;
            width: 100%;
            min-width: auto;
            position: relative;
            display: inline-block;
            margin-right: -4px;
            text-align: center;
            border-right: 0;
            float: none; } }
        #index .valoraciones-portada .ekomi_last_comments .comments-home-list .comment .coment-header {
          padding-top: 15px;
          overflow: auto;
          margin-bottom: 15px; }
          #index .valoraciones-portada .ekomi_last_comments .comments-home-list .comment .coment-header .comment_image {
            float: left;
            margin-right: 25px;
            margin-left: 25px; }
            @media (max-width: 768px) {
              #index .valoraciones-portada .ekomi_last_comments .comments-home-list .comment .coment-header .comment_image {
                margin-left: 0;
                margin-right: 0px;
                margin-bottom: 0px;
                float: none; } }
            #index .valoraciones-portada .ekomi_last_comments .comments-home-list .comment .coment-header .comment_image img {
              max-width: 90px; }
          #index .valoraciones-portada .ekomi_last_comments .comments-home-list .comment .coment-header .comment_title {
            font-family: "Roboto", sans-serif;
            font-weight: 500;
            font-size: 15px;
            color: #111111;
            padding-top: 10px; }
            @media (max-width: 768px) {
              #index .valoraciones-portada .ekomi_last_comments .comments-home-list .comment .coment-header .comment_title {
                font-size: 13px; } }
          #index .valoraciones-portada .ekomi_last_comments .comments-home-list .comment .coment-header .comment_author {
            font-family: "Roboto", sans-serif;
            font-weight: 300;
            font-size: 14px;
            color: #666666; }
            @media (max-width: 768px) {
              #index .valoraciones-portada .ekomi_last_comments .comments-home-list .comment .coment-header .comment_author {
                font-size: 10px; } }
        #index .valoraciones-portada .ekomi_last_comments .comments-home-list .comment .comment_body {
          background-color: #666666;
          padding: 25px 20px 20px;
          clear: both;
          min-height: 130px;
          position: relative; }
          #index .valoraciones-portada .ekomi_last_comments .comments-home-list .comment .comment_body::before {
            content: "";
            width: 0;
            height: 0;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            border-bottom: 10px solid #666666;
            position: absolute;
            top: -10px;
            left: 50%;
            transform: translateX(-50%); }
          #index .valoraciones-portada .ekomi_last_comments .comments-home-list .comment .comment_body .comment-text {
            color: white;
            font-size: 15px;
            font-style: italic;
            padding: 5px 15px; }

#index .djv-home-propuestasvalor {
  background-image: url("https://cdn1.golosinasysnacks.com/themes/base/assets/img/parallax-home.jpg");
  text-align: center;
  background-attachment: fixed;
  background-position: bottom;
  background-size: cover;
  background-repeat: no-repeat; }
  @media (max-width: 768px) {
    #index .djv-home-propuestasvalor {
      background-image: url("https://cdn1.golosinasysnacks.com/themes/base/assets/img/parallax-home_mobile.jpg"); } }
  #index .djv-home-propuestasvalor .djv-home-propuestasvalor-container {
    margin-right: auto;
    margin-left: auto;
    max-width: 1170px;
    min-width: 920px;
    display: flex;
    flex-direction: row; }
    @media (max-width: 1024px) {
      #index .djv-home-propuestasvalor .djv-home-propuestasvalor-container {
        max-width: 920px;
        min-width: 750px; } }
    @media (max-width: 768px) {
      #index .djv-home-propuestasvalor .djv-home-propuestasvalor-container {
        max-width: 750px;
        min-width: 480px; } }
    @media (max-width: 480px) {
      #index .djv-home-propuestasvalor .djv-home-propuestasvalor-container {
        max-width: 500px;
        min-width: 300px; } }
    #index .djv-home-propuestasvalor .djv-home-propuestasvalor-container .djv-home-propuestasvalor-element {
      margin: 0 15px;
      background-color: rgba(255, 255, 255, 0.65);
      padding: 40px 20px;
      flex: 1; }
      @media (max-width: 768px) {
        #index .djv-home-propuestasvalor .djv-home-propuestasvalor-container .djv-home-propuestasvalor-element {
          padding: 20px; } }
      #index .djv-home-propuestasvalor .djv-home-propuestasvalor-container .djv-home-propuestasvalor-element i {
        font-size: 48px;
        color: #a51c5a;
        margin-bottom: 10px;
        display: block; }
        #index .djv-home-propuestasvalor .djv-home-propuestasvalor-container .djv-home-propuestasvalor-element i.djv-icon-calidad {
          font-size: 44px; }
      #index .djv-home-propuestasvalor .djv-home-propuestasvalor-container .djv-home-propuestasvalor-element h3 {
        font-size: 26px;
        font-family: "Bree Serif", serif;
        font-weight: 400;
        color: #a51c5a;
        font-weight: 600;
        position: relative;
        margin-top: -17px;
        margin-bottom: 5px; }
      #index .djv-home-propuestasvalor .djv-home-propuestasvalor-container .djv-home-propuestasvalor-element p {
        font-size: 15px; }
      #index .djv-home-propuestasvalor .djv-home-propuestasvalor-container .djv-home-propuestasvalor-element a {
        color: inherit;
        font-size: 13px; }

#index .djv-home-categories-shortcut {
  margin-right: auto;
  margin-left: auto;
  max-width: 1170px;
  min-width: 920px;
  text-align: center;
  margin-bottom: 50px; }
  @media (max-width: 1024px) {
    #index .djv-home-categories-shortcut {
      max-width: 920px;
      min-width: 750px; } }
  @media (max-width: 768px) {
    #index .djv-home-categories-shortcut {
      max-width: 750px;
      min-width: 480px; } }
  @media (max-width: 480px) {
    #index .djv-home-categories-shortcut {
      max-width: 500px;
      min-width: 300px; } }
  #index .djv-home-categories-shortcut .djv-home-categories-shortcut-explain {
    font-size: 17px;
    margin-top: 0px;
    font-family: "Roboto", sans-serif;
    font-weight: 300;
    line-height: auto;
    margin-bottom: 20px; }
  #index .djv-home-categories-shortcut .djv-home-categories-shortcut-claim {
    font-family: "Bree Serif", serif;
    font-weight: 400;
    font-size: 28px;
    margin: 10px; }
  #index .djv-home-categories-shortcut .djv-home-categories-shortcut-container {
    display: grid;
    grid-template-columns: repeat(4, calc(25% - 30px));
    grid-gap: 15px; }
    @media (max-width: 768px) {
      #index .djv-home-categories-shortcut .djv-home-categories-shortcut-container {
        display: block;
        width: 100%; }
        #index .djv-home-categories-shortcut .djv-home-categories-shortcut-container .djv-home-categories-shortcut-element {
          margin-bottom: 10px;
          width: 100%; } }
    #index .djv-home-categories-shortcut .djv-home-categories-shortcut-container img {
      max-width: 100%; }
      @media (max-width: 768px) {
        #index .djv-home-categories-shortcut .djv-home-categories-shortcut-container img {
          display: none; } }
    #index .djv-home-categories-shortcut .djv-home-categories-shortcut-container .djv-home-categories-shortcut-element {
      border: 1px solid #dddddd;
      padding: 0 0 6px 0; }
      @media (max-width: 768px) {
        #index .djv-home-categories-shortcut .djv-home-categories-shortcut-container .djv-home-categories-shortcut-element {
          background-image: none !important;
          border: 2px solid #aaaaaa;
          border-radius: 50px;
          min-height: auto; } }
      #index .djv-home-categories-shortcut .djv-home-categories-shortcut-container .djv-home-categories-shortcut-element .djv-home-categories-shortcut-element-wrapper {
        color: black;
        width: 100%; }
        @media (max-width: 768px) {
          #index .djv-home-categories-shortcut .djv-home-categories-shortcut-container .djv-home-categories-shortcut-element .djv-home-categories-shortcut-element-wrapper {
            margin-top: 0;
            padding: 10px 5px; } }
        #index .djv-home-categories-shortcut .djv-home-categories-shortcut-container .djv-home-categories-shortcut-element .djv-home-categories-shortcut-element-wrapper h3 {
          font-size: 20px;
          font-family: "Bree Serif", serif;
          font-weight: 600;
          position: relative;
          margin-top: 5px;
          margin-bottom: 5px;
          color: black; }
          @media (max-width: 768px) {
            #index .djv-home-categories-shortcut .djv-home-categories-shortcut-container .djv-home-categories-shortcut-element .djv-home-categories-shortcut-element-wrapper h3 {
              color: #ab2b65;
              text-decoration: underline;
              font-size: 19px; } }
        #index .djv-home-categories-shortcut .djv-home-categories-shortcut-container .djv-home-categories-shortcut-element .djv-home-categories-shortcut-element-wrapper p {
          font-size: 16px;
          font-family: "Roboto", sans-serif;
          font-weight: 300; }
          @media (max-width: 768px) {
            #index .djv-home-categories-shortcut .djv-home-categories-shortcut-container .djv-home-categories-shortcut-element .djv-home-categories-shortcut-element-wrapper p {
              font-size: 14px;
              color: #444444;
              margin-bottom: 0px; } }
        #index .djv-home-categories-shortcut .djv-home-categories-shortcut-container .djv-home-categories-shortcut-element .djv-home-categories-shortcut-element-wrapper a {
          color: #ab2b65; }

#index .mejor-tienda {
  margin-right: auto;
  margin-left: auto;
  max-width: 1170px;
  min-width: 920px;
  text-align: center;
  margin-bottom: 20px; }
  @media (max-width: 1024px) {
    #index .mejor-tienda {
      max-width: 920px;
      min-width: 750px; } }
  @media (max-width: 768px) {
    #index .mejor-tienda {
      max-width: 750px;
      min-width: 480px; } }
  @media (max-width: 480px) {
    #index .mejor-tienda {
      max-width: 500px;
      min-width: 300px; } }
  #index .mejor-tienda .mejor-tienda-header {
    margin-bottom: 20px; }
    #index .mejor-tienda .mejor-tienda-header img {
      width: 80px; }
    #index .mejor-tienda .mejor-tienda-header h2 {
      font-family: "Bree Serif", serif;
      font-weight: 400;
      font-size: 22px; }
    #index .mejor-tienda .mejor-tienda-header p {
      font-size: 16px; }
  #index .mejor-tienda .mejor-tienda-grid {
    display: grid;
    grid-template-columns: repeat(2, calc(50% - 0px));
    grid-gap: 0px;
    grid-row-gap: 0;
    width: 100%; }
    #index .mejor-tienda .mejor-tienda-grid > div {
      border-top: 1px solid #dddddd;
      border-bottom: 1px solid #dddddd;
      padding: 16px 0;
      margin: 0 10px; }
      #index .mejor-tienda .mejor-tienda-grid > div:nth-of-type(1), #index .mejor-tienda .mejor-tienda-grid > div:nth-of-type(2) {
        border-bottom: 0; }
      #index .mejor-tienda .mejor-tienda-grid > div h3 {
        font-family: "Bree Serif", serif;
        font-weight: 400;
        font-size: 20px;
        color: #E65EA6; }
      #index .mejor-tienda .mejor-tienda-grid > div p {
        font-size: 14px;
        width: 80%;
        display: block;
        margin: 0 auto; }
        #index .mejor-tienda .mejor-tienda-grid > div p a {
          color: #E65EA6; }

@media (max-width: 768px) {
  #index .djv-home-propuestasvalor {
    background-attachment: inherit; }
    #index .djv-home-propuestasvalor .djv-home-propuestasvalor-container {
      display: block !important;
      padding: 15px !important; }
      #index .djv-home-propuestasvalor .djv-home-propuestasvalor-container #h1Portada {
        text-align: center;
        font-family: "Bree Serif", serif;
        font-weight: 400;
        font-size: 24px; }
      #index .djv-home-propuestasvalor .djv-home-propuestasvalor-container #claimPortada {
        color: inherit; }
      #index .djv-home-propuestasvalor .djv-home-propuestasvalor-container .djv-home-propuestasvalor-element {
        display: block;
        width: 95%;
        margin: 20px auto !important; } }

.djv-home-propuestasvalor {
  background-image: url("/themes/base/assets/img/parallax-home.jpg");
  text-align: center;
  background-attachment: fixed;
  background-position: bottom;
  background-size: cover;
  background-repeat: no-repeat; }
  .djv-home-propuestasvalor .djv-home-propuestasvalor-container {
    margin-right: auto;
    margin-left: auto;
    max-width: 1170px;
    min-width: 920px;
    display: flex;
    flex-direction: row; }
    @media (max-width: 1024px) {
      .djv-home-propuestasvalor .djv-home-propuestasvalor-container {
        max-width: 920px;
        min-width: 750px; } }
    @media (max-width: 768px) {
      .djv-home-propuestasvalor .djv-home-propuestasvalor-container {
        max-width: 750px;
        min-width: 480px; } }
    @media (max-width: 480px) {
      .djv-home-propuestasvalor .djv-home-propuestasvalor-container {
        max-width: 500px;
        min-width: 300px; } }
    .djv-home-propuestasvalor .djv-home-propuestasvalor-container .djv-home-propuestasvalor-element {
      margin: 0 15px;
      background-color: rgba(255, 255, 255, 0.65);
      padding: 40px 20px;
      flex: 1; }
      @media (max-width: 768px) {
        .djv-home-propuestasvalor .djv-home-propuestasvalor-container .djv-home-propuestasvalor-element {
          padding: 20px; } }
      .djv-home-propuestasvalor .djv-home-propuestasvalor-container .djv-home-propuestasvalor-element i {
        font-size: 48px;
        color: #a51c5a;
        margin-bottom: 10px;
        display: block; }
        .djv-home-propuestasvalor .djv-home-propuestasvalor-container .djv-home-propuestasvalor-element i.djv-icon-calidad {
          font-size: 44px; }
      .djv-home-propuestasvalor .djv-home-propuestasvalor-container .djv-home-propuestasvalor-element h3 {
        font-size: 26px;
        font-family: "Bree Serif", serif;
        font-weight: 400;
        color: #a51c5a;
        font-weight: 600;
        position: relative;
        margin-top: -17px;
        margin-bottom: 5px; }
      .djv-home-propuestasvalor .djv-home-propuestasvalor-container .djv-home-propuestasvalor-element p {
        font-size: 15px; }
      .djv-home-propuestasvalor .djv-home-propuestasvalor-container .djv-home-propuestasvalor-element a {
        color: inherit;
        font-size: 13px; }

@media (max-width: 768px) {
  .djv-home-propuestasvalor {
    background-attachment: inherit; }
    .djv-home-propuestasvalor .djv-home-propuestasvalor-container {
      display: block !important;
      padding: 15px !important; }
      .djv-home-propuestasvalor .djv-home-propuestasvalor-container #h1Portada {
        text-align: center;
        font-family: "Bree Serif", serif;
        font-weight: 400;
        font-size: 24px; }
      .djv-home-propuestasvalor .djv-home-propuestasvalor-container #claimPortada {
        color: inherit; }
      .djv-home-propuestasvalor .djv-home-propuestasvalor-container .djv-home-propuestasvalor-element {
        display: block;
        width: 95%;
        margin: 20px auto !important; } }

#index .products .product-miniature {
  border-right: 1px solid #dddddd;
  padding-left: 10px;
  padding-right: 10px;
  text-align: center;
  position: relative; }
  #index .products .product-miniature .product-description-short p {
    margin-bottom: 5px; }
  #index .products .product-miniature > a img {
    width: 100%;
    max-width: 210px; }

#contact #content {
  margin-right: auto;
  margin-left: auto;
  max-width: 1170px;
  min-width: 920px; }
  @media (max-width: 1024px) {
    #contact #content {
      max-width: 920px;
      min-width: 750px; } }
  @media (max-width: 768px) {
    #contact #content {
      max-width: 750px;
      min-width: 480px; } }
  @media (max-width: 480px) {
    #contact #content {
      max-width: 500px;
      min-width: 300px; } }
  #contact #content h1 {
    font-size: 22px;
    color: #03b8d6; }
  #contact #content .login-form {
    font-family: "Roboto", sans-serif;
    font-weight: 400; }
    #contact #content .login-form h2 {
      font-size: 18px;
      font-weight: 600; }
    #contact #content .login-form .form-fields {
      display: grid;
      grid-template-columns: 50%; }
      #contact #content .login-form .form-fields label {
        margin-bottom: 10px;
        display: block; }
        #contact #content .login-form .form-fields label span {
          display: block;
          margin-bottom: 10px; }
        #contact #content .login-form .form-fields label.psgdpr_consent_message input {
          display: inline-block;
          margin-top: 5px;
          float: left;
          margin-right: 10px; }
        #contact #content .login-form .form-fields label.psgdpr_consent_message span {
          display: inline-block;
          width: 80%; }
    #contact #content .login-form button {
      background-color: #5abe20;
      color: white;
      border: 2px solid #5abe20;
      border-radius: 2px;
      transition: all 500ms;
      text-align: center;
      text-transform: uppercase;
      padding: 4px 10px;
      cursor: pointer;
      padding-left: 16px;
      padding-right: 16px; }
      #contact #content .login-form button:hover {
        background-color: white;
        color: #5abe20; }
    #contact #content .login-form i.material-icons {
      display: none; }

section .page-cms {
  margin-right: auto;
  margin-left: auto;
  max-width: 1170px;
  min-width: 920px;
  font-family: "Roboto", sans-serif;
  font-weight: 400; }
  @media (max-width: 1024px) {
    section .page-cms {
      max-width: 920px;
      min-width: 750px; } }
  @media (max-width: 768px) {
    section .page-cms {
      max-width: 750px;
      min-width: 480px; } }
  @media (max-width: 480px) {
    section .page-cms {
      max-width: 500px;
      min-width: 300px; } }
  section .page-cms h1 {
    margin-bottom: 30px;
    padding-bottom: 15px;
    border-bottom: 1px dashed #bababa;
    font-size: 28px;
    color: #03b8d6; }
    @media (max-width: 576px) {
      section .page-cms h1 {
        font-size: 22px; } }
  section .page-cms h2 {
    margin-top: 30px;
    margin-bottom: 30px;
    font-size: 22px;
    color: #03b8d6; }
  section .page-cms ul {
    list-style-type: disc;
    box-sizing: border-box; }
    section .page-cms ul.nobullet {
      list-style-type: none; }
    @media (max-width: 768px) {
      section .page-cms ul {
        display: block; }
        section .page-cms ul li {
          display: block;
          margin-bottom: 20px; } }

#module-djv_navigation-nsearch #djv-two-columns-layer #content-wrapper .textoSeo .troncal-almayor {
  text-align: center; }
  #module-djv_navigation-nsearch #djv-two-columns-layer #content-wrapper .textoSeo .troncal-almayor h2, #module-djv_navigation-nsearch #djv-two-columns-layer #content-wrapper .textoSeo .troncal-almayor h3 {
    font-family: "Bree Serif", serif;
    font-weight: 400;
    color: black; }
  #module-djv_navigation-nsearch #djv-two-columns-layer #content-wrapper .textoSeo .troncal-almayor h3 {
    font-size: 20px;
    margin-top: 16px; }
  #module-djv_navigation-nsearch #djv-two-columns-layer #content-wrapper .textoSeo .troncal-almayor p {
    line-height: normal;
    font-size: 16px;
    color: black; }
  #module-djv_navigation-nsearch #djv-two-columns-layer #content-wrapper .textoSeo .troncal-almayor a.troncal-btn {
    background-color: #03b8d6;
    color: white;
    padding: 7px 14px;
    margin: 16px auto;
    display: block;
    width: max-content; }
  #module-djv_navigation-nsearch #djv-two-columns-layer #content-wrapper .textoSeo .troncal-almayor img {
    margin: auto; }
    @media (max-width: 768px) {
      #module-djv_navigation-nsearch #djv-two-columns-layer #content-wrapper .textoSeo .troncal-almayor img {
        max-width: 100%; } }
  #module-djv_navigation-nsearch #djv-two-columns-layer #content-wrapper .textoSeo .troncal-almayor > div {
    margin-top: 40px;
    margin-bottom: 40px; }
  #module-djv_navigation-nsearch #djv-two-columns-layer #content-wrapper .textoSeo .troncal-almayor .propuestasvalor {
    display: grid;
    grid-template-columns: repeat(4, calc(25% - 20px));
    grid-gap: 10px; }
    @media (max-width: 768px) {
      #module-djv_navigation-nsearch #djv-two-columns-layer #content-wrapper .textoSeo .troncal-almayor .propuestasvalor {
        display: block; } }
    #module-djv_navigation-nsearch #djv-two-columns-layer #content-wrapper .textoSeo .troncal-almayor .propuestasvalor p {
      font-size: 15px; }
    #module-djv_navigation-nsearch #djv-two-columns-layer #content-wrapper .textoSeo .troncal-almayor .propuestasvalor > div img {
      width: 50px; }
  #module-djv_navigation-nsearch #djv-two-columns-layer #content-wrapper .textoSeo .troncal-almayor .categorias1 > div, #module-djv_navigation-nsearch #djv-two-columns-layer #content-wrapper .textoSeo .troncal-almayor .categorias2 > div {
    margin-bottom: 16px;
    border: 1px solid #eeeeee;
    max-width: 277px; }
    #module-djv_navigation-nsearch #djv-two-columns-layer #content-wrapper .textoSeo .troncal-almayor .categorias1 > div h3, #module-djv_navigation-nsearch #djv-two-columns-layer #content-wrapper .textoSeo .troncal-almayor .categorias2 > div h3 {
      padding: 0 16px; }
    #module-djv_navigation-nsearch #djv-two-columns-layer #content-wrapper .textoSeo .troncal-almayor .categorias1 > div p, #module-djv_navigation-nsearch #djv-two-columns-layer #content-wrapper .textoSeo .troncal-almayor .categorias2 > div p {
      padding: 0 16px 14px 0; }
  #module-djv_navigation-nsearch #djv-two-columns-layer #content-wrapper .textoSeo .troncal-almayor .categorias1 {
    padding: 0 10%;
    display: grid;
    grid-template-columns: repeat(3, calc(33.33333% - 20px));
    grid-gap: 10px; }
    @media (max-width: 768px) {
      #module-djv_navigation-nsearch #djv-two-columns-layer #content-wrapper .textoSeo .troncal-almayor .categorias1 {
        display: block;
        padding: 0; } }
  #module-djv_navigation-nsearch #djv-two-columns-layer #content-wrapper .textoSeo .troncal-almayor .categorias2 {
    padding: 0 20%;
    display: grid;
    grid-template-columns: repeat(2, calc(50% - 20px));
    grid-gap: 10px; }
    @media (max-width: 768px) {
      #module-djv_navigation-nsearch #djv-two-columns-layer #content-wrapper .textoSeo .troncal-almayor .categorias2 {
        display: block;
        padding: 0; } }
  #module-djv_navigation-nsearch #djv-two-columns-layer #content-wrapper .textoSeo .troncal-almayor .contactanos-full-width {
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url("https://cdn1.golosinasysnacks.com/themes/base/assets/img/bg-fullwidth-almayor.jpg");
    text-align: center;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat; }
    #module-djv_navigation-nsearch #djv-two-columns-layer #content-wrapper .textoSeo .troncal-almayor .contactanos-full-width .contactanos-container {
      background-color: #fffc;
      padding: 26px;
      width: 30%;
      border-radius: 3px; }
      #module-djv_navigation-nsearch #djv-two-columns-layer #content-wrapper .textoSeo .troncal-almayor .contactanos-full-width .contactanos-container h3 {
        font-size: 30px; }
      @media (max-width: 768px) {
        #module-djv_navigation-nsearch #djv-two-columns-layer #content-wrapper .textoSeo .troncal-almayor .contactanos-full-width .contactanos-container {
          width: 90%; } }
  #module-djv_navigation-nsearch #djv-two-columns-layer #content-wrapper .textoSeo .troncal-almayor .paraque .paraque-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center; }
    @media (max-width: 768px) {
      #module-djv_navigation-nsearch #djv-two-columns-layer #content-wrapper .textoSeo .troncal-almayor .paraque .paraque-container {
        display: block; } }
    #module-djv_navigation-nsearch #djv-two-columns-layer #content-wrapper .textoSeo .troncal-almayor .paraque .paraque-container > div {
      width: 48%;
      margin: 16px 1%;
      padding: 16px; }
      @media (max-width: 768px) {
        #module-djv_navigation-nsearch #djv-two-columns-layer #content-wrapper .textoSeo .troncal-almayor .paraque .paraque-container > div {
          width: 100%; } }
      #module-djv_navigation-nsearch #djv-two-columns-layer #content-wrapper .textoSeo .troncal-almayor .paraque .paraque-container > div h3 {
        color: #ab2b65; }
  #module-djv_navigation-nsearch #djv-two-columns-layer #content-wrapper .textoSeo .troncal-almayor .envoltorio {
    background-image: url("https://cdn1.golosinasysnacks.com/themes/base/assets/img/envoltorio-individual.jpg");
    text-align: center;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 32px 0; }
    #module-djv_navigation-nsearch #djv-two-columns-layer #content-wrapper .textoSeo .troncal-almayor .envoltorio .envoltorio-container {
      width: 420px;
      margin: 0 auto; }
      @media (max-width: 768px) {
        #module-djv_navigation-nsearch #djv-two-columns-layer #content-wrapper .textoSeo .troncal-almayor .envoltorio .envoltorio-container {
          width: 90%; } }
    #module-djv_navigation-nsearch #djv-two-columns-layer #content-wrapper .textoSeo .troncal-almayor .envoltorio .constick::before {
      content: "";
      display: inline-block;
      width: 18px;
      height: 14px;
      background-image: url("https://cdn1.golosinasysnacks.com/themes/base/assets/img/garrapata.svg");
      background-repeat: no-repeat;
      background-size: contain; }
  #module-djv_navigation-nsearch #djv-two-columns-layer #content-wrapper .textoSeo .troncal-almayor .descuentos {
    background-image: url("https://cdn1.golosinasysnacks.com/themes/base/assets/img/fondo-descuentos-grandes-cantidades.jpg");
    text-align: center;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 32px 0; }
    #module-djv_navigation-nsearch #djv-two-columns-layer #content-wrapper .textoSeo .troncal-almayor .descuentos .envoltorio-container {
      width: 420px;
      margin: 0 auto; }
      @media (max-width: 768px) {
        #module-djv_navigation-nsearch #djv-two-columns-layer #content-wrapper .textoSeo .troncal-almayor .descuentos .envoltorio-container {
          width: 90%; } }
  #module-djv_navigation-nsearch #djv-two-columns-layer #content-wrapper .textoSeo .troncal-almayor .preguntas-frecuentes h2 {
    margin-bottom: 40px; }
  #module-djv_navigation-nsearch #djv-two-columns-layer #content-wrapper .textoSeo .troncal-almayor .preguntas-frecuentes .question {
    font-weight: bold;
    font-size: 20px;
    color: black;
    margin: 32px auto 16px; }

#module-ph_simpleblog-list #djv-two-columns-layer,
#module-ph_simpleblog-page #djv-two-columns-layer,
#module-ph_simpleblog-category #djv-two-columns-layer,
#module-ph_simpleblog-tag #djv-two-columns-layer,
#module-ph_simpleblog-categorypage #djv-two-columns-layer {
  margin-right: auto;
  margin-left: auto;
  max-width: 1170px;
  min-width: 920px;
  display: grid;
  grid-template-columns: calc(20% - 20px) calc(80% - 20px);
  grid-gap: 20px; }
  @media (max-width: 1024px) {
    #module-ph_simpleblog-list #djv-two-columns-layer,
    #module-ph_simpleblog-page #djv-two-columns-layer,
    #module-ph_simpleblog-category #djv-two-columns-layer,
    #module-ph_simpleblog-tag #djv-two-columns-layer,
    #module-ph_simpleblog-categorypage #djv-two-columns-layer {
      max-width: 920px;
      min-width: 750px; } }
  @media (max-width: 768px) {
    #module-ph_simpleblog-list #djv-two-columns-layer,
    #module-ph_simpleblog-page #djv-two-columns-layer,
    #module-ph_simpleblog-category #djv-two-columns-layer,
    #module-ph_simpleblog-tag #djv-two-columns-layer,
    #module-ph_simpleblog-categorypage #djv-two-columns-layer {
      max-width: 750px;
      min-width: 480px; } }
  @media (max-width: 480px) {
    #module-ph_simpleblog-list #djv-two-columns-layer,
    #module-ph_simpleblog-page #djv-two-columns-layer,
    #module-ph_simpleblog-category #djv-two-columns-layer,
    #module-ph_simpleblog-tag #djv-two-columns-layer,
    #module-ph_simpleblog-categorypage #djv-two-columns-layer {
      max-width: 500px;
      min-width: 300px; } }
  @media (max-width: 1024px) {
    #module-ph_simpleblog-list #djv-two-columns-layer,
    #module-ph_simpleblog-page #djv-two-columns-layer,
    #module-ph_simpleblog-category #djv-two-columns-layer,
    #module-ph_simpleblog-tag #djv-two-columns-layer,
    #module-ph_simpleblog-categorypage #djv-two-columns-layer {
      display: block; } }
  #module-ph_simpleblog-list #djv-two-columns-layer h1,
  #module-ph_simpleblog-page #djv-two-columns-layer h1,
  #module-ph_simpleblog-category #djv-two-columns-layer h1,
  #module-ph_simpleblog-tag #djv-two-columns-layer h1,
  #module-ph_simpleblog-categorypage #djv-two-columns-layer h1 {
    font-size: 28px;
    margin: 13px 0 14px; }
  #module-ph_simpleblog-list #djv-two-columns-layer .simpleblog-posts::before,
  #module-ph_simpleblog-page #djv-two-columns-layer .simpleblog-posts::before,
  #module-ph_simpleblog-category #djv-two-columns-layer .simpleblog-posts::before,
  #module-ph_simpleblog-tag #djv-two-columns-layer .simpleblog-posts::before,
  #module-ph_simpleblog-categorypage #djv-two-columns-layer .simpleblog-posts::before {
    display: none; }
  #module-ph_simpleblog-list #djv-two-columns-layer a:hover,
  #module-ph_simpleblog-page #djv-two-columns-layer a:hover,
  #module-ph_simpleblog-category #djv-two-columns-layer a:hover,
  #module-ph_simpleblog-tag #djv-two-columns-layer a:hover,
  #module-ph_simpleblog-categorypage #djv-two-columns-layer a:hover {
    color: #6abac7; }

#module-ph_simpleblog-list .pagination > li > a, #module-ph_simpleblog-list .pagination > li > span,
#module-ph_simpleblog-page .pagination > li > a,
#module-ph_simpleblog-page .pagination > li > span,
#module-ph_simpleblog-category .pagination > li > a,
#module-ph_simpleblog-category .pagination > li > span,
#module-ph_simpleblog-tag .pagination > li > a,
#module-ph_simpleblog-tag .pagination > li > span,
#module-ph_simpleblog-categorypage .pagination > li > a,
#module-ph_simpleblog-categorypage .pagination > li > span {
  position: relative;
  float: left;
  padding: 6px 12px;
  line-height: 1.42857;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #ddd;
  margin-left: -1px;
  color: inherit; }

#module-ph_simpleblog-list .pagination > li.disabled,
#module-ph_simpleblog-page .pagination > li.disabled,
#module-ph_simpleblog-category .pagination > li.disabled,
#module-ph_simpleblog-tag .pagination > li.disabled,
#module-ph_simpleblog-categorypage .pagination > li.disabled {
  cursor: not-allowed; }

#module-ph_simpleblog-category .post-additional-info {
  background-color: transparent; }

.simpleblog-recent .btn-hover-animated-green-dark {
  margin-left: auto;
  margin-right: auto; }
  .simpleblog-recent .btn-hover-animated-green-dark a {
    color: inherit; }

#module-ph_simpleblog-single #djv-two-columns-layer {
  margin-right: auto;
  margin-left: auto;
  max-width: 1170px;
  min-width: 920px;
  display: grid;
  grid-template-columns: calc(20% - 20px) calc(80% - 20px);
  grid-gap: 20px; }
  @media (max-width: 1024px) {
    #module-ph_simpleblog-single #djv-two-columns-layer {
      max-width: 920px;
      min-width: 750px; } }
  @media (max-width: 768px) {
    #module-ph_simpleblog-single #djv-two-columns-layer {
      max-width: 750px;
      min-width: 480px; } }
  @media (max-width: 480px) {
    #module-ph_simpleblog-single #djv-two-columns-layer {
      max-width: 500px;
      min-width: 300px; } }
  @media (max-width: 1024px) {
    #module-ph_simpleblog-single #djv-two-columns-layer {
      display: block; } }
  #module-ph_simpleblog-single #djv-two-columns-layer h2 strong {
    color: #6abac7; }
  #module-ph_simpleblog-single #djv-two-columns-layer #left-column {
    padding: 16px; }
  #module-ph_simpleblog-single #djv-two-columns-layer .djv_carouselh h5 {
    font-size: 22px;
    line-height: inherit; }
    #module-ph_simpleblog-single #djv-two-columns-layer .djv_carouselh h5 a {
      color: #999999; }
  #module-ph_simpleblog-single #djv-two-columns-layer .djv_carouselh .price {
    font-size: 26px; }
  #module-ph_simpleblog-single #djv-two-columns-layer .djv_carouselh .price-discounted {
    color: #E65EA6;
    font-size: 26px; }
  #module-ph_simpleblog-single #djv-two-columns-layer .djv_carouselh .regular-price {
    text-decoration: line-through;
    font-size: 26px; }
  #module-ph_simpleblog-single #djv-two-columns-layer .djv_carouselh .discount-percentage {
    display: none; }
  #module-ph_simpleblog-single #djv-two-columns-layer .djv_carouselh .products > * {
    min-height: 370px; }
  #module-ph_simpleblog-single #djv-two-columns-layer #simpleblog-post-comments {
    font-family: "Roboto", sans-serif;
    font-weight: 400; }
    #module-ph_simpleblog-single #djv-two-columns-layer #simpleblog-post-comments .post-comment:nth-child(2n) {
      margin-left: 90px;
      padding-right: 40px;
      border-bottom-left-radius: 10px; }

#ph_simpleblog_categories {
  padding-top: 7px; }
  #ph_simpleblog_categories > a {
    border-bottom: 1px solid #dddddd;
    font-size: 28px;
    display: block;
    color: inherit;
    margin-bottom: 20px;
    padding-bottom: 14px; }
  #ph_simpleblog_categories ul {
    margin: 0;
    padding: 0; }
    #ph_simpleblog_categories ul li {
      border-bottom: 1px dashed #cccccc;
      padding-bottom: 10px;
      margin-bottom: 10px;
      list-style: none;
      margin-bottom: 5px;
      padding-bottom: 5px; }
      #ph_simpleblog_categories ul li a {
        color: inherit; }

body:not(#index) .ph_simpleblog .post-meta-info .post-likes, body:not(#index) .ph_simpleblog .post-meta-info .post-views {
  display: none; }

body:not(#index) .ph_simpleblog .simpleblog-posts {
  display: grid !important;
  grid-gap: 10px;
  grid-template-columns: repeat(2, calc(50% - 10px));
  margin-left: auto !important;
  margin-right: auto !important; }
  body:not(#index) .ph_simpleblog .simpleblog-posts .simpleblog-post-item .post-item {
    border-radius: 6px;
    overflow: hidden; }
    body:not(#index) .ph_simpleblog .simpleblog-posts .simpleblog-post-item .post-item h2 {
      padding-top: 20px; }
      body:not(#index) .ph_simpleblog .simpleblog-posts .simpleblog-post-item .post-item h2 a {
        font-size: 22px;
        text-transform: uppercase;
        text-decoration: none;
        padding: 0 5px;
        display: block;
        color: #76c9d7; }
    body:not(#index) .ph_simpleblog .simpleblog-posts .simpleblog-post-item .post-item .post-content {
      font-family: "Roboto", sans-serif;
      font-weight: 400;
      font-size: 20px;
      line-height: 18px; }

.simpleblog-single h1 {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 22px !important;
  line-height: 32px !important;
  margin: 13px 0 14px; }

.simpleblog-single .post-featured-image {
  width: 100%;
  margin-bottom: 40px !important; }
  .simpleblog-single .post-featured-image img {
    width: 100%; }

.simpleblog-single .post-content {
  width: 80%; }
  @media (max-width: 768px) {
    .simpleblog-single .post-content {
      padding: 16px;
      width: 100%; } }
  .simpleblog-single .post-content h2 {
    font-family: "Roboto", sans-serif;
    font-weight: 600;
    font-size: 22px;
    margin-top: 60px;
    margin-bottom: 10px;
    padding-bottom: 5px; }
  .simpleblog-single .post-content h3 {
    font-size: 22px;
    margin-top: 50px; }
  .simpleblog-single .post-content p {
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-size: 22px; }
  .simpleblog-single .post-content a:hover {
    color: #6abac7; }

.simpleblog-single .post-category a:hover {
  color: #6abac7; }

#notifications {
  margin-right: auto;
  margin-left: auto;
  max-width: 1170px;
  min-width: 920px;
  padding: 10px 10px 1px;
  margin-top: 5px; }
  @media (max-width: 1024px) {
    #notifications {
      max-width: 920px;
      min-width: 750px; } }
  @media (max-width: 768px) {
    #notifications {
      max-width: 750px;
      min-width: 480px; } }
  @media (max-width: 480px) {
    #notifications {
      max-width: 500px;
      min-width: 300px; } }

.notification-danger {
  background: #f2dede;
  border: #ebccd1; }

.notification-warning {
  background: #fcf8e3;
  border: #faebcc; }

.notification-success {
  background: #dff0d8;
  border: #d6e9c6; }

.notification-info {
  background: #d9edf7;
  border: #bce8f1; }

.watchmework {
  font-size: 2.25; }
