@import "project/_djv-project";
@import "project/_vars";
@import "project/djv-icon-font/css/comunes";
@import "project/djv-icon-font/css/golosinas";// NOTE: Especifico de golosinas
@import "project/_mixins";
@import "project/_djv-extends";// NOTE: Eliminar todos los extends en cuanto se pueda
@import "project/_common";
@import "project/_layout";
@import "project/_breadcrumbs";
//----------------
// HEADER global y menu
//----------------
@import "project/_header-grid";
//@import "project/_header-sticky"; // NOTE: Sin unificar
@import "project/_djv-corporative-nav"; // NOTE: Sin unificar
@import "project/_header-mobile";

@import "project/_main-menu";
//@import "project/_main-menu-sticky";
//@import "project/_main-menu-mobile";

@import "project/_cart-preview";

@import "project/_footer";

//@import "project/_carousel"; // NOTE:Para el slider de banners
@import "project/_slider"; // NOTE:Para el slider de banners
@import "project/_carouselh";// NOTE:Para listas con carousel
//@import "project/_carousel-marcas";// NOTE:Para listas de marcas
//@import "project/_swipe";// NOTE:En este proyecto no se carga por que esta en un mixin
@import "project/_zoom-pan";
@import "project/_expandtext";

@import "project/_vouchers";// NOTE: Proyectos que muestren cupones
//----------------
//PRODUTO - ficha
//----------------
@import "project/_product-sheet";
@import "project/_product-img";
@import "project/_product-quantity";
@import "project/_product-prices";
//@import "project/_product-price-by-unit-resume";// NOTE: Proyectos que muestren calculo de precio según unidades
//@import "project/_product-discounts"; // NOTE: Proyectos que muestren tabla de descuentos
@import "project/_product-variants";// NOTE: Proyectos que muestren tabla de variantes
//@import "project/_product-customizations";// NOTE: Proyectos que muestren opciones de personalización
@import "project/_product-availability";// NOTE: Proyectos que muestren disponibilidad
@import "project/_product-accesories";// NOTE: Proyectos que muestren accesorios o productos relacionados
@import "project/_product-description-extended";
//@import "project/_product-attachments";// NOTE: Proyectos que muestren ficheros adjuntos en ficha de producto
@import "project/_product-breadcrumb";
@import "project/_product-share";// NOTE: Proyectos que muestren redes en ficha de producto
@import "project/_djv-product-opiniones";// NOTE: Sin unificar
//----------------
//PRODUTO - listados y miniaturas
//----------------
@import "project/_product-miniature";
@import "project/_products-list";
@import "project/_pagination";
@import "project/_sort-order";
@import "project/_navigation-left";
@import "project/_navigation-left-filters";
@import "project/_products-listas";
@import "project/_pagenotfound";

@import "project/_modal";
@import "project/_manufacturers";

//----------------
// CHECKOUT Y CART
//----------------
@import "project/_checkout";
@import "project/_cart";
@import "project/_authentication";
@import "project/_order-confirmation";
//----------------
// MY ACCOUNT
//----------------
@import "project/_user-info";
@import "project/_my-account";
@import "project/_my-account-addresses";
@import "project/_my-account-identity";
@import "project/_my-account-history";
//----------------
// PÁGINAS, CMS Y TRONCALES
//----------------
@import "project/_home";
@import "project/_djv-home-propuestasvalor";// NOTE: Sin unificar
@import "project/_product-list-home";
@import "project/_contact-form";
@import "project/_search";
//@import "project/_forms";
@import "project/_cms";
@import "project/_troncales";

@import "project/_ph-simpleblog";

@import "project/_notifications";

//@import "project/shame";
//@import "project/_loader";
//@import "project/_djv-temporal";
.watchmework { font-size: 2.25}
