.djv-breadcrumb {
    @include djv-border-bottom(solid);
    text-align: center;
    font-size: $font-xxs;
    padding-bottom: 0;
    margin-top: 25px;
    color: $gris5;
    @media(max-width: $screen-md){
        padding-top: 0;
        margin-top: 50px;
        margin-bottom: 0;
        text-align: left;
    }

    ol {
        @media(max-width: $screen-md){
            padding-left: 10px;
        }
        li {
            display: inline-block;
            &::after {
                content: ">";
                margin-left: 2px;
                margin-right: 2px;
            }

            a {
                color: $gris6;
            }
        }
    }
}
