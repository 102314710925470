#checkout{
    .cart-voucher {
        margin-top: 10px;
        padding: 0px 16px 5px;
        h3{
            @include fuente($font-second, $font-semibold);
            font-size: $font-xl;
        }
        input{
            display: block;
            padding:4px 10px;
            width: calc(100% - 4px);
        }
        button{
            @include btn-hover-animated($primario7, white, 30px, 4px, 10px);
            margin-top: 10px;
            width: 100%;
            font-size: $font-sm;
        }

        p{
            font-size: $font-xs;
            margin-top: 10px;
        }
        #vouchers-form{
            height: 0;
            opacity: 0;
            display: none;
        }
        #added_vouchers + .vouchers-title + #vouchers-form{
            height: 100%;
            opacity: 1;
        }
        .show-vouchers{
            animation-name: showvouchers;
            animation-duration: 1s;
            animation-fill-mode: forwards;
        }
        @keyframes showvouchers{
            from{
                height: 0;
                opacity: 0;
            }
            to{
                height: 100%;
                opacity: 1;
            }
        }
        .added-vouchers-title,.vouchers-title{
            @include fuente($font-first, $font-medium);
            font-size: $font-md;
            color: $primario1;
            text-decoration: underline;
        }
        .vouchers-title{
            margin-bottom: 10px;
            text-align: center;
            cursor: pointer;
        }

        #added_vouchers{
            list-style: none;
            font-size: $font-sm;
            padding: 5px;
            li{
                display: flex;
                justify-content: space-between;
                > *{
                    display: table-cell;
                }
            }
        }
        form{
            ul{
                padding-left: 0;
                li{
                    list-style: none;
                    font-size: $font-xs;
                    text-align: center;
                    padding: 5px;
                    margin: 10px auto;
                    background-color:#e0e0e0;
                    transition: background-color 500ms;
                    &:hover{
                        background-color: #fafafa;

                        .label{
                            .code{
                                font-size: $font-lg;
                            }
                        }
                    }
                    .label{
                        font-size: $font-smd;
                        .code{
                            display: block;
                            @include fuente($font-first, $font-bold);
                            font-size: $font-bmd;
                            cursor: pointer;
                            transition: font-size 500ms;
                        }
                    }
                    p{
                        display: inline-block;
                        width: 49%;
                    }
                    a{
                        display: inline-block;
                        float: right;
                        padding: 0 14px;
                        font-size: 14px;
                    }
                }
            }
        }
        input[name="discount_name"] {
            width: 160px;
            text-align: center;
            font-size: $font-xs;
            border:1px solid $gris3;
            padding: 3px;
        }

        button {
            @include btn-hover-animated($primario7, white, 30px, 3px, 27px);
            float: right;
            font-size: 14px;
        }
        .djv-icon-question{
            font-size: $font-xs - 1px;
            color: $primario1;
            margin-top: 46px;
            display: block;
            &::before{
                margin-right: 5px;
            }
        }
        .notification{
            padding: 5px 0px;
            display: none;
            .js-error-text{
                color: $complementario2;
                font-size: $font-sm;
                margin: 0;
            }
        }
    }
}
#cart{
    .cart-voucher {
        margin-top: 16px;
        padding: 16px;
        .vouchers-title{
            @include fuente($font-second, $font-regular);
            font-size: $font-md;
        }
        #vouchers-form{
            height: 0;
            opacity: 0;
        }
        .show-vouchers{
            animation-name: showvouchers;
            animation-duration: 1s;
            animation-fill-mode: forwards;
        }
        @keyframes showvouchers{
            from{
                height: 0;
                opacity: 0;
            }
            to{
                height: 100%;
                opacity: 1;
            }
        }
        .added-vouchers-title,.vouchers-title{
            @include fuente($font-first, $font-medium);
            font-size: $font-md;
            color: $primario1;
            text-decoration: underline;
        }
        .vouchers-title{
            margin-bottom: 10px;
            text-align: center;
            cursor: pointer;
        }
        p{
            font-size: $font-xs;
            margin-top: 10px;
        }
        ul{
            padding-left: 0;

            li{
                list-style: none;
                font-size: $font-xs;
                display: flex;
                padding: 5px;
                align-items: center;
                p,span{
                    display: block;
                    margin-right: 5px;
                    width: 100%;
                }
                a{
                    display: inline-block;
                    padding: 0;
                    font-size: 14px;
                }
                &:nth-of-type(2n - 1){
                    background-color: $gris1;
                }
            }
        }

        input[name="discount_name"] {
            width: 160px;
            text-align: center;
            font-size: $font-xs;
            border:1px solid $gris3;
            padding: 3px;
        }

        button {
            @include btn-hover-animated($primario7, white, 50px, 20px, 5px);
            padding: 3px 27px 2px;
            float: right;
            font-size: 14px;
        }
        .djv-icon-question{
            font-size: $font-xs - 1px;
            color: $primario1;
            margin-top: 16px;
            display: block;
            &::before{
                margin-right: 5px;
            }
        }
    }
}
