.djv-home-propuestasvalor {
    background-image: url("/themes/base/assets/img/parallax-home.jpg");
    text-align: center;
    background-attachment: fixed;
    background-position: bottom;
    background-size: cover;
    background-repeat: no-repeat;
    .djv-home-propuestasvalor-container {
        @include djv-container();
        display: flex;
        flex-direction: row;
        .djv-home-propuestasvalor-element {
            margin: 0 15px;
            background-color: rgba(255,255,255,0.65);
            //background-color: $primario8;
            padding: 40px 20px;
            flex: 1;
            @media(max-width: $screen-md){
                padding: 20px;
            }

            i {
                font-size: $font-xxxl*1.2;
                color: $primario8;
                margin-bottom: 10px;
                display:block;
                &.djv-icon-calidad{
                    font-size: $font-xxxl*1.1;
                }
            }

            h3 {
                font-size: $font-xl - 2px;
                @include fuente($font-second,$font-regular);
                color: $primario8;
                font-weight: 600;
                position: relative;
                margin-top: -17px;
                margin-bottom: 5px;
            }

            p {
                font-size: $font-xs;
            }

            a {
                color: inherit;
                font-size: $font-xxs;
            }
        }
    }
}

@media (max-width:768px) {
    .djv-home-propuestasvalor {
        background-attachment:inherit;
        .djv-home-propuestasvalor-container {
            display: block!important;
            padding: 15px !important;
            #h1Portada{
                text-align: center;
                @include fuente($font-second,$font-regular);
                font-size: $font-lg + 2;
            }
            #claimPortada{
                color:inherit;
            }
            .djv-home-propuestasvalor-element {
                display: block;
                width: 95%;
                margin: 20px auto !important;
            }
        }
    }
}
