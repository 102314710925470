#contact {
    #content {
        @include djv-container();
        h1 {
            @extend .djv-titulos-left-linea-discontinua;
            font-size: $font-lg;
            color: $primario5;
        }

        .login-form {
            @include fuente($font-first,$font-regular);

            h2 {
                font-size: 18px;
                font-weight: 600;
            }

            .form-fields {
                display: grid;
                grid-template-columns: 50%;

                label {
                    margin-bottom: 10px;
                    display: block;

                    span {
                        display: block;
                        margin-bottom: 10px;
                    }
                    &.psgdpr_consent_message{
                        input{
                            display: inline-block;
                            margin-top: 5px;
                            float: left;
                            margin-right: 10px;
                        }
                        span{
                            display:inline-block;
                            width: 80%;
                        }
                    }
                }
            }

            button {
                @include btn-hover-animated($complementario1,white,2px,4px,10px);
                padding-left: 16px;
                padding-right: 16px;
            }

            i.material-icons {
                display: none;
            }
        }
    }
}
