#module-ph_simpleblog-list,
#module-ph_simpleblog-page,
#module-ph_simpleblog-category,
#module-ph_simpleblog-tag,
#module-ph_simpleblog-categorypage{
    #djv-two-columns-layer{
        @include djv-container();
        display: grid;
        grid-template-columns: calc(20% - 20px) calc(80% - 20px);
        grid-gap: 20px;
        @media(max-width: $screen-lg){
            display: block;
        }
        h1{
            font-size: $font-xl;
            margin: 13px 0 14px
        }

        .simpleblog-posts{

            &::before{
                display: none;
            }
        }
        a{
            &:hover{
                color:$primario3;
            }
        }
    }
    .pagination{
        >li{
            > a , > span{
                position: relative;
                float: left;
                padding: 6px 12px;
                line-height: 1.42857;
                text-decoration: none;
                background-color: #fff;
                border: 1px solid #ddd;
                margin-left: -1px;
                color: inherit;
            }
            &.disabled{

                cursor:not-allowed;
            }
        }
    }

}
#module-ph_simpleblog-category{
    .post-additional-info{
        background-color: transparent;
    }
}
.simpleblog-recent{
    .btn-hover-animated-green-dark{
        margin-left: auto;
        margin-right: auto;
        a{
            color: inherit;
        }
    }
}

#module-ph_simpleblog-single{
    #djv-two-columns-layer{
        @include djv-container();
        display: grid;
        grid-template-columns: calc(20% - 20px) calc(80% - 20px);
        grid-gap: 20px;
        @media(max-width: $screen-lg){
            display: block;
        }
        h2{
            strong{
                color:$primario3;
            }
        }
        #left-column{
            padding: 16px;
        }
        .djv_carouselh{
            h5{
                font-size: $font-md*1.1;
                line-height: inherit;
                a{
                    color:$gris5;
                }
            }
			.price{
                font-size: $font-md*1.3;

            }
            .price-discounted{
                color:$primario6;
                font-size: $font-md*1.3;

            }
            .regular-price{
                text-decoration: line-through;
				font-size: $font-md*1.3;

            }
            .discount-percentage{
                display: none;
            }
            .products > *{
                min-height: 370px;
            }
        }
        #simpleblog-post-comments{
            h1{

            }
            @include fuente($font-first,$font-regular);
            .post-comment:nth-child(2n) {
                margin-left: 90px;
                padding-right: 40px;
                border-bottom-left-radius: 10px;
            }
        }
    }
}
#ph_simpleblog_categories{
    padding-top: 7px;
    >a{
        border-bottom: 1px solid $gris2;
        font-size: $font-xl;
        display: block;
        color: inherit;
        margin-bottom: 20px;
        padding-bottom: 14px;
    }
    ul{
        margin: 0;
        padding: 0;
        li{
            @include djv-border-bottom(dashed);
            list-style: none;
            margin-bottom: 5px;
            padding-bottom: 5px;
            a{
                color: inherit;
            }
        }
    }
}
body:not(#index){
    .ph_simpleblog{
    .title_block{
    }
    .post-meta-info{
        .post-likes, .post-views{
            display: none;
        }
    }
    .simpleblog-posts{
        display: grid !important;
        grid-gap: 10px;
        grid-template-columns: repeat(2,calc(50% - 10px));
        margin-left: auto !important;
        margin-right: auto !important;
        .simpleblog-post-item {
            .post-item{
                border-radius: 6px;
                overflow: hidden;
                h2{
                    padding-top: 20px;
                    a{
                        font-size: $font-slg;
                        text-transform: uppercase;
                        text-decoration: none;
                        padding: 0 5px;
                        display: block;
                        color: $primario1;
                    }
                }
                .post-content{
                    @include fuente($font-first,$font-regular);
                    font-size: $font-smd;
                    line-height: 18px;
                }
            }
        }
    }
}
}
.simpleblog-single{
    h1{
        @include fuente($font-first,$font-regular);
        font-size: $font-blg !important;
        line-height:32px!important;
        margin: 13px 0 14px;
    }
    .post-featured-image{
        width: 100%;
        margin-bottom: 40px !important;
        img{
            width: 100%;
        }
    }
    .post-content{
        width: 80%;
        @media(max-width: $screen-md){
            padding: 16px;
            width: 100%;
        }
        h2{
            @include fuente($font-first,$font-semibold);
            font-size: $font-blg;
            margin-top: 60px;
            margin-bottom: 10px;
            padding-bottom: 5px;
        }
        h3{
            font-size: $font-lg;
            margin-top: 50px;
        }
        p{
            @include fuente($font-first,$font-regular);
            font-size: $font-slg;
        }
		a{
			&:hover{
				color: $primario3;
			}
		}
    }
	.post-category{
		a{
			&:hover{
				color: $primario3;
			}
		}
	}
}
