#order-confirmation{
	#main{
		@include djv-container();
		@include fuente($font-first,$font-regular);
		h3{
			@extend .djv-titulos-left-linea-discontinua;
			@include fuente($font-first,$font-regular);
		}
		hr{
			border-top: 3px solid $primario3;
			margin: 30px 0;
		}
		#order-items{
			.djv-order-confirmation-items-table{
				width: 100%;
				tr{
					border-bottom: 1px solid $primario3;
					@media(max-width: $screen-sm){
						border-bottom: 3px solid $primario3;
					}
					td{
						padding: 10px;
						border-right: 1px dotted $gris3;
						position: relative;
						text-align: center;
						@media(max-width: $screen-sm){
							display: block;
							padding: 10px;
							border-right: none;
							text-align: center;
						}
						&.djv-order-confirmation-quantity{
							font-size: 18px;
							@media(max-width: $screen-sm){
								display: inline-block;
								width: calc(50% - 10px);
							}
						}
						&.djv-order-confirmation-price{
							font-size: 18px;
							@media(max-width: $screen-sm){
								display: inline-block;
								width: calc(50% - 10px);
							}
						}
						&:last-of-type{
							border-right: none;
						}
						img{
							max-width: 100%;
							@media(max-width: $screen-sm){
								width: 60%;
								margin-left: auto;
								margin-right: auto;
							}
						}
						&:nth-of-type(2){
							@media(max-width: $screen-sm){
								border-top: 1px dotted $primario3;
								border-bottom: 1px dotted $primario3;
								text-align: left;
							}
						}
					}
					&:last-of-type{
						border-bottom: none;
					}
				}
			}
			.djv-order-confirmation-resume-table{
				tr{
					border-bottom: 1px dotted $primario3;
					td{
						padding: 16px 30px;
					}
					&:last-of-type{
						border-bottom: none;
						background-color: $primario1;
						font-size: 16px;
					}
				}
			}
		}
	}
}
