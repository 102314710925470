#header{
    min-height: 212px;
    position: relative;
    z-index: 99;
    width: 100%;
    top: 0;
    box-shadow: 1px 1px 5px 2px rgba(0, 0, 0, 0.2);
    @media(max-width: $screen-md){
        min-height: 40PX;
        height: 40px;
        position: fixed;
    }
    #header-topbanner{
        position: relative;
        width: 100%;
        overflow: hidden;
        background-color: white;
        @media(max-width: $screen-md){
            //display: none;
        }
        .djv-container{
            position: relative;
            @media(max-width: $screen-md){
                width: 100%;
                margin-left: 0;
                margin-right: 0;
                max-height: 70px;
            }

            #close-banner-top{
                position: absolute;
                top:5px;
                right: 10px;
                background-color: white;
                border-radius: 50%;
                height: 18px;
                @media(max-width: $screen-md){
                    right: 2px;
                }
            }
            a{
                position: relative;
                display: block;
                overflow: hidden;
                width: 100%;
            }
            img{
                position: relative;
                left: 50%;
                transform: translateX(-50%);
                @media(max-width: $screen-md){
                    max-width: 180%;
                    max-height: 70px;
                }
            }

        }
    }
    .djv-icon-lupa{
        display: none;
        position: relative;
        color: #26314c;
        cursor: pointer;
        z-index: 9;
        top: 9px;
        font-size: 19px;
        position: absolute;
        right: 115px;
        @media(max-width: $screen-lg){
            display: block;
            right: auto;
            left: calc(-100vw + 169px);
            position: absolute;
        }
    }
    #menu-icon-responsive {
        display: inline-block;
        position: relative;
        top: 1px;
        font-size: $font-lg;
        left: 2px;
        color: #26314c;
        cursor: pointer;
        z-index: 9;
        &::before{
            @media(max-width: $screen-lg){
                display: inline-block;
            }
        }
        span{
            @media(max-width: $screen-lg){
                display: none;
            }
        }

    }
    .headerGrid{
        background-image: url("#{$urlimagenes}topbarbg.gif");
        background-repeat: repeat-x;
        background-size: 1px 40px;
        background-color: white;
        height: 240px;
        @media(max-width: $screen-lg){
            height: 40px;
        }
        > .djv-container{
            display: grid;
            grid-template-columns: 30% 40% 30%;
            grid-template-areas:
            "topbarleft topbarcenter topbarright"
            "headersectionleft headersectioncenter headersectionright"
            "mainMenu mainMenu mainMenu";
            align-items: center;
            @media(max-width: $screen-lg){
                grid-template-columns: 100px auto 100px;
                grid-template-areas:"navicons headersectioncenter topbarright";
            }
            @media(max-width: $screen-lg){
                margin-left: 0px;
                margin-right: 0px;
                width: 100%;
                position: relative;
            }
            /* Bloque de grid */
            .nav-icons{
                grid-area: navicons;
                display: none;
                @media(max-width: $screen-lg){
                    display: block;
                }
            }
            .header-topbar-before{
                grid-area: topbarleft;
                display: inline-block;
                font-size: 0.8rem;
                i{
                    &::before{
                        margin-right: 8px;
                        margin-left: 5px;
                    }
                }
                @media(max-width: $screen-lg){
                    display: none;
                }
                a{
                    color:inherit;
                }
            }
            .header-topbar-center{
                grid-area: topbarcenter;
                display: inline-block;
                font-size: 0.8rem;
                text-align: center;
                i{
                    position: relative;
                    &::before{
                        position: absolute;
                        font-size: 22px;
                        left: -28px;
                        top: -3px;
                        font-weight: bold;
                    }
                }
                @media(max-width: $screen-lg){
                    display: none;
                }
            }
            .header-topbar-after{
                grid-area: topbarright;
                display: inline-block;
                font-size: 0.8rem;
                position: relative;
            }
            .header-section-left{
                grid-area: headersectionleft;
                @media(max-width: $screen-lg){
                    display: none;
                }
                h1,h2{
                    font-size: $font-md;
                    line-height: $font-sm;
                    @include fuente($font-second,$font-light);
                    > p:first-of-type {
                        @include fuente($font-first,$font-light);
                        font-size: $font-sm + 1px;
                    }
                    @media(max-width: $screen-lg){
                        display: none;
                    }
                }
                > p:first-of-type {
                    @include fuente($font-first,$font-light);
                    font-size: $font-sm + 1px;
                }
                @media(max-width: $screen-lg){
                    display: none;
                }
            }
            .header-section-center{
                grid-area: headersectioncenter;
            }
            .header-section-right{
                grid-area: headersectionright;
                position: relative;
                @media(max-width: $screen-lg){
                    display: none;
                    position: absolute;
                    top: 40px;
                    width: 100%;
                    padding: 0px 4px;
                    background-color: $primario3;
                }
            }
            .header-nav-top{
                grid-area: mainMenu;
                @media(max-width: $screen-lg){
                    display: none;
                }
            }
            /* --- Fin Bloque de elementos adicionales*/
            /* Bloque de elementos adicionales*/
            .djv-icon-usuario {
                font-size:21px;
                position: absolute;
                top: 8px;
                right: 71px;
                padding: 0 6px;
                cursor: pointer;
                color: #26314c;
            }
            .djv-icon-coment, .djv-icon-crono{
                font-size: $font-sm*1.1;
                margin-right: 4px;
                position: relative;
                display: inline-block;
                width: 20px;
                height: 20px;
            }
            .djv-icon-coment::before, .djv-icon-crono::before{
                margin-right: 5px;
                font-size: 17.6px;
                left: 0;
                top: 5px;
                position: absolute;
            }
            .logo{
                width: 260px;
                height: 130px;
                display: block;
                margin: 0px auto 0;
                padding-top: 20px;
                @media(max-width: $screen-lg){
                    width: 100px;
                    height: auto;
                    padding-top: 0px;
                    img{
                        width: 100%;
                    }
                }
                @media(max-width: $screen-sm){
                }
            }
            #search_widget {
                width: 100%;
                display: block;
                margin: 5px auto;
                position: relative;
                input{
                    background-color: white;
                    width: 100%;
                    display: block;
                    position: relative;
                    border: 1px solid #999999;
                    border-radius: 3px;
                    padding: 3px 20px 3px 5px;
                    color: #26445C;
                    font-family: "OpenSans-Regular";
                    font-size: $font-xs;
                    @media(max-width: $screen-lg){
                        border:none;
                    }
                    &::placeholder {
                        font-size: 12px;
                        color: #666666;
                        font-family: "OpenSans-Light";
                        text-align: center;
                    }
                }
                button {
                    position: absolute;
                    top: 8px;
                    right: 0px;
                    border: none;
                    background-color: transparent;
                    width: 30px;
                    height: 30px;
                    cursor: pointer;
                    &:before{
                        display: inline-block;
                        font-family: "comunes";
                        font-style: normal;
                        font-weight: normal;
                        line-height: 1;
                        -webkit-font-smoothing: antialiased;
                        -moz-osx-font-smoothing: grayscale;
                        content:'\0045';
                        font-size: $font-md;
                        right: 4px;
                        top: -2px;
                        position: absolute;
                        color:$gris3;
                        @media(max-width: $screen-lg){
                            z-index: 0;
                        }
                    }
                }
            }
            #blockcart-wrapper {
                position: relative;
                z-index: 999;
                display: inline-block;
                float: right;
            }
            .blockcart {
                .header {
                    color: white;
                    font-size: 0.8rem;
                }
            }
            .header-nav-corporative{
                margin: 0;
                padding: 0;
                text-align: center;
                @media(max-width: $screen-lg){
                    display: none;
                }
                li{
                    list-style: none;
                    display: inline-block;
                    font-size: 13px;
                    color:$primario3;
                    a{
                        color:inherit;
                        &.nav-link{
                            padding: 5px;
                        }
                    }
                    &.nav-item-separator{
                        color:$gris9;
                    }
                }
            }
            /* -- Fin Bloque de elementos adicionales*/
        }
    }
    &.recogido{
        height: 40px;
        min-height: auto;
        position: fixed;
        top:0;
        #menu-icon-responsive {
            display: inline-block;
            font-size: $font-sm;
            top: 0px;
            position: relative;
            &::before{
                display: none;
            }
            span{
                font-style: normal;
                &::after{
                    content:'\0057';
                    display: inline-block;
                    font-family: "golosinas";
                    font-style: normal;
                    font-weight: normal;
                    line-height: 1;
                    -webkit-font-smoothing: antialiased;
                    -moz-osx-font-smoothing: grayscale;
                    margin-left: 10px;
                    position: absolute;
                    top: 5px;
                }
            }
        }
        .djv-icon-lupa{
            display: inline-block;
        }
        .headerGrid{
            height: 40px;
            border-bottom: 1px solid $primario3;
            > .djv-container{
                grid-template-columns: 180px auto 140px;
                grid-template-areas:"headersectioncenter mainMenu topbarright";
                position: relative;
                form{
                    .djv-icon-lupa{
                        display: none;
                    }
                }
            }
            .nav-icons{
                grid-area: navicons;
                display: none;
            }
            .header-topbar-before{
                display: none;
            }
            .header-topbar-center{
                display: none;
            }
            .header-topbar-after{
                grid-area: topbarright;
            }
            .header-section-left{
                display: none;
            }
            .header-section-center{
                grid-area: headersectioncenter;
            }
            .header-section-right{
                display: none;
                position: absolute;
                right: 110px;
                width: 200px;
                top: 40px;
                background-color: $primario3;
                padding: 10px;
                border-bottom-left-radius: 10px;
                border-bottom-right-radius: 10px;
                @media(max-width: $screen-md){
                    top: 79px;
                    position: absolute;
                }
            }
            .header-nav-top{
                grid-area: mainMenu;
                display: block;
                position: relative;
                left: 0;
                top:12px;
            }
            .header-nav-corporative{
                display:none;
            }
            .logo{
                width: 90px;
                display: block;
                margin: 0px auto 0;
                padding-top: 0px;
                height: auto;
                @media(max-width: $screen-lg){
                    margin: 0;
                }
            }
        }
    }
}
