/* Generated by Glyphter (http://www.glyphter.com) on  Thu May 16 2019*/
@font-face {
    font-family: 'comunes';
    src: url('project/djv-icon-font/fonts/comunes.eot');
    src: url('project/djv-icon-font/fonts/comunes.eot?#iefix') format('embedded-opentype'),
         url('project/djv-icon-font/fonts/comunes.woff') format('woff'),
         url('project/djv-icon-font/fonts/comunes.ttf') format('truetype');
        // url('project/djv-icon-font/fonts/comunes.svg#comunes') format('svg');
    font-weight: normal;
    font-style: normal;
}
[class*='djv-icon']:before{
	display: inline-block;
   font-family: 'comunes';
   font-style: normal;
   font-weight: normal;
   line-height: 1;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale
}
.djv-icon-coment:before{content:'\0041';}
.djv-icon-coment-2:before{content:'\0042';}
.djv-icon-carro:before{content:'\0043';}
.djv-icon-menu:before{content:'\0044';}
.djv-icon-lupa:before{content:'\0045';}
.djv-icon-lupa-plus:before{content:'\0046';}
.djv-icon-lupa-minus:before{content:'\0047';}
.djv-icon-usuario:before{content:'\0048';}
.djv-icon-telefono:before{content:'\0049';}
.djv-icon-mail:before{content:'\004a';}
.djv-icon-arrow-left:before{content:'\004b';}
.djv-icon-arrow-right:before{content:'\004c';}
.djv-icon-arrow-up:before{content:'\004d';}
.djv-icon-arrow-down:before{content:'\004e';}
.djv-icon-facebook:before{content:'\004f';}
.djv-icon-twitter:before{content:'\0050';}
.djv-icon-youtube:before{content:'\0051';}
.djv-icon-instagram:before{content:'\0052';}
.djv-icon-delete:before{content:'\0053';}
.djv-icon-close:before{content:'\0054';}
.djv-icon-ok:before{content:'\0055';}
.djv-icon-ok-circle:before{content:'\0056';}
.djv-icon-ok-circle-open:before{content:'\0057';}
.djv-icon-plus:before{content:'\0058';}
.djv-icon-minus:before{content:'\0059';}
.djv-icon-refresh:before{content:'\005a';}
.djv-icon-reload:before{content:'\0061';}
.djv-icon-reply:before{content:'\0062';}
.djv-icon-move:before{content:'\0063';}
.djv-icon-filter:before{content:'\0064';}
.djv-icon-exit:before{content:'\0065';}
.djv-icon-gear:before{content:'\0066';}
.djv-icon-question:before{content:'\0067';}
.djv-icon-question-circle:before{content:'\0068';}
.djv-icon-information:before{content:'\0069';}
.djv-icon-warning:before{content:'\006a';}
.djv-icon-star:before{content:'\006b';}
.djv-icon-empty-star:before{content:'\006c';}
.djv-icon-printer:before{content:'\006d';}
.djv-icon-swipe:before{content:'\006e';}
.djv-icon-tap:before{content:'\006f';}
.djv-icon-whatsapp:before{content:'\0070';}
.djv-icon-pinterest:before{content:'\0071';}
