#product {
	.page-content {
		#ancla-opiniones{
			height: 20px;
			margin-top: -20px;
		}
        .djv-product-opiniones{
            .djv-product-opinion{
                .comment_author{
                    @include fuente($font-first,$font-light);
                    font-size: $font-xs;
                    color: $gris7;
                }
                .comment_body{
                    @include fuente($font-first,$font-light);
                    font-size: $font-md;
                    color: $gris9;
                }
				&.ocultar{
					display: none;
				}
            }
        }
	}
}
