
#history{
    .page-content{
        @include djv-container();
        h2{
            font-size: $font-md;
            @include djv-border-bottom(dashed);
        }

        .page-footer{
            @include djv-container();
            margin-top: 40px;
            padding-top: 20px;
            border-top: 1px dashed #999;
            ul{
                margin: 0;
                padding: 0;
                li{

                    list-style: none;
                    display: inline-block;
                    a{
                        @include btn-hover-animated($primario5,white,2px,4px,10px);
                    }
                }
            }
        }
    }
    .history-list{
        .history-list-header{
            display: grid;
            grid-template-columns: repeat(3,12%) 22% repeat(2,12%) 18%;
            border-bottom: solid 1px black;
            padding-bottom: 10px;
            margin-bottom: 20px;
            @media(max-width: $screen-sm){
                display: none;
            }
            >div{
                @include fuente($font-first,$font-medium);
                font-size: $font-sm;
            }
        }
        .history-list-body{
            .history-list-body-item{
                display: grid;
                grid-template-columns: repeat(3,12%) 22% repeat(2,12%) 18%;
                padding: 20px 0;
                border-bottom: 1px solid $gris2;
                @media(max-width: $screen-sm){
                    display:block;
                }
                .history-list-body-item-col{
                    align-content: center;
                    font-size: $font-xs - 1px;
                    @media(max-width: $screen-sm){
                        margin-bottom: 10px;
                    }
                    .etiqueta{
                        display:none;
                        @media(max-width: $screen-sm){
                            display: inline-block;
                            font-weight: 600;
                            margin-right: 10px;
                        }
                    }
                    [data-link-action="view-order-details"]{
                        @include btn-hover-animated($primario5,white,2px,4px,10px);
                        display: inline-block;
                        margin-bottom: 8px;
                        font-size: $font-xs - 2px;
                    }
                    .order-reorder-link{
                        @include btn-hover-animated($complementario1,white,2px,4px,10px);
                        display: inline-block;
                        font-size: $font-xs - 2px;
                    }
                    .order-status-label {
                        font-size: $font-xs - 2px;
                        white-space: nowrap;
                        color: white;
                        padding: 5px;
                        display: inline-block;
                        width: 10px;
                        height: 10px;
                        border-radius: 50%;
                        text-align: center;
                    }
                }
            }
        }
    }
}
#order-detail{
    #main{
        @include fuente($font-first,$font-regular);
        @include djv-container();
        margin-top: 40px;
    }
    #order-infos{
        @extend .cms-block-example;
        color: black;
        a{
            color: black;
            text-decoration: underline;
        }
    }
    #order-history{
        padding: 16px;
        margin-bottom: 16px;
        h1{
            @extend .djv-titulos-left-linea-discontinua;
        }
        table{
            thead{
                border-bottom: 1px solid #999;
            }
            td,th{
                padding: 8px;
                .order-status-label {
                    padding:4px 8px;
                    font-size: 14px;
                    white-space: nowrap;
                    color: white;
                }
            }
        }

    }
    #delivery-address,#invoice-address{
        width: calc(50% - 20px);
        margin: 8px;
        display: inline-block;
        border: 1px dashed #999;
        padding: 16px;
        @media(max-width: $screen-sm){
            width: calc(100% - 20px);
        }
    }
    .box{
        ul{
            margin:10px 0;
            padding: 0;
            li{
                list-style: none;
            }
        }
    }
    #order-products{
        margin: 40px 0;
        th{
            white-space: nowrap;
            padding: 8px;
            background-color:$gris2;
            @media(max-width: $screen-sm){
                font-size: $font-xs - 2px;
            }
        }
        td{
            padding: 8px;
            @media(max-width: $screen-sm){
                font-size: $font-xs - 2px;
            }
        }
        tfoot, .line-total{
            border-top: 2px solid $gris5;
        }
    }
    #order-shipping{
        display: block;
        width: 100%;
        tr{
            width: 100%;
            @media(max-width: $screen-sm){
                font-size: $font-xs - 2px;
            }
            td{
                padding: 8px;
                @media(max-width: $screen-sm){
                    font-size: $font-xs - 2px;
                }
            }
            th{
                white-space: nowrap;
                padding: 8px;
                background-color:$gris2;
            }
        }
    }
    .order-message-form{
        margin-top: 20px;
        padding-top: 20px;
        border-top: 1px solid $gris6;
        .form-fields{
            label{
                display: block;
                margin-bottom: 15px;
                span{
                    margin-right: 10px;
                }
            }
        }
        .form-footer{
            button{
                @include btn-hover-animated($complementario1,white,2px,4px,10px);
            }
        }
    }
}
