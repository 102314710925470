#module-djv_navigation-nsearch #djv-two-columns-layer #content-wrapper .textoSeo{
    .troncal-almayor{
        text-align: center;
        h2,h3{
            @include fuente($font-second, $font-regular);
            color: black;
        }
        h3{
            font-size: $font-bmd;
            margin-top: 16px;
        }
        p{
            line-height: normal;
            font-size: $font-sm;
            color: black;
        }
        a.troncal-btn{
            background-color:$primario5;
            color: white ;
            padding:7px 14px;
            margin: 16px auto;
            display: block;
            width: max-content;
        }
        img{
            margin: auto;
            @media(max-width: $screen-md){
                max-width: 100%;
            }
        }
        >div{
            margin-top: 40px;
            margin-bottom: 40px;
        }
        .propuestasvalor{
            @include grid(4,10);
            @media(max-width: $screen-md){
                display: block;
            }

            p{
                font-size: $font-xs;
            }
            >div{
                img{
                    width: 50px;
                }
            }
        }
        .categorias1,.categorias2{
            >div{
                margin-bottom: 16px;
                border: 1px solid $gris1;
                max-width: 277px;
                h3{
                    padding: 0 16px;
                }
                p{
                    padding:0 16px 14px 0;
                }
            }
        }
        .categorias1{
            padding: 0 10%;
            @include grid(3,10);
            @media(max-width: $screen-md){
                display: block;
                padding: 0;
            }

        }
        .categorias2{
            padding: 0 20%;
            @include grid(2,10);
            @media(max-width: $screen-md){
                display: block;
                padding: 0;
            }
        }
        .contactanos-full-width{
            height: 300px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-image: url("#{$cdnserver1}/themes/base/assets/img/bg-fullwidth-almayor.jpg");
            text-align: center;
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            .contactanos-container{
                background-color: #fffc;
                padding: 26px;
                width: 30%;
                border-radius: 3px;
                h3{
                    font-size: $font-xxl;
                }
                @media(max-width: $screen-md){
                    width: 90%;
                }
            }
        }
        .paraque{
            .paraque-container{
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                @media(max-width: $screen-md){
                    display: block;
                }
                >div{
                    width: 48%;
                    margin: 16px 1%;
                    padding: 16px;
                    @media(max-width: $screen-md){
                        width: 100%;
                    }
                    h3{
                        color: $primario7;
                    }
                }
            }
        }
        .envoltorio{
            background-image: url("#{$cdnserver1}/themes/base/assets/img/envoltorio-individual.jpg");
            text-align: center;
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            padding: 32px 0;
            .envoltorio-container{
                width: 420px;
                margin: 0 auto;
                @media(max-width: $screen-md){
                    width: 90%;
                }
            }
            .constick{
                &::before{
                    content:"";
                    display: inline-block;
                    width: 18px;
                    height: 14px;
                    background-image: url("#{$cdnserver1}/themes/base/assets/img/garrapata.svg");
                    background-repeat: no-repeat;
                    background-size: contain;
                }
            }
        }

        .descuentos{
            background-image: url("#{$cdnserver1}/themes/base/assets/img/fondo-descuentos-grandes-cantidades.jpg");
            text-align: center;
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            padding: 32px 0;
            .envoltorio-container{
                width: 420px;
                margin: 0 auto;
                @media(max-width: $screen-md){
                    width: 90%;
                }
            }
        }
        .preguntas-frecuentes{
            h2{
                margin-bottom: 40px;
            }
            .question{
                font-weight: bold;
                font-size: $font-bmd;
                color: black;
                margin: 32px auto 16px;
            }
        }
    }
}
