@mixin btn-hover-animated($color,$colorHover,$round,$padY,$padX){
    background-color: $color;
    color: $colorHover;
    border: 2px solid $color;
    border-radius: $round;
    transition: all 500ms;
    text-align: center;
    text-transform: uppercase;
    padding: $padY $padX;
    cursor: pointer;
    &:hover{
        background-color: $colorHover;
        color: $color;
    }
}
@mixin swipeMobile(){
    &::after{
        display: inline-block;
        font-family: 'comunes';
        font-style: normal;
        font-weight: normal;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        content:'\006e';
        position: absolute;
        left: 50%;
        bottom: -20px;
        transform: translateX(-50%);
        margin: 10px 0;
        font-size: 42px;
        color: $gris5;
    }
    >*{
        overflow-x: auto;
        white-space: nowrap;
        -webkit-overflow-scrolling: touch;
        -webkit-scroll-snap-type: mandatory;
        scroll-snap-type: x mandatory;
        -webkit-scroll-snap-points-x: repeat(100%);
        scroll-snap-points-x: repeat(100%);
        position: relative;
        >*{
            scroll-snap-align: start;
            position: relative;
            display: inline-block;
            margin-bottom: 0;
        }
    }
}
@mixin fuente($family , $weight){
    font-family: $family;
    font-weight: $weight;
}
@mixin djv-container($maxWidth:1170px,$minWidth:920px){
    margin-right: auto;
    margin-left: auto;
    max-width: $maxWidth;
    min-width: $minWidth;
    @media(max-width:$screen-lg){
        max-width: 920px;
        min-width: 750px;
    }
    @media(max-width:$screen-md){
        max-width: 750px;
        min-width: 480px;
    }
    @media(max-width:$screen-xs){
        max-width: 500px;
        min-width: 300px;
    }
}
@mixin full-width($width:100vw){
    width: $width;
    display: block;
    position: relative;
    margin-left: 50%;
    transform: translateX(-50%);
}
@mixin djv-border-top($type){
    border-top: 1px $type $gris3;
    padding-top: 10px;
    margin-top: 10px;
}
@mixin djv-border-bottom($type){
    border-bottom: 1px $type $gris3;
    padding-bottom: 10px;
    margin-bottom: 10px;
}
@mixin djv-titulos-barras-laterales(){
    display: grid;
    grid-template-columns: auto 30% auto;
    position: relative;
    @media(max-width: $screen-md){
        grid-template-columns: auto 80% auto;
    }
    &::before{
        content: "";
        border-bottom: 1px solid $gris3;
        display: block;
        width: 100%;
        margin-right: 20px;
        position: relative;
        transform: translateY(-50%);
    }
    &::after{
        content: "";
        border-bottom: 1px solid $gris3;
        display: block;
        width: 100%;
        margin-right: 20px;
        position: relative;
        transform: translateY(-50%);
    }
}
@mixin grid($cols,$gap){
    display: grid;
    $realGap : $gap*2;
    $colPercent :100 / $cols;
    grid-template-columns: repeat($cols,(calc(#{$colPercent}% - #{$realGap}px)));
    grid-gap: #{$gap}px;
}
