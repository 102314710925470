#product{
	.djv-product-detail-share{
		border-top: 1px solid $gris2;
		margin-top: 20px;
		padding-top: 20px;
		text-align: center;
		color:$gris5;
		font-size: 14px;
		i{
			margin: 10px;
			font-size: 24px;
			cursor: pointer;
			@media(max-width: $screen-sm){
				font-size: 18px;
			}
			&:last-of-type{
				margin-left: 60px;
			}
		}
	}
}
