#blockcart-modal.modal{
    display: block;
    background-color: rgba(0,0,0,.5);
}
.modal-dialog{
    max-width: 920px;
    width: 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -70%);
    .modal-content{
        .modal-header{
            text-align: center;
            background-color: $primario5;
            color:white;
            button.close{
                position: absolute;
                right: 16px;
                top: 16px;
            }
            h4{
                text-align: center;
                .djv-icon-ok{
                    margin-right: 20px;
                }
            }
        }
        .modal-body{
            display: grid;
            grid-template-areas: "imagen data content" "imagen data buttons";
            grid-gap: 10px;
            @media(max-width: $screen-md){
                grid-template-areas:
                "imagen data"
                "content content"
                "buttons buttons";
            }
            .modal-body-product-img{
                grid-area: imagen;

                @media(max-width: $screen-md){
                    max-width: 250px;
                }
                img{
                    max-width: 250px;
                    @media(max-width: $screen-md){
                        width: 100%;
                    }
                }
            }
            .modal-body-product-data{
                grid-area:data;
                h6{
                    display: block;
                    min-height: 44px;
                }
                .modal-product-price{
                    font-size: $font-lg;
                    color:$primario8;
                    @include fuente($font-first,$font-bold);
                }
                @media(max-width: $screen-md){

                }
            }
            .modal-body-cart-content{
                grid-area:content;
                @media(max-width: $screen-md){
                    padding-left: calc(15px + 5%);
                    padding-right: calc(15px + 5%);
                    padding-top: 15px;
                    border-top: 1px solid #dadada;
                }
                p{
                    @media(max-width: $screen-md){
                        margin-bottom: auto;
                        line-height: $font-lg;
                    }
                }
            }
            .cart-content-btn{
                grid-area:buttons;
                margin-top: 15px;
                display: flex;
                width: 100%;
                >*{
                    flex: 1;
                    margin-left: 1%;
                    margin-right: 1%;
                    @media(max-width: $screen-md){
                        font-size: $font-xs;
                    }
                }
                .djv-icon-ok{
                    margin-right: 10px;
                    @media(max-width: $screen-md){
                        display: none;
                    }
                }
                .btn-primary{
                    padding-top: 10px;
                }
            }
        }
    }
}
