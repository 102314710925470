#footer{
    margin-top: 50px;
    .djv-email-subscription{
        background-color: $primario4;
        padding: 16px 0 6px;
        .djv-email-subscription-container{
            @include djv-container();
            display:grid;
            grid-template-columns: 22% 40% 35%;
            grid-gap: 35px;
            @media(max-width: $screen-md){
                display: block;
                >div{
                    margin-bottom: 20px;
                    >img{
                        width: 75%;
                        margin-left: auto;
                        margin-right: auto;
                        display: block;
                        max-width: 300px;
                    }
                }
            }

            .email-subcription-text{
                padding-top:5px;
                padding-left: 10px;
                @media(max-width: $screen-md){
                    margin-bottom: 0;
                }
                h4{
                    font-size: $font-md*1.2;
                    border-bottom: 1px solid $gris3;
                    padding-bottom: 3px;
                    display: inline-block;
                    padding-right:25px;
                    @include fuente($font-second,$font-regular);
                    @media(max-width: $screen-md){
                        font-size: 19px;
                        text-align: center;
                        padding: 0;
                        padding-bottom: 10px;
                    }
                }
                p{
                    @include fuente($font-first,$font-light);
                    font-size: $font-xs;
                    color: $gris7;
                }
                p.notification{
                    position: relative;
                    right: -453px;
                    width: 350px;
                    &.notification-success{
                        color:$primario12;
                        @media(max-width: $screen-md){
                            right: 0;
                            margin-bottom: 0;
                        }
                    }
                    &.notification-error{
                        color:#f13340;
                        &::before{
                            content:"* ";
                            font-size: $font-sm;
                        }
                        @media(max-width: $screen-md){
                            right: 0;
                            margin-bottom: 0;
                        }
                    }
                }
            }
            .djv-email-subscription-form{
                input[type="text"]{
                    border: 1px solid $gris2;
                    padding: 11px 16px;
                    font-size: 13px;
                    width: 70%;
                    margin-right: 5%;
                }
                input[type="submit"]{
                    width: 20%;
                    background-color: $primario5;
                    border: none;
                    color: white;
                    padding: 11px;
                    font-size: 13px;
                }
                .control-label{
                    font-size: $font-xs;
                    margin-top: 10px;
                    color:$gris5;
                }
                form{
                    p{
                        display: none;
                    }
                }
            }
            .notification-error{
                position: relative;
                right: -496px;
                width: 350px;
                color:$primario8;
            }
        }
    }
    .block-contact{
        padding: 10px;
        background-color: white;
        font-size: 14px;
        background-color:darken($primario2, 3%);
        @include fuente($font-first,$font-light);
        .djv-block-contact-container{
            @include djv-container();
            >div:not(.djv-block-contact-social){
                margin: 5px;
                display: inline;
                i{
                    margin-right: 5px;
                }
            }
            .djv-block-contact-social{
                float: right;
                @media(max-width: $screen-md){
                    margin-top: 10px;
                    padding-top: 10px;
                    border-top: 1px solid $gris2;
                    float: none;
                }
                [class*='djv-icon-']::before{
                    font-size: 18px;
                    color:black;
                }
            }
            a{
                color:$gris7;
            }
            @media(max-width: $screen-md){
                >div:not(.djv-block-contact-social){
                    display: block;
                }
                >div{
                    display: block;
                }
            }
        }
    }
    .djv-footer-navigation{
        background: $gris9;
        color:white;
        padding: 30px 0;
        @media(max-width: $screen-md){
            padding: 10px 0;
        }
        .djv-footer-navigation-container{
            @include djv-container();
            display: grid;
            grid-template-columns: repeat(3,calc(33.333% - 20px));
            grid-gap: 40px;

            @media(max-width: $screen-md){
                display: block;
            }

            >div{
                padding: 0 40px 0 0;
                border-right: 1px solid $gris4;
                @media(max-width: $screen-md){
                        margin-bottom: 15px;
                        border-right: none;
                        padding: 10px;
                }
                h4{
                    font-size: $font-xs;
                    padding-bottom: 6px;
                    border-bottom: 1px solid $gris4;
                    display: block;
                }
                ul{
                    margin: 0;
                    padding: 0;
                    li{
                        list-style: none;
                        @include fuente($font-first,$font-light);
                        font-size: $font-xxs;
                        background: transparent;
                        background-size: 0%;
                        transition: all 1s;
                        padding: 3px;
                        &:hover{
                            background: $gris5;
                            cursor: pointer;
                        }
                        a{
                            color: inherit;
                        }
                    }
                }
                &::after{

                }
                &:last-of-type{
                    border-right: none;
                }
                &.djv-email-subscription-logo{
                    @media(max-width: $screen-md){
                        img{
                            width: 250px;
                        }
                    }
                }
            }
            .djv-footer-navigation-metodos-pago{
                >div{
                    img:nth-of-type(1), img:nth-of-type(2){
                        width: 110px;
                        display: inline-block;
                        margin: 10px 20px 0 20px;
                        text-align: center;
                        @media(max-width: $screen-md){
                            width: 20%;
                            margin: 2px;
                        }
                    }
                    img:nth-of-type(3){
                        width: 90px;
                        display: inline-block;
                        @media(max-width: $screen-md){
                            width: calc(20% - 10px);
                            margin: 2px;
                        }
                    }
                    img:nth-of-type(4){
                        width: 72px;
                        display: inline-block;
                        @media(max-width: $screen-md){
                            width: calc(20% - 20px);
                            margin: 2px;
                        }
                    }
                    img:nth-of-type(5){
                        width:110px;
                        display: inline-block;
                        @media(max-width: $screen-md){
                            width: calc(20% - 10px);
                            margin: 2px;
                        }
                    }
                }

            }
        }
    }
    .djv-footer-below{
        background-color: $gris10;
        padding: 5px 0px;
        .djv-footer-below-container{
            @include djv-container();
            .djv-footer-below-copyright{
                font-size: $font-xxs;
                color:$gris2;
                p{
                    margin: 0;
                }
                @media(max-width: $screen-md){
                    font-size: $font-xxxs*0.8;
                }
            }
        }
    }
}
