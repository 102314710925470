#identity{
    #main{
        @include djv-container();
        //@include fuente($font-first,$font-regular);
        h3{
            //@extend .djv-titulos-left-linea-discontinua;
            //@include fuente($font-first,$font-regular);
        }
        .form-fields{
            width: auto;
            display: grid;
            grid-template-areas:
            'item1 item1 item1'
            'item2 item3 item4'
            'item5 item6 empty1'
            'item7 empty2 empty2'
            'item8 item9 item10'
            'item11 item11 item11';
            grid-template-columns: 33% 33% 33%;
            grid-gap: 20px;
            @media(max-width: $screen-md){
                display: block;
                margin-bottom: 20px;
            }
            label{
                @media(max-width: $screen-md){
                    display: block;
                    margin-bottom: 20px;
                }
                &.required{
                    span{
                        &::before{
                            content: "*";
                            color:red;
                            position: relative;
                            padding-right: 5px
                        }
                    }
                }
                span{
                    display: block;
                }
                input:not([type=radio]),input:not([type=checkbox]), select{
                    border: none;
                    background-color: #eee;
                    padding: 5px;
                    width: 100%;
                }
                input[type="radio"],input[type="checkbox"]{
                    display: inline-block;
                    margin: 5px;
                    padding: 5px;
                }
            }

            >label:nth-of-type(1){
                grid-area: item1;
                label{
                    text-align: center;
                    margin-right: 10px;
                    @media(max-width: $screen-md){
                        display: block;
                    }
                    input{
                        display: inline-block;
                        width: auto;
                        padding-right: 5px;
                    }
                }
            }
            >label:nth-of-type(2){
                grid-area: item2;
            }
            >label:nth-of-type(3){
                grid-area: item3;
            }
            >label:nth-of-type(4){
                grid-area: item4;
            }
            >label:nth-of-type(5){
                grid-area: item5;
            }
            >label:nth-of-type(6){
                grid-area: item6;
                display: none;
            }
            >label:nth-of-type(7){
                grid-area: item7;
                display: none;
            }
            >label:nth-of-type(8){
                grid-area: item8;
                display: grid;
                grid-template-columns: 20px auto;
                align-items: flex-start;
                grid-gap: 5px;
                span{
                    display: inline-block;
                }
                em{
                    display: none;
                }
            }
            >label:nth-of-type(9){
                grid-area: item9;
                display: grid;
                grid-template-columns: 20px auto;
                align-items: flex-start;
                grid-gap: 5px;
                span{
                    display: inline-block;
                }
            }
            >label:nth-of-type(10){
                grid-area: item10;
                display: grid;
                grid-template-columns:20px auto;
                align-items: flex-start;
                grid-gap: 5px;
                span{
                    display: inline-block;
                }
            }
        }
        .form-footer{
            [data-link-action="save-customer"]{
                @include btn-hover-animated($complementario1,white,2px,4px,10px);
            }
        }
        .page-footer{
            @include djv-border-top(solid);
            ul{
                margin: 0;
                padding: 0;
                li{
                    @include btn-hover-animated($primario5,white,2px,4px,10px);
                    display: inline-block;
                    margin-right: 10px;
                    a{
                        color: inherit;
                    }
                }
            }
        }
    }
}
#password{
    .form-fields{
        header{
            margin-bottom: 30px;
        }
        label{
            display: block;
            margin-bottom: 25px;
            span{
                display: block;
            }
        }
    }
    .form-footer{
        margin-top: 40px;
        button{
            @include btn-hover-animated($complementario1,white,2px,4px,10px);
        }
    }
}
