#notifications{
    @include djv-container();
    padding: 10px 10px 1px;
    margin-top: $small-padding;
}

.notification{
 // padding: (3 * $small-padding);
}

.notification-danger{
  background: #f2dede;
  border: #ebccd1;
}

.notification-warning{
  background: #fcf8e3;
  border: #faebcc;
}

.notification-success{
  background: #dff0d8;
  border: #d6e9c6;
}

.notification-info{
  background: #d9edf7;
  border: #bce8f1;
}
