/* Generated by Glyphter (http://www.glyphter.com) on  Mon Nov 18 2019*/
@font-face {
    font-family: 'golosinas';
    src: url('project/djv-icon-font/fonts/golosinas.eot');
    src: url('project/djv-icon-font/fonts/golosinas.eot?#iefix') format('embedded-opentype'),
         url('project/djv-icon-font/fonts/golosinas.woff') format('woff'),
         url('project/djv-icon-font/fonts/golosinas.ttf') format('truetype');
        // url('project/djv-icon-font/fonts/golosinas.svg#golosinas') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: fallback;
}
[class*='gol-icon']:before{
	display: inline-block;
   font-family: 'golosinas';
   font-style: normal;
   font-weight: normal;
   line-height: 1;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale
}
.gol-icon-asesoramiento:before{content:'\0041';}
.gol-icon-calidad:before{content:'\0042';}
.gol-icon-bebidas:before{content:'\0043';}
.gol-icon-contacto:before{content:'\0044';}
.gol-icon-garantia:before{content:'\0045';}
.gol-icon-truck:before{content:'\0046';}
.gol-icon-triangulo:before{content:'\004b';}
.gol-icon-usuario:before{content:'\004d';}
.gol-icon-caramelo:before{content:'\0050';}
.gol-icon-snacks:before{content:'\0051';}
.gol-icon-chocolate:before{content:'\0052';}
.gol-icon-fiestas:before{content:'\0053';}
.gol-icon-cromos:before{content:'\0054';}
.gol-icon-otros:before{content:'\0055';}
.gol-icon-mayor:before{content:'\0056';}
.gol-icon-new:before{content:'\0057';}
.gol-icon-clock:before{content:'\005a';}
.gol-icon-question:before{content:'\0061';}
.gol-icon-truck:before{content:'\0062';}
.gol-icon-heart:before{content:'\0068';}
.gol-icon-devoluciones:before{content:'\006f';}
.gol-icon-exit:before{content:'\0070';}
.gol-icon-box:before{content:'\0075';}
.gol-icon-card:before{content:'\0076';}
.gol-icon-piggy:before{content:'\0077';}
.gol-icon-shipped:before{content:'\0078';}
.gol-icon-spain:before{content:'\0079';}
.gol-icon-swipe:before{content:'\007a';}
