section .page-cms{
    @include djv-container();
    @include fuente($font-first,$font-regular);
    h1{
        margin-bottom: 30px;
        padding-bottom: 15px;
        border-bottom: 1px dashed #bababa;
        font-size: $font-xl;
        color:$primario5;
        @media(max-width: $screen-sm){
            font-size: 22px;
        }
    }
    h2{
        margin-top: 30px;
        margin-bottom: 30px;
        font-size: $font-lg;
        color:$primario5;
    }
    ul{

        list-style-type: disc;
        box-sizing: border-box;
        &.nobullet{
            list-style-type:none;
        }
        @media(max-width: $screen-md){
            display: block;
            li{
                display: block;
                margin-bottom: 20px;
            }
        }
    }
}
