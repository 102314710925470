.product-quantity{
    height: 50px;
    position: relative;
    label{
        float: left;
        margin-top: 30px;
        margin-right: 10px;
        font-size: $font-xs;
        display: none;
    }

    .product-add-to-cart-quantity{
        display: inline-block;
        #quantity_wanted{
            width: 80px;
            text-align: center;
            background-color: #eaeafa;
            border: 2px solid #d7d7d7;
            height: 37px;
            display: block;
            float: left;
            padding-top: 5px;
            @media(max-width: $screen-md){
                width: 55px;
                font-size: $font-sm;
            }
        }
        .djv-product-add-to-cart-quantity-btn-less{
            background-color: white;
            border: 2px solid #d7d7d7;
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
            display: block;
            height: 37px;
            margin-top: 0px;
            position: relative;
            float: left;
            border-right: none;
            padding: 0px 10px;
            font-size: 29px;
            line-height: 31px;
            cursor:pointer;
        }
        .djv-product-add-to-cart-quantity-btn-plus{
            background-color: white;
            border: 2px solid #d7d7d7;
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
            display: block;
            height: 37px;
            margin-top: 0px;
            position: relative;
            float: left;
            border-left: none;
            padding: 5px 10px;
            line-height: 21px;
            cursor:pointer;
        }
    }
}
