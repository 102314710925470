#module-djv_navigation-nsearch{
    @media(max-width: $screen-md){
        #left-column{
            display: none;
        }
    }
}
html:not([dir="rtl"]) .noUi-horizontal .noUi-handle {
	right: -10px !important;
	left: auto;
}
#djv-navigation-left{
    @include fuente($font-first,$font-regular);
    font-size:$font-sm;
    @media(max-width: $screen-sm){
        position: fixed;
        top: 0;
        background-color: white;
        z-index: 100;
        width: 100%;
        left: 0;
        padding: 45px 10px 10px;
        overflow-y: scroll;
        height: 100vh;
    }
    h2{
        @include fuente($font-first,$font-regular);
        font-size: $font-sm*1.15;
        padding: 0px 0 4px 0;
        border-bottom: 1px solid $gris2;
        margin-bottom: 10px;
        text-transform: uppercase;
        @media(max-width: $screen-md){
            position: fixed;
            font-size: $font-sm;
            width: 100%;
            background-color: white;
            left: 0;
            top: 0;
            padding: 10px;
            z-index: 2;
        }

    }
    #left-responsive-switch{
        @include fuente($font-first,$font-bold);
        font-size: $font-sm;
        text-transform: uppercase;
        color: $primario8;
        padding-top: 12px;
        padding-left: 0;
        display: block;
        height: 36px;
        border-bottom: 1px solid #eee;
        padding-bottom: 30px;

        #djv-icon-close-filters {
                display: none;
                float: right;
                font-size: $font-xxxs;
                font-style: normal;
                @include fuente($font-first,$font-regular);
                cursor: pointer;
                background-color: $complementario2;
                color:white;
                padding: 5px;
                border-radius: 5px;
                &::before {
                    display: inline-block;
                    font-style: normal;
                    font-weight: normal;
                    line-height: 1;
                    -webkit-font-smoothing: antialiased;
                    -moz-osx-font-smoothing: grayscale;
                    content: 'X';
                    color: $complementario2;
                    background-color: white;
                    padding: 4px 6px;
                    border-radius: 50px;
                    font-size: $font-xxs;
                    font-weight: bold;
                    margin-right: 0px;
                }
                @media(max-width: $screen-sm) {
                    display: inline-block;
                    padding: 4px 7px;
                    position: absolute;
                    right: 10px;
                    top: 5px;
                }
            }

        span {
            float: right;
            width: 20%;
            font-size: $font-xxs;
            color: $gris3;
            padding-top: 8px;
        }
    }
    h3{
       @include fuente($font-first,$font-bold);
       font-size: $font-sm;
       color: $primario8;
       padding-top: 0;
       //margin-left: 10px;
    }
    .djv_navigation-price{
        margin-bottom: 15px;
    }
    article,div{
        margin-bottom: 15px;
        ul{
            margin-left: 0;
            padding-left: 0;
            li{
                list-style: none;
                font-size: $font-xxs;
                @media(max-width: $screen-md){
                    font-size: $font-sm;
                }
                a{
                    color: inherit;
                    b{
                        display: none;
                    }
                }
                span{
                    margin-left: 5px;
                    font-size: $font-xxs*0.75;
                    color: $gris4;
                }
            }
        }
        &.special{
            background-color: $primario6;
            padding: 5px;
            h3{

            }
            ul{
                li{

                }
            }
        }
    }
    .djv_navigation-price{
        .djv_navigation-price-slider-range{
            margin-bottom: 15px;
            font-size: $font-xxs;
            @media(max-width: $screen-md){
                font-size:$font-sm;
            }
        }
        .noUi-base, .noUi-connects {
            height: 2px;
            background:$gris1;
        }
        .noUi-target {
            background: $gris5;
            border-radius: 0px;
            border: none;
            box-shadow: none;
            height: 2px;
        }
        .djv_navigation-price-slider{
            margin-bottom: 20px;
            width: 90%;
            margin-left: 5%;
            margin-bottom: 20px;
            .noUi-connect {
                background: $gris5;
            }
            .noUi-handle{
                border: none;
                border-radius: 50px;
                background: $gris5;
                cursor: default;
                box-shadow: none;
                width: 14px;
                height: 14px;
            }
            .noUi-handle::after, .noUi-handle::before {
            	content: "";
            	display: none;
            }
        }
        .djv_navigation-price-apply-button{
            @include btn-hover-animated($primario5,white,2px,4px,10px);
            margin-top: 29px;
            width: 99%;
            margin-left: auto;
            margin-right: auto;
            display: block;
            font-size: $font-xxs;
        }
    }
    .djv-navigation-left-selected-item{
        a{
            padding-left: 18px;
            position: relative;
            &::before{
                display: inline-block;
                font-family: 'comunes';
                font-style: normal;
                font-weight: normal;
                line-height: 1;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
                content:'\0054';
                color:$primario8;
                position: absolute;
                left: 0;
                top: 1px;
            }
        }
    }
    .djv-navigation-left-nofilter-selected{
        color:$gris6;
    }
    .filters-selected{
        #djv-filters-your-seleccion{
            padding-left: 0;
            margin-left: 5px;
            .djv-navigation-left-selected-item{
                list-style: none;
                .djv-icon-delete{
                    label{
                        display: none;
                    }
                    &::before{
                        margin-right: 5px;
                    }
                }
            }
        }
    }
}
