.product-miniature{
    text-align: center;
    margin-bottom: 20px;
    position: relative;
    border-right: 1px solid $gris1;
    display: grid;
    grid-auto-rows: 50% 50%;
    height: 450px;
    &:nth-of-type(4n) {
        border-right: 0px;
    }
    @media(max-width: $screen-md){
        padding: 5px;
        padding-bottom: 10px;
        border-bottom: 0px solid $gris1;
        border-right: 0px;
        min-height: 200px;
        margin-bottom: 15px;

    }
    .product-miniature-img-block{
        border: none;
        display: block;
        padding: 0px 0 10px 0;
        margin: 0px;
        .product-thumbnail{

        @media(max-width: $screen-md){
            border: none;
            display: block;
        }
        img{
            display: block;
            position: relative;
            width: 100%;
            max-width: 210px;
            margin-left: auto;
            margin-right: auto;
        }
    }
    }
    .product-miniature-data-block{
        display: grid;
        grid-template-areas: "name" "stars" "prices" "button";
        grid-template-columns: auto;
        grid-template-rows: max-content max-content auto 44px;
        grid-gap: 5px;
        align-items: end;
        line-height: normal;
        height: 150px;
        @media(max-width: $screen-md){
            grid-gap: 0px;
        }
        .name-product{
            grid-area: name;
            align-self: start;
            display: block;
            font-size: $font-sm;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 0;
            @media(max-width: $screen-md){
                align-self: center;
            }
            a{
                color: $gris8;
                @media(max-width: $screen-md){
                    white-space: normal;
                }
            }
        }
        .stars{
            grid-area: stars;
        }
        .djv-product-availability-info{
            display: none;
        }
        .product-prices{
            grid-area: prices;
            margin-bottom: 0px;
            align-self: start;
            @media(max-width: $screen-md){
                min-height: auto;
            }
            display: block;
            .product-discount{
                position: relative;
                margin-top: 0;
                display: inline-block;
                .regular-price{
                    text-decoration: line-through;
                    margin-right: 4px;
                }
            }
            .product-price:not(.has-discount){
                width: 100%;
                display: block;
                .with-taxes{
                    text-align: center;
                    font-size: $font-xl;
                    @include fuente($font-first,$font-bold);
                    color: black;
                    small{
                        display: none;
                    }
                }
            }
            .product-price.has-discount{
                min-width: auto;
                display: inline-block;
                .with-taxes{
                    min-width: auto;
                    color:$primario8;
                    font-size: $font-xl;
                    @include fuente($font-first,$font-bold);
                }
                small{
                    display: none;
                }
            }

            .discount-percentage{
                display: none;
            }
            .unit-price{
                display: block;
                color:$gris6;
                font-size: $font-xxs - 1;
                margin-top: 0px;
                span{
                    font-size: $font-xxs;
                }
            }
            .decimales{
                font-size: 75%;
            }
        }
        .product-flags{
            position: absolute !important;
            top:0;
            left:0px;
            padding-left: 0;
            > li {
                list-style: none;
                margin-bottom: 5px;
                text-transform: uppercase;
                font-size: $font-xxs;
                padding:5px 10px;
                color:white;
            }
            .new{
                background-color: $primario11;
            }
            .discount{
                background-color: $complementario2;
            }
            .on-sale{
                background-color: $primario8;
            }
        }
        .variant-links{
            display: none;
            grid-area: variants;
            .color{
                display: inline-block;
                width: 20px;
                height: 20px;
                border: 1px solid $gris3;
            }
        }
        .product-availability{
            display: none;
        }
        .product-list-actions{
            grid-area: button;
            .djv-ver-mas,.add-to-cart{
                @include btn-hover-animated($primario5, white, 0px, 6px, 20px);
            }
        }
    }
}
