
.slide{
    position: relative;
    display: block;
    margin: auto;
    overflow: hidden;
    @media(max-width: $screen-lg){
        max-height: 332px;
    }
    .slide-control-prev,.slide-control-next{
        position: absolute;
        top: 50%;
        z-index: 5;
        transform: translateY(-50%);
        background-color: $primario6;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        font-size: $font-md;
        color: white;
        cursor: pointer;
        @media(max-width: $screen-lg){
            width: 80px;
            height: 80px;
            background-color: transparentize($primario6, .5);
        }
    }
    .slide-control-prev{
        left: 10%;
        @media(max-width: $screen-lg){
            left: -40px;
        }
    }
    .slide-control-next{
        right: 10%;
        @media(max-width: $screen-lg){
            right: -40px;
        }
    }
    .djv-icon-arrow-left,.djv-icon-arrow-right{
        &::before{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
        }
    }
    @media(max-width: $screen-lg){
        .djv-icon-arrow-left{
            &::before{
                left: auto;
                right: 10%;
                font-weight: bold;
            }
        }
        .djv-icon-arrow-right{
            &::before{
                left: 30%;
                font-weight: bold;
            }
        }
    }
    .slide-inner{
        position: relative;
        left: 0;
        display: flex;
        flex-direction : row;
        transition: all .5s;

        &.noanimate{
            transition: none;
        }
        .slide-item{
            position: relative;
            width: 100%;
            overflow: hidden;
            min-height: 320px;
            @media(max-width: $screen-lg){
                min-height: auto;
            }
            img{

                width: auto;
                object-fit: cover;
                object-position: center;
                position: relative;
                left: 50%;
                transform: translateX(-50%);
                @media(max-width: $screen-lg){
                    max-width: 100vw;
                }

            }
        }
    }
    .slide-indicator-container{
        display: block;
        position: relative;
        bottom: 0em;
        height: 22px;
        width: 100%;
        padding-right: 15px;
        padding-left: 15px;
        margin-right: auto;
        margin-left: auto;
        border-top: 1px solid $gris2;
        border-bottom: 1px solid $gris2;

        .slide-indicators{
            @include djv-container();
            position: relative;
            right: 0;
            bottom: 0px;
            left: 0;
            z-index: 15;
            display: flex;
            justify-content: center;
            padding-left: 0;
            margin-right: auto;
            margin-left: auto;
            margin-bottom: 0;
            list-style: none;
            width: 70%;

            >li{
                border-left: 1px solid $gris2;
                border-right: 1px solid $gris2;
                width: auto;
                height: 20px;
                text-indent: 0;
                background-color: white;
                padding: 0px 15px;
                text-align: center;
                cursor: pointer;
                border-radius: 0;
                margin: 0;
                @include fuente($font-first,$font-light);
                font-size: $font-xs;
                white-space: nowrap;
                text-overflow: ellipsis;
                &::before{
                    display: none;
                }
                &::after{
                    display: none;
                }
                &.active {
                    width: auto;
                    height: auto;
                    margin: 0;
                    background-color: $primario6;
                    color: white;
                    border:none;
                }
            }
        }
        @media(max-width: $screen-lg){
            display: block;
            position: relative;
            bottom: 0;
            height: 22px;
            width: 100%;
            padding: 4px;
            margin-right: auto;
            margin-left: auto;
            .slide-indicators{
                @include djv-container();
                position: relative;
                right: 0;
                bottom: 0px;
                left: 0;
                z-index: 15;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-pack: center;
                -ms-flex-pack: center;
                justify-content: center;
                padding-left: 0;
                margin-right: auto;
                margin-left: auto;
                margin-bottom: 0;
                list-style: none;
                width: 70%;
                min-width: 100px;
                >li{
                    border-right: 0;
                    width: 12px;
                    height: 12px;
                    padding: 0;
                    border-radius: 50px;
                    margin-right: 5px;
                    @include fuente($font-first, $font-light);
                    font-size: 0;
                    background-color: $primario2;
                    color: white;
                    cursor: pointer;
                    &::before{
                        display: none;
                    }
                    &::after{
                        display: none;
                    }
                    &.active {
                        background-color: $primario3;
                        width: 12px;
                        height: 12px;
                        padding: 0;
                        border-radius: 50px;
                        margin-right: 5px;
                    }
                    @media(min-width: $screen-lg){
                        &:last-of-type{
                            border-right: none;
                        }
                    }
                    &::marker{
                        display:none;
                    }
                }
            }
        }
    }
}
/*#bannerSlider{
    height: 445px;
}*/
