// CDN servers

$cdnserver1: "https://cdn1.golosinasysnacks.com";
$cdnserver2: "https://cdn2.golosinasysnacks.com";
$cdnserver3: "https://cdn3.golosinasysnacks.com";

// IMAGES URL
$urlimagenes:"/themes/base/assets/img/";
$urlicons:"/themes/base/assets/icons/";

// IMAGES URL
$urlimagenes: "/themes/base/assets/img/";
$urlicons:    "/themes/base/assets/icons/";

// #### COLORES
$empty-bg:url("#{$urlimagenes}bg-pattern.png");

//Colors
$primario1:  #76c9d7;
$primario2:  #d3f0f5;
$primario3:  #6abac7;
$primario4:  #eafafd;
$primario5:  #03b8d6;
$primario6:  #E65EA6;
$primario7:  #ab2b65;
$primario8:  #a51c5a;
$primario9:  #fdf278;
$primario10: #ffa030;
$primario11: #ff8a00;
$primario12: #13a323;


//Escala de grises
$gris1:  #eeeeee;
$gris2:  #dddddd;
$gris3:  #cccccc;
$gris4:  #aaaaaa;
$gris5:  #999999;
$gris6:  #777777;
$gris7:  #666666;
$gris8:  #444444;
$gris9:  #333333;
$gris10: #111111;


//Colores complementarios
$complementario1:  #5abe20;
$complementario2:  #D53128;
$complementario3:  #ff7926;
$complementario4:  #0083ff;
$complementario5:  white;
$complementario6:  white;
$complementario7:  white;
$complementario8:  white;
$complementario9:  white;
$complementario10: white;


//Fonts
//$font-first-Light:     'Roboto-Light';
//$font-first-Regular:   'Roboto-Regular';
//$font-first-Medium:    'Roboto-Medium';
//$font-first-SemiBold:  'Roboto-Bold';
//$font-first-Bold:      'Roboto-Bold';
//$font-first-Extrabold: 'Roboto-Black';
//$font-first-Black:     'Roboto-Black';

//$font-secondary:           'Bree Serif';
//$font-secondary-Light:     'Bree Serif';
//$font-secondary-Regular:   'Bree Serif';
//$font-secondary-SemiBold:  'Bree Serif';
//$font-secondary-Bold:      'Bree Serif';
//$font-secondary-Extrabold: 'Bree Serif';

$font-first: 'Roboto', sans-serif;
$font-second: 'Bree Serif', serif;
$font-light:     300;
$font-regular:   400;
$font-medium:    500;
$font-semibold:  600;
$font-bold:      700;
$font-extrabold: 800;
$font-black:     900;
// Font Sizes
$font-xxxs: 12px;
$font-xxs:  13px;
$font-xs:   15px;
$font-sm:   16px;
$font-smd:  20px;
$font-md:   20px;
$font-bmd:  20px;
$font-slg:  22px;
$font-lg:   22px;
$font-blg:  22px;
$font-xl:   28px;
$font-xxl:  30px;
$font-xxxl: 40px;

//Screen sizes
// Extra small screen / phone
$screen-xs: 480px;
$screen-phone: $screen-xs;

// Small screen / tablet
$screen-sm: 576px;
$screen-tablet: $screen-sm;

// Medium screen / desktop
$screen-md: 768px;
$screen-desktop: $screen-md;

// Large screen / wide desktop
$screen-lg: 1024px;
$screen-lg-desktop: $screen-lg;

// Very large screen / wide desktop
$screen-xl: 1200px;
$screen-xl-desktop: $screen-xl;

// hd screen / wide desktop
$screen-hd: 1500px;

// So media queries don't overlap when required, provide a maximum
$screen-xs-max: ($screen-sm - 1);
$screen-sm-max: ($screen-md - 1);
$screen-md-max: ($screen-lg - 1);
$screen-lg-max: ($screen-xl - 1);
$screen-xl-max: ($screen-hd - 1);

// *** herencia de plantilla

// Sizes
$spacing-unit: 30px;
$medium-padding: $spacing-unit / 2;
$small-padding: $spacing-unit / 6;
$tiny-padding: 2px;

// Breakpoints
$breakpoint-small : 1000px;
$breakpoint-medium: 1200px;
$breakpoint-large : 1800px;
