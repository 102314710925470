.pagination{
    position: relative;
    display: block;
    padding-bottom: 1px;
    margin: 0px 0;
    border-radius: 0;
    width:100%;
    font-size: $font-xxs;
    @media(max-width: $screen-md){
        display: block;
        padding-bottom: 0px;
    }
    .pagination-summary{
        color:$gris6;
        @media(max-width: $screen-md){
            display: none;
        }
    }
    ul{
        position: absolute;
        margin-left: 0;
        padding-left: 0;
        right: 16px;
        top:-3px;
        @media(max-width: $screen-md){
            position: relative;
            margin-top: 0px;
            margin-bottom: 0px;
            right: 0;
            top:0;
        }
        li{
            display: inline-block;
            position: relative;
            a{
                color: $gris5;
                padding:1px 6px;
                &[rel="prev"]{
                    margin-right: 10px;
                }
                &[rel="nofollow"]{
                    min-width: 26px;
                    display: block;
                    text-align: center;
                    border: 1px solid $gris1;
                    font-size: 14px;
                }
                &[rel="next"]{
                    margin-left: 10px;
                }
            }
            &.current{
                a[rel="nofollow"]{
                    background-color: white;
                    color:$gris1;
                }
            }
        }
    }

}
