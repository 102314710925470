.cart-preview.cart-overview {
    width: 100%;
    position: inherit;
}
.cart-preview.cart-overview .body {
    display: block;
    position: inherit;
    width: 100%;
    color: $gris6;
}
.cart-preview {
    float: right;
    position: relative;
    a,
    a:hover,
    a:visited {
        text-decoration: none;
        color: inherit;
    }
    .header {
        display: block;
        font-weight: bold;
        cursor: pointer;
        background-color: $primario3;
        text-align: center;
        align-content: center;
        height: 40px;
        padding: 0 15px 0 10px;
        > :first-child {
            float: left;
        }
        > :last-child {
            float: right;
        }

        i {
            font-size: 30px;
            display: block;
            line-height: 13px;
            padding-top: 6px;
        }
        span {
            position: absolute;
            top: 2px;
            left: 20px;
            font-size: 12px;
            color: #fdf278;
            text-align: center;
            display: block;
            width: 20px;
        }
    }
    .body {
        display: none;
        width: calc(100vw - 1.1rem);
        background-color: white;
        right: 0;
        border: 1px solid $primario3;
        border-top: 0;
        color: $gris6;
        &:hover {
            display: block;
            position: absolute;
        }
        > ul {
            margin: 0;
            padding: 5px 14px 5px 0;
            margin-top: 20px;
            margin-bottom: 20px;
            max-height: 210px;
            overflow: auto;
            padding-right: 16px;
            li {
                list-style: none;
                margin-bottom: 5px;
                padding: 0;
                display: grid;
                grid-template-columns: 9% 60% 26% 5%;
                > * {
                    vertical-align: top;
                }
                .product-name {
                    text-align: left;
                    padding: 0px;
                }
                .product-price {

                }
                .product-quantity {
                    color: $primario3;
                    height: auto;
                    &::after {
                        content: 'x';
                        margin-right: 8px;
                    }
                }
                .remove-from-cart {
                    i::before {
                        color: $primario5;
                    }
                    float: right;
                }
            }
        }
        .cart-subtotals {
            border-top: 1px solid $primario3;
            padding-top: 10px;
            .products {
                text-align: right;
                .value{
                    min-width: 60px;
                    display: inline-block;
                    &::after {
                        content: " €";
                    }
                }
            }
            .shipping,.tax, .discount{
                text-align: right;
                .value{
                    min-width: 60px;
                    display: inline-block;
                    &::after {
                        content: " €";
                    }
                }
            }
            .tax{
                display: none;
            }
        }
        @media(min-width: $screen-sm) {
            width: 400px;
        }

        .ps-cart-remove-icon {
            position: relative;
            &::before {
                position: absolute;
                color: $primario3;
                display: inline-block;
                font-family: 'comunes';
                font-style: normal;
                font-weight: normal;
                line-height: 1;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
                content:'\0053';
                font-size: 6px;
                border: 1px solid;
                border-radius: 50%;
                padding: 3px;
                right: -2px;
                top: 17px;
            }
        }
        .blockcart-finalizar{
            background-color: #13a323;
            color: white;
            margin: 20px auto 10px;
            display: block;
            width: 90%;
            padding: 6px;
            text-align: center;
            text-transform: uppercase;
            cursor: pointer;
            border: 2px solid #13a323;
            transition: all 500ms;
            &:hover {
                box-shadow: 2px 2px 9px rgba(0, 0, 0, 0.4);
                background-color: white;
                color: #61af26;
                border: 2px solid #61af26;
            }
        }
    }

    &:hover .body {
        display: block;
        position: absolute;
        z-index: 999;
    }
}
/* this is to make the containers take up vertical space */

.cart-preview .cart-total .label::after {
    content: ": ";
}

.cart-preview .cart-total > div {
    clear: both;
    border-bottom: 1px solid #ccc;
}

.cart-preview .cart-total > div:not(:last-child) {
    margin-bottom: 5px;
}

.cart-preview .cart-total {
    font-weight: bold;
    padding-top: 14px;
    margin-top: 14px;
    border-top: 1px solid #ccc;

    span {
        font-size: 18px;
    }

    .value::after {
        content: " €";
    }
}
