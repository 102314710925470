*{
    box-sizing: border-box;
}
body{
    @include fuente($font-first,$font-regular);
    overflow-x: hidden;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
}
a{
    color:$primario3;
    &:hover{
        color:$primario5;
        text-decoration: underline;
    }
}
p{
    margin-bottom: auto;
}
.ps-alert-success{
    padding: 90px 20px;
    li{
        list-style: none;
        border-bottom: 1px dashed $gris7;
        i{
            float: left;
            margin-right: 20px;
            color:$complementario1;
            font-size: $font-xxl;
            padding-bottom: 20px;
        }
    }
}
.whatsapp{
    cursor: pointer;
}
.full-width{
    width: 100vw;
    display: block;
    position: relative;
    margin-left: 50%;
    transform: translateX(-50%);
}
#lgcookieslaw_banner{
    #lgcookieslaw_accept{
        background-color: #dadada;
        padding: 5px;
        display: block;
        border: 0;
        border-radius: 6px;
    }
}
