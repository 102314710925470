#authentication{
    #main{
        width: 100%;
        max-width: 920px;
        margin-left: auto;
        margin-right: auto;
        @media(max-width: $screen-sm){
            padding: 10px;
        }
        .djv-register-link{
            display: block;
            @include djv-border-top(dashed);
            @include djv-border-bottom(dashed);
        }
        .form-footer{
            button{
                border: none;
                background-color: $primario12;
                padding: 6px 30px;
                color:white;
                transition: all 500ms;
                border: 2px solid $primario12;
                cursor: pointer;
                &:hover{
                    box-shadow: 2px 2px 9px rgba(0,0,0,.4);
                    background-color: white;
                    color:$primario12;
                    border: 2px solid $primario12;
                }
            }
        }
        .page-authentication{
            #login-form{
                margin-top: 20px;
                label{
                    display: inline-block;
                    margin-right: 20px;
                    vertical-align: top;
                    span{
                        display: block;
                        margin-bottom: 10px;
                    }
                    input{
                        display:block;
                        width: 100%;
                    }
                }
                .lost_password{
                    margin-top: 20px;
                }

            }
            #customer-form{
                .form-fields{
                    margin-top: 20px;
                    label{
                        vertical-align: top;
                    }
                    label:nth-of-type(6), label:nth-of-type(5){
                        display: none !important;
                    }
                    label:nth-of-type(4){
                        margin-bottom: 30px;
                    }
                    label:not(.checkbox-field){
                        display: block;
                        max-width: 60%;
                        span{
                            display: block;
                            width: 50%;
                            margin-bottom: 10px;
                        }
                        input{
                            display:block;
                            width: 100%;
                        }
                    }
                    .lost_password{
                        margin-top: 20px;
                    }

                    label.checkbox-field{
                        display: block;
                        span{
                            margin-left: 20px;
                            display: block;
                            margin-top: -24px;
                            position: relative;
                            &::after{
                                display: block;
                                float: right;
                                display: inline-block;
                                position: absolute;
                                left: 312px;
                                top:0;
                            }
                        }
                        &:first-of-type{
                            display: none;
                        }
                    }
                    label.radio-field {
                        label{
                            display:inline-block;
                            width: auto;
                            margin-right: 5px;
                            input{
                                display:inline-block;
                                width: auto;
                                margin-right: 5px;
                            }
                        }
                    }
                    label.required span::after{
                        content: " *";
                        color: darken(red, 15%);
                    }
                }
            }
        }
        .form-errors{
            padding-left: 0;
            li{
                color: white;
                display: block;
                list-style: none;
                border: 1px dashed darken(red, 15%);
                padding: 10px;
                text-align: center;
            }
        }
    }
}
