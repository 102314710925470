#product {
    .product-accessories {
        width: 30%;
        margin-left: 10px;
        @media(max-width: $screen-md){
            width: auto;
            float: none;
            padding: 0px;
            margin: 0;
        }
        > h3 {
            @include djv-border-bottom(solid);
            @include fuente($font-first,$font-regular);
            font-size: $font-md;
            padding: 10px 0;
            color: black;
            margin-top: 3px;
            @media(max-width: $screen-md){
                border-bottom: 0;
            }
        }

        .product-accessories-container {
            padding: 16px;
            text-align: center;
            @include fuente($font-first,$font-medium);

            @media(max-width: $screen-sm) {
                padding: 0;
            }
            &.djv_carouselh{

                @media(max-width: $screen-md) {
                    left:-10px;
                }
                .product-miniature {
                    margin-bottom: 20px;
                    width: 320px;
                    border-right: 0;
                    padding: 0 40px;
                    .product-flags{
                        list-style: none;
                        position: absolute;
                        top: 12px;
                        text-transform: uppercase;
                        color: white;
                        left: 0px;
                        font-size: 14px;
                        margin-left: 0;
                        padding-left: 0;
                        z-index: 2;
                        @media(max-width: $screen-md){
                            left: 10px;
                        }
                        li{
                            padding: 6px;
                            margin-bottom: 10px;
                        }
                        .discount,.new{
                            background-color: $primario11;
                        }
                        .on-sale,.special{
                            background-color: $primario8;
                        }
                    }
                    @media(max-width: $screen-md) {
                        width: 100%;
                    }
                    .product-miniature-img-block{
                        .thumbnail{
                        @include djv-border-bottom(solid);
                        margin-bottom: 5px;
                        padding-bottom: 5px;
                        img {
                            font-size: 12px;
                            max-width: 210px;
                            margin-left: auto;
                            margin-right: auto;
                        }
                    }
                    }
                    .product-miniature-data-block{
                        .name-product{
                            height: auto;
                        }
                        .product-price-and-shipping {
                            .discount-percentage {
                                display: none;
                            }

                            .price {
                                font-size: 28px;
                                @media(max-width: $screen-sm) {
                                    font-size: 20px;
                                }
                            }
                            .regular-price {
                                font-size: 14px;
                                color: $gris5;
                                text-decoration: line-through;
                            }
                            .product-discount {
                                margin-top: 3px;
                                display: block;
                                .discount-amount,
                                .discount-percentage {
                                    display: none;
                                }
                            }
                        }
                        .product-availability.unavailable {
                            color: $gris5;
                            font-size: 12px;
                        }
                        .add-to-cart{

                        }
                    }
                }
                .djv_carouselh-left-arrow{
                    left: 0;
                }
                .djv_carouselh-right-arrow{
                    right: 0;
                }
            }
        }
    }
}
