#product{
    .product-prices{
        .desde{
            display: none;
        }
        .product-price{
            .with-taxes{
                font-size: $font-xxl*1.4;
                @include fuente($font-first,$font-bold);
                display: inline-block;
                min-width: 180px;
                @media(max-width: $screen-md){
                    font-size: $font-xxl;
                    display: block;
                    min-width: 0;
                }

                small {
                    display: none;
                }
            }
            .product-without-taxes {
                color: $gris2;
                &::after {
                    content: "sin IVA";
                    position: absolute;
                    left: 0;
                    @include fuente($font-first,$font-light);
                    font-size: $font-xxxs;
                    top: -18px;
                }
            }
            &.has-discount{
                .with-taxes{
                    color: $primario8;
                }
            }
        }

        .unit-price {
            display: block;
            color: $gris5;
            font-size: $font-xxs*.9;
            //margin-left: 40px;
            @media(max-width: $screen-md){

            }
            span{
                font-weight: bold;
                font-size: $font-md;
                display: block;
                text-align: center;
            }
        }

        .product-discount {
            margin-top: -14px;
            position: absolute;
            .discount-amount,
            .discount-percentage {
                display: none;
                min-height: auto;
                padding: 0 5px;
                background-color: $primario6;
                font-size: $font-sm;
                @include fuente($font-first,$font-medium);
                font-size: 22px;
                color: white;
            }
            .regular-price {
                display: inline-block;
                @include fuente($font-first,$font-medium);
                font-size: $font-sm + 3;
                text-decoration: line-through;
                color: #2c2c2c;
                margin-left: 5px;
            }
        }
    }
}
