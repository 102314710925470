.djv-listas-productos{
    margin-top: 25px;
    margin-bottom: 25px;
    width: calc(100% - 100px);
    margin-left: auto;
    margin-right: auto;
    @media(max-width: $screen-md){
        width: 100%;
    }
    h2{
        @include fuente($font-second,$font-regular);
        font-size: $font-xl;
        text-align: center;
        @media(max-width: $screen-md){
            font-size: $font-lg + 2px;
        }
    }
    p{
        text-align: center;
    }
    .djv_carouselh{
        margin-top: 40px;
        margin-bottom: 40px;
        h2{
            @include fuente($font-first,$font-regular);
        }
        article{
            width: 214px;
            @media(max-width: $screen-md){
                scroll-snap-align: start;
                width: 100%;
                position: relative;
                display: inline-block;
                margin-right: -4px;
                text-align: center;
                border-right: 0;
            }
        }
    }
}
#index{
     .products{
        .product-miniature{
            border-right: 1px solid $gris2;
            padding-left: 10px;
            padding-right: 10px;
            text-align: center;
            position: relative;
            .product-description-short{
                p{
                    margin-bottom: 5px;
                }
            }
            .add-to-cart,.djv-ver-mas{
            }
            >a{
                img{
                    width: 100%;
                    max-width: 210px;
                }
            }
        }
    }
}
