#modal-zoom{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(255,255,255,1);
    z-index: 5000;
    @media(max-width: 768px){
        overflow: hidden;
    }
    #consolaDebug{
        @media(max-width: $screen-md){
            position: fixed;
            top: 60px;
            width: 80px;
            padding: 10px;
        }
    }
    #modal-zoom-close{
        margin-bottom: 50px;
        position: absolute;
        right: 10px;
        top: 10px;
        z-index: 10;
        font-size: 32px;
        @media(max-width: $screen-md){
            top: 16px;
            z-index: 11;
            font-size: 26px;
            color: white;
        }
    }
    .controls{
        position: absolute;
        left: 50%;
        bottom: 142px;
        z-index: 10;
        transform: translateX(-50%);
        background-color: black;
        padding: 18px 10px 10px;
        display: block;
        border-radius: 3px;
        @media(max-width: 768px){
            bottom: auto;
            top: 0;
            right: auto;
            left: 0;
            transform: none;
            border-top-left-radius: 0px;
            border-top-right-radius: 0px;
            border-bottom-right-radius: 3px;
            width: 100%;
            display: flex;
            justify-content: center;
        }
        > i{
            font-style: normal;
            font-weight: 800;
            font-size: 18px;
            color: white;
            margin: 5px 10px 1px;
            cursor: pointer;
            @media(max-width: 768px){

            }
            &::before{

            }
        }

        #modal-zoom-in{
        }
        #modal-zoom-out{
        }
    }
    #image-inside-modal{
        position: fixed;
        display: block;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        @media(max-width: 768px){
            overflow: scroll;
        }
        #consola{
            position: fixed;
            top: 0;
            left: 0;
            z-index: 1000;
        }
        .product-cover{
            @media(max-width: 768px){
                position: absolute;
                top: 0;
                left: 0;
                width: 100vw;
                height: 100vh;
                overflow-x: scroll;
            }
            img{
                touch-action: pinch-zoom;
                position: absolute;
                transform-origin: center center 0;
                width: auto;
                height: 67vh;
                cursor: move;
                @media(max-width: 768px){
                    position: relative;
                    width: 100vw;
                    height: auto;
                    display: block;
                    border: 1px solid black;
                }
            }
        }
        #thumbs-modal{
            position: absolute;
            bottom: 0px;
            width: 100%;
            padding: 10px;
            background-color: rgba(235,235,235,1);
            border: 1px solid #ddd;
            border-radius: 10px;
            ul{
                list-style: none;
                padding: 5px;
                display: flex;
                justify-content: space-around;
                margin-bottom: 0;
                @media(max-width: 768px){
                    width: 100%;
                    overflow-x: auto;
                    overflow-y: hidden;
                    white-space: nowrap;
                    -webkit-overflow-scrolling: touch;
                    -webkit-scroll-snap-type: mandatory;
                    scroll-snap-type: x mandatory;
                    -webkit-scroll-snap-points-x: repeat(100%);
                    scroll-snap-points-x: repeat(100%);
                }
                li{
                    margin: 0 4px;
                    width: 85px;
                    height: 85px;

                    padding: 0;

                    @media(max-width: 768px){
                        scroll-snap-align: start;
                        width: 100%;
                        position: relative;
                        display: inline-block;
                        margin-right: -4px;
                        text-align: center;
                    }
                    img{
                        background-color: white;
                        margin-top: 0;
                        transition: all 500ms ease-out;
                        cursor: pointer;
                        box-shadow: 0 0 0 rgba(10,10,10,0);
                        width: 85px;
                        height: 85px;
                        &:hover{
                            margin-top: -10px;
                            box-shadow: 6px 3px 10px rgba(10,10,10,0.85);
                        }
                    }
                }
            }
        }
        .product-flags{
            display: none;
        }
        .djv-product-img-action{
            display: none;
        }
    }
}
#modal-zoom-pan{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(255,255,255,1);
    z-index: 5000;
    @media(max-width: 768px){
        overflow: hidden;
    }
    #controls-modal{
        position: absolute;
        left: 50%;
        bottom: 142px;
        z-index: 10;
        transform: translateX(-50%);
        background-color: black;
        padding: 18px 10px 10px;
        display: block;
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
        @media(max-width: 768px){
            bottom: auto;
            top: 0;
            right: auto;
            left: 0;
            transform: none;
            border-top-left-radius: 0px;
            border-top-right-radius: 0px;
            border-bottom-right-radius: 3px;
            width: 100%;
            display: flex;
            justify-content: center;
        }
        > i{
            font-style: normal;
            font-weight: 800;
            font-size: 18px;
            color: white;
            margin: 5px 10px 1px;
            cursor: pointer;
            @media(max-width: 768px){

            }
            &::before{

            }
        }

        #modal-zoom-in{
        }
        #modal-zoom-out{
        }
    }
    #modal-zoom-close{
        margin-bottom: 50px;
        position: absolute;
        right: 10px;
        top: 10px;
        z-index: 10;
        font-size: 32px;
        @media(max-width: $screen-md){
            top: 16px;
            z-index: 11;
            font-size: 26px;
            color: white;
        }
    }
    #imgZoomPan{
        position: absolute;
        /*-webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;*/
    }
    #thumbsZoomPan{
        position: absolute;
        bottom: 0px;
        width: 100%;
        padding: 10px;
        background-color: rgba(235,235,235,1);
        border: 1px solid #ddd;
        border-radius: 10px;
        ul{
            list-style: none;
            padding: 5px;
            display: flex;
            justify-content: space-around;
            margin-bottom: 0;
            @media(max-width: 768px){
                width: 100%;
                overflow-x: auto;
                overflow-y: hidden;
                white-space: nowrap;
                -webkit-overflow-scrolling: touch;
                -webkit-scroll-snap-type: mandatory;
                scroll-snap-type: x mandatory;
                -webkit-scroll-snap-points-x: repeat(100%);
                scroll-snap-points-x: repeat(100%);
            }
            li{
                margin: 0 4px;
                width: 85px;
                height: 85px;

                padding: 0;

                @media(max-width: 768px){
                    scroll-snap-align: start;
                    width: 100%;
                    position: relative;
                    display: inline-block;
                    margin-right: -4px;
                    text-align: center;
                }
                img{
                    background-color: white;
                    margin-top: 0;
                    transition: all 500ms ease-out;
                    cursor: pointer;
                    box-shadow: 0 0 0 rgba(10,10,10,0);
                    width: 85px;
                    height: 85px;
                    &:hover{
                        margin-top: -10px;
                        box-shadow: 6px 3px 10px rgba(10,10,10,0.85);
                    }
                }
            }
        }
    }
}
