#module-djv_navigation-nsearch, #search{
    .cabecera-imagen-estatica, .category-cover{
        display: none;
    }
    .djv-navigation-nsearch-header{
        @include djv-container();
        #category-description{
            padding: 10px 0 20px;
            @media(max-width: $screen-md){
                margin-left: 0;
                width: 100%;
                margin-bottom: 0px;
                padding-bottom: 10px;
                border: none;
            }
            h1{
                display: block;
                font-size: $font-xxl;
                @include fuente($font-second,$font-regular);
                text-align: center;
                @media(max-width: $screen-md){
                    font-size: $font-md;
                }
            }
            a{
                color:$primario6;
            }
            p{
                @include fuente($font-first,$font-light);
                font-size: $font-md*0.95;
                text-align: center;
                padding: 5px 20px;
                color:$gris7;
                @media(max-width: $screen-md){
                    font-size: $font-xs;
                }
            }
            .djv-product-list-count{
                position:absolute;
                top:15px;
                right: 15px;
            }
            @media(max-width: $screen-md){
                width: 100%;
            }
            span{
                color:$primario6;
                cursor: pointer;
                &:hover{
                    text-decoration: underline;
                }
            }
            .cart-advisor{
              display: none;
              background-color: #E65EA6;
              padding: 6px 16px;
              text-align: center;
              color: #fff;
            }
        }
    }
    #djv-two-columns-layer{
        @include djv-container();
        @media(max-width: $screen-md){
            margin-top: 0px !important;
        }

        #content-wrapper{
            position: relative;
            h1{
                display: block;
                text-align: center;
                font-size: $font-md;
                @media(max-width: $screen-md){
                    font-size: 28px;
                }
            }
            #js-product-list-top{
                border-bottom: 1px solid $gris1;
                margin: -8px 0 20px;
                display: block;
                padding: 15px 0 5px;
                @media(max-width: $screen-md){
                    grid-template-columns:100%;
                    margin-top: 5px;
                    padding: 5px 5px 0px;
                    border:none;
                    background-color: #eee;
                }
                .products-sort-order{
                    position: relative;
                    ul{
                        padding: 0;
                        li{
                            list-style: none;
                            display: inline-block;
                            margin: 5px;
                            font-size: 14px;
                            &.sort-selected{
                                background-color: $primario2;
                                color:white;
                                padding: 5px 10px;
                                border-radius: 2px;
                                display: block;
                                width: 25%;
                            }
                            a{
                                padding: 5px 10px;
                                background-color: $gris2;
                                border-radius: 2px;
                                display: inline-block;
                                color: $gris6;
                            }
                        }
                    }
                    select{
                        border:none;
                        background-color: white;
                        font-size: $font-xxs;
                        position: absolute;
                        right: 0;
                        top: -18px;
                    }
                    @media(max-width: $screen-md){
                        select{
                            border: none;
                            background-color: #eee;
                        }
                    }
                }

                .djv-navigator-responsive-filter-button{
                    display:none;
                    font-size: 14px;
                    color:$gris6;
                    text-align: right;
                    padding-bottom: 5px;
                    position: relative;
                    cursor: pointer;
                    &::before{
                        font-size: 22px;
                        margin-right: 10px;
                        position: absolute;
                        right: 30px;
                        top:-45px;
                    }
                    @media(max-width: $screen-md){
                        display: block;
                        font-size: 0;
                        &::before{
                            right: 0;
                            top: -24px;
                        }

                    }
                }
                #djv-filters-your-seleccion{
                    margin-left: 0;
                    padding-left: 0;
                    margin-top: 20px;
                    display: none;
                    li{
                        list-style: none;
                        a{
                            color: inherit;
                            b{
                                display: none;
                            }
                        }
                    }
                    .djv-navigation-left-selected-item a::before{
                        display: inline-block;
                        font-style: normal;
                        font-weight: normal;
                        line-height: 1;
                        -webkit-font-smoothing: antialiased;
                        -moz-osx-font-smoothing: grayscale;
                        content: 'X';
                        color: white;
                        background-color: $complementario2;
                        padding: 4px 6px;
                        border-radius: 50px;
                        font-size: 12px;
                        font-weight: bold;
                        margin-right: 5px;
                    }
                }
            }
            #js-product-list{
                display: grid;
                grid-template-columns: repeat(4,25%);
                @media(max-width: $screen-lg){
                    position: relative;
                    width: 100%;
                    grid-template-columns: 50% 50%;
                    grid-gap: 5px;
                }
                .product-miniature{
                    .name-product{
                        @media(max-width: $screen-md){
                            height: 40px;
                            overflow: hidden;
                        }
                    }
                    .product-list-actions{
                        .add-to-cart{
                        }
                    }
                }
            }
            .textoSeo{
                color:$gris7;
                @include fuente($font-first,$font-light);
                img{
                    margin-top:40px;
                    margin-bottom: 20px;
                }
                a{
                    color:$primario6;
                }
                h2{
                    @include fuente($font-second,$font-regular);
                    font-weight: 400;
                    font-size: $font-xxl;
                    margin-top: 30px;
                    color:$gris9;
                }
            }
        }
    }
}
