#product{

    .djv-product-images{
        position: relative;
        .product-flags{
            position: absolute;
            top: 12px;
            left: 0px;
            z-index: 2;
            margin-left: 0;
            padding-left: 0;
            text-transform: uppercase;
            color: white;
            list-style: none;
            font-size: 14px;
            text-align: center;

            @media(max-width: $screen-md){
                left: 10px;
            }
            li{
                padding: 6px;
                margin-bottom: 10px;
            }
            .discount{
                background-color: $complementario2;
            }
            .new{
                background-color: $primario11;
            }
            .on-sale,.special{
                background-color: $primario8;
            }
        }
        .product-cover{
            overflow: hidden;
            position: relative;
            margin-bottom: 20px;
            cursor: pointer;
            @media(max-width: $screen-sm){
                min-width: 200px;
                min-height: 0px;
            }
            img{
                width: auto;
                max-width: 100%;
                margin-left: auto;
                margin-right: auto;
                display: block;
                @media(max-width: $screen-sm){
                    max-width: 100%;
                }
            }
        }
        .djv-product-img-action{
            display:none;
            position: relative;
            overflow: auto;
            top: -70px;
            left: 50%;
            transform: translateX(-50%);
            background: rgba(255, 255, 255, 0.6);
            -moz-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.5);
            -webkit-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.5);
            box-shadow: 0 2px 8px rgba(0, 0, 0, 0.5);
            padding: 10px;
            cursor: pointer;
            .ver-grande{

                position:relative;
                font-size: 16px;
                text-align: center;
            }
        }
        .product-thumbnails.djv_carouselh,.product-thumbnails{

            @media(max-width: $screen-sm){
                margin-top: -16px;
            }
            .products{
                /*width: 100%;*/
                height: 125px;
                overflow: hidden;
                padding: 0;

                li{
                    list-style: none;
                    padding: 6px;
                    display: inline-block;
                    width: 125px;
                    height: 125px;
                    min-height: 125px;
                    min-width: 125px;
                    @media(max-width: $screen-sm){
                        height: 120px;
                        width: 120px;
                        min-height: 120px;
                        min-width: 120px;
                    }
                    img{
                        width: 100%;
                        border: 1px solid $gris2;
                    }
                }
            }
        }
        .djv-light-box{
            display: none;
            position:fixed;
            top:0;
            left: 0;
            width: calc(100vw - 16px);
            height: 100vh;
            background-color: rgba(0,0,0,.8);
            z-index: 100;
            padding: 10%;
            .djv-light-box-container{
                position: relative;
                width: 90%;
                top:50%;
                left: 50%;
                transform: translate(-50% , -50%);
                background-color: white;
                .products > *{
                    width: 100%;
                }
                img{
                    width: 90%;
                    max-width: 750px;
                    margin-left: auto;
                    margin-right: auto;
                    display: block;
                }
                .djv-light-box-button-close{
                    position: absolute;
                    right: 10px;
                    top:10px;
                    z-index: 2;
                    &::before{
                        font-size: $font-xl;
                    }
                }
            }
        }
    }
}
