.djv-icon-menu {
    display: none;
    color: $primario3;
    font-size: $font-md;
    padding: 7px 10px 5px;
    width: 40px;
    height: 40px;
    font-size: 22px;
    position: absolute;
    top: 0;
    @media(max-width: $screen-lg) {
        display: block;
    }
}

#searcher-icon-responsive {
    display: none;
    @media(max-width: $screen-lg) {
        display: block;
        position: absolute;
        top: 2px;
        left: 45px;
        border-radius: 50%;
        width: 35px;
        height: 35px;
        text-align: center;
        cursor: pointer;
        padding: 5px;
        font-size: $font-lg;
        color: $primario3;
    }
}
.header-nav-top{
    @media(max-width: $screen-lg) {
        display: none;
    }
}
#main-menu {
    @include djv-container();
    //margin-top: -75px;

    @media(max-width: $screen-lg) {
        margin-top: 0;
        position: fixed;
        background-color: rgba(255,255,255,.95);
        height: 100vh;
        padding-top: 40px;
        border-top: 1px solid $gris1;
        width: 100vw;
        overflow: hidden;
        top: 0;
        left: 0;
        z-index: 1000;
    }
    #djv-close-nav-responsive {
        display: none;
        @media(max-width: $screen-lg) {
            display: block;
            position: absolute;
            top: 10px;
            right: 24px;
            border-radius: 50%;
            width: 35px;
            height: 35px;
            text-align: center;
            cursor: pointer;
            padding: 5px;
            &::before {
                color: $gris8;
                font-size: $font-xl;
            }
        }
    }

    > .djv-container {
        @media(max-width: $screen-lg) {
            overflow-y: auto;
            max-height: calc(100%);
        }
        .djv-main-menu {
            display: flex;
            justify-content: center;
            padding: 0;
            margin-left: auto;
            margin-right: auto;
            @include fuente($font-first,$font-regular);
            font-size: 15px;
            color: $gris9;
            white-space: nowrap;
            @media(max-width: $screen-lg) {
                padding-top: 20px;
                display: block;
            }
            li {
                list-style: none;
            }
            > li {
                display: inline-block;
                text-transform: uppercase;
                margin-left: 2%;
                margin-right: 2%;
                cursor: pointer;
                position: relative;
                cursor: context-menu;
                transition: all 500ms;
                position: relative;

                > span {
                    a {
                        color: inherit;
                    }
                    .djv-icon-arrow-down{
                        &::before {
                            display: none;
                        }
                    }
                }
                @media(max-width: $screen-lg) {
                    display: block;
                    text-transform: none;
                    padding-bottom: 0;
                    margin-bottom: 0;
                    @include fuente($font-first,$font-bold);
                    font-size: $font-md*0.85;

                    > span > .menu-toggle-icon{
                        display: block;
                        position: absolute;
                        right: 10px;
                        top: 15px;
                        z-index: 2;
                        &::before{
                            display: none;
                            display: block;
                            font-size: $font-xs;
                        }
                    }

                    &.desplegado {
                        border-bottom: 1px solid $gris2;

                        > span > .djv-icon-arrow-down::before{
                            display: none;
                            content: '\004d';
                        }
                    }

                    > span {
                        display: block;
                        position: relative;
                        border-bottom: 1px solid $gris2;
                        padding-left: 10px;
                        padding-bottom: 10px;
                        padding-top: 10px;
                    }

                    &:last-of-type {
                        span {
                            display: block;
                            padding: 10px;
                        }

                        &::before {
                            content: "";
                        }
                    }
                }

                .marcador {
                    border-bottom: 2px solid $primario5;
                    width: 0;
                    transition: width 500ms;
                    @media(max-width: $screen-lg) {
                        display: none;
                    }
                }

                .grupo-submenu {
                    position: absolute;
                    display: none;
                    padding: 24px;
                    background-color: white;
                    box-shadow: 0 3px 5px rgba(0,0,0,.4);
                    &.alineado-derecha{
                        right: 0;
                    }
                    @media(max-width: $screen-lg) {
                        position: relative;
                        box-shadow: none;
                        padding: 0;
                        position: relative;
                        background-color: rgba(255,255,255,0);
                        overflow: hidden;
                        display: none;
                    }

                    > ul {
                        padding: 0 20px 0 0;
                        text-align: left;
                        margin-right: 20px;
                        border-right: 1px solid #ddd;
                        @media(max-width: $screen-lg) {
                            border-right: none;
                            @include fuente($font-first,$font-regular);
                            font-size: $font-xxs;
                            padding: 0;
                            text-align: left;
                            margin-right: 0;
                            margin-left: -10px;
                        }

                        > li {
                            text-transform: none;
                            white-space: nowrap;
                            margin-bottom: 5px;
                            .djv-icon-arrow-down{
                                &::before {
                                    display: none;
                                }
                            }
                            @media(max-width: $screen-lg) {
                                border-bottom: 1px solid $gris2;
                                padding-bottom: 5px;
                                text-transform: none;
                                white-space: nowrap;
                                padding-left: 20px;
                                margin-bottom: 0;

                                &:not(.encabezados-submenu) {
                                    padding-left: 30px;
                                    padding-top: 5px;
                                    padding-bottom: 5px;
                                    display: none;

                                }
                            }
                        }

                        &:last-of-type {
                            border-right: none;
                            padding-right: 0;
                            margin-right: 0;
                        }
                    }
                }

                &:hover {
                    color: $primario3;

                    [class*="gol-icon-"]::before {
                        margin-top: -10px;
                        margin-bottom: 15px;
                    }

                    .djv-icon-cromos::before {
                        font-size: 38px !important;
                        margin-bottom: 5px !important;
                    }

                    .marcador {
                        width: 100%;
                        @media(max-width: $screen-lg) {
                            display: none;
                        }
                    }

                    .grupo-submenu {
                        @media(min-width: $screen-lg) {
                            display: flex;
                        }
                        > ul {
                            > li {
                                > a {
                                    color: $gris6;
                                    cursor: pointer;

                                    &:hover {
                                        @media(min-width: $screen-lg) {
                                            color: $primario3;
                                        }
                                    }
                                }
                                &.encabezados-submenu{
                                    > a {
                                        color:$primario3;
                                    }
                                }
                            }
                        }
                    }
                }

                &:first-of-type {
                    ul {
                        &:nth-of-type(2) {
                            //border-right: none;
                        }
                    }
                }
            }
            .vertodos {
                display: none;
                @media(max-width: $screen-lg) {
                    display: inline-block;
                    @include fuente($font-first,$font-light);
                    font-size: $font-xxs;
                    margin-left: 10px;
                    color: $gris7;
                    background-color: $primario2;
                    padding: 0 6px;
                    border-radius: 6px;
                    margin: 10px 5px;
                }
            }

            [class*="gol-icon-"]::before {
                display: block;
                text-align: center;
                font-size: $font-xl;
                margin-bottom: 5px;
                opacity: 1;
                transition: all 500ms;
                @media(max-width: $screen-lg) {
                    display: none;
                }
            }

            .djv-icon-cromos::before {
                font-size: 38px !important;
                margin-bottom: -5px !important;
            }

            .encabezados-submenu {
                @include fuente($font-first,$font-medium);
                text-decoration: underline;
                margin-bottom: 10px;
                position: relative;
                a{
                    color:$primario3;
                }
                @media(max-width: $screen-lg) {
                    text-decoration: none;
                    padding-bottom: 5px;
                    padding-top: 5px;
                    margin-bottom: 15px;
                    margin-top: 0;
                    border-bottom: 1px solid $gris2;
                    color: $gris7;
                    @include fuente($font-first,$font-regular);
                    font-size: $font-xs;
                    .menu-toggle-icon{
                        &::after {
                            font-family: 'comunes';
                            font-style: normal;
                            font-weight: normal;
                            line-height: 1;
                            -webkit-font-smoothing: antialiased;
                            -moz-osx-font-smoothing: grayscale;
                            content: '\004e';
                            position: absolute;
                            right: 10px;
                            font-size: $font-xxs;
                            z-index: 2;
                            top: 10px;
                        }
                        &.djv-icon-arrow-down{
                            &::before {
                                display: none;
                            }
                        }
                    }
                    &.desplegado {
                        background-color: rgba(1,1,1,0.05);
                        .menu-toggle-icon{
                            &::after {
                                content: '\004d';
                            }
                        }
                    }
                }
            }
        }
    }
}

.recogido {
    #main-menu {
        min-width: 200px;
        .djv-container{
            min-width: 200px;
            .djv-main-menu {
                width: 85%;
                font-size: $font-xxs*0.9;
                min-width: 200px;

                [class*="gol-icon-"]::before {
                    font-size: 0;
                    opacity: 0;
                    margin-top: -2px;
                    margin-bottom: 0;
                }

                .djv-icon-cromos::before {
                    font-size: 0 !important;
                    margin-bottom: -0px !important;
                }

                > li {
                    margin-left: 1%;
                    margin-right: 1%;

                    &:hover {
                        @media(min-width: $screen-lg) {
                            color: $primario3;

                            [class*="gol-icon-"]::before {
                                margin-top: -10px;
                                margin-bottom: 15px;
                            }

                            .djv-icon-cromos::before {
                                font-size: 0 !important;
                                margin-bottom: 15px !important;
                            }
                        }
                    }
                }
            }
        }
    }
}
.djv-nav-drop-columns-column-2{
    margin: 0 14px;
    padding: 0px;
    column-count: 2;
    @media(max-width: $screen-md){
        width: 100%;
        column-count: 1;
        margin: inherit;
        .xs-hidden{
            display: none;
        }
    }
}
