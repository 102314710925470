#cart {
    #notifications {
        display: block;
        margin-left: auto;
        margin-right: auto;
    }

    #content-wrapper {
        @include djv-container();


    }
    #main {
        display: grid;
        grid-template-columns: 75% 25%;
        gap: 0;
        @media(max-width: $screen-md) {
            display: block;
        }
        > div {
            @media(max-width: $screen-md) {
                box-shadow: 2px 2px 8px rgba(0,0,0,0);
            }
        }
    }
    .cart-advisor{
        display: none;
        background-color: $primario6;
        padding: 6px 16px;
        text-align: center;
        color: white;
    }
    h1 {
        padding: 16px;
        border-bottom: 1px solid lighten($gris2, 15%);
        text-transform: uppercase;
        font-size: 23px;
    }

    .cart-overview {
        margin-right: 25px;
        @media(max-width: $screen-md) {
            margin-right: 0;
        }
        .body {
            padding: 15px 0px;

            ul {
                padding-left: 0;
            }
        }
        .cart-header-items{
            list-style: none;
            display: grid;
            grid-template-columns: 65% 35%;
            border-bottom: 1px solid $gris3;
            color: $gris7;
            .cart-header-items-description{}
            .cart-header-items-total{
                text-align: right;
                margin-right: 50px;
            }
        }
        .cart-item {
            list-style: none;
            display: grid;
            grid-template-columns: 65% 35%;
            gap: 16px;
            padding-bottom: 16px;
            border-bottom: 1px dashed $gris2;
            padding-top: 16px;
            font-size: $font-bmd;
            @media(max-width: $screen-lg) {
                display: block;
                margin-bottom: 20px;
            }
            a {
                color: inherit;
            }
            .secction-label{
                @include fuente($font-first,$font-medium);
                font-size: $font-smd;
            }

            .cart-detailed-product-line-detail {
                display: grid;
                grid-template-columns: 30% 70%;
                gap: 16px;
                @media(max-width: $screen-md){
                    display: block;
                }

                .cart-detailed-product-line-detail-image {
                    @media(max-width: $screen-md){
                        float: left;
                        width: 40%;
                        margin-right: 15px;
                        margin-bottom: 15px;
                    }
                    img {
                        width: 100%;
                    }
                }

                .cart-detailed-product-line-detail-tex {
                    @media(max-width: $screen-md){

                    }
                    > span {
                        display: block;
                    }
                    .product-price{
                        @include fuente($font-first,$font-bold);
                        font-size: $font-slg;
                        small{
                            @include fuente($font-first,$font-regular);
                            font-size: $font-xxs;
                            @media(max-width: $screen-md){
                                display: block;
                            }
                        }
                    }

                    .product-discount {
                        position: relative;
                        @media(max-width: $screen-md){

                        }
                        .regular-price {
                            text-decoration: line-through;
                            color: $gris3;
                        }

                        .discount-percentage {
                            left: -60px;
                            @media(max-width: $screen-md){
                                top: -80px;
                                left: 80px;
                            }
                        }
                    }
                    .cart-resume-selected-attributes{
                        @media(max-width: $screen-sm){
                            display: block;
                            clear: both;
                        }
                        .cart-resume-attributes{
                            margin-left: 20px;
                            list-style: none;
                            margin-bottom: 10px;
                            li{
                                line-height: $font-xs + 1;
                                font-size: $font-xs;
                                label{
                                    margin-bottom: 0;
                                }
                            }
                        }
                    }
                }
            }
            .cart-detailed-product-line-modify {
                display: grid;
                grid-template-columns: 45% 45% 20px;
                text-align: center;
                @media(max-width: $screen-lg){
                    margin-top: 5px;
                    padding-top: 15px;
                    border-top: 1px dashed $gris3;
                    clear: both;
                }
                .totalUnidades{
                    font-size: 15px;
                    margin-top: 3px;
                }
                .product-price {
                    text-align: center;
                    font-size: $font-slg;
                }
                .cart-edit-personalization{
                    display: none;
                    padding-top: 10px;
                    color: $primario1;
                    font-size: $font-xs;
                    &::before{
                        font-size: $font-sm;
                    }
                }
                .djv-icon-delete {
                    text-align: center;
                    font-size: $font-sm;
                    color:red;
                    padding-top: 6px;
                }
                .product-quantity{
                    border-bottom: 0;
                    .product-add-to-cart-quantity{
                        width: 135px;
                        #quantity_wanted {
                            width: 55px;
                        }
                    }
                }
            }
        }
    }
    .djv-cart-promo{
        font-size: $font-xs;
        margin-top: 10px;
        padding: 8px 16px;
        border-bottom: 1px solid #dddddd;
        .djv-icon-calidad::before{
            margin-right: 8px;
        }
        .djv-cart-promo-item{
            display: grid;
            grid-template-columns: 50% 40% 10%;
            color:$primario3;
            .djv-icon-close{
                color: $complementario2;
            }
        }
    }
    .cart-detailed-totals {
        border: 1px solid $gris2;
        border-top: none;
        .cart-subtotals {
            padding:8px 16px;
            border-bottom: 1px solid $gris2;
            .products {
                display: none;
            }

            .shipping {
                display: grid;
                grid-template-columns: auto auto;
                padding-bottom: 10px;
                margin-bottom: 10px;

                .value {
                    text-align: right;
                }
            }

            .discount {
                display: grid;
                grid-template-columns: auto auto;
                padding-bottom: 10px;
                margin-bottom: 10px;

                .value {
                    text-align: right;
                    &::before{
                        content:"-";
                    }
                }
            }

            .tax {
                display: grid;
                grid-template-columns: auto auto;
                padding-bottom: 10px;
                margin-bottom: 10px;

                .value {
                    text-align: right;
                }
            }
        }

        .cart-total {
            display: grid;
            grid-template-columns: auto auto;
            grid-gap: 10px;
            align-items: center;
            font-size: $font-smd;
            padding: 8px 16px;
            color: black;
            .label{
                text-align: left;
            }
            .value {
                text-align: right;
                font-size: $font-blg;
                font-weight: 700;
            }
        }
    }

    .tramitar-pedido-btn {
        @include btn-hover-animated($complementario1,white,2px,4px,10px);
        margin: 10px auto;
        display: block;
        width: 100%;
        font-size: $font-lg;
    }
    #block-reassurance {
        padding: 16px;
        border-bottom: 1px solid $gris2;

        ul {
            padding-left: 30px;

            li {
                list-style: none;
                margin-bottom: 16px;

                img {
                    margin-left: -30px;
                    filter: sepia(100%) brightness(310%) hue-rotate(60deg) saturate(300%);
                }
            }
        }
    }
    #items-subtotal {
        //padding: 16px;
        overflow: auto;
        border: 1px solid $gris2;
        border-top: none;
        > * {
            padding:8px 16px;
        }
        .cart-title{
            background-color: $gris2;
            color: black;
            text-transform: uppercase;
            font-size: $font-sm;
        }
        .js-subtotal{
            float: left;
        }
        span:not(.js-subtotal) {
            text-align: right;
            display: inline-block;
            &:last-of-type{
                float: right;
            }
        }
    }
    .continue-shopping {
        width: 230px;
        margin-left: inherit;
        margin-top: 10px;
        padding-top: 6px;
        padding-left: 20px;
        position: relative;
        @media(max-width: $screen-md) {
            display: block;
            margin-left: auto;
            margin-right: auto;
            margin-top: 20px;
        }

        i {
            position: absolute;
            left: 0px;
            top: 8px;
        }
    }
    &.cart-empty {
        .checkout {
            display: none;
        }

        .cart-overview {
            .body::after {
                content: "No hay articulos seleccionados";
            }
        }
    }
}
